<template>
  <v-navigation-drawer
    width="910px"
    style="height: 100%; position: fixed"
    right
    v-model="show"
    app
    temporary
  >
    <v-col>
      <v-row class="justify-end flex-nowrap mt-0">
        <v-col class="pb-0">
          <HeaderTitle
            v-if="status == 'Μη δημοσιευμένο'"
            fontFamilyTitle="CenturyGothicBold"
            :title="$tc('editNonPublishedProperty', 2)"
          />
          <HeaderTitle v-else :title="$tc('processingOfProperty', 2)" />
        </v-col>
        <v-col class="flex-grow-0 pb-0">
          <v-icon
            @click="closeHandler('simple')"
            class="primaryColorOnHover xClass"
          >
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
      <v-row class="navbar justify-end">
        <v-col>
          <v-tabs show-arrows="always" v-model="activeTab">
            <v-tabs-slider style="height: 6px" color="error" />
            <v-tab class="text-capitalize textDecorationBold tabsText">
              {{ $t("features") }}
              <InfoField
                v-if="errorOnCharacteristics"
                color="#ff0000"
                :hoverText="$t('reqFieldsprg')"
              />
            </v-tab>
            <v-tab class="text-capitalize textDecorationBold tabsText">
              {{ $t("location") }}
              <InfoField
                v-if="errorOnLocation"
                color="#ff0000"
                :hoverText="$t('reqFieldsprg')"
              />
            </v-tab>
            <v-tab class="text-capitalize textDecorationBold tabsText">
              Marketing
              <InfoField
                v-if="errorOnMarketing"
                color="#ff0000"
                :hoverText="$t('reqFieldsprg')"
              />
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col class="flex-grow-0">
          <v-row class="flex-nowrap">
            <v-col class="flex-grow-0 pl-0 pr-1">
              <v-btn
                class="btn-custom"
                key="fBtn1"
                outlined
                color="primary"
                :loading="loader"
                @click="saveHandler"
              >
                <Icon
                  icon="eva:save-outline"
                  height="25"
                  outline
                  color="#263573"
                />
              </v-btn>
            </v-col>
            <v-col class="flex-grow-0 pl-1 pr-1">
              <v-btn
                class="btn-custom"
                key="fBtn2"
                outlined
                color="primary"
                @click="onViewEstate"
              >
                <Icon
                  icon="clarity:eye-show-line"
                  height="25"
                  outline
                  color="#263573"
                />
              </v-btn>
            </v-col>
            <v-col class="flex-grow-0 pl-1">
              <v-btn
                elevation="5"
                align="center"
                class="btn"
                color="error"
                v-if="status == 'Μη δημοσιευμένο'"
                @click="publishEstate"
                :loading="publishLoading"
              >
                {{ $t("publish") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0 pr-0 pl-0">
          <v-divider />
        </v-col>
      </v-row>
      <v-row
        :class="
          status == 'Μη δημοσιευμένο' ? 'overFlowRowLong' : 'overFlowRowShort'
        "
      >
        <v-col>
          <v-row>
            <v-col align="left" class="pl-6 pr-6 pt-0">
              <v-tabs-items v-model="activeTab">
                <v-tab-item>
                  <v-row class="mt-3">
                    <v-col>
                      <span
                        class="textDecorationBold text-capitalize"
                        style="font-size: 16px"
                      >
                        {{ $t("basicInfo") }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pr-0 pl-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="mt-2" cols="12" sm="6" md="6" lg="6">
                      <v-row
                        class="textCenter"
                        v-if="this.data.Category && this.data.Task"
                      >
                        {{ this.data.Category }} {{ $t("propTo") }}
                        {{ this.data.Task }}
                      </v-row>
                    </v-col>
                    <v-col
                      class="mt-2"
                      cols="12"
                      sm="6"
                      md="6"
                      lg="6"
                      v-if="checkIfExist('Κενό')"
                    >
                    </v-col>
                    <v-col class="mt-2" cols="12" sm="6" md="6" lg="6">
                      <v-select
                        v-model="type"
                        :items="getSubCategoryList"
                        :label="$t('selectPropSubcat')"
                        item-text="text"
                        item-value="val"
                      >
                        <template v-slot:selection="{ item }">
                          <v-row class="flex-grow-0">
                            <v-col style="display: flex; align-items: center">
                              {{ $t(item.text) }}
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col
                                  style="display: flex; align-items: center"
                                >
                                  {{ $t(item.text) }}
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      class="mt-2"
                      cols="12"
                      sm="6"
                      md="6"
                      lg="6"
                      v-if="checkIfExist('Είδος')"
                    >
                      <v-select
                        v-model="kind"
                        :items="kindList"
                        :label="$t('kind')"
                        item-text="text"
                        item-value="val"
                      >
                        <template v-slot:selection="{ item }">
                          <v-row class="flex-grow-0">
                            <v-col style="display: flex; align-items: center">
                              {{ $t(item.text) }}
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col
                                  style="display: flex; align-items: center"
                                >
                                  {{ $t(item.text) }}
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      lg="6"
                      v-if="checkIfExist('Αέρας')"
                    >
                      <v-text-field
                        v-model="air"
                        :label="$t('air')"
                        clearable
                        color="primary"
                        :suffix="$t('sqftTable')"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col
                      class="mt-2"
                      cols="12"
                      sm="6"
                      md="6"
                      lg="6"
                      v-if="checkIfExist('Όροφος')"
                    >
                      <v-select
                        v-model="floor"
                        multiple
                        :items="floorsList"
                        class="text-capitalize"
                        :label="checkFloorNecessary"
                        :error-messages="floorErrorMessages"
                        item-value="val"
                        return-object
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-row>
                            <v-col>
                              <span v-if="index == 0">
                                {{ $t(item.text) }}
                              </span>
                              <span
                                v-if="floor.length > 1 && index == 0"
                                class="grey--text text-caption"
                              >
                                + {{ floor.length - 1 }}
                                {{ $t("moreSelected") }}
                              </span>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col
                                  style="display: flex; align-items: center"
                                >
                                  {{ $t(item.text) }}
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-text-field
                        v-model="price"
                        :label="$t('priceTable') + '*'"
                        clearable
                        color="primary"
                        suffix="EUR (€)"
                        :error-messages="priceErrorMessages"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-checkbox
                        style="margin: 22px 0px -30px 0px"
                        v-model="negotiable_price"
                        :label="$t('priceNegotiable')"
                      />
                    </v-col>

                    <v-col class="mt-2" cols="12" sm="6" md="6" lg="6">
                      <v-text-field
                        v-model="objectivePrice"
                        :label="$t('objectiveValue')"
                        clearable
                        color="primary"
                        suffix="EUR (€)"
                      />
                    </v-col>
                    <v-col class="mt-2" cols="12" sm="6" md="6" lg="6">
                      <v-text-field
                        v-model="agentPrice"
                        :label="$t('valuationAgent')"
                        clearable
                        color="primary"
                        suffix="EUR (€)"
                        hide-details="auto"
                      />
                    </v-col>

                    <v-col class="mt-2" cols="12" sm="6" md="6" lg="6">
                      <v-text-field
                        v-model="unit"
                        :label="$t('areasqm') + '*'"
                        clearable
                        color="primary"
                        :suffix="$t('sqftTable')"
                        :error-messages="unitErrorMessages"
                        hide-details="auto"
                      />
                    </v-col>

                    <v-col
                      class="mt-2"
                      cols="12"
                      sm="6"
                      md="6"
                      lg="6"
                      v-if="checkIfExist('Εμβαδόν οικοπέδου')"
                    >
                      <v-text-field
                        v-model="plot_unit"
                        :label="$t('plotAreasqm')"
                        clearable
                        color="primary"
                        :suffix="$t('sqftTable')"
                        hide-details="auto"
                      />
                    </v-col>

                    <v-col
                      class="mt-2"
                      cols="12"
                      sm="6"
                      md="6"
                      lg="6"
                      v-if="checkIfExist('Διαθεσιμότητα')"
                    >
                      <v-select
                        v-model="currentUse"
                        :items="availabilityList"
                        :label="$t('availability')"
                        item-text="text"
                        item-value="val"
                      >
                        <template v-slot:selection="{ item }">
                          <v-row class="flex-grow-0">
                            <v-col style="display: flex; align-items: center">
                              {{ $t(item.text) }}
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col
                                  style="display: flex; align-items: center"
                                >
                                  {{ $t(item.text) }}
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col class="mt-2" cols="12" sm="6" md="6" lg="6">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="currentUseDateFromFormatted"
                            :label="$t('availableFrom')"
                            clearable
                            color="primary"
                            hide-details="auto"
                            @blur="
                              currentUseDateFrom = parseDate(
                                currentUseDateFromFormatted,
                                'currentUseDateFrom'
                              )
                            "
                          >
                            <template v-slot:append>
                              <v-icon v-bind="attrs" v-on="on">
                                mdi-calendar
                              </v-icon>
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          :locale="locale == 'gr' ? 'el' : locale"
                          v-model="currentUseDateFrom"
                          scrollable
                          @input="menu = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                      class="mt-2"
                      cols="12"
                      sm="6"
                      md="6"
                      lg="6"
                      v-if="checkIfExist('Επαγγελματκή χρήση')"
                    >
                      <v-checkbox
                        v-model="proUse"
                        :label="$t('professionalUse')"
                      />
                    </v-col>
                    <v-col
                      class="mt-2"
                      cols="12"
                      sm="6"
                      md="6"
                      lg="6"
                      v-if="checkIfExist('Εκτός σχεδίου')"
                    >
                      <v-checkbox v-model="outOfPlan" :label="$t('offPlan')" />
                    </v-col>

                    <v-col
                      class="mb-3"
                      cols="12"
                      sm="6"
                      md="6"
                      lg="6"
                      v-if="checkIfExist('Επίπεδα')"
                    >
                      <MinusPlusField
                        fontSize="15px"
                        :label="$t('levels')"
                        fieldName="levels"
                        :val="Number(levels)"
                        :plusMinusChangeHandler="updatePlusMinus"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      xs="6"
                      sm="6"
                      md="6"
                      lg="6"
                      v-if="checkIfExist('Αριθμός Χώρων')"
                      class="mb-3"
                    >
                      <MinusPlusField
                        fontSize="15px"
                        class="text-capitalize"
                        :label="$t('numberOfPlaces')"
                        fieldName="numberOfPlaces"
                        :val="Number(numberOfPlaces)"
                        :plusMinusChangeHandler="updatePlusMinus"
                      />
                    </v-col>
                    <v-col
                      class="mt-2 mb-3"
                      cols="12"
                      sm="6"
                      md="6"
                      lg="6"
                      v-if="checkIfExist('Αποθήκη')"
                    >
                      <v-checkbox
                        v-model="storage"
                        :label="$t('warehouse')"
                        style="font-size: 15px"
                      />
                    </v-col>
                    <v-col
                      style="margin-top: 1px"
                      cols="12"
                      xs="6"
                      sm="6"
                      md="6"
                      lg="6"
                      v-if="checkIfExist('Μπαλκόνια')"
                      class="mb-3"
                    >
                      <MinusPlusField
                        fontSize="15px"
                        class="text-capitalize"
                        :label="$t('balconies')"
                        fieldName="balconies"
                        :val="Number(balconies)"
                        :plusMinusChangeHandler="updatePlusMinus"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      lg="6"
                      v-if="checkIfExist('Υπνοδωματια')"
                      class="mb-3"
                    >
                      <MinusPlusField
                        fontSize="15px"
                        class="text-capitalize"
                        :label="$t('bedrooms') + '*'"
                        fieldName="bedrooms"
                        :val="Number(bedrooms)"
                        :plusMinusChangeHandler="updatePlusMinus"
                      />
                    </v-col>
                    <v-col
                      class="mt-2 mb-3"
                      cols="12"
                      sm="6"
                      md="6"
                      lg="6"
                      v-if="checkIfExist('Εμβαδόν Μπαλκονιών') && balconies > 0"
                    >
                      <v-text-field
                        v-model="balconies_in_sqm"
                        :label="$t('sqftBalcony')"
                        clearable
                        color="primary"
                        style="
                          margin-top: -15px;
                          padding-bottom: 20px;
                          font-size: 15px;
                        "
                        :suffix="$t('sqftTable')"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col
                      class="mb-3"
                      cols="12"
                      sm="6"
                      md="6"
                      lg="6"
                      v-if="checkIfExist('Κουζίνες')"
                    >
                      <MinusPlusField
                        fontSize="15px"
                        :label="$t('kitchens')"
                        fieldName="kitchens"
                        :val="Number(kitchens)"
                        :plusMinusChangeHandler="updatePlusMinus"
                      />
                    </v-col>
                    <v-col
                      class="mb-3"
                      cols="12"
                      sm="6"
                      md="6"
                      lg="6"
                      v-if="checkIfExist('Σαλόνια')"
                    >
                      <MinusPlusField
                        fontSize="15px"
                        class="text-capitalize"
                        :label="$t('livingRooms')"
                        fieldName="livingRooms"
                        :val="Number(livingRooms)"
                        :plusMinusChangeHandler="updatePlusMinus"
                      />
                    </v-col>
                    <v-col
                      class="mb-3"
                      cols="12"
                      sm="6"
                      md="6"
                      lg="6"
                      v-if="checkIfExist('Μπάνια')"
                    >
                      <MinusPlusField
                        fontSize="15px"
                        class="text-capitalize"
                        :label="$t('bathrooms')"
                        fieldName="bathrooms"
                        :val="Number(bathrooms)"
                        :plusMinusChangeHandler="updatePlusMinus"
                      />
                    </v-col>
                    <v-col
                      class="mt-2"
                      cols="12"
                      sm="6"
                      md="6"
                      lg="6"
                      v-if="checkIfExist('Από κατασκευαστές')"
                    >
                      <v-checkbox
                        style="font-size: 15px"
                        v-model="fromManufacturer"
                        :label="$t('fromManufacturers')"
                      />
                    </v-col>
                    <v-col
                      class="mb-3"
                      cols="12"
                      sm="6"
                      md="6"
                      lg="6"
                      v-if="checkIfExist('wc')"
                    >
                      <MinusPlusField
                        fontSize="15px"
                        :label="$t('wc')"
                        fieldName="wc"
                        :val="Number(wc)"
                        :plusMinusChangeHandler="updatePlusMinus"
                      />
                    </v-col>
                    <div class="mt-10">
                      <!--  <span class="bold"> {{ $t("moreAreas") }} </span> 
               <v-divider>  </v-divider>  -->
                    </div>
                    <!--  </v-col>  -->
                  </v-row>
                  <v-row v-if="checkIfExist('Επιπλέον Χώροι')">
                    <v-col class="mt-2" cols="12" sm="12" md="12" lg="12">
                      <span class="textDecorationBold">
                        {{ $t("moreAreas") }}
                      </span>
                      <v-divider />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-checkbox
                        v-model="storage_room"
                        :label="$t('warehouse')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-checkbox v-model="attic" :label="$t('attic')" />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-text-field
                        :disabled="!storage_room"
                        v-model="storage_room_sqm"
                        :label="$t('warehouseSqft')"
                        clearable
                        color="primary"
                        style="margin-top: -5px; padding-bottom: 20px"
                        :suffix="$t('sqftTable')"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-text-field
                        :disabled="!attic"
                        v-model="attic_in_sqm"
                        :label="$t('atticSqft')"
                        clearable
                        color="primary"
                        style="margin-top: -5px; padding-bottom: 20px"
                        :suffix="$t('sqftTable')"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-checkbox
                        v-model="service_room"
                        :label="$t('serviceRoom')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-checkbox
                        v-model="owned_terrace"
                        :label="$t('privateTerrace')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-text-field
                        :disabled="!service_room"
                        v-model="service_room_in_sqm"
                        :label="$t('serviceRoomSqft')"
                        clearable
                        color="primary"
                        style="margin-top: -5px; padding-bottom: 20px"
                        :suffix="$t('sqftTable')"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-text-field
                        :disabled="!owned_terrace"
                        v-model="owned_terrace_in_sqm"
                        :label="$t('privateTerraceSqft')"
                        clearable
                        color="primary"
                        style="margin-top: -5px; padding-bottom: 20px"
                        :suffix="$t('sqftTable')"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-checkbox v-model="playroom" :label="$t('playroom')" />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6" />
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-text-field
                        :disabled="!playroom"
                        v-model="playroom_in_sqm"
                        :label="$t('playroomSqft')"
                        clearable
                        color="primary"
                        style="margin-top: -5px; padding-bottom: 20px"
                        :suffix="$t('sqftTable')"
                        hide-details="auto"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-if="checkIfExist('Οικονομικά ακινήτου')">
                    <v-col class="mt-2" cols="12" sm="12" md="12" lg="12">
                      <span class="textDecorationBold">
                        {{ $t("propertyFinancials") }}
                      </span>
                      <v-divider />
                    </v-col>
                    <v-col cols="12" xs="6" sm="6" md="6" lg="6">
                      <v-row class="checkboxRow">
                        <v-col class="mb-6">
                          <v-menu
                            ref="menu"
                            v-model="menu3"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="payment_in_advance_date_formatted"
                                :label="$t('depositDate')"
                                clearable
                                color="primary"
                                hide-details="auto"
                                @blur="
                                  payment_in_advance_date = parseDate(
                                    payment_in_advance_date_formatted,
                                    'payment_in_advance_date'
                                  )
                                "
                              >
                                <template v-slot:append>
                                  <v-icon v-bind="attrs" v-on="on">
                                    mdi-calendar
                                  </v-icon>
                                </template>
                              </v-text-field>
                            </template>
                            <v-date-picker
                              :locale="locale == 'gr' ? 'el' : locale"
                              v-model="payment_in_advance_date"
                              scrollable
                              @input="menu3 = false"
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-text-field
                        v-model="payment_in_advance_amount"
                        :label="$t('depositAmount')"
                        clearable
                        color="primary"
                        suffix="EUR (€)"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-text-field
                        v-model="monthly_rent_price"
                        :label="
                          $t('monthlyRent') +
                          (suitable_for_investment == 'Δεν έιναι μισθωμένο' ||
                          suitable_for_investment == null
                            ? ''
                            : '*')
                        "
                        clearable
                        color="primary"
                        suffix="EUR (€)"
                        hide-details="auto"
                        :error-messages="monthlyRentPriceErrorMessages"
                      />
                    </v-col>
                    <v-col cols="12" xs="6" sm="6" md="6" lg="6">
                      <v-row class="checkboxRow">
                        <v-col class="mb-6">
                          <v-menu
                            ref="menu"
                            v-model="menu2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="date_of_lease_expiration_formatted"
                                :label="
                                  $t('agreementEndDate') +
                                  (suitable_for_investment ==
                                    'Δεν έιναι μισθωμένο' ||
                                  suitable_for_investment == null
                                    ? ''
                                    : '*')
                                "
                                clearable
                                color="primary"
                                hide-details="auto"
                                :error-messages="dateOfLeaseErrorMessages"
                                @blur="
                                  date_of_lease_expiration = parseDate(
                                    date_of_lease_expiration_formatted,
                                    'date_of_lease_expiration'
                                  )
                                "
                              >
                                <template v-slot:append>
                                  <v-icon v-bind="attrs" v-on="on">
                                    mdi-calendar
                                  </v-icon>
                                </template>
                              </v-text-field>
                            </template>
                            <v-date-picker
                              :locale="locale == 'gr' ? 'el' : locale"
                              v-model="date_of_lease_expiration"
                              scrollable
                              @input="menu2 = false"
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-text-field
                        v-model="shared_fees_monthly_price"
                        :label="$t('monthlyFees')"
                        clearable
                        color="primary"
                        :suffix="$t('eurosPerMonth')"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col cols="12" xs="6" sm="6" md="6" lg="6">
                      <v-text-field
                        v-model="yearly_tax"
                        :label="$t('yearlyTaxes')"
                        clearable
                        color="primary"
                        suffix="EUR (€)"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col cols="12" xs="6" sm="6" md="6" lg="6">
                      <v-text-field
                        v-model="yearly_yield"
                        :label="$t('yearlyReturn')"
                        clearable
                        color="primary"
                        suffix="%"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col cols="12" xs="6" sm="6" md="6" lg="6">
                      <v-text-field
                        v-model="yearly_income_before_tax"
                        :label="$t('yearlyReturnWithoutTaxes')"
                        clearable
                        color="primary"
                        suffix="EUR (€)"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col class="mt-4" cols="12" xs="6" sm="6" md="6" lg="6">
                      <v-checkbox v-model="mortgage" :label="$t('mortgage')" />
                    </v-col>
                    <v-col cols="12" xs="6" sm="6" md="6" lg="6">
                      <v-select
                        v-model="suitable_for_investment"
                        :items="investmentList"
                        :label="$t('investment')"
                        item-text="text"
                        item-value="val"
                        clearable
                      >
                        <template v-slot:selection="{ item }">
                          <v-row class="flex-grow-0">
                            <v-col style="display: flex; align-items: center">
                              {{ $t(item.text) }}
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col
                                  style="display: flex; align-items: center"
                                >
                                  {{ $t(item.text) }}
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" xs="6" sm="6" md="6" lg="6">
                      <v-text-field
                        v-model="bank_name"
                        :label="$t('bank')"
                        clearable
                        color="primary"
                        style="margin-top: -5px; padding-bottom: 20px"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col cols="12" xs="6" sm="6" md="6" lg="6">
                      <v-text-field
                        v-model="amount_or_percentage"
                        :label="$t('amountPercentage')"
                        clearable
                        color="primary"
                        style="margin-top: -5px; padding-bottom: 20px"
                        suffix="EUR (€) | %"
                        hide-details="auto"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-if="checkIfExist('Περισσότερα στοιχεία οικοπέδου')">
                    <v-col class="mt-2" cols="12" sm="12" md="12" lg="12">
                      <span class="textDecorationBold">
                        {{ $t("morePlotInfo") }}
                      </span>
                      <v-divider />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-text-field
                        v-model="dimensions"
                        :label="$t('dimensions')"
                        color="primary"
                        :suffix="$t('meters')"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-text-field
                        v-model="can_build"
                        :label="$t('build')"
                        color="primary"
                        :suffix="$t('sqftTable')"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-text-field
                        v-model="coverage_coefficient"
                        :label="$t('coverageRatio')"
                        color="primary"
                        :suffix="$t('sqftTable')"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-text-field
                        v-model="structure_coefficient"
                        :label="$t('structureFactor')"
                        color="primary"
                        :suffix="$t('sqftTable')"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-text-field
                        v-model="benefits"
                        :label="$t('benefits')"
                        color="primary"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                      <v-select
                        v-model="chooseAViewSelected"
                        :items="chooseAViewList"
                        multiple
                        :label="$t('chooseAView')"
                        item-text="text"
                        item-value="val"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-row>
                            <v-col>
                              <span v-if="index == 0">
                                {{ $t(item.text) }}
                              </span>
                              <span
                                v-if="
                                  chooseAViewSelected.length > 1 && index == 0
                                "
                                class="grey--text text-caption"
                              >
                                + {{ chooseAViewSelected.length - 1 }}
                                {{ $t("moreSelected") }}
                              </span>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col
                                  style="display: flex; align-items: center"
                                >
                                  {{ $t(item.text) }}
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                      <v-select
                        v-model="zoneSelected"
                        :items="zoneList"
                        multiple
                        :label="$t('selectzone')"
                        item-text="text"
                        item-value="val"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-row>
                            <v-col>
                              <span v-if="index == 0">
                                {{ $t(item.text) }}
                              </span>
                              <span
                                v-if="zoneSelected.length > 1 && index == 0"
                                class="grey--text text-caption"
                              >
                                + {{ zoneSelected.length - 1 }}
                                {{ $t("moreSelected") }}
                              </span>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col
                                  style="display: flex; align-items: center"
                                >
                                  {{ $t(item.text) }}
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col class="py-0" cols="12" xs="12" sm="12" md="6" lg="6">
                      <v-select
                        v-model="selectPositionSelected"
                        :items="selectPositionList"
                        multiple
                        :label="$t('selectPosition')"
                        item-text="text"
                        item-value="val"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-row>
                            <v-col>
                              <span v-if="index == 0">
                                {{ $t(item.text) }}
                              </span>
                              <span
                                v-if="
                                  selectPositionSelected.length > 1 &&
                                  index == 0
                                "
                                class="grey--text text-caption"
                              >
                                + {{ selectPositionSelected.length - 1 }}
                                {{ $t("moreSelected") }}
                              </span>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col
                                  style="display: flex; align-items: center"
                                >
                                  {{ $t(item.text) }}
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col class="py-0" cols="12" xs="12" sm="12" md="6" lg="6">
                      <v-select
                        v-model="orientationSelected"
                        :items="orientationList"
                        multiple
                        :label="$t('orientation')"
                        item-value="val"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-row>
                            <v-col>
                              <span v-if="index == 0">
                                {{ $t(item.text) }}
                              </span>
                              <span
                                v-if="
                                  orientationSelected.length > 1 && index == 0
                                "
                                class="grey--text text-caption"
                              >
                                + {{ orientationSelected.length - 1 }}
                                {{ $t("moreSelected") }}
                              </span>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col
                                  style="display: flex; align-items: center"
                                >
                                  {{ $t(item.text) }}
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="checkIfExist('Θέρμανση & κατανάλωση')">
                    <v-col>
                      <div class="mt-10">
                        <span class="textDecorationBold">
                          {{ $t("heatAndConsuption") }}
                        </span>
                        <v-divider />
                      </div>
                      <v-row class="mt-2">
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-row class="checkboxRow">
                            <v-col class="mb-6">
                              <v-select
                                v-model="energy_class"
                                :items="energyClassList"
                                :label="$t('chooseEnergyClass') + '* ...'"
                                :error-messages="energyClassErrorMessages"
                                item-text="text"
                                item-value="val"
                              >
                                <template v-slot:selection="{ item }">
                                  <v-row class="flex-grow-0">
                                    <v-col
                                      style="display: flex; align-items: center"
                                    >
                                      {{ $t(item.text) }}
                                    </v-col>
                                  </v-row>
                                </template>
                                <template v-slot:item="{ item }">
                                  <v-list>
                                    <v-list-item-title>
                                      <v-row>
                                        <v-col
                                          style="
                                            display: flex;
                                            align-items: center;
                                          "
                                        >
                                          {{ $t(item.text) }}
                                        </v-col>
                                      </v-row>
                                    </v-list-item-title>
                                  </v-list>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="mt-2">
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-checkbox
                            v-model="autonomous_heating"
                            :label="$t('independentHeating')"
                          />
                          <v-checkbox
                            v-model="air_conditioning"
                            :label="$t('acUnit')"
                          />
                          <v-checkbox
                            v-model="solar_water_heater"
                            :label="$t('solarBoiler')"
                          />
                          <v-checkbox
                            v-model="fireplace"
                            :label="$t('fireplace')"
                          />
                          <v-checkbox v-model="gas" :label="$t('liquidGas')" />
                          <v-checkbox v-model="stove" :label="$t('heater')" />
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-checkbox
                            v-model="central_heating"
                            :label="$t('centralHeating')"
                          />
                          <v-checkbox
                            v-model="under_floor_heating"
                            :label="$t('underfloorHeating')"
                          />
                          <v-checkbox
                            v-model="natural_gas"
                            :label="$t('naturalGas')"
                          />
                          <v-checkbox v-model="oil" :label="$t('oil')" />
                          <v-checkbox
                            v-model="electrical_heating"
                            :label="$t('electricity')"
                          />
                          <v-checkbox
                            v-model="heat_accumulator"
                            :label="$t('heatAccumulator')"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-if="checkIfExist('Κατασκευή')">
                    <v-col>
                      <div class="mt-10">
                        <span class="textDecorationBold">
                          {{ $t("construction") }}
                        </span>
                        <v-divider />
                      </div>
                      <v-row class="mt-2">
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-row class="checkboxRow">
                            <v-col class="mb-6">
                              <v-select
                                v-model="buildingStatusSelected"
                                :items="buildingStatusList"
                                :label="$t('status')"
                                item-text="text"
                                item-value="val"
                              >
                                <template v-slot:selection="{ item }">
                                  <v-row class="flex-grow-0">
                                    <v-col
                                      style="display: flex; align-items: center"
                                    >
                                      {{ $t(item.text) }}
                                    </v-col>
                                  </v-row>
                                </template>
                                <template v-slot:item="{ item }">
                                  <v-list>
                                    <v-list-item-title>
                                      <v-row>
                                        <v-col
                                          style="
                                            display: flex;
                                            align-items: center;
                                          "
                                        >
                                          {{ $t(item.text) }}
                                        </v-col>
                                      </v-row>
                                    </v-list-item-title>
                                  </v-list>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="mt-2">
                        <v-col cols="12" xs="12" sm="6" md="4" lg="4">
                          <v-text-field
                            v-model="buildYear"
                            height="30"
                            dense
                            :label="
                              this.buildingStatusSelected == 'Υπο κατασκευή'
                                ? $t('yearOfConstruction')
                                : $t('yearOfConstruction') + '*'
                            "
                            class="ma-1 text-capitalize"
                            outlined
                            clearable
                            color="primary"
                            hide-details="auto"
                            :disabled="
                              buildingStatusSelected == 'Υπο κατασκευή'
                            "
                            :error-messages="buildYearErrorMessages"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="4" lg="4">
                          <v-text-field
                            v-model="renovateYear"
                            height="30"
                            dense
                            :label="$t('renovateYear')"
                            class="ma-1"
                            outlined
                            clearable
                            color="primary"
                            hide-details="auto"
                            :disabled="
                              buildingStatusSelected == 'Υπο κατασκευή'
                            "
                          />
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="4" lg="4">
                          <v-btn
                            class="ma-1 btn textDecorationBold"
                            style="height: 40px"
                            outlined
                            @click="
                              () => {
                                this.buildingStatusSelected = 'Υπο κατασκευή';
                                this.renovateYear = null;
                                this.buildYear = null;
                              }
                            "
                          >
                            {{ $t("underConstraction") }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-if="checkIfExist('Τεχνικά γνωρίσματα & εσωτερικό')">
                    <v-col>
                      <div class="mt-10">
                        <span class="textDecorationBold text-capitalize">
                          {{ $t("technicalFeaturesAndInterior") }}
                        </span>
                        <v-divider />
                      </div>
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-select
                            v-model="selectBoxesSelected"
                            :items="selectBoxesList"
                            multiple
                            :label="$t('frames')"
                            item-text="text"
                            item-value="val"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-row>
                                <v-col>
                                  <span v-if="index == 0">
                                    {{ $t(item.text) }}
                                  </span>
                                  <span
                                    v-if="
                                      selectBoxesSelected.length > 1 &&
                                      index == 0
                                    "
                                    class="grey--text text-caption"
                                  >
                                    + {{ selectBoxesSelected.length - 1 }}
                                    {{ $t("moreSelected") }}
                                  </span>
                                </v-col>
                              </v-row>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list>
                                <v-list-item-title>
                                  <v-row>
                                    <v-col
                                      style="display: flex; align-items: center"
                                    >
                                      {{ $t(item.text) }}
                                    </v-col>
                                  </v-row>
                                </v-list-item-title>
                              </v-list>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-select
                            v-model="floorSelected"
                            :items="floorList"
                            multiple
                            :label="$t('ground')"
                            item-text="text"
                            item-value="val"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-row>
                                <v-col>
                                  <span v-if="index == 0">
                                    {{ $t(item.text) }}
                                  </span>
                                  <span
                                    v-if="
                                      floorSelected.length > 1 && index == 0
                                    "
                                    class="grey--text text-caption"
                                  >
                                    + {{ floorSelected.length - 1 }}
                                    {{ $t("moreSelected") }}
                                  </span>
                                </v-col>
                              </v-row>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list>
                                <v-list-item-title>
                                  <v-row>
                                    <v-col
                                      style="display: flex; align-items: center"
                                    >
                                      {{ $t(item.text) }}
                                    </v-col>
                                  </v-row>
                                </v-list-item-title>
                              </v-list>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row class="mt-2">
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-checkbox
                            v-model="furnished"
                            :label="$t('furnished')"
                          />
                          <v-checkbox v-model="bright" :label="$t('bright')" />
                          <v-checkbox
                            v-model="painted"
                            :label="$t('painted')"
                          />
                          <v-checkbox
                            v-model="electrical_equipment"
                            :label="$t('electricalEquipment')"
                          />
                          <v-checkbox
                            v-model="satellite_antenna"
                            :label="$t('satelliteAntenna')"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-checkbox
                            v-model="luxurious"
                            :label="$t('luxurious')"
                          />
                          <v-checkbox
                            v-model="screens"
                            :label="$t('windowNets')"
                          />
                          <v-checkbox
                            v-model="pets_allowed"
                            :label="$t('allowingPets')"
                          />
                          <v-checkbox
                            v-model="internal_staircase"
                            :label="$t('internalStaircase')"
                          />
                          <v-checkbox
                            v-model="elevator"
                            :label="$t('elevator')"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-if="checkIfExist('Ασφάλεια')">
                    <v-col>
                      <div class="mt-10">
                        <span class="textDecorationBold">
                          {{ $t("security") }}
                        </span>
                        <v-divider />
                      </div>
                      <v-row class="mt-2">
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-checkbox
                            v-model="safety_door"
                            :label="$t('safetyDoor')"
                          />
                          <v-checkbox
                            v-model="security_cam"
                            :label="$t('cameras')"
                          />
                          <v-checkbox
                            v-model="security_systems"
                            :label="$t('safetySystem')"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-checkbox v-model="alarm" :label="$t('alarm')" />
                          <v-checkbox v-model="cctv" :label="$t('cctv')" />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-if="checkIfExist('Εξωτερ. χώροι & θέση ακινήτου')">
                    <v-col class="ml-1">
                      <div class="mt-10">
                        <span class="textDecorationBold text-capitalize">
                          {{ $t("exteriorPlaces") }}
                        </span>
                        <v-divider />
                      </div>
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-select
                            v-model="chooseAViewSelected"
                            :items="chooseAViewList"
                            multiple
                            :label="$t('chooseAView')"
                            item-text="text"
                            item-value="val"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-row>
                                <v-col>
                                  <span v-if="index == 0">
                                    {{ $t(item.text) }}
                                  </span>
                                  <span
                                    v-if="
                                      chooseAViewSelected.length > 1 &&
                                      index == 0
                                    "
                                    class="grey--text text-caption"
                                  >
                                    + {{ chooseAViewSelected.length - 1 }}
                                    {{ $t("moreSelected") }}
                                  </span>
                                </v-col>
                              </v-row>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list>
                                <v-list-item-title>
                                  <v-row>
                                    <v-col
                                      style="display: flex; align-items: center"
                                    >
                                      {{ $t(item.text) }}
                                    </v-col>
                                  </v-row>
                                </v-list-item-title>
                              </v-list>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-select
                            v-model="zoneSelected"
                            :items="zoneList"
                            multiple
                            :label="$t('selectzone')"
                            item-text="text"
                            item-value="val"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-row>
                                <v-col>
                                  <span v-if="index == 0">
                                    {{ $t(item.text) }}
                                  </span>
                                  <span
                                    v-if="zoneSelected.length > 1 && index == 0"
                                    class="grey--text text-caption"
                                  >
                                    + {{ zoneSelected.length - 1 }}
                                    {{ $t("moreSelected") }}
                                  </span>
                                </v-col>
                              </v-row>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list>
                                <v-list-item-title>
                                  <v-row>
                                    <v-col
                                      style="display: flex; align-items: center"
                                    >
                                      {{ $t(item.text) }}
                                    </v-col>
                                  </v-row>
                                </v-list-item-title>
                              </v-list>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-select
                            v-model="selectPositionSelected"
                            :items="selectPositionList"
                            multiple
                            :label="$t('selectPosition')"
                            item-text="text"
                            item-value="val"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-row>
                                <v-col>
                                  <span v-if="index == 0">
                                    {{ $t(item.text) }}
                                  </span>
                                  <span
                                    v-if="
                                      selectPositionSelected.length > 1 &&
                                      index == 0
                                    "
                                    class="grey--text text-caption"
                                  >
                                    + {{ selectPositionSelected.length - 1 }}
                                    {{ $t("moreSelected") }}
                                  </span>
                                </v-col>
                              </v-row>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list>
                                <v-list-item-title>
                                  <v-row>
                                    <v-col
                                      style="display: flex; align-items: center"
                                    >
                                      {{ $t(item.text) }}
                                    </v-col>
                                  </v-row>
                                </v-list-item-title>
                              </v-list>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-select
                            v-model="orientationSelected"
                            :items="orientationList"
                            multiple
                            :label="$t('orientation')"
                            item-value="val"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-row>
                                <v-col>
                                  <span v-if="index == 0">
                                    {{ $t(item.text) }}
                                  </span>
                                  <span
                                    v-if="
                                      orientationSelected.length > 1 &&
                                      index == 0
                                    "
                                    class="grey--text text-caption"
                                  >
                                    + {{ orientationSelected.length - 1 }}
                                    {{ $t("moreSelected") }}
                                  </span>
                                </v-col>
                              </v-row>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list>
                                <v-list-item-title>
                                  <v-row>
                                    <v-col
                                      style="display: flex; align-items: center"
                                    >
                                      {{ $t(item.text) }}
                                    </v-col>
                                  </v-row>
                                </v-list-item-title>
                              </v-list>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          class="mt-2"
                          cols="12"
                          xs="12"
                          sm="12"
                          md="6"
                          lg="6"
                        >
                          <v-row class="checkboxRow checkboxInputCombo">
                            <v-col>
                              <v-row>
                                <v-checkbox
                                  v-model="parking"
                                  :label="$t('vechParking')"
                                />
                              </v-row>
                              <v-row>
                                <v-text-field
                                  v-model="parking_spots"
                                  :label="$t('vechParkingCount')"
                                  :disabled="!parking"
                                  clearable
                                  color="primary"
                                  style="margin-top: -5px; padding-bottom: 20px"
                                  hide-details="auto"
                                />
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col
                          class="mt-2"
                          cols="12"
                          xs="12"
                          sm="12"
                          md="6"
                          lg="6"
                        >
                          <v-row class="checkboxRow checkboxInputCombo2">
                            <v-col>
                              <div style="margin: -3px -24px -16px -21px">
                                <v-select
                                  v-model="typeOfParkingSelected"
                                  :items="typeOfParkingList"
                                  multiple
                                  :disabled="!parking"
                                  :label="$t('typeOfParking')"
                                  item-value="val"
                                >
                                  <template v-slot:selection="{ item, index }">
                                    <v-row>
                                      <v-col>
                                        <span v-if="index == 0">
                                          {{ $t(item.text) }}
                                        </span>
                                        <span
                                          v-if="
                                            typeOfParkingSelected.length > 1 &&
                                            index == 0
                                          "
                                          class="grey--text text-caption"
                                        >
                                          +
                                          {{
                                            typeOfParkingSelected.length - 1
                                          }}
                                          {{ $t("moreSelected") }}
                                        </span>
                                      </v-col>
                                    </v-row>
                                  </template>
                                  <template v-slot:item="{ item }">
                                    <v-list>
                                      <v-list-item-title>
                                        <v-row>
                                          <v-col
                                            style="
                                              display: flex;
                                              align-items: center;
                                            "
                                          >
                                            {{ $t(item.text) }}
                                          </v-col>
                                        </v-row>
                                      </v-list-item-title>
                                    </v-list>
                                  </template>
                                </v-select>
                              </div>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-row class="checkboxRow checkboxInputCombo">
                            <v-col class="mb-6">
                              <v-row>
                                <v-checkbox
                                  v-model="garden"
                                  :label="$t('garden')"
                                />
                              </v-row>
                              <v-row>
                                <v-text-field
                                  v-model="garden_in_sqm"
                                  :label="$t('gardenSqft')"
                                  clearable
                                  color="primary"
                                  :disabled="!garden"
                                  style="margin-top: -5px; padding-bottom: 20px"
                                  :suffix="$t('sqftTable')"
                                  hide-details="auto"
                                />
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-row class="checkboxRow checkboxInputCombo">
                            <v-col class="mb-6">
                              <v-row>
                                <v-checkbox
                                  v-model="pool"
                                  :label="$t('pool')"
                                />
                              </v-row>
                              <v-row>
                                <v-text-field
                                  v-model="pool_in_sqm"
                                  :label="$t('poolSqft')"
                                  clearable
                                  color="primary"
                                  :disabled="!pool"
                                  style="margin-top: -5px; padding-bottom: 20px"
                                  :suffix="$t('sqftTable')"
                                  hide-details="auto"
                                />
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-checkbox
                            v-model="veranda"
                            :label="$t('terrace')"
                          />
                          <v-checkbox
                            v-model="awnings"
                            :label="$t('awnings')"
                          />
                          <v-checkbox
                            v-model="separate_entrance"
                            :label="$t('independentEntrance')"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-checkbox
                            v-model="access_for_disabled_people"
                            :label="$t('disabledAccess')"
                          />
                          <v-checkbox v-model="bbq" :label="$t('BBQ')" />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-if="checkIfExist('Κατάλληλο για')">
                    <v-col>
                      <div class="mt-10">
                        <span class="textDecorationBold text-capitalize">
                          {{ $t("suitableFor") }}
                        </span>
                        <v-divider />
                      </div>
                      <v-row class="mt-2">
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-checkbox
                            v-model="suitable_for_students"
                            :label="$t('student')"
                          />
                          <v-checkbox v-model="airbnb" :label="$t('airbnb')" />
                          <v-checkbox
                            v-model="suitable_for_commercial_use"
                            :label="$t('professionalUse')"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-if="checkIfExist('Αποστάσεις')">
                    <v-col>
                      <div class="mt-10">
                        <span class="textDecorationBold text-capitalize">
                          {{ $t("distances") }}
                        </span>
                        <v-divider />
                      </div>
                      <RadioField
                        :val="selectedDistanceUnit"
                        fieldName="selectedDistanceUnit"
                        :isRow="true"
                        :options="distanceRadioFields"
                        radioValues="distanceRadioFields"
                        :radioChangeHandler="updateRadioField"
                      />
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-text-field
                            v-model="distance_from_sea"
                            :label="$t('sea')"
                            clearable
                            color="primary"
                            :suffix="$t(distanceUnit)"
                            hide-details="auto"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-text-field
                            v-model="distance_from_airport"
                            :label="$t('airport')"
                            clearable
                            color="primary"
                            :suffix="$t(distanceUnit)"
                            hide-details="auto"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-text-field
                            v-model="distance_from_acropolis"
                            :label="$t('acropoliMonuments')"
                            clearable
                            color="primary"
                            :suffix="$t(distanceUnit)"
                            hide-details="auto"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-text-field
                            v-model="distance_from_bank"
                            :label="$t('bank')"
                            clearable
                            color="primary"
                            :suffix="$t(distanceUnit)"
                            hide-details="auto"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-text-field
                            v-model="distance_from_schools"
                            :label="$t('school')"
                            clearable
                            color="primary"
                            :suffix="$t(distanceUnit)"
                            hide-details="auto"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-text-field
                            v-model="distance_from_super_market"
                            :label="$t('supermarket')"
                            clearable
                            color="primary"
                            :suffix="$t(distanceUnit)"
                            hide-details="auto"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-text-field
                            v-model="distance_from_metro"
                            :label="$t('metro')"
                            clearable
                            color="primary"
                            :suffix="$t(distanceUnit)"
                            hide-details="auto"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-text-field
                            v-model="distance_from_commuting_stops"
                            :label="$t('urbanTransport')"
                            clearable
                            color="primary"
                            :suffix="$t(distanceUnit)"
                            hide-details="auto"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-text-field
                            v-model="distance_from_train"
                            :label="$t('ΗΣΑΠ')"
                            clearable
                            color="primary"
                            :suffix="$t(distanceUnit)"
                            hide-details="auto"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-text-field
                            v-model="distance_from_square"
                            :label="$t('square')"
                            clearable
                            color="primary"
                            :suffix="$t(distanceUnit)"
                            hide-details="auto"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-text-field
                            v-model="distance_from_park"
                            :label="$t('park')"
                            clearable
                            color="primary"
                            :suffix="$t(distanceUnit)"
                            hide-details="auto"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-text-field
                            v-model="distance_from_hospital"
                            :label="$t('hospital')"
                            clearable
                            color="primary"
                            :suffix="$t(distanceUnit)"
                            hide-details="auto"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-text-field
                            v-model="distance_from_suburban"
                            :label="$t('suburban')"
                            clearable
                            color="primary"
                            :suffix="$t(distanceUnit)"
                            hide-details="auto"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-text-field
                            v-model="distance_from_tram"
                            :label="$t('tram')"
                            clearable
                            color="primary"
                            :suffix="$t(distanceUnit)"
                            hide-details="auto"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-text-field
                            v-model="distance_from_international_schools"
                            :label="$t('internationalSchools')"
                            clearable
                            color="primary"
                            :suffix="$t(distanceUnit)"
                            hide-details="auto"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-if="checkIfExist('Περιγραφή')">
                    <v-col>
                      <div class="mt-10">
                        <span class="textDecorationBold text-capitalize">
                          {{ $t("description") }}
                        </span>
                      </div>
                      <v-divider />
                      <v-row class="mt-2">
                        <v-col cols="12" xs="12" sm="12" md="10" lg="10">
                          <v-tabs align="left" v-model="activeLangTab">
                            <v-tabs-slider style="height: 4px" color="error" />
                            <v-tab class="text-capitalize textDecorationBold">
                              {{ $t("greek") }}
                            </v-tab>
                            <v-tab class="text-capitalize textDecorationBold">
                              {{ $t("english") }}
                            </v-tab>
                            <v-tab class="text-capitalize textDecorationBold">
                              {{ $t("arabic") }}
                            </v-tab>
                            <v-tab class="text-capitalize textDecorationBold">
                              {{ $t("chinesse") }}
                            </v-tab>
                          </v-tabs>
                        </v-col>
                        <v-col
                          style="padding: 38px 15px 0px 0px"
                          cols="12"
                          xs="12"
                          sm="12"
                          md="2"
                          lg="2"
                        >
                          <div align="right">
                            {{
                              descriptionLength
                                ? descriptionLength + "/5000"
                                : "0/5000"
                            }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-tabs-items v-model="activeLangTab">
                        <v-tab-item>
                          <v-textarea
                            :label="$t('inputDescription')"
                            v-model="greekDescription"
                            auto-grow
                            class="mt-4"
                            outlined
                            rows="8"
                            row-height="15"
                          />
                        </v-tab-item>
                        <v-tab-item>
                          <v-textarea
                            :label="$t('inputDescription')"
                            v-model="englishDescription"
                            auto-grow
                            class="mt-4"
                            outlined
                            rows="8"
                            row-height="15"
                          />
                        </v-tab-item>
                        <v-tab-item>
                          <v-textarea
                            :label="$t('inputDescription')"
                            v-model="arabicDescription"
                            auto-grow
                            class="mt-4"
                            outlined
                            rows="8"
                            row-height="15"
                          />
                        </v-tab-item>
                        <v-tab-item>
                          <v-textarea
                            :label="$t('inputDescription')"
                            v-model="chinesseDescription"
                            auto-grow
                            class="mt-4"
                            outlined
                            rows="8"
                            row-height="15"
                          />
                        </v-tab-item>
                      </v-tabs-items>
                    </v-col>
                  </v-row>
                  <v-row v-if="checkIfExist('Διαχείριση ακινήτου')">
                    <v-col>
                      <div class="mt-10">
                        <span class="textDecorationBold">
                          {{ $t("propertyManagement") }}
                        </span>
                        <v-divider />
                      </div>
                      <v-row class="mt-2">
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-row class="checkboxRow checkboxInputCombo">
                            <v-col class="mb-6">
                              <v-text-field
                                v-model="estateCode"
                                disabled
                                :label="$t('propertyCode')"
                                clearable
                                color="primary"
                                style="margin-top: -5px; padding-bottom: 20px"
                                hide-details="auto"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-row class="checkboxRow checkboxInputCombo">
                            <v-col class="mb-6">
                              <v-text-field
                                v-model="orderCode"
                                :label="$t('orderCode')"
                                clearable
                                color="primary"
                                style="margin-top: -5px; padding-bottom: 20px"
                                hide-details="auto"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="mt-2">
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-row class="checkboxRow checkboxInputCombo">
                            <v-col class="mb-6">
                              <v-autocomplete
                                v-model="agent"
                                return-object
                                :label="$t('propertyResponsiblePerson')"
                                :items="agentsList"
                                item-text="fullname"
                                :filter="filterList"
                                :search-input.sync="searchAgent"
                                :loading="agentAutocompleteLoader"
                                hide-no-data
                                clearable
                                flat
                                hide-selected
                                attach
                              >
                                <template v-slot:selection="{ item }">
                                  <v-row>
                                    <v-col
                                      class="flex-grow-0 pr-0"
                                      v-if="item && item.Photo"
                                    >
                                      <v-avatar size="24">
                                        <v-img
                                          :src="getUserPhoto(item.Photo)"
                                        />
                                      </v-avatar>
                                    </v-col>
                                    <v-col>
                                      {{ item.fullname }}
                                    </v-col>
                                  </v-row>
                                </template>
                                <template slot="item" slot-scope="data">
                                  <v-list>
                                    <v-list-item-title>
                                      <v-row>
                                        <v-col
                                          class="flex-grow-0 pr-0"
                                          v-if="data.item && data.item.Photo"
                                        >
                                          <v-avatar size="24">
                                            <v-img
                                              :src="
                                                getUserPhoto(data.item.Photo)
                                              "
                                            />
                                          </v-avatar>
                                        </v-col>
                                        <v-col>
                                          {{ data.item.fullname }}
                                        </v-col>
                                      </v-row>
                                    </v-list-item-title>
                                  </v-list>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-row class="checkboxRow checkboxInputCombo">
                            <v-col class="mb-6">
                              <v-autocomplete
                                v-model="owner"
                                return-object
                                :label="$t('ownerProfile')"
                                :items="ownerList"
                                item-value="_id"
                                item-text="name"
                                :filter="filterList"
                                :search-input.sync="searchOwner"
                                :loading="ownerAutocompleteLoader"
                                hide-no-data
                                clearable
                                flat
                                hide-selected
                                attach
                              >
                                <template v-slot:selection="{ item }">
                                  <v-row class="ss">
                                    <v-col
                                      class="flex-grow-0 pr-0"
                                      v-if="item && item.Photo"
                                    >
                                      <v-avatar size="24">
                                        <v-img
                                          :src="getUserPhoto(item.Photo)"
                                        />
                                      </v-avatar>
                                    </v-col>
                                    <v-col class="flex-grow-0 text-no-wrap">
                                      {{ item.name }}
                                    </v-col>
                                  </v-row>
                                </template>
                                <template slot="item" slot-scope="data">
                                  <v-list>
                                    <v-list-item-title>
                                      <v-row>
                                        <v-col
                                          class="flex-grow-0 pr-0"
                                          v-if="data.item && data.item.Photo"
                                        >
                                          <v-avatar size="24">
                                            <v-img
                                              :src="
                                                getUserPhoto(data.item.Photo)
                                              "
                                            />
                                          </v-avatar>
                                        </v-col>
                                        <v-col class="flex-grow-0 text-no-wrap">
                                          {{ data.item.name }}
                                        </v-col>
                                      </v-row>
                                    </v-list-item-title>
                                  </v-list>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="mt-2">
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-row class="checkboxRow checkboxInputCombo"
                            >,
                            <v-col class="mb-6">
                              <v-autocomplete
                                v-model="notary"
                                return-object
                                :label="$t('realEstateNotary')"
                                :items="notariesList"
                                item-text="name"
                                :filter="filterList"
                                :search-input.sync="searchNotary"
                                :loading="notaryAutocompleteLoader"
                                hide-no-data
                                clearable
                                flat
                                hide-selected
                                attach
                              >
                                <template v-slot:selection="{ item }">
                                  <v-row>
                                    <v-col
                                      class="flex-grow-0 pr-0"
                                      v-if="item && item.Photo"
                                    >
                                      <v-avatar size="24">
                                        <v-img
                                          :src="getUserPhoto(item.Photo)"
                                        />
                                      </v-avatar>
                                    </v-col>
                                    <v-col>
                                      {{ item.name }}
                                    </v-col>
                                  </v-row>
                                </template>
                                <template slot="item" slot-scope="data">
                                  <v-list>
                                    <v-list-item-title>
                                      <v-row>
                                        <v-col
                                          class="flex-grow-0 pr-0"
                                          v-if="data.item && data.item.Photo"
                                        >
                                          <v-avatar size="24">
                                            <v-img
                                              :src="
                                                getUserPhoto(data.item.Photo)
                                              "
                                            />
                                          </v-avatar>
                                        </v-col>
                                        <v-col>
                                          {{ data.item.name }}
                                        </v-col>
                                      </v-row>
                                    </v-list-item-title>
                                  </v-list>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-row class="checkboxRow checkboxInputCombo">
                            <v-col class="mb-6">
                              <v-autocomplete
                                v-model="attorney"
                                return-object
                                :label="$t('realEstateLawyer')"
                                :items="attorneysList"
                                item-text="name"
                                :filter="filterList"
                                :search-input.sync="searchAttorney"
                                :loading="attorneyAutocompleteLoader"
                                hide-no-data
                                clearable
                                flat
                                hide-selected
                                attach
                              >
                                <template v-slot:selection="{ item }">
                                  <v-row>
                                    <v-col
                                      class="flex-grow-0 pr-0"
                                      v-if="item && item.Photo"
                                    >
                                      <v-avatar size="24">
                                        <v-img
                                          :src="getUserPhoto(item.Photo)"
                                        />
                                      </v-avatar>
                                    </v-col>
                                    <v-col>
                                      {{ item.name }}
                                    </v-col>
                                  </v-row>
                                </template>
                                <template slot="item" slot-scope="data">
                                  <v-list>
                                    <v-list-item-title>
                                      <v-row>
                                        <v-col
                                          class="flex-grow-0 pr-0"
                                          v-if="data.item && data.item.Photo"
                                        >
                                          <v-avatar size="24">
                                            <v-img
                                              :src="
                                                getUserPhoto(data.item.Photo)
                                              "
                                            />
                                          </v-avatar>
                                        </v-col>
                                        <v-col>
                                          {{ data.item.name }}
                                        </v-col>
                                      </v-row>
                                    </v-list-item-title>
                                  </v-list>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="mt-2">
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-row class="checkboxRow checkboxInputCombo">
                            <v-col class="mb-6">
                              <v-select
                                v-model="commandType"
                                :items="getAssignationsList"
                                :error-messages="commandTypeErrorMessages"
                                :label="$t('orderType')"
                                item-text="text"
                                item-value="val"
                              >
                                <template v-slot:selection="{ item }">
                                  <v-row class="flex-grow-0">
                                    <v-col
                                      style="display: flex; align-items: center"
                                    >
                                      {{ $t(item.text) }}
                                    </v-col>
                                  </v-row>
                                </template>
                                <template v-slot:item="{ item }">
                                  <v-list>
                                    <v-list-item-title>
                                      <v-row>
                                        <v-col
                                          style="
                                            display: flex;
                                            align-items: center;
                                          "
                                        >
                                          {{ $t(item.text) }}
                                        </v-col>
                                      </v-row>
                                    </v-list-item-title>
                                  </v-list>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-row class="checkboxRow checkboxInputCombo">
                            <v-col class="mb-6">
                              <v-select
                                v-model="status"
                                :items="statusList"
                                :label="$t('adStatus')"
                                item-text="text"
                                item-value="val"
                              >
                                <template v-slot:selection="{ item }">
                                  <v-row class="flex-grow-0">
                                    <v-col
                                      style="display: flex; align-items: center"
                                    >
                                      {{ $t(item.text) }}
                                    </v-col>
                                  </v-row>
                                </template>
                                <template v-slot:item="{ item }">
                                  <v-list>
                                    <v-list-item-title>
                                      <v-row>
                                        <v-col
                                          style="
                                            display: flex;
                                            align-items: center;
                                          "
                                        >
                                          {{ $t(item.text) }}
                                        </v-col>
                                      </v-row>
                                    </v-list-item-title>
                                  </v-list>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="mt-2">
                        <v-col cols="12" xs="12" sm="12" md="9" lg="9">
                          <v-row class="checkboxRow checkboxInputCombo">
                            <v-col class="mb-6">
                              <v-row>
                                <v-checkbox
                                  class="ml-2"
                                  v-model="orderForSpesificCustomer"
                                  disabled
                                  :label="$t('orderForSpesificCustomer')"
                                />
                              </v-row>
                              <v-row>
                                <v-col class="mb-6">
                                  <v-text-field
                                    v-model="inlandCommission"
                                    :label="$t('commissionPercentage')"
                                    clearable
                                    color="primary"
                                    style="
                                      margin-top: -5px;
                                      padding-bottom: 20px;
                                    "
                                    hide-details="auto"
                                  />
                                </v-col>
                                <v-col class="mb-6">
                                  <v-text-field
                                    v-model="inlandCommissionNoTax"
                                    :label="$t('commissionAmountWithoutTax')"
                                    clearable
                                    color="primary"
                                    style="
                                      margin-top: -5px;
                                      padding-bottom: 20px;
                                    "
                                    hide-details="auto"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="mt-2">
                        <v-col cols="12" xs="12" sm="12" md="9" lg="9">
                          <v-row class="checkboxRow checkboxInputCombo">
                            <v-col class="mb-6">
                              <v-row>
                                <v-checkbox
                                  class="ml-2"
                                  v-model="orderForOtherCustomer"
                                  disabled
                                  :label="$t('orderForOtherCustomer')"
                                />
                              </v-row>
                              <v-row>
                                <v-col class="mb-6">
                                  <v-text-field
                                    v-model="outlandCommission"
                                    :label="$t('commissionPercentage')"
                                    clearable
                                    color="primary"
                                    style="
                                      margin-top: -5px;
                                      padding-bottom: 20px;
                                    "
                                    hide-details="auto"
                                  />
                                </v-col>
                                <v-col class="mb-6">
                                  <v-text-field
                                    v-model="outlandCommissionNoTax"
                                    :label="$t('commissionAmountWithoutTax')"
                                    clearable
                                    color="primary"
                                    style="
                                      margin-top: -5px;
                                      padding-bottom: 20px;
                                    "
                                    hide-details="auto"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-if="checkIfExist('Σημειώσεις')">
                    <v-col>
                      <div class="mt-10">
                        <v-icon class="textDecorationBold">
                          mdi-eye-off
                        </v-icon>
                        <span class="textDecorationBold">
                          {{ $t("notes") }}
                        </span>
                      </div>
                      <v-textarea
                        :label="$t('inputDescription')"
                        v-model="personalComments"
                        auto-grow
                        class="mt-1"
                        outlined
                        rows="8"
                        row-height="15"
                      />
                      <v-row>
                        <v-col>
                          <HintField
                            icon="mdi-eye-off"
                            color="#000000"
                            :data="$t('internalUse')"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <div class="mt-10">
                    <v-row>
                      <v-col>
                        <span class="textDecorationBold">
                          {{ $t("searchMapLoc") }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-divider />
                    <v-row class="mt-5">
                      <v-col cols="11">
                        <p class="listHeader">{{ $t("searchMapLocMsg") }}.</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <div class="mapDiv">
                        <Map :locationMarkers="locationMarkers" />
                        <Location
                          class="mapInput"
                          :placeholder="$t('typeAddrNo') + '...'"
                          :vuexProps="false"
                          :setLocation="setACLocation"
                          iconName="mdi-map-marker-outline"
                          iconColor="primary"
                        />
                      </div>
                    </v-row>
                    <v-row class="mb-0">
                      <v-col class="pb-0">
                        <span class="textDecorationBold">
                          {{ $t("chooseDisplayOnUser") }}:
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-row>
                          <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-row>
                              <v-col class="align-self-center" cols="1">
                                <v-checkbox v-model="addressCheckbox" />
                              </v-col>
                              <v-col cols="10" class="align-self-center">
                                <v-text-field
                                  v-model="address"
                                  :label="$t('theLocationOfTheProperty')"
                                  clearable
                                  color="primary"
                                  hide-details="auto"
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-row>
                              <v-col class="align-self-center" cols="1">
                                <v-checkbox v-model="exactLocationCheckbox" />
                              </v-col>
                              <v-col class="align-self-center" cols="10">
                                <v-text-field
                                  v-model="gps"
                                  :label="$t('exactLocation')"
                                  clearable
                                  color="primary"
                                  hide-details="auto"
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <span class="textDecorationBold">
                          {{ $t("areaSelect") }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row class="mt-0">
                      <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                        <v-combobox
                          v-model="region"
                          :items="regionsList"
                          @focusout="fetchLocation('states')"
                          :loading="regionsLoading"
                          :label="$t('area')"
                          clearable
                          color="primary"
                          :error-messages="regionErrorMessages"
                          hide-details="auto"
                        />
                      </v-col>
                      <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                        <v-combobox
                          v-model="state"
                          :items="statesList"
                          :loading="statesLoading"
                          @focusout="fetchLocation('districts')"
                          :label="$t('city')"
                          clearable
                          color="primary"
                          :error-messages="cityErrorMessages"
                          hide-details="auto"
                        />
                      </v-col>
                      <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                        <v-combobox
                          v-model="district"
                          :items="districtsList"
                          :loading="districtsLoading"
                          @focusout="fetchLocation('boroughs')"
                          :label="$t('municipality') + ' *'"
                          clearable
                          color="primary"
                          :error-messages="districtErrorMessages"
                          hide-details="auto"
                        />
                      </v-col>
                      <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                        <v-combobox
                          v-model="borough"
                          :items="boroughsList"
                          :loading="boroughsLoading"
                          :label="$t('neighborhood') + ' *'"
                          :placeholder="$t('noSelect')"
                          persistent-placeholder
                          clearable
                          color="primary"
                          hide-details="auto"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <span class="textDecorationBold">
                          {{ $t("setSecureLevel") }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row class="mb-4">
                      <v-col>
                        <RadioField
                          :val="selectedSecurity"
                          fieldName="selectedSecurity"
                          :isRow="true"
                          :options="securityFields"
                          radioValues="securityFields"
                          :radioChangeHandler="updateRadioField"
                        />
                      </v-col>
                    </v-row>
                  </div>
                </v-tab-item>
                <v-tab-item>
                  <v-row>
                    <v-col>
                      <span class="textDecorationBold">
                        {{ $t("photos") }}
                      </span>
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row class="mt-3">
                    <v-col>
                      <p class="listHeader" v-if="data.photos">
                        {{ $t("xPhotosUploadedP1") }} {{ data.photos.length
                        }}{{ $t("xPhotosUploadedP2") }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col class="pt-0 pl-1">
                      <DraggableImages
                        :images="dragImages"
                        :disable="watermark"
                        :uploadLoading="isUploadingImg"
                        :deleteLoading="isDeletingImg"
                        itemImageObjName="title"
                        itemImageTitleProp="gr"
                        uploadImagePropName="titleGr"
                        itemCheckBoxPropName="public"
                        :itemCheckBoxDescr="$t('public')"
                        itemCheckBoxFalseVal="Private"
                        itemCheckBoxTrueVal="Public"
                        :topLeftLabel="
                          require('@/assets/draggable/top_left_layer.png')
                        "
                        :supportEdit="true"
                        :supportDelete="true"
                        innerClass="blueishInnerShadow"
                        :startDragHandler="startDrag"
                        :updateArrayHandler="(m) => updateImageArray(m, false)"
                        :uploadImagesHandler="(m) => uploadImages(m, false)"
                        :editObjHandler="(m, n) => editImg(m, n, false)"
                        :deleteObjHandler="(m) => deleteImg(m, false)"
                        :updateObjHandler="(m, n) => updateImg(m, n, false)"
                        class="pt-0"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="ml-0 mr-0 mt-4">
                      <v-checkbox
                        v-model="watermark"
                        class="fontSize14"
                        :label="$t('withWatermark')"
                        :false-value="false"
                        :true-value="true"
                      />
                    </v-col>
                    <v-col class="mt-2 mr-3">
                      <v-btn
                        class="pr-1 pl-1 white-btn fontSize14 noCapitalize"
                        plain
                        :loading="dwnPhotosloader"
                        @click="downloadImages('plain', 'dwnPhotosloader')"
                      >
                        {{ $t("downloadPhotos") }}
                      </v-btn>
                    </v-col>
                    <v-col class="mt-2">
                      <v-btn
                        class="pl-1 pr-1 white-btn fontSize14 noCapitalize"
                        plain
                        :loading="dwnWtPhotosloader"
                        @click="
                          downloadImages('watermark', 'dwnWtPhotosloader')
                        "
                      >
                        {{ $t("downloadPhotosWithWatermark") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span class="textDecorationBold">
                        {{ $t("video") }}
                      </span>
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row class="mt-3">
                    <v-col class="pb-0">
                      <p class="listHeader">{{ $t("pasteYoutubeLink") }}</p>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col cols="8" class="pt-0 pb-0">
                      <v-text-field
                        v-model="youtubeLink"
                        :label="`Π.χ. https://www.youtube.com/watch?v= [${$t(
                          'videoCode'
                        )}]`"
                        single-line
                      />
                    </v-col>
                    <v-col class="align-self-center pt-0 pb-0">
                      <v-btn
                        class="btn-custom-blue"
                        key="fBtn3"
                        outlined
                        color="primary"
                        @click="youtubeLink = null"
                      >
                        <Icon
                          icon="eva:trash-2-outline"
                          height="30"
                          outline
                          color="white"
                        />
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col v-if="youtubeLink != null" cols="10" class="pt-2">
                      <iframe
                        width="553"
                        height="283"
                        :src="getEmbedLink(youtubeLink)"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span class="textDecorationBold">
                        {{ $t("generalMarkInfo") }}
                      </span>
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="pb-0">
                      <v-row>
                        <v-col class="textDecorationBold pb-0">
                          {{ $t("keywords") }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pt-0">
                          <RadioField
                            :val="keys"
                            fieldName="keys"
                            :isRow="true"
                            :options="yesOrNoFields"
                            radioValues="yesOrNoFields"
                            :radioChangeHandler="updateRadioField"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="6">
                      <v-row>
                        <v-col class="textDecorationBold pb-0">
                          {{ $t("bannerAtTheEntrance") }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pt-0">
                          <RadioField
                            :val="bannerAtTheEntrance"
                            fieldName="bannerAtTheEntrance"
                            :isRow="true"
                            :options="yesOrNoFields"
                            radioValues="yesOrNoFields"
                            :radioChangeHandler="updateRadioField"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="pb-0">
                      <v-row>
                        <v-col class="textDecorationBold pb-0">
                          {{ $t("banner") }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pt-0">
                          <RadioField
                            :val="pano"
                            fieldName="pano"
                            :isRow="true"
                            :options="yesOrNoFields"
                            radioValues="yesOrNoFields"
                            :radioChangeHandler="updateRadioField"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="6">
                      <v-row>
                        <v-col class="textDecorationBold pb-0">
                          {{ $t("bannerOnStreets") }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pt-0">
                          <RadioField
                            :val="bannerOnStreets"
                            fieldName="bannerOnStreets"
                            :isRow="true"
                            :options="yesOrNoFields"
                            radioValues="yesOrNoFields"
                            :radioChangeHandler="updateRadioField"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-if="pano == true" class="mt-0">
                    <v-col>
                      <v-row>
                        <v-col>
                          <HintField
                            icon="mdi-alert-circle"
                            color="#43425D"
                            :data="$t('commissionBanner')"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pt-0 pl-1">
                          <DraggableImages
                            :images="bannerImage"
                            :uploadLoading="isUploadingBannerImg"
                            :deleteLoading="isDeletingBannerImg"
                            itemImageObjName="title"
                            itemImageTitleProp="gr"
                            uploadImagePropName="titleGr"
                            :supportEdit="true"
                            itemCheckBoxPropName="public"
                            :itemCheckBoxDescr="$t('public')"
                            itemCheckBoxFalseVal="Private"
                            itemCheckBoxTrueVal="Public"
                            :supportUpload="
                              bannerImage.length > 0 ? false : true
                            "
                            :supportDelete="true"
                            innerClass="blueishInnerShadow"
                            :uploadImagesHandler="(m) => uploadImages(m, true)"
                            :editObjHandler="(m, n) => editImg(m, n, true)"
                            :deleteObjHandler="(m) => deleteImg(m, true)"
                            :updateObjHandler="(m, n) => updateImg(m, n, true)"
                            :updateArrayHandler="
                              (m) => updateImageArray(m, true)
                            "
                            class="pt-0"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span class="textDecorationBold">
                        {{ $t("documentsMedia") }}
                      </span>
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <Files
                        :documents="
                          data.documents != undefined ? data.documents : {}
                        "
                        :docTypesList="docTypesList"
                        :btnText="$t('add')"
                        :supportUpload="isAuthorized('upload', data) == true"
                        :supportUpdate="true"
                        :updateHandler="updateDocument"
                        :supportDelete="true"
                        :deleteHandler="deleteDocument"
                        :supportDownload="true"
                        :downloadHandler="downloadDocument"
                        :btnClickedHandler="filesBtnClicked"
                        listIcon="hint"
                        :labelContent="$t('docMediaSubMsg')"
                        labelClasses="listHeader"
                        :excludeObjectKeys="['_id', 'estate_id']"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span class="textDecorationBold">
                        Virtual Tour 360°
                      </span>
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span class="fontColorBlue">
                        {{ $t("virtualTour") }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" xl="8" lg="8" md="8" sm="8" xs="12">
                      <p class="listHeader">{{ $t("virtualCreate") }}</p>
                    </v-col>
                    <v-col
                      cols="12"
                      xl="4"
                      lg="4"
                      md="4"
                      sm="4"
                      xs="12"
                      class="align-self-center"
                    >
                      <v-btn class="ma-1 btn" elevation="1" outlined>
                        {{ $t("learnMore") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col class="align-self-center" cols="3">
              <u class="pt-10">
                <a class="errorColor" @click="clearFilters">
                  {{ $t("clear") }}
                </a>
              </u>
            </v-col>
            <v-col class="align-self-center" cols="3">
              <v-btn
                elevation="5"
                @click="continueButton"
                align="center"
                class="ma-1 btn"
                color="error"
              >
                {{ $t("continue") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog
      v-model="dialog.open"
      v-if="dialog.type == 'upload'"
      class="pa-6"
      @click:outside="true"
      persistent
    >
      <Upload
        class="pa-6"
        :supportDocType="true"
        :docTypeRequired="true"
        :docType="dialog.data.selectedDocType"
        :docTypesList="dialog.data.docTypesList"
        :supportCheckBox="true"
        itemCheckBoxPropName="public"
        :itemCheckBoxDescr="$t('public')"
        itemCheckBoxFalseVal="Private"
        itemCheckBoxTrueVal="Public"
        :closeHandler="closeDialog"
        :submitHandler="uploadDocuments"
      />
    </v-dialog>
    <v-dialog
      v-model="dialog.open"
      v-if="dialog.type == 'editImage'"
      persistent
      fullscreen
    >
      <EditImage
        :data="dialog.data"
        :index="dialog.index"
        :submitHandler="(m, n) => updateImg(m, n, dialog.data.isBanner)"
        :closeHandler="closeDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="dialog.open"
      v-if="dialog.type == 'congrats'"
      :max-width="685"
      :max-height="378"
    >
      <PropertyCongrats
        :content="dialog.content"
        :type="dialog.type"
        :title="$t('propertyPublished')"
        :propertyCode="estateCode"
        :onFirstHandler="closeCongrats"
        :onSecondHandler="onViewEstate"
      />
    </v-dialog>
  </v-navigation-drawer>
</template>
<script>
/*
 Name: EditRealEstateCard
 Description: Component for the edit the real estate
 API Property: 
    data: Object for the data
    onView: Function on view
    onCloseSimple: Function on close without edit
    onClose: Function on close with edit
    refreshEstate: Function on refresh real estate
    updateEstate: Function on update real estate
    uploadDocSuccessHandler: Function on success upload doc
    updateDocSuccessHandler: Function on success update doc
    deleteDocSuccessHandler: Function on success delete doc

    Components Used:
      HeaderTitle
      Files
      Upload
      Icon
      Location
      Map
      HintField
      RadioField
      MinusPlusField
      DraggableImages
      InfoField
      EditImage
      PropertyCongrats
  
    Endpoints Functions Called:
    -ContactRepo
      getOwners
      get
      getAgents

    -RealEstateRepo
      getLawyers
      getNotaries
      update
      reOrderEstatePhotos
      uploadEstatePhotos
      createEstateWatermarkedPhotos
      uploadEstateDocuments
      updateEstateDocuments
      downloadEstateDocuments
      deleteEstateDocuments
      getRegions
      getStates
      getDistricts
      getBoroughs
*/
import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
import Files from "@/components/Files/Files";
import Upload from "@/components/Upload/Upload.vue";
import Map from "@/components/Maps/Map";
import Location from "@/components/Maps/Location.vue";
import HintField from "@/components/Fields/HintField/HintField.vue";
import { mapGetters, mapState, mapActions } from "vuex";
import { Icon } from "@iconify/vue2";
import Repository from "@/services/Repository";
import CoreHelper from "@/helpers/Core/core";
import RealEstateHelper from "@/helpers/RealEstate/realEstates.js";
import i18n from "@/i18n/i18n";
import RadioField from "@/components/Fields/RadioField/RadioField.vue";
import MinusPlusField from "@/components/Fields/MinusPlusField/MinusPlusField.vue";
import DraggableImages from "@/components/Draggable/DraggableImages.vue";
import InfoField from "@/components/Fields/InfoField/InfoField.vue";
import EditImage from "@/components/RealEstate/RealEstateEdit/EditImage.vue";
import PropertyCongrats from "@/components/Popups/PropertyCongrats.vue";
import AuthHelper from "@/helpers/Core/auth";
import { requiredIf, required } from "vuelidate/lib/validators";
import RealEstateModel from "@/static/realEstate/realEstate.js";
import JsZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
const ContactRepo = Repository.get("contacts");
const RealEstateRepo = Repository.get("realEstate");
const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};
export default {
  name: "EditRealEstateCard",
  components: {
    HeaderTitle,
    Files,
    Upload,
    Icon,
    Location,
    Map,
    HintField,
    RadioField,
    MinusPlusField,
    DraggableImages,
    InfoField,
    EditImage,
    PropertyCongrats,
  },
  props: {
    data: Object,
    onView: Function,
    onCloseSimple: Function,
    onClose: Function,
    refreshEstate: Function,
    updateEstate: Function,
    uploadDocSuccessHandler: Function,
    updateDocSuccessHandler: Function,
    deleteDocSuccessHandler: Function,
  },
  created() {
    this.initialData();
    this.fetchLocation("regions");
  },
  data() {
    return {
      loader: false,
      dwnPhotosloader: false,
      dwnWtPhotosloader: false,
      isUploadingImg: false,
      isUploadingBannerImg: false,
      isDeletingImg: false,
      isDeletingBannerImg: false,
      dialog: {
        open: false,
        data: null,
        type: null,
      },
      docTypesList: RealEstateModel.docTypesList,
      distanceRadioFields: [
        { label: i18n.t("meters2"), value: 0 },
        { label: i18n.t("km2"), value: 1 },
      ],
      yesOrNoFields: [
        {
          label: "no",
          value: 0,
        },
        {
          label: "yes",
          value: 1,
        },
      ],
      securityFields: [
        {
          label: "toMeOnly",
          value: 0,
        },
        {
          label: "office",
          value: 1,
        },
        {
          label: "network",
          value: 2,
        },
        {
          label: "public",
          value: 3,
        },
      ],
      errorOnCharacteristics: false,
      errorOnMarketing: false,
      errorOnLocation: false,
      publishLoading: false,
      temp: null,
      owner: {
        _id: null,
        name: null,
        Photo: null,
      },
      searchOwner: null,
      ownerAutocompleteLoader: null,
      ownerList: [],
      searchAgent: null,
      agentAutocompleteLoader: false,
      agentsList: [],
      notary: {
        _id: null,
        fullname: null,
        Photo: null,
      },
      searchNotary: null,
      notaryAutocompleteLoader: false,
      notariesList: [],
      attorney: {
        _id: null,
        fullname: null,
        Photo: null,
      },
      searchAttorney: null,
      attorneyAutocompleteLoader: false,
      attorneysList: [],
      agent: {
        _id: null,
        fullname: null,
        Photo: null,
      },
      sLocationDetails: null,
      locationMarkers: [],
      locPlaces: [],
      regionsList: [],
      regionsLoading: false,
      statesList: [],
      statesLoading: false,
      districtsList: [],
      districtsLoading: false,
      boroughsList: [],
      boroughsLoading: false,
      show: true,
      existingPlace: null,
      unit: null,
      id: null,
      menu: false,
      menu1: false,
      menu2: false,
      menu3: false,
      propertyCategories: null,
      type: null,
      buildingStatusSelected: null,
      objectivePrice: null,
      esign: null,
      esignCheckbox: false,
      orderStart: null,
      companyName: null,
      propertySubCategories: null,
      available: null,
      levels: 0,
      bathrooms: 0,
      bedrooms: 0,
      balconies: 0,
      numberOfPlaces: 0,
      livingRooms: 0,
      wc: 0,
      plot_unit: null,
      commandType: null,
      balconies_in_sqm: null,
      parking: false,
      parking_spots: null,
      storage_room: false,
      storage_room_sqm: null,
      attic: false,
      attic_in_sqm: null,
      service_room: false,
      service_room_in_sqm: null,
      autonomous_heating: false,
      solar_water_heater: false,
      air_conditioning: false,
      fireplace: false,
      gas: false,
      stove: false,
      central_heating: false,
      under_floor_heating: false,
      natural_gas: false,
      oil: false,
      electrical_heating: false,
      heat_accumulator: false,
      owned_terrace: false,
      owned_terrace_in_sqm: null,
      garden_in_sqm: null,
      garden: false,
      playroom: false,
      playroom_in_sqm: null,
      pool: false,
      poolSqft: null,
      personalComments: null,
      kitchens: 0,
      item: null,
      monthly_rent_price: null,
      deposit_amount: null,
      yearly_tax: null,
      yearly_yield: null,
      yearly_income_before_tax: null,
      bank_name: null,
      underConstraction: undefined,
      buildYear: undefined,
      renovateYear: null,
      floor: [],
      floorSelected: [],
      selectBoxesSelected: [],
      orientationSelected: [],
      energy_class: null,
      suitable_for_investment: null,
      typeOfParkingSelected: [],
      amount_or_percentage: null,
      shared_fees_monthly_price: null,
      selectPositionSelected: [],
      zoneSelected: [],
      dimensions: null,
      can_build: null,
      coverage_coefficient: null,
      structure_coefficient: null,
      benefits: null,
      estateCode: null,
      chooseAViewSelected: [],
      currentUse: null,
      kind: null,
      date_of_lease_expiration: null,
      date_of_lease_expiration_formatted: null,
      currentUseDateFrom: null,
      currentUseDateFromFormatted: null,
      payment_in_advance_date: null,
      payment_in_advance_date_formatted: null,
      payment_in_advance_amount: null,
      price: null,
      air: null,
      selectedSecurity: null,
      negotiable_price: false,
      proUse: false,
      outOfPlan: false,
      storage: false,
      fromManufacturer: false,
      technicalFeaturesInteriorSelected: null,
      suitableForSelected: null,
      outdoorSpacesSelected: null,
      status: null,
      typeOfMandateSelected: null,
      securitySelected: null,
      propertyResponsiblePerson: null,
      inlandCommission: null,
      inlandCommissionNoTax: null,
      orderCode: null,
      outlandCommission: null,
      outlandCommissionNoTax: null,
      youtubeLink: null,
      selectedDistanceUnit: 0,
      mortgage: false,
      addressCheckbox: false,
      watermark: false,
      address: null,
      exactLocationCheckbox: false,
      gps: null,
      keys: 0,
      bannerAtTheEntrance: 0,
      pano: 0,
      bannerOnStreets: 0,
      activeLangTab: 0,
      greekDescription: "",
      englishDescription: "",
      arabicDescription: "",
      chinesseDescription: "",
      activeTab: 0,
      agentPrice: null,
      furnished: null,
      luxurious: null,
      screens: null,
      bright: null,
      painted: null,
      electrical_equipment: null,
      satellite_antenna: null,
      pets_allowed: null,
      internal_staircase: null,
      elevator: null,
      safety_door: null,
      alarm: null,
      security_cam: null,
      cctv: null,
      security_systems: null,
      veranda: false,
      access_for_disabled_people: false,
      awnings: false,
      bbq: false,
      separate_entrance: false,
      suitable_for_students: false,
      airbnb: false,
      suitable_for_commercial_use: false,
      distance_from_sea: null,
      distance_from_airport: null,
      distance_from_acropolis: null,
      distance_from_bank: null,
      distance_from_schools: null,
      distance_from_super_market: null,
      distance_from_metro: null,
      distance_from_commuting_stops: null,
      distance_from_train: null,
      distance_from_square: null,
      distance_from_park: null,
      distance_from_hospital: null,
      distance_from_suburban: null,
      distance_from_tram: null,
      pool_in_sqm: null,
      region: null,
      state: null,
      district: null,
      borough: null,
      distance_from_international_schools: null,
    };
  },
  validations() {
    const category = this.data.Category;
    const suitForInvestment = this.suitable_for_investment;
    return {
      //required on characteristics
      floor: {
        required: requiredIf(() => {
          return !["Γη", "Αξιοποιήσιμη Γη", "ΓΗ"].includes(category);
        }),
      },
      energy_class: {
        required: requiredIf(() => {
          return !["Γη", "Αξιοποιήσιμη Γη", "ΓΗ"].includes(category);
        }),
      },
      date_of_lease_expiration_formatted: {
        required: requiredIf(() => {
          return (
            suitForInvestment != "Δεν έιναι μισθωμένο" &&
            suitForInvestment != null
          );
        }),
      },
      monthly_rent_price: {
        required: requiredIf(() => {
          return (
            suitForInvestment != "Δεν έιναι μισθωμένο" &&
            suitForInvestment != null
          );
        }),
      },
      buildYear: {
        required: requiredIf(() => {
          return (
            this.buildingStatusSelected != "Υπο κατασκευή" &&
            !this.buildYear &&
            !["Γη", "Αξιοποιήσιμη Γη", "ΓΗ"].includes(category)
          );
        }),
      },
      commandType: { required },
      price: { required },
      // objectivePrice: {required,},
      // agentPrice: { required, },
      unit: { required },
      //required on location
      region: { required },
      state: { required },
      district: { required },
    };
  },
  computed: {
    ...mapGetters([
      "realEstateCategoriesList",
      "buildingStatusList",
      "publishedList",
      "statusList",
      "energyClassList",
      "investmentList",
      "typeOfParkingList",
      "selectBoxesList",
      "floorList",
      "orientationList",
      "selectPositionList",
      "zoneList",
      "chooseAViewList",
      "distancesList",
      "floorsList",
      "kindList",
      "availabilityList",
      "getAssignationsList",
    ]),
    ...mapState({
      listRealEstate: (state) => state.listRealEstate,
      userDetails: (state) => state.auth.userDetails,
    }),
    ...mapActions(["setAreas"]),
    locale() {
      return this.$i18n.locale != undefined ? this.$i18n.locale : "gr";
    },

    checkFloorNecessary() {
      return !["Γη", "Αξιοποιήσιμη Γη", "ΓΗ"].includes(this.category)
        ? i18n.t("floors") + "*"
        : i18n.t("floors");
    },

    orderForSpesificCustomer() {
      return (
        ![undefined, null, ""].includes(this.inlandCommissionNoTax) ||
        ![undefined, null, ""].includes(this.inlandCommission)
      );
    },
    orderForOtherCustomer() {
      return (
        ![undefined, null, ""].includes(this.outlandCommissionNoTax) ||
        ![undefined, null, ""].includes(this.outlandCommission)
      );
    },
    images() {
      return this.data.photos ? this.data?.photos : [];
    },
    dragImages() {
      return !this.watermark
        ? this.data.photos
          ? this.data.photos
          : []
        : this.data.watermarkedphotos
        ? this.data.watermarkedphotos
        : [];
    },
    bannerImage() {
      return this.data.photos
        ? this.data.photos.filter((p) => {
            if (CoreHelper.parseBoolean(p.is_banner) == true) return p;
          })
        : [];
    },
    descriptionLength: {
      get: function () {
        if (this.activeLangTab == 0 && this.greekDescription) {
          return this.greekDescription ? this.greekDescription.length : 0;
        } else if (this.activeLangTab == 1) {
          return this.englishDescription ? this.englishDescription.length : 0;
        } else if (this.activeLangTab == 2) {
          return this.arabicDescription ? this.arabicDescription.length : 0;
        } else if (this.activeLangTab == 3) {
          return this.chinesseDescription ? this.chinesseDescription.length : 0;
        }
        return 0;
      },
    },
    distanceUnit: {
      get: function () {
        if (this.selectedDistanceUnit == 0) return "meters";
        else return "km";
      },
    },
    getSubCategoryList() {
      return this.realEstateCategoriesList.filter((c) => {
        if (this.data.Category) {
          if (c.val == this.data.Category) {
            return c;
          }
        } else {
          return c;
        }
      })[0].subCategoriesList;
    },
    floorErrorMessages() {
      const errors = [];
      if (!this.$v.floor.$dirty) return errors;
      if (!this.$v.floor.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    energyClassErrorMessages() {
      const errors = [];
      if (!this.$v.energy_class.$dirty) return errors;
      if (!this.$v.energy_class.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    commandTypeErrorMessages() {
      const errors = [];
      if (!this.$v.commandType.$dirty) return errors;
      if (!this.$v.commandType.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    priceErrorMessages() {
      const errors = [];
      if (!this.$v.price.$dirty) return errors;
      if (!this.$v.price.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    monthlyRentPriceErrorMessages() {
      const errors = [];
      if (!this.$v.monthly_rent_price.$dirty) return errors;
      if (!this.$v.monthly_rent_price.required)
        errors.push(i18n.t("requiredField"));
      return errors;
    },
    buildYearErrorMessages() {
      const errors = [];
      if (!this.$v.buildYear.$dirty) return errors;
      if (!this.$v.buildYear.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    dateOfLeaseErrorMessages() {
      const errors = [];
      if (!this.$v.date_of_lease_expiration_formatted.$dirty) return errors;
      if (!this.$v.date_of_lease_expiration_formatted.required)
        errors.push(i18n.t("requiredField"));
      return errors;
    },
    // objectivePriceErrorMessages() {
    //   const errors = [];
    //   if (!this.$v.objectivePrice.$dirty) return errors;
    //   if (!this.$v.objectivePrice.required)
    //     errors.push(i18n.t("requiredField"));
    //   return errors;
    // },
    // agentPriceErrorMessages() {
    //   const errors = [];
    //   if (!this.$v.agentPrice.$dirty) return errors;
    //   if (!this.$v.agentPrice.required) errors.push(i18n.t("requiredField"));
    //   return errors;
    // },
    unitErrorMessages() {
      const errors = [];
      if (!this.$v.unit.$dirty) return errors;
      if (!this.$v.unit.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    regionErrorMessages() {
      const errors = [];
      if (!this.$v.region.$dirty) return errors;
      if (!this.$v.region.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    cityErrorMessages() {
      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      if (!this.$v.state.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    districtErrorMessages() {
      const errors = [];
      if (!this.$v.district.$dirty) return errors;
      if (!this.$v.district.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
  },
  methods: {
    initialData() {
      if (this.data["_id"]) this.id = this.data["_id"];
      if (this.data.Type) this.type = this.data.Type;
      if (this.data.Kind) this.kind = this.data.Kind;
      if (this.data.floors && this.data.floors.length > 0) {
        let floors = [];
        this.data.floors.map((m) => {
          let floorVal = ![null, undefined, ""].includes(m.FloorValue)
            ? Number(m.FloorValue)
            : ![null, undefined, ""].includes(m.Value)
            ? Number(m.Value)
            : m;
          let floorLit = ![null, undefined, ""].includes(m.FloorLiteral)
            ? m.FloorLiteral
            : i18n.t(floorVal);
          let fl = { val: floorVal, text: floorLit };
          floors.push(fl);
        });
        this.floor = floors;
      }
      if (this.data.Price) this.price = this.data.Price;
      if (this.data.air) this.air = this.data.air;
      if (this.data.ObjectivePrice)
        this.objectivePrice = this.data.ObjectivePrice;
      if (this.data.AgentPrice) this.agentPrice = this.data.AgentPrice;
      if (this.data.Unit) this.unit = this.data.Unit;
      if (this.data.plot_unit) this.plot_unit = this.data.plot_unit;
      if (this.data.CurrentUse) this.currentUse = this.data.CurrentUse;
      if (this.data.CurrentUseDateFrom)
        this.currentUseDateFrom = this.data.CurrentUseDateFrom;

      // Έτος κατασκευής
      if (this.data.BuildYear != undefined)
        this.buildYear = this.data.BuildYear;
      // Έτος ανακαίνησης
      if (this.data.RenovationYear != undefined)
        this.renovateYear = this.data.RenovationYear;
      // Κουφώματα
      //Αποστάσεις
      this.selectedDistanceUnit = this.data.DistanceMeasure;
      if (this.data.characteristics) {
        // Γη περισσότερα στοιχεία οικοπέδου
        if (this.data.characteristics.dimensions)
          this.dimensions = this.data.characteristics.dimensions;
        if (this.data.characteristics.can_build)
          this.can_build = this.data.characteristics.can_build;
        if (this.data.characteristics.coverage_coefficient)
          this.coverage_coefficient =
            this.data.characteristics.coverage_coefficient;
        if (this.data.characteristics.structure_coefficient)
          this.structure_coefficient =
            this.data.characteristics.structure_coefficient;
        if (this.data.characteristics.benefits)
          this.benefits = this.data.characteristics.benefits;
        this.negotiable_price = CoreHelper.parseBoolean(
          this.data.negotiable_price
        );
        if (this.data.characteristics.proUse)
          this.proUse = this.data.characteristics.proUse;
        if (this.data.characteristics.outOfPlan)
          this.outOfPlan = this.data.characteristics.outOfPlan;
        if (this.data.characteristics.storage)
          this.storage = this.data.characteristics.storage;
        if (this.data.characteristics.fromManufacturer)
          this.fromManufacturer = this.data.characteristics.fromManufacturer;
        if (this.data.characteristics.levels)
          this.levels = this.data.characteristics.levels;
        if (this.data.characteristics.bedrooms)
          this.bedrooms = this.data.characteristics.bedrooms;
        if (this.data.characteristics.kitchen_rooms)
          this.kitchens = this.data.characteristics.kitchen_rooms;
        if (this.data.characteristics.bathrooms)
          this.bathrooms = this.data.characteristics.bathrooms;
        if (this.data.characteristics.numberOfPlaces)
          this.numberOfPlaces = this.data.characteristics.numberOfPlaces;
        if (this.data.characteristics.balconies)
          this.balconies = this.data.characteristics.balconies;
        if (this.data.characteristics.balconies_in_sqm)
          this.balconies_in_sqm = this.data.characteristics.balconies_in_sqm;
        if (this.data.characteristics.living_rooms)
          this.livingRooms = this.data.characteristics.living_rooms;
        if (this.data.characteristics.wc)
          this.wc = this.data.characteristics.wc;
        if (this.data.characteristics.storage_room)
          this.storage_room = this.data.characteristics.storage_room;
        if (this.data.characteristics.storage_room_sqm)
          this.storage_room_sqm = this.data.characteristics.storage_room_sqm;
        if (this.data.characteristics.attic)
          this.attic = this.data.characteristics.attic;
        if (this.data.characteristics.attic_in_sqm)
          this.attic_in_sqm = this.data.characteristics.attic_in_sqm;
        if (this.data.characteristics.service_room)
          this.service_room = this.data.characteristics.service_room;
        if (this.data.characteristics.service_room_in_sqm)
          this.service_room_in_sqm =
            this.data.characteristics.service_room_in_sqm;
        if (this.data.characteristics.owned_terrace)
          this.owned_terrace = this.data.characteristics.owned_terrace;
        if (this.data.characteristics.owned_terrace_in_sqm)
          this.owned_terrace_in_sqm =
            this.data.characteristics.owned_terrace_in_sqm;
        if (this.data.characteristics.playroom)
          this.playroom = this.data.characteristics.playroom;
        if (this.data.characteristics.playroom_in_sqm)
          this.playroom_in_sqm = this.data.characteristics.playroom_in_sqm;
        if (this.data.characteristics.payment_in_advance_date)
          this.payment_in_advance_date =
            this.data.characteristics.payment_in_advance_date;
        if (this.data.characteristics.payment_in_advance_amount)
          this.payment_in_advance_amount =
            this.data.characteristics.payment_in_advance_amount;
        if (this.data.characteristics.monthly_rent_price)
          this.monthly_rent_price =
            this.data.characteristics.monthly_rent_price;
        if (this.data.characteristics.deposit_amount)
          this.deposit_amount = this.data.characteristics.deposit_amount;
        if (this.data.characteristics.date_of_lease_expiration)
          this.date_of_lease_expiration =
            this.data.characteristics.date_of_lease_expiration;
        if (this.data.characteristics.shared_fees_monthly_price)
          this.shared_fees_monthly_price =
            this.data.characteristics.shared_fees_monthly_price;
        if (this.data.characteristics.yearly_tax)
          this.yearly_tax = this.data.characteristics.yearly_tax;
        if (this.data.characteristics.yearly_yield)
          this.yearly_yield = this.data.characteristics.yearly_yield;
        if (this.data.characteristics.yearly_income_before_tax)
          this.yearly_income_before_tax =
            this.data.characteristics.yearly_income_before_tax;
        this.mortgage = CoreHelper.parseBoolean(
          this.data.characteristics.mortgage
        );
        if (this.data.characteristics.bank_name)
          this.bank_name = this.data.characteristics.bank_name;
        if (this.data.characteristics.amount_or_percentage)
          this.amount_or_percentage =
            this.data.characteristics.amount_or_percentage;
        if (this.data.characteristics.energy_class)
          this.energy_class = this.data.characteristics.energy_class[0];
        if (this.data.characteristics.suitable_for_investment)
          this.suitable_for_investment =
            this.data.characteristics.suitable_for_investment[0];
        this.autonomous_heating = CoreHelper.parseBoolean(
          this.data.characteristics.autonomous_heating
        );
        this.air_conditioning = CoreHelper.parseBoolean(
          this.data.characteristics.air_conditioning
        );
        this.solar_water_heater = CoreHelper.parseBoolean(
          this.data.characteristics.solar_water_heater
        );
        this.fireplace = CoreHelper.parseBoolean(
          this.data.characteristics.fireplace
        );
        this.gas = CoreHelper.parseBoolean(this.data.characteristics.gas);
        this.stove = CoreHelper.parseBoolean(this.data.characteristics.stove);
        this.central_heating = CoreHelper.parseBoolean(
          this.data.characteristics.central_heating
        );
        this.under_floor_heating = CoreHelper.parseBoolean(
          this.data.characteristics.under_floor_heating
        );
        this.natural_gas = CoreHelper.parseBoolean(
          this.data.characteristics.natural_gas
        );
        this.oil = CoreHelper.parseBoolean(this.data.characteristics.oil);
        this.electrical_heating = CoreHelper.parseBoolean(
          this.data.characteristics.electrical_heating
        );
        this.heat_accumulator = CoreHelper.parseBoolean(
          this.data.characteristics.heat_accumulator
        );
        // Κατάσταση
        if (
          CoreHelper.parseBoolean(this.data.characteristics.renovated) == true
        )
          this.buildingStatusSelected = "Ανακαινισμένο";
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.perfect_condition
          ) == true
        )
          this.buildingStatusSelected = "Άριστη κατάσταση";
        if (
          CoreHelper.parseBoolean(this.data.characteristics.good_condition) ==
          true
        )
          this.buildingStatusSelected = "Καλή κατάσταση";
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.under_construction
          ) == true
        )
          this.buildingStatusSelected = "Υπο κατασκευή";
        if (
          CoreHelper.parseBoolean(this.data.characteristics.newly_built) == true
        )
          this.buildingStatusSelected = "Νεόδμητο";
        if (
          CoreHelper.parseBoolean(this.data.characteristics.unfinished) == true
        )
          this.buildingStatusSelected = "Ημιτελές";
        if (
          CoreHelper.parseBoolean(this.data.characteristics.needs_renovation) ==
          true
        )
          this.buildingStatusSelected = "Χρήζει ανακαίνισης";
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.double_layer_windows
          ) == true
        )
          this.selectBoxesSelected.push("Διπλά Tζάμια");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.wooden_frames) ==
          true
        )
          this.selectBoxesSelected.push("Ξύλινα κουφώματα");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.synthetic_frames) ==
          true
        )
          this.selectBoxesSelected.push("Συνθετικά κουφώματα");
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.electrical_frames
          ) == true
        )
          this.selectBoxesSelected.push("Ηλεκτρικά κουφώματα");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.aluminum_frames) ==
          true
        )
          this.selectBoxesSelected.push("Κουφώματα αλουμινίου");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.sliding_rollers) ==
          true
        )
          this.selectBoxesSelected.push("Συρόμενα Ρολά");
        if (CoreHelper.parseBoolean(this.data.characteristics.screens) == true)
          this.selectBoxesSelected.push("Σίτες");
        //Δάπεδο
        if (CoreHelper.parseBoolean(this.data.characteristics.cement) == true)
          this.floorSelected.push("Τσιμέντο");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.marble_floors) ==
          true
        )
          this.floorSelected.push("Μάρμαρο");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.wooden_floor) ==
          true
        )
          this.floorSelected.push("Ξύλο");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.stone_floors) ==
          true
        )
          this.floorSelected.push("Πέτρα");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.tile_floors) == true
        )
          this.floorSelected.push("Πλακάκι");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.mosaic_floor) ==
          true
        )
          this.floorSelected.push("Μωσαϊκό");
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.marble_wooden_floor
          ) == true
        )
          this.floorSelected.push("Μάρμαρο - Ξύλο");
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.marble_tile_floor
          ) == true
        )
          this.floorSelected.push("Μάρμαρο-Πλακάκι");
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.stone_wooden_floors
          ) == true
        )
          this.floorSelected.push("Πέτρα-Ξύλο");
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.stone_marble_floors
          ) == true
        )
          this.floorSelected.push("Πέτρα - Μάρμαρο");
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.tile_wooden_floor
          ) == true
        )
          this.floorSelected.push("Πλακάκι-Ξύλο");
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.mosaic_wooden_floors
          ) == true
        )
          this.floorSelected.push("Μωσαϊκό-Ξύλο");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.industrial_floor) ==
          true
        )
          this.floorSelected.push("Βιομηχανικό δάπεδο");
        if (CoreHelper.parseBoolean(this.data.characteristics.laminate) == true)
          this.floorSelected.push("Laminate");
        // Τεχνικά γνωρίσματα
        this.furnished = CoreHelper.parseBoolean(
          this.data.characteristics.furnished
        );
        this.bright = CoreHelper.parseBoolean(this.data.characteristics.bright);
        this.painted = CoreHelper.parseBoolean(
          this.data.characteristics.painted
        );
        this.electrical_equipment = CoreHelper.parseBoolean(
          this.data.characteristics.electrical_equipment
        );
        this.satellite_antenna = CoreHelper.parseBoolean(
          this.data.characteristics.satellite_antenna
        );
        this.luxurious = CoreHelper.parseBoolean(
          this.data.characteristics.luxurious
        );
        this.screens = CoreHelper.parseBoolean(
          this.data.characteristics.screens
        );
        this.pets_allowed = CoreHelper.parseBoolean(
          this.data.characteristics.pets_allowed
        );
        this.internal_staircase = CoreHelper.parseBoolean(
          this.data.characteristics.internal_staircase
        );
        this.elevator = CoreHelper.parseBoolean(
          this.data.characteristics.elevator
        );
        // Ασφάλεια
        this.safety_door = CoreHelper.parseBoolean(
          this.data.characteristics.safety_door
        );
        this.alarm = CoreHelper.parseBoolean(this.data.characteristics.alarm);
        this.security_cam = CoreHelper.parseBoolean(
          this.data.characteristics.security_cam
        );
        this.cctv = CoreHelper.parseBoolean(this.data.characteristics.cctv);
        this.security_systems = CoreHelper.parseBoolean(
          this.data.characteristics.security_systems
        );
        // Τεχνικά Γνωρίσματα - θέα
        if (
          CoreHelper.parseBoolean(this.data.characteristics.urban_view) == true
        )
          this.chooseAViewSelected.push("Πόλη");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.mountain_view) ==
          true
        )
          this.chooseAViewSelected.push("Βουνό");
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.acropolis_or_monuments_view
          ) == true
        )
          this.chooseAViewSelected.push("Μνημεία");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.lake_view) == true
        )
          this.chooseAViewSelected.push("Λίμνη");
        if (CoreHelper.parseBoolean(this.data.characteristics.sea_view) == true)
          this.chooseAViewSelected.push("Θάλασσα");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.river_view) == true
        )
          this.chooseAViewSelected.push("Ποτάμι");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.park_view) == true
        )
          this.chooseAViewSelected.push("Πάρκο");
        // Ζώνη
        if (
          CoreHelper.parseBoolean(this.data.characteristics.residential_zone) ==
          true
        )
          this.zoneSelected.push("Οικιστική ζώνη");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.commercial_zone) ==
          true
        )
          this.zoneSelected.push("Εμπορική ζώνη");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.rural_zone) == true
        )
          this.zoneSelected.push("Αγροτική ζώνη");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.industrial_zone) ==
          true
        )
          this.zoneSelected.push("Βιομηχανική ζώνη");
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.regeneration_zone
          ) == true
        )
          this.zoneSelected.push("Ζώνη ανάπλασης");
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.outside_city_plan
          ) == true
        )
          this.zoneSelected.push("Εκτός σχεδίου");
        // Θεση
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.eastern_western_coverage
          ) == true
        )
          this.selectPositionSelected.push("Ανατολικοδυτικός");
        if (CoreHelper.parseBoolean(this.data.characteristics.internal) == true)
          this.selectPositionSelected.push("Εσωτερικό");
        if (CoreHelper.parseBoolean(this.data.characteristics.facade) == true)
          this.selectPositionSelected.push("Προσόψεως");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.penthouse) == true
        )
          this.selectPositionSelected.push("Ρετιρέ");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.double_frontage) ==
          true
        )
          this.selectPositionSelected.push("Διαμπερές");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.on_main_road) ==
          true
        )
          this.selectPositionSelected.push("Σε κεντρικό δρόμο");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.on_sidewalk) == true
        )
          this.selectPositionSelected.push("Σε πεζόδρομο");
        if (CoreHelper.parseBoolean(this.data.characteristics.corner) == true)
          this.selectPositionSelected.push("Γωνιακό");
        //Προσανατολισμος
        if (
          CoreHelper.parseBoolean(this.data.characteristics.eastern_coverage) ==
          true
        )
          this.orientationSelected.push("eastern");
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.eastern_western_coverage
          ) == true
        )
          this.orientationSelected.push("eastWest");
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.eastern_meridian_coverage
          ) == true
        )
          this.orientationSelected.push("easternMeridian");
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.northern_coverage
          ) == true
        )
          this.orientationSelected.push("north");
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.northern_eastern_coverage
          ) == true
        )
          this.orientationSelected.push("northEast");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.north_west) == true
        )
          this.orientationSelected.push("northWest");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.western_coverage) ==
          true
        )
          this.orientationSelected.push("west");
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.meridian_coverage
          ) == true
        )
          this.orientationSelected.push("meridian");
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.southern_coverage
          ) == true
        )
          this.orientationSelected.push("south");
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.southern_eastern_coverage
          ) == true
        )
          this.orientationSelected.push("southEast");
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.southern_western_coverage
          ) == true
        )
          this.orientationSelected.push("southWest");
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.western_meridian_coverage
          ) == true
        )
          this.orientationSelected.push("westMeridian");
        // parking
        this.parking = CoreHelper.parseBoolean(
          this.data.characteristics.parking
        );
        if (this.data.characteristics.parking_spots)
          this.parking_spots = this.data.characteristics.parking_spots;
        if (
          CoreHelper.parseBoolean(this.data.characteristics.enclosed_parking) ==
          true
        )
          this.typeOfParkingSelected.push("closed");
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.underground_parking
          ) == true
        )
          this.typeOfParkingSelected.push("basement");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.pilot_parking) ==
          true
        )
          this.typeOfParkingSelected.push("pilot");
        if (
          CoreHelper.parseBoolean(this.data.characteristics.open_parking) ==
          true
        )
          this.typeOfParkingSelected.push("open");
        // Κηπος
        this.garden = CoreHelper.parseBoolean(this.data.characteristics.garden);
        if (this.data.characteristics.garden_in_sqm)
          this.garden_in_sqm = this.data.characteristics.garden_in_sqm;
        // Πισίνα
        this.pool = CoreHelper.parseBoolean(this.data.characteristics.pool);
        if (this.data.characteristics.pool_in_sqm)
          this.pool_in_sqm = this.data.characteristics.pool_in_sqm;
        this.veranda = CoreHelper.parseBoolean(
          this.data.characteristics.veranda
        );
        this.access_for_disabled_people = CoreHelper.parseBoolean(
          this.data.characteristics.access_for_disabled_people
        );
        this.awnings = CoreHelper.parseBoolean(
          this.data.characteristics.awnings
        );
        this.bbq = CoreHelper.parseBoolean(this.data.characteristics.bbq);
        this.separate_entrance = CoreHelper.parseBoolean(
          this.data.characteristics.separate_entrance
        );
        // Κατάλληλο Για
        this.suitable_for_students = CoreHelper.parseBoolean(
          this.data.characteristics.suitable_for_students
        );
        this.airbnb = CoreHelper.parseBoolean(this.data.characteristics.airbnb);
        this.suitable_for_commercial_use = CoreHelper.parseBoolean(
          this.data.characteristics.suitable_for_commercial_use
        );
        if (this.data.characteristics.distance_from_sea)
          this.distance_from_sea = this.data.characteristics.distance_from_sea;
        if (this.data.characteristics.distance_from_airport)
          this.distance_from_airport =
            this.data.characteristics.distance_from_airport;
        if (this.data.characteristics.distance_from_acropolis)
          this.distance_from_acropolis =
            this.data.characteristics.distance_from_acropolis;
        if (this.data.characteristics.distance_from_bank)
          this.distance_from_bank =
            this.data.characteristics.distance_from_bank;
        if (this.data.characteristics.distance_from_schools)
          this.distance_from_schools =
            this.data.characteristics.distance_from_schools;
        if (this.data.characteristics.distance_from_super_market)
          this.distance_from_super_market =
            this.data.characteristics.distance_from_super_market;
        if (this.data.characteristics.distance_from_metro)
          this.distance_from_metro =
            this.data.characteristics.distance_from_metro;
        if (this.data.characteristics.distance_from_commuting_stops)
          this.distance_from_commuting_stops =
            this.data.characteristics.distance_from_commuting_stops;
        if (this.data.characteristics.distance_from_train)
          this.distance_from_train =
            this.data.characteristics.distance_from_train;
        if (this.data.characteristics.distance_from_square)
          this.distance_from_square =
            this.data.characteristics.distance_from_square;
        if (this.data.characteristics.distance_from_park)
          this.distance_from_park =
            this.data.characteristics.distance_from_park;
        if (this.data.characteristics.distance_from_hospital)
          this.distance_from_hospital =
            this.data.characteristics.distance_from_hospital;
        if (this.data.characteristics.distance_from_suburban)
          this.distance_from_suburban =
            this.data.characteristics.distance_from_suburban;
        if (this.data.characteristics.distance_from_tram)
          this.distance_from_tram =
            this.data.characteristics.distance_from_tram;
        if (this.data.characteristics.distance_from_international_schools)
          this.distance_from_international_schools =
            this.data.characteristics.distance_from_international_schools;
        if (this.data.characteristics.keys)
          this.keys =
            CoreHelper.parseBoolean(this.data.characteristics.keys) == true
              ? 1
              : 0;
        if (this.data.characteristics.pano)
          this.pano =
            CoreHelper.parseBoolean(this.data.characteristics.pano) == true
              ? 1
              : 0;
        if (this.data.characteristics.banner)
          this.bannerAtTheEntrance =
            CoreHelper.parseBoolean(this.data.characteristics.banner) == true
              ? 1
              : 0;
        if (this.data.characteristics.banners_external)
          this.bannerOnStreets =
            CoreHelper.parseBoolean(
              this.data.characteristics.banners_external
            ) == true
              ? 1
              : 0;
        // (σε ποια άτομα θα είναι ορατό)
        if (
          CoreHelper.parseBoolean(
            this.data.characteristics.visible_only_to_me
          ) == true
        )
          this.selectedSecurity = 0;
        else if (
          CoreHelper.parseBoolean(
            this.data.characteristics.branch_visibility
          ) == true
        )
          this.selectedSecurity = 1;
        else if (
          CoreHelper.parseBoolean(
            this.data.characteristics.network_visibility
          ) == true
        )
          this.selectedSecurity = 2;
        else if (
          CoreHelper.parseBoolean(this.data.characteristics.visible) == true
        )
          this.selectedSecurity = 3;
      }
      // Περιγραφή
      if (this.data.descriptions) {
        this.greekDescription = this.data.descriptions.filter((d) => {
          if (d.Language == "gre") {
            return d;
          }
        })[0]?.Description;
        this.englishDescription = this.data.descriptions.filter((d) => {
          if (d.Language == "eng") {
            return d;
          }
        })[0]?.Description;
        this.arabicDescription = this.data.descriptions.filter((d) => {
          if (d.Language == "ara") {
            return d;
          }
        })[0]?.Description;
        this.chinesseDescription = this.data.descriptions.filter((d) => {
          if (d.Language == "chn") {
            return d;
          }
        })[0]?.Description;
      }
      //Διαχείριση ακινήτου
      if (this.data.EstateCode) {
        this.estateCode = this.data.EstateCode;
        this.orderCode = this.data.EstateCode;
      }
      if (this.data.Assignation) this.commandType = this.data.Assignation;
      if (this.data.Status) this.status = this.data.Status;
      if (this.data.InlandCommission)
        this.inlandCommission = this.data.InlandCommission;
      if (this.data.InlandCommissionNoTax)
        this.inlandCommissionNoTax = this.data.InlandCommissionNoTax;
      if (this.data.OutlandCommission)
        this.outlandCommission = this.data.OutlandCommission;
      if (this.data.OutlandCommissionNoTax)
        this.outlandCommissionNoTax = this.data.OutlandCommissionNoTax;
      if (this.data.personalComments)
        this.personalComments = this.data.personalComments;
      if (this.data.Youtube) this.youtubeLink = this.data.Youtube;
      // Location
      if (this.data.GPS) this.gps = this.data.GPS[0] + "," + this.data.GPS[1];
      if (this.data.GoogleAddress) this.address = this.data.GoogleAddress;
      if (this.data.Area) {
        let splitArea = this.data.Area.split("/");
        this.region = splitArea[0] ? splitArea[0] : null;
        this.state = splitArea[1] ? splitArea[1] : null;
        this.district = splitArea[2] ? splitArea[2] : null;
        this.borough = splitArea[3] ? splitArea[3] : null;
      }
      if (this.data.CustomerID ) {
        this.owner["_id"] = this.data.CustomerID ? this.data.CustomerID : null;
        this.owner.name =
          (this.data.ClientName ? this.data.ClientName : "") +
          " " +
          (this.data.ClientSurName ? this.data.ClientSurName : "");
        this.owner.Photo =
          this.data.ClientPhoto && this.data.ClientPhoto.trim() != ""
            ? this.data.ClientPhoto
            : null;
        this.ownerList.push(this.owner);
      }
      if (this.data.UserID) {
        this.agent = {
          _id: this.data.UserID ? this.data.UserID : null,
          fullname:
            (this.data.AgentName ? this.data.AgentName : "") +
            " " +
            (this.data.AgentSurName ? this.data.AgentSurName : ""),
          Photo:
            this.data.AgentPhoto && this.data.AgentPhoto.trim() != ""
              ? this.data.AgentPhoto
              : null,
        };
        this.agentsList.push(this.agent);
      }
      if (this.data.NotaryID) {
        this.notary = {
          _id: this.data.NotaryID ? this.data.NotaryID : null,
          fullname:
            (this.data.NotaryName ? this.data.NotaryName : "") +
            " " +
            (this.data.NotarySurName ? this.data.NotarySurName : ""),
          Photo:
            this.data.NotaryPhoto && this.data.NotaryPhoto.trim() != ""
              ? this.data.NotaryPhoto
              : null,
        };
        this.notariesList.push(this.notary);
      }
      if (this.data.AttorneyID) {
        this.attorney = {
          _id: this.data.AttorneyID ? this.data.AttorneyID : null,
          fullname:
            (this.data.AttorneyName ? this.data.AttorneyName : "") +
            " " +
            (this.data.AttorneySurName ? this.data.AttorneySurName : ""),
          Photo:
            this.data.AttorneyPhoto && this.data.AttorneyPhoto.trim() != ""
              ? this.data.AttorneyPhoto
              : null,
        };
        this.attorneysList.push(this.attorney);
      }
    },
    async publishEstate() {
      try {
        this.$v.$touch();
        this.validationRequirements();
        if (this.$v.$invalid) return;
        this.publishLoading = true;
        this.status = "Ενεργό";
        this.saveHandler("publish");
        this.publishLoading = false;
      } catch (e) {
        this.publishLoading = false;
        throw e;
      }
    },
    setACLocation(loc) {
      this.sLocationDetails = loc;
    },
    setMarker(lat, lng) {
      this.locationMarkers = [
        {
          position: {
            lat: lat,
            lng: lng,
          },
          icon: { url: require("@/assets/maps/marker-a.png") },
        },
        // { url: require('../../assets/maps/marker-b.png') }'
      ];
    },
    getUserPhoto(photo) {
      return CoreHelper.getImagePath({ path: photo });
    },
    async fetchOwnerList(val, type) {
      try {
        this.ownerAutocompleteLoader = true;
        let filters;
        if (this.userDetails.Role == "Admin") {
          filters = [
            { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
          ];
        } else if (
          ["Secretary", "OfficeAdmin", "HR"].includes(this.userDetails.Role)
        ) {
          filters = [
            { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
            [{ LastCompany: this.userDetails.Company }],
          ];
        } else {
          filters = [
            { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
            [{ Creator: this.userDetails._id }],
          ];
        }
        if (![null, undefined, ""].includes(val) && type == "id") {
          filters = [
            { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
            [{ _id: val }],
          ];
        } else if (
          ![null, undefined, ""].includes(val) &&
          type == "splitName"
        ) {
          if (filters.length == 1) {
            filters.push([]);
          }
          filters[1].push({
            ["LastName"]: {
              $regex: CoreHelper.toRegex(val.split(" ")[0]),
              $options: "i",
            },
          });
        }
        let resp = await ContactRepo.getOwners(filters, { page: 0, items: 20 });
        this.ownerList = resp.data.owners;
        if (this.owner) this.ownerList.push(this.owner);
        this.ownerAutocompleteLoader = false;
      } catch (e) {
        this.ownerAutocompleteLoader = false;
        throw e;
      }
    },
    async fetchAgentsList(val, type) {
      try {
        let filters;
        this.agentAutocompleteLoader = true;
        if (this.userDetails.Role == "Admin") {
          filters = [
            { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
          ];
        } else if (
          ["Secretary", "OfficeAdmin", "HR"].includes(this.userDetails.Role)
        ) {
          filters = [
            { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
            [{ LastCompany: this.userDetails.Company }],
          ];
        } else if (this.userDetails.Role == "Leader") {
          let ids = [];
          if (this.userDetails.Team && this.userDetails.Team.length > 0)
            ids = [...this.userDetails.Team, this.userDetails._id];
          else ids = [this.userDetails._id];
          filters = {
            _id: { $in: ids },
          };
          if (val != null) {
            filters.LastName = {
              $regex: CoreHelper.toRegex(val.split(" ")[0]),
              $options: "i",
            };
          }
          let resp = await ContactRepo.get(filters, {
            page: 0,
            items: 60,
          });
          this.agentsList = resp.data.contacts.map((a) => {
            return { ...a, fullname: a.LastName + " " + a.FirstName };
          });
          return false;
        } else {
          // filters = [
          //   { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
          //   [{ _id: this.userDetails._id }],
          // ];
          this.agentsList = [];
          return false;
        }

        if (![null, undefined, ""].includes(val) && type == "id") {
          filters = [
            { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
            [{ _id: val }],
          ];
        } else if (
          ![null, undefined, ""].includes(val) &&
          type == "splitName"
        ) {
          if (val != null) {
            if (filters.length == 1) {
              filters.push([]);
            }
            filters[1].push({
              ["LastName"]: {
                $regex: CoreHelper.toRegex(val.split(" ")[0]),
                $options: "i",
              },
            });
          }
        }
        let resp = await ContactRepo.getAgents(filters, { page: 0, items: 20 });
        this.agentsList = resp.data.agents.map((a) => {
          return { ...a, fullname: a.LastName + " " + a.FirstName };
        });
        if (this.agent) {
          this.agentsList.push(this.agent);
        }
        this.agentAutocompleteLoader = false;
      } catch (e) {
        this.agentAutocompleteLoader = false;
        throw e;
      }
    },
    async fetchAttorneysList(val, type) {
      try {
        let filters;
        this.attorneyAutocompleteLoader = true;
        if (this.userDetails.Role == "Admin") {
          filters = [
            { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
          ];
        } else if (
          ["Secretary", "OfficeAdmin", "HR"].includes(this.userDetails.Role)
        ) {
          filters = [
            { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
            [{ LastCompany: this.userDetails.Company }],
          ];
        } else {
          filters = [
            { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
            [{ Creator: this.userDetails._id }],
          ];
        }
        if (![null, undefined, ""].includes(val) && type == "id") {
          filters = [
            { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
            [{ _id: val }],
          ];
        } else if (
          ![null, undefined, ""].includes(val) &&
          type == "splitName"
        ) {
          if (filters.length == 1) {
            filters.push([]);
          }
          filters[1].push({
            ["LastName"]: {
              $regex: CoreHelper.toRegex(val.split(" ")[0]),
              $options: "i",
            },
          });
        }
        let resp = await RealEstateRepo.getLawyers(filters, {
          page: 0,
          items: 60,
        });
        this.attorneysList = resp.data.attorneys;
        if (this.attorney) this.attorneysList.push(this.attorney);
        this.attorneyAutocompleteLoader = false;
      } catch (e) {
        this.attorneyAutocompleteLoader = false;
        throw e;
      }
    },
    async fetchNotariesList(val, type) {
      try {
        let filters;
        this.notaryAutocompleteLoader = true;
        if (this.userDetails.Role == "Admin") {
          filters = [
            { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
          ];
        } else if (
          ["Secretary", "OfficeAdmin", "HR"].includes(this.userDetails.Role)
        ) {
          filters = [
            { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
            [{ LastCompany: this.userDetails.Company }],
          ];
        } else {
          filters = [
            { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
            [{ Creator: this.userDetails._id }],
          ];
        }
        if (![null, undefined, ""].includes(val) && type == "id") {
          filters = [
            { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
            [{ _id: val }],
          ];
        } else if (
          ![null, undefined, ""].includes(val) &&
          type == "splitName"
        ) {
          if (filters.length == 1) {
            filters.push([]);
          }
          filters[1].push({
            ["LastName"]: {
              $regex: CoreHelper.toRegex(val.split(" ")[0]),
              $options: "i",
            },
          });
        }
        let resp = await RealEstateRepo.getNotaries(filters, {
          page: 0,
          items: 20,
        });
        this.notariesList = resp.data.notaries;
        if (this.notary) this.notariesList.push(this.notary);
        this.notaryAutocompleteLoader = false;
      } catch (e) {
        this.notaryAutocompleteLoader = false;
        throw e;
      }
    },
    capitalizeFirstLetter(string) {
      return string
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    continueButton() {
      this.activeTab = this.activeTab == 2 ? 0 : (this.activeTab += 1);
    },
    updatePlusMinus(data) {
      this[data.fieldName] = data.textFieldValue;
    },
    updateRadioField(data) {
      this[data.fieldName] = this[data.radioValues][data.selectedRadio].value;
    },
    updateRatingField(data) {
      this[data.fieldName] = data.selectedRating;
    },
    clearFilters() {
      this.propertyCategories = null;
      this.type = null;
      this.buildingStatusSelected = null;
      this.esign = null;
      this.esignCheckbox = false;
      this.orderStart = null;
      this.companyName = null;
      this.propertySubCategories = null;
      this.available = null;
      this.levels = 0;
      this.bathrooms = 0;
      this.bedrooms = 0;
      this.numberOfPlaces = 0;
      this.balconies = 0;
      this.livingRooms = 0;
      this.wc = 0;
      this.balconies_in_sqm = null;
      this.parking = false;
      this.parking_spots = null;
      this.storage_room = false;
      this.storage_room_sqm = null;
      this.attic = false;
      this.attic_in_sqm = null;
      this.service_room = false;
      this.service_room_in_sqm = null;
      this.owned_terrace = false;
      this.owned_terrace_in_sqm = null;
      this.garden_in_sqm = null;
      this.garden = false;
      this.playroom = false;
      this.playroom_in_sqm = null;
      this.pool = false;
      this.poolSqft = null;
      this.personalComments = null;
      this.kitchens = 0;
      this.item = null;
      this.monthly_rent_price = null;
      this.deposit_amount = null;
      this.yearlyTaxes = null;
      this.yearly_yield = null;
      this.yearly_income_before_tax = null;
      this.bank_name = null;
      this.underConstraction = undefined;
      this.buildYear = undefined;
      this.renovateYear = null;
      this.floor = [];
      this.floorSelected = [];
      this.selectBoxesSelected = [];
      this.orientationSelected = [];
      this.energyClassSelected = null;
      this.typeOfParkingSelected = [];
      this.amount_or_percentage = null;
      this.shared_fees_monthly_price = null;
      this.selectPositionSelected = [];
      this.zoneSelected = [];
      this.estateCode = null;
      this.chooseAViewSelected = [];
      this.currentUse = null;
      this.kind = null;
      this.date_of_lease_expiration = null;
      this.date_of_lease_expiration_formatted = null;
      this.currentUseDateFrom = null;
      this.currentUseDateFromFormatted = null;
      this.payment_in_advance_date = null;
      this.payment_in_advance_date_formatted = null;
      this.payment_in_advance_amount = null;
      this.price = null;
      this.air = null;
      this.selectedSecurity = null;
      this.negotiable_price = false;
      this.proUse = false;
      this.outOfPlan = false;
      this.storage = false;
      this.fromManufacturer = false;
      this.technicalFeaturesInteriorSelected = null;
      this.suitableForSelected = null;
      this.outdoorSpacesSelected = null;
      this.status = null;
      (this.owner = { _id: null, fullname: null, Photo: null }),
        (this.typeOfMandateSelected = null);
      this.securitySelected = null;
      this.propertyResponsiblePerson = null;
      this.inlandCommission = null;
      this.inlandCommissionNoTax = null;
      this.orderCode = null;
      this.outlandCommission = null;
      this.outlandCommissionNoTax = null;
      this.selectedDistanceUnit = 0;
      this.mortgage = false;
      this.addressCheckbox = false;
      this.address = null;
      this.watermark = false;
      this.exactLocationCheckbox = false;
      this.gps = null;
      this.youtubeLink = null;
      this.activeLangTab = 0;
      this.keys = 0;
      this.bannerAtTheEntrance = 0;
      this.pano = 0;
      this.bannerOnStreets = 0;
      this.greekDescription = "";
      this.englishDescription = "";
      this.arabicDescription = "";
      this.chinesseDescription = "";
      this.region = null;
      this.state = null;
      this.district = null;
      this.borough = null;
      this.locationMarkers = [];
      this.activeTab = null;
      this.agentPrice = null;
      this.plot_unit = null;
      this.updatePlusMinus = null;
      this.yearly_tax = null;
      this.energy_class = null;
      this.suitable_for_investment = null;
      this.autonomous_heating = null;
      this.air_conditioning = null;
      this.solar_water_heater = null;
      this.fireplace = null;
      this.gas = null;
      this.stove = null;
      this.central_heating = null;
      this.under_floor_heating = null;
      this.natural_gas = null;
      this.oil = null;
      this.electrical_heating = null;
      this.heat_accumulator = null;
      this.furnished = null;
      this.electrical_equipment = null;
      this.satellite_antenna = null;
      this.luxurious = null;
      this.pets_allowed = null;
      this.internal_staircase = null;
      this.elevator = null;
      this.safety_door = null;
      this.security_cam = null;
      this.security_systems = null;
      this.alarm = null;
      this.cctv = null;
      this.pool_in_sqm = null;
      this.separate_entrance = null;
      this.access_for_disabled_people = null;
      this.bbq = null;
      this.suitable_for_students = null;
      this.suitable_for_commercial_use = null;
      this.distance_from_sea = null;
      this.distance_from_acropolis = null;
      this.distance_from_schools = null;
      this.distance_from_metro = null;
      this.distance_from_train = null;
      this.distance_from_park = null;
      this.distance_from_suburban = null;
      this.distance_from_international_schools = null;
      this.distance_from_airport = null;
      this.distance_from_bank = null;
      this.distance_from_super_market = null;
      this.distance_from_commuting_stops = null;
      this.distance_from_square = null;
      this.distance_from_hospital = null;
      this.distance_from_tram = null;
      this.commandType = null;
      this.address = null;
      this.region = null;
      this.state = null;
      this.district = null;
      this.borough = null;
      this.watermark = null;
      this.youtubeLink = null;
      this.objectivePrice = null;
      this.unit = null;
      this.bright = null;
      this.painted = null;
      this.veranda = null;
      this.awnings = null;
      this.airbnb = null;
    },
    closeHandler(type) {
      type == "simple" ? this.onCloseSimple() : this.onClose(this.id);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "currentUseDateFrom") {
          this.currentUseDateFrom = null;
          this.currentUseDateFromFormatted = null;
        } else if (dateName == "payment_in_advance_date") {
          this.payment_in_advance_date = null;
          this.payment_in_advance_date_formatted = null;
        } else if (dateName == "date_of_lease_expiration") {
          this.date_of_lease_expiration = null;
          this.date_of_lease_expiration_formatted = null;
        }
        return null;
      }
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getEmbedLink(link) {
      return link
        ? "https://www.youtube.com/embed/" + CoreHelper.getVideoEmbedLink(link)
        : "";
    },
    async saveHandler(pressType) {
      this.$v.$touch();
      this.validationRequirements();
      if (this.$v.$invalid) return;
      this.loader = true;
      let descrtipionArray = [];
      if (this.greekDescription != null)
        descrtipionArray.push({
          Description: this.greekDescription,
          Language: "gre",
        });
      if (this.englishDescription != null)
        descrtipionArray.push({
          Description: this.englishDescription,
          Language: "eng",
        });
      if (this.arabicDescription != null)
        descrtipionArray.push({
          Description: this.arabicDescription,
          Language: "ara",
        });
      if (this.chinesseDescription != null)
        descrtipionArray.push({
          Description: this.chinesseDescription,
          Language: "chn",
        });
      let body = {};
      // if (this.id && this.id != null) {
      //   body["_id"] = this.id;
      // }
      body.Area =
        this.borough != null
          ? this.region +
            "/" +
            this.state +
            "/" +
            this.district +
            "/" +
            this.borough
          : this.region + "/" + this.state + "/" + this.district;
      if (this.agentPrice) body.AgentPrice = this.agentPrice;
      if (this.agent && this.agent["_id"]) body.UserID = this.agent["_id"];
      if (this.owner && this.owner["_id"]) body.owner = this.owner["_id"];
      if (this.attorney && this.attorney["_id"])
        body.AttorneyID = this.attorney["_id"];
      if (this.notary && this.notary["_id"]) body.NotaryID = this.notary["_id"];
      if (this.commandType) body.Assignation = this.commandType;
      if (this.buildYear == null || this.buildYear != undefined)
        body["BuildYear"] = this.buildYear;
      if (this.renovateYear) body["RenovationYear"] = this.renovateYear;
      if (this.data.Category) body["Category"] = this.data.Category;
      if (this.estateCode) body["EstateCode"] = this.estateCode;
      if (this.inlandCommission)
        body["InlandCommission"] = this.inlandCommission;
      if (this.inlandCommissionNoTax)
        body["InlandCommissionNoTax"] = this.inlandCommissionNoTax;
      if (this.kind) body["Kind"] = this.kind;
      if (this.objectivePrice) body["ObjectivePrice"] = this.objectivePrice;
      if (this.outlandCommission)
        body["OutlandCommission"] = this.outlandCommission;
      if (this.outlandCommissionNoTax)
        body["OutlandCommissionNoTax"] = this.outlandCommissionNoTax;
      if (this.price) body["Price"] = this.price;
      if (this.air) body["Air"] = this.air;
      if (this.data.Task) body["Task"] = this.data.Task;
      if (this.status) body["Status"] = this.status;
      if (this.type) body["Type"] = this.type;
      if (this.unit) body["Unit"] = this.unit;
      if (this.floor && this.floor.length > 0) {
        let floorsWithValue = [];
        this.floor.map((m) => {
          let fl = {
            FloorValue: m.val,
            Value: m.val,
            FloorLiteral: i18n.t(m.text),
          };
          floorsWithValue.push(fl);
        });
        body["floors"] = floorsWithValue;
      }
      if (this.negotiable_price)
        body["negotiable_price"] = this.negotiable_price;
      if (this.proUse) body["proUse"] = this.proUse;
      if (this.outOfPlan) body["outOfPlan"] = this.outOfPlan;
      if (this.storage) body["storage"] = this.storage;
      if (this.fromManufacturer)
        body["fromManufacturer"] = this.fromManufacturer;
      if (this.plot_unit) body["plot_unit"] = this.plot_unit;
      if (this.currentUse) body["CurrentUse"] = this.currentUse;
      if (this.currentUseDateFrom)
        /*epoc time*/ body["CurrentUseDateFrom"] = this.currentUseDateFrom;
      body["DistanceMeasure"] = this.selectedDistanceUnit;
      if (this.personalComments)
        body["personalComments"] = this.personalComments;
      if (descrtipionArray && descrtipionArray.length > 0)
        body["descriptions"] = descrtipionArray;
      if (this.youtubeLink) body["Youtube"] = this.youtubeLink;
      if (this.address) body["GoogleAddress"] = this.address;
      if (this.gps) {
        let lat = this.gps.split(",")[0] ? this.gps.split(",")[0] : "";
        let lng = this.gps.split(",")[1] ? this.gps.split(",")[1] : "";
        lat = parseFloat(parseFloat(lat).toFixed(7));
        lng = parseFloat(parseFloat(lng).toFixed(7));
        body.GPS = [lat, lng];
      }
      // characteristics
      let characteristics = {};
      if (this.dimensions) characteristics["dimensions"] = this.dimensions;
      if (this.can_build) characteristics["can_build"] = this.can_build;
      if (this.coverage_coefficient)
        characteristics["coverage_coefficient"] = this.coverage_coefficient;
      if (this.structure_coefficient)
        characteristics["structure_coefficient"] = this.structure_coefficient;
      if (this.benefits) characteristics["benefits"] = this.benefits;
      if (this.selectedSecurity != null && this.selectedSecurity != undefined) {
        characteristics["visible_only_to_me"] = this.selectedSecurity == 0;
        characteristics["branch_visibility"] = this.selectedSecurity == 1;
        characteristics["network_visibility"] = this.selectedSecurity == 2;
        characteristics["visible"] = this.selectedSecurity == 3;
      }
      if (this.keys) characteristics["keys"] = this.keys == 1 ? true : false;
      if (this.bannerAtTheEntrance)
        characteristics["banner"] =
          this.bannerAtTheEntrance == 1 ? true : false;
      if (this.pano) characteristics["pano"] = this.pano == 1 ? true : false;
      if (this.bannerOnStreets)
        characteristics["banners_external"] =
          this.bannerOnStreets == 1 ? true : false;
      if (this.levels) characteristics["levels"] = this.levels;
      if (this.bedrooms) characteristics["bedrooms"] = this.bedrooms;
      if (this.kitchens) characteristics["kitchen_rooms"] = this.kitchens;
      if (this.bathrooms) characteristics["bathrooms"] = this.bathrooms;
      if (this.numberOfPlaces)
        characteristics["numberOfPlaces"] = this.numberOfPlaces;
      if (this.balconies) characteristics["balconies"] = this.balconies;
      if (this.balconies_in_sqm)
        characteristics["balconies_in_sqm"] = this.balconies_in_sqm;
      if (this.livingRooms) characteristics["living_rooms"] = this.livingRooms;
      if (this.wc) characteristics["wc"] = this.wc;

      if (this.storage_room)
        characteristics["storage_room"] = String(this.storage_room);
      if (this.storage_room_sqm && this.storage_room) {
        characteristics["storage_room_sqm"] = this.storage_room_sqm;
      } else {
        characteristics["storage_room_sqm"] = null;
      }

      if (this.attic) characteristics["attic"] = String(this.attic);
      if (this.attic_in_sqm && this.attic) {
        characteristics["attic_in_sqm"] = this.attic_in_sqm;
      } else {
        characteristics["attic_in_sqm"] = null;
      }

      if (this.service_room)
        characteristics["service_room"] = String(this.service_room);
      if (this.service_room_in_sqm && this.service_room) {
        characteristics["service_room_in_sqm"] = this.service_room_in_sqm;
      } else {
        characteristics["service_room_in_sqm"] = null;
      }

      if (this.owned_terrace)
        characteristics["owned_terrace"] = String(this.owned_terrace);
      if (this.owned_terrace_in_sqm && this.owned_terrace) {
        characteristics["owned_terrace_in_sqm"] = this.owned_terrace_in_sqm;
      } else {
        characteristics["owned_terrace_in_sqm"] = null;
      }

      if (this.playroom) characteristics["playroom"] = String(this.playroom);
      if (this.playroom_in_sqm && this.playroom) {
        characteristics["playroom_in_sqm"] = this.playroom_in_sqm;
      } else {
        characteristics["playroom_in_sqm"] = null;
      }

      if (this.monthly_rent_price)
        characteristics["monthly_rent_price"] = this.monthly_rent_price;
      if (this.deposit_amount)
        characteristics["deposit_amount"] = this.deposit_amount;
      if (this.date_of_lease_expiration)
        characteristics["date_of_lease_expiration"] =
          this.date_of_lease_expiration;
      if (this.payment_in_advance_date)
        characteristics["payment_in_advance_date"] =
          this.payment_in_advance_date;
      if (this.payment_in_advance_amount)
        characteristics["payment_in_advance_amount"] =
          this.payment_in_advance_amount;
      if (this.shared_fees_monthly_price)
        characteristics["shared_fees_monthly_price"] =
          this.shared_fees_monthly_price;
      if (this.yearly_tax) characteristics["yearly_tax"] = this.yearly_tax;
      if (this.yearly_yield)
        characteristics["yearly_yield"] = this.yearly_yield;
      if (this.yearly_income_before_tax)
        characteristics["yearly_income_before_tax"] =
          this.yearly_income_before_tax;
      if (this.mortgage) characteristics["mortgage"] = this.mortgage;
      if (this.bank_name) characteristics["bank_name"] = this.bank_name;
      if (this.amount_or_percentage)
        characteristics["amount_or_percentage"] = this.amount_or_percentage;
      if (this.energy_class)
        characteristics["energy_class"] = this.energy_class
          ? Array.isArray(this.energy_class)
            ? this.energy_class
            : [this.energy_class]
          : null;
      if (this.suitable_for_investment)
        characteristics["suitable_for_investment"] = this
          .suitable_for_investment
          ? Array.isArray(this.suitable_for_investment)
            ? this.suitable_for_investment
            : [this.suitable_for_investment]
          : null;
      if (this.autonomous_heating)
        characteristics["autonomous_heating"] = this.autonomous_heating;
      if (this.air_conditioning)
        characteristics["air_conditioning"] = this.air_conditioning;
      if (this.solar_water_heater)
        characteristics["solar_water_heater"] = this.solar_water_heater;
      if (this.fireplace) characteristics["fireplace"] = this.fireplace;
      if (this.gas) characteristics["gas"] = this.gas;
      if (this.stove) characteristics["stove"] = this.stove;
      if (this.central_heating) characteristics["central_heating"] = this.stove;
      if (this.under_floor_heating)
        characteristics["under_floor_heating"] = this.under_floor_heating;
      if (this.natural_gas) characteristics["natural_gas"] = this.natural_gas;
      if (this.oil) characteristics["oil"] = this.oil;
      if (this.electrical_heating)
        characteristics["electrical_heating"] = this.electrical_heating;
      if (this.heat_accumulator)
        characteristics["heat_accumulator"] = this.heat_accumulator;
      if (this.buildingStatusSelected) {
        characteristics["renovated"] =
          this.buildingStatusSelected == "Ανακαινισμένο";
        characteristics["perfect_condition"] =
          this.buildingStatusSelected == "Άριστη κατάσταση";
        characteristics["good_condition"] =
          this.buildingStatusSelected == "Καλή κατάσταση";
        characteristics["under_construction"] =
          this.buildingStatusSelected == "Υπο κατασκευή";
        characteristics["newly_built"] =
          this.buildingStatusSelected == "Νεόδμητο";
        characteristics["unfinished"] =
          this.buildingStatusSelected == "Ημιτελές";
        characteristics["needs_renovation"] =
          this.buildingStatusSelected == "Χρήζει ανακαίνισης";
      }
      if (this.selectBoxesSelected) {
        characteristics["double_layer_windows"] =
          this.selectBoxesSelected.includes("Διπλά Tζάμια");
        characteristics["wooden_frames"] =
          this.selectBoxesSelected.includes("Ξύλινα κουφώματα");
        characteristics["synthetic_frames"] = this.selectBoxesSelected.includes(
          "Συνθετικά κουφώματα"
        );
        characteristics["electrical_frames"] =
          this.selectBoxesSelected.includes("Ηλεκτρικά κουφώματα");
        characteristics["aluminum_frames"] = this.selectBoxesSelected.includes(
          "Κουφώματα αλουμίνιου"
        );
        characteristics["sliding_rollers"] =
          this.selectBoxesSelected.includes("Συρόμενα Ρολά");
        characteristics["screens"] = this.selectBoxesSelected.includes("Σίτες");
      }
      if (this.floorSelected && this.floorSelected.length > 0) {
        characteristics["cement"] = this.floorSelected.includes("Τσιμέντο");
        characteristics["marble_floors"] =
          this.floorSelected.includes("Μάρμαρο");
        characteristics["wooden_floor"] = this.floorSelected.includes("Ξύλο");
        characteristics["stone_floors"] = this.floorSelected.includes("Πέτρα");
        characteristics["tile_floors"] = this.floorSelected.includes("Πλακάκι");
        characteristics["mosaic_floor"] =
          this.floorSelected.includes("Μωσαϊκό");
        characteristics["marble_wooden_floor"] =
          this.floorSelected.includes("Μάρμαρο - Ξύλο");
        characteristics["marble_tile_floor"] =
          this.floorSelected.includes("Μάρμαρο-Πλακάκι");
        characteristics["stone_wooden_floors"] =
          this.floorSelected.includes("Πέτρα-Ξύλο");
        characteristics["stone_marble_floors"] =
          this.floorSelected.includes("Πέτρα - Μάρμαρο");
        characteristics["marble_tile_floor"] =
          this.floorSelected.includes("Μάρμαρο-Πλακάκι");
        characteristics["stone_wooden_floors"] =
          this.floorSelected.includes("Πέτρα-Ξύλο");
        characteristics["tile_wooden_floor"] =
          this.floorSelected.includes("Πλακάκι-Ξύλο");
        characteristics["mosaic_wooden_floors"] =
          this.floorSelected.includes("Μωσαϊκό-Ξύλο");
        characteristics["industrial_floor"] =
          this.floorSelected.includes("Βιομηχανικό δάπεδο");
        characteristics["laminate"] = this.floorSelected.includes("Laminate");
      }
      if (this.furnished) characteristics["furnished"] = this.furnished;
      if (this.bright) characteristics["bright"] = this.bright;
      if (this.painted) characteristics["painted"] = this.painted;
      if (this.electrical_equipment)
        characteristics["electrical_equipment"] = this.electrical_equipment;
      if (this.satellite_antenna)
        characteristics["satellite_antenna"] = this.satellite_antenna;
      if (this.luxurious) characteristics["luxurious"] = this.luxurious;
      if (this.screens) characteristics["screens"] = this.screens;
      if (this.pets_allowed)
        characteristics["pets_allowed"] = this.pets_allowed;
      if (this.internal_staircase)
        characteristics["internal_staircase"] = this.internal_staircase;
      if (this.elevator) characteristics["elevator"] = this.elevator;
      if (this.safety_door) characteristics["safety_door"] = this.safety_door;
      if (this.alarm) characteristics["alarm"] = this.alarm;
      if (this.security_cam)
        characteristics["security_cam"] = this.security_cam;
      if (this.cctv) characteristics["cctv"] = this.cctv;
      if (this.security_systems)
        characteristics["security_systems"] = this.security_systems;
      if (this.chooseAViewSelected && this.chooseAViewSelected.length > 0) {
        characteristics["urban_view"] =
          this.chooseAViewSelected.includes("Πόλη");
        characteristics["mountain_view"] =
          this.chooseAViewSelected.includes("Βουνό");
        characteristics["acropolis_or_monuments_view"] =
          this.chooseAViewSelected.includes("Μνημεία");
        characteristics["lake_view"] =
          this.chooseAViewSelected.includes("Λίμνη");
        characteristics["sea_view"] =
          this.chooseAViewSelected.includes("Θάλασσα");
        characteristics["park_view"] =
          this.chooseAViewSelected.includes("Πάρκο");
        characteristics["river_view"] =
          this.chooseAViewSelected.includes("Ποτάμι");
      }
      if (this.zoneSelected && this.zoneSelected.length > 0) {
        characteristics["residential_zone"] =
          this.zoneSelected.includes("Οικιστική ζώνη");
        characteristics["commercial_zone"] =
          this.zoneSelected.includes("Εμπορική ζώνη");
        characteristics["rural_zone"] =
          this.zoneSelected.includes("Αγροτική ζώνη");
        characteristics["industrial_zone"] =
          this.zoneSelected.includes("Βιομηχανική ζώνη");
        characteristics["regeneration_zone"] =
          this.zoneSelected.includes("Ζώνη ανάπλασης");
        characteristics["outside_city_plan"] =
          this.zoneSelected.includes("Εκτός σχεδίου");
      }
      if (
        this.selectPositionSelected &&
        this.selectPositionSelected.length > 0
      ) {
        characteristics["eastern_western_coverage"] =
          this.selectPositionSelected.includes("Ανατολικοδυτικός");
        characteristics["internal"] =
          this.selectPositionSelected.includes("Εσωτερικό");
        characteristics["facade"] =
          this.selectPositionSelected.includes("Προσόψεως");
        characteristics["penthouse"] =
          this.selectPositionSelected.includes("Ρετιρέ");
        characteristics["double_frontage"] =
          this.selectPositionSelected.includes("Διαμπερές");
        characteristics["on_main_road"] =
          this.selectPositionSelected.includes("Σε κεντρικό δρόμο");
        characteristics["on_sidewalk"] =
          this.selectPositionSelected.includes("Σε πεζόδρομο");
        characteristics["corner"] =
          this.selectPositionSelected.includes("Γωνιακό");
      }
      if (this.orientationSelected && this.orientationSelected.length > 0) {
        characteristics["eastern_coverage"] =
          this.orientationSelected.includes("eastern");
        characteristics["eastern_western_coverage"] =
          this.orientationSelected.includes("eastWest");
        characteristics["eastern_meridian_coverage"] =
          this.orientationSelected.includes("easternMeridian");
        characteristics["northern_coverage"] =
          this.orientationSelected.includes("north");
        characteristics["northern_eastern_coverage"] =
          this.orientationSelected.includes("northEast");
        characteristics["north_west"] =
          this.orientationSelected.includes("northWest");
        characteristics["western_coverage"] =
          this.orientationSelected.includes("west");
        characteristics["meridian_coverage"] =
          this.orientationSelected.includes("meridian");
        characteristics["southern_coverage"] =
          this.orientationSelected.includes("south");
        characteristics["southern_eastern_coverage"] =
          this.orientationSelected.includes("southEast");
        characteristics["southern_western_coverage"] =
          this.orientationSelected.includes("southWest");
        characteristics["western_meridian_coverage"] =
          this.orientationSelected.includes("westMeridian");
      }
      if (this.parking) characteristics["parking"] = this.parking;
      if (this.parking_spots && this.parking) {
        characteristics["parking_spots"] = this.parking_spots;
      } else {
        characteristics["parking_spots"] = null;
      }

      if (this.typeOfParkingSelected && this.typeOfParkingSelected.length > 0) {
        characteristics["enclosed_parking"] =
          this.typeOfParkingSelected.includes("closed");
        characteristics["underground_parking"] =
          this.typeOfParkingSelected.includes("basement");
        characteristics["pilot_parking"] =
          this.typeOfParkingSelected.includes("pilot");
        characteristics["open_parking"] =
          this.typeOfParkingSelected.includes("open");
      }
      if (this.garden)
        characteristics["garden"] = CoreHelper.parseBoolean(this.garden);
      if (this.garden_in_sqm && this.garden) {
        characteristics["garden_in_sqm"] = this.garden_in_sqm;
      } else {
        characteristics["garden_in_sqm"] = null;
      }
      if (this.pool)
        characteristics["pool"] = CoreHelper.parseBoolean(this.pool);
      if (this.pool_in_sqm && this.pool) {
        characteristics["pool_in_sqm"] = this.pool_in_sqm;
      } else {
        characteristics["pool_in_sqm"] = null;
      }
      if (this.veranda)
        characteristics["veranda"] = CoreHelper.parseBoolean(this.veranda);
      if (this.access_for_disabled_people)
        characteristics["access_for_disabled_people"] = CoreHelper.parseBoolean(
          this.access_for_disabled_people
        );
      if (this.awnings)
        characteristics["awnings"] = CoreHelper.parseBoolean(this.awnings);
      if (this.bbq) characteristics["bbq"] = CoreHelper.parseBoolean(this.bbq);
      if (this.separate_entrance)
        characteristics["separate_entrance"] = CoreHelper.parseBoolean(
          this.separate_entrance
        );
      if (this.suitable_for_students)
        characteristics["suitable_for_students"] = CoreHelper.parseBoolean(
          this.suitable_for_students
        );
      if (this.airbnb)
        characteristics["airbnb"] = CoreHelper.parseBoolean(this.airbnb);
      if (this.suitable_for_commercial_use)
        characteristics["suitable_for_commercial_use"] =
          CoreHelper.parseBoolean(this.suitable_for_commercial_use);
      if (this.distance_from_sea)
        characteristics["distance_from_sea"] = this.distance_from_sea;
      if (this.distance_from_airport)
        characteristics["distance_from_airport"] = this.distance_from_airport;
      if (this.distance_from_acropolis)
        characteristics["distance_from_acropolis"] =
          this.distance_from_acropolis;
      if (this.distance_from_bank)
        characteristics["distance_from_bank"] = this.distance_from_bank;
      if (this.distance_from_schools)
        characteristics["distance_from_schools"] = this.distance_from_schools;
      if (this.distance_from_super_market)
        characteristics["distance_from_super_market"] =
          this.distance_from_super_market;
      if (this.distance_from_metro)
        characteristics["distance_from_metro"] = this.distance_from_metro;
      if (this.distance_from_commuting_stops)
        characteristics["distance_from_commuting_stops"] =
          this.distance_from_commuting_stops;
      if (this.distance_from_train)
        characteristics["distance_from_train"] = this.distance_from_train;
      if (this.distance_from_square)
        characteristics["distance_from_square"] = this.distance_from_square;
      if (this.distance_from_park)
        characteristics["distance_from_park"] = this.distance_from_park;
      if (this.distance_from_hospital)
        characteristics["distance_from_hospital"] = this.distance_from_hospital;
      if (this.distance_from_suburban)
        characteristics["distance_from_suburban"] = this.distance_from_suburban;
      if (this.distance_from_tram)
        characteristics["distance_from_tram"] = this.distance_from_tram;
      if (this.distance_from_international_schools)
        characteristics["distance_from_international_schools"] =
          this.distance_from_international_schools;
      body["characteristics"] = characteristics;
      let resp = await RealEstateRepo.update(this.id, body);
      if (resp && resp.status == 200) {
        try {
          await this.refreshEstate(this.id);
        } catch (err) {
          true;
        }

        if (pressType == "publish") {
          this.openDialog("congrats");
        } else {
          this.onViewEstate();
        }
      }
      // this.onViewEstate();
      this.loader = false;
    },
    startDrag(evt) {
      evt;
    },
    editImg(image, indx, isBanner) {
      this.dialog = {
        open: true,
        type: "editImage",
        data: {
          image: image,
          index: indx,
          isBanner: isBanner,
        },
      };
    },
    deleteImg(indx, isBanner) {
      let imgs;
      let indexFound =
        isBanner == true
          ? this.images.findIndex((item) => item.is_banner == true)
          : indx;
      if (indexFound != -1) {
        imgs = this.images.filter((i, index) => {
          return index != indexFound;
        });
        this.reOrderImages(
          imgs,
          this.data._id,
          isBanner == true ? "isDeletingImg" : "isDeletingBannerImg",
          isBanner
        );
      }
    },
    async reOrderImages(images, id, loader, isBanner) {
      this[loader] = true;
      let payload = { photos: images };
      try {
        let resp = await RealEstateRepo.reOrderEstatePhotos(payload, id);
        if (resp.status && resp.status == 200) {
          if (isBanner != true) this.createEstateWtPhotos();
          resp.data.photos
            ? this.updateEstate(this.data._id, "photos", resp.data.photos)
            : console.error("No photos returned");
          resp.data.main_photo
            ? this.updateEstate(
                this.data._id,
                "main_photo",
                resp.data.main_photo
              )
            : console.error("No main photo returned");
        }
      } catch (err) {
        console.error(err);
      }
      this[loader] = false;
    },
    async uploadImages(newImages, isBanner) {
      this.isUploadingImg = true;
      let formData = new FormData();
      newImages.map((m, index) => {
        formData.append("visibility_" + (index + 1), m.public);
        if (m.titleGr) formData.append("title_gr_" + (index + 1), m.titleGr);
        if (m.titleEn) formData.append("title_en_" + (index + 1), m.titleEn);
        m.file != undefined
          ? formData.append("file_" + (index + 1), m.file)
          : null;
        if (isBanner == true) formData.append("is_banner", "true");
        return m;
      });
      try {
        let resp = await RealEstateRepo.uploadEstatePhotos(
          formData,
          this.data._id
        );
        if (resp.status != undefined && resp.status == 200) {
          if (this.isBanner != true) this.createEstateWtPhotos();
          resp.data.photos != undefined
            ? this.updateEstate(this.data._id, "photos", resp.data.photos)
            : console.error("No photos returned");
          resp.data.main_photo != undefined
            ? this.updateEstate(
                this.data._id,
                "main_photo",
                resp.data.main_photo
              )
            : console.error("No main photo returned");
          if (isBanner == true) {
            let body = { characteristics: { pano: true } };
            await RealEstateRepo.update(this.id, body);
          }
        }
      } catch (err) {
        console.error(err);
      }
      this.isUploadingImg = false;
    },
    createEstateWtPhotos() {
      try {
        RealEstateRepo.createEstateWatermarkedPhotos(this.data._id);
        this.$store.dispatch("onSetSnackbar", {
          open: true,
          text: i18n.t("watermarkProcessMsg"),
          color: "success",
        });
      } catch (err) {
        console.error(err);
      }
    },
    async downloadImages(type, loader) {
      let paths = [];
      let zip = new JsZip();
      let img = zip.folder("images");
      //If type plain then get the url paths of images
      if (type == "plain") {
        this[loader] = true;
        if (
          this.images == undefined ||
          this.images == null ||
          this.images.length == 0
        ) {
          this[loader] = false;
          return false;
        }
        this.images.map((m) => {
          paths.push(this.getImage(m));
        });
        //If type watermark then get the url paths of watermarked images
      } else if (type == "watermark") {
        this[loader] = true;
        if (this.data.watermarkedphotos == undefined) {
          this[loader] = false;
          return false;
        } else if (this.data.watermarkedphotos.length == 0) {
          this[loader] = false;
          return false;
        }
        this.data.watermarkedphotos.map((m) => {
          paths.push(this.getImage(m));
        });
      } else return false;
      //Fill zip folder with images from paths found
      paths.map((p) => {
        let filename = CoreHelper.getFileNameFromUrl(p);
        img.file(filename, this.urlToPromise(p), { binary: true });
      });
      //Create zip as blob and download
      zip
        .generateAsync({ type: "blob" })
        .then((content) => {
          this[loader] = false;
          saveAs(content, `${this.estateCode}_imgs_${type}.zip`);
        })
        .catch((err) => {
          console.error(err);
          this[loader] = false;
        });
    },
    //Download images from url
    urlToPromise(url) {
      return new Promise(function (resolve, reject) {
        JSZipUtils.getBinaryContent(url, function (err, data) {
          if (err) reject(err);
          else resolve(data);
        });
      });
    },
    getImage(item) {
      return CoreHelper.getImagePath(item);
    },
    updateImg(obj, indx, isBanner) {
      let imgs = JSON.parse(JSON.stringify(this.data.photos));
      let index =
        isBanner == true
          ? this.images.findIndex((item) => item.is_banner == true)
          : indx;
      if (index != -1) {
        imgs[index] = obj;
        this.reOrderImages(
          imgs,
          this.data._id,
          isBanner == true ? "isUploadingBannerImg" : "isUploadingImg",
          isBanner
        );
      }
    },
    updateImageArray(newImages, isBanner) {
      this.reOrderImages(newImages, this.data._id, "isUploadingImg", isBanner);
    },
    onViewEstate() {
      this.onView(this.data);
    },
    checkIfExist(fieldName) {
      return RealEstateHelper.checkIfFieldExist(this.data.Category, fieldName);
    },
    filesBtnClicked(selectedDocType, docTypesList) {
      this.dialog = {
        open: true,
        type: "upload",
        data: {
          selectedDocType: selectedDocType,
          docTypesList: docTypesList,
        },
      };
    },
    async uploadDocuments(documents) {
      for (let index = 0; index < documents.length; index++) {
        try {
          let formData = new FormData();
          formData.append("file", documents[index].file);
          let resp = await RealEstateRepo.uploadEstateDocuments(
            formData,
            this.data._id,
            documents[index].docType
          );
          if (resp.status != undefined && resp.status == 200) {
            let obj = Object.keys(resp.data)[0];
            let docType = Object.keys(resp.data[obj])[0];
            let newDoc = resp.data[obj][docType];
            try {
              this.uploadDocSuccessHandler(
                this.data._id,
                documents[index].docType,
                newDoc
              );
            } catch (err) {
              console.error(err);
            }
          }
        } catch (err) {
          console.error("Error on", documents[index], err);
        }
      }
    },
    async updateDocument(doc) {
      let formData = new FormData();
      formData.append(
        "visibility",
        doc.visibility == "public" ? "private" : "public"
      );
      let resp = await RealEstateRepo.updateEstateDocuments(
        this.data._id,
        doc.docType,
        doc.indx,
        formData
      );
      if (
        resp != undefined &&
        resp.status != undefined &&
        resp.status == 200 &&
        resp.data.document != undefined &&
        resp.data.document.visibility != undefined
      )
        this.updateDocSuccessHandler(
          this.data._id,
          doc.docType,
          doc.indx,
          resp.data.document
        );
    },
    async downloadDocument(doc) {
      let realFilename =
        doc.name != undefined
          ? doc.name
          : doc.path != undefined
          ? CoreHelper.getFileNameFromUrl(doc.path)
          : "";
      let filename =
        doc.path != undefined ? CoreHelper.getFileNameFromUrl(doc.path) : "";
      await RealEstateRepo.downloadEstateDocuments(
        this.data._id,
        filename,
        realFilename
      );
    },
    async deleteDocument(docType, indx) {
      let resp = await RealEstateRepo.deleteEstateDocuments(
        this.data._id,
        docType,
        indx
      );
      if (resp != undefined && resp.status != undefined && resp.status == 200)
        this.deleteDocSuccessHandler(this.data._id, docType, indx);
    },
    fetchLocation(type) {
      switch (type) {
        case "regions":
          this.fetchRegions(this.locale);
          break;
        case "states":
          this.fetchStates(this.region, this.locale);
          break;
        case "districts":
          this.fetchDistricts(this.state, this.region, this.locale);
          break;
        case "boroughs":
          this.fetchBoroughs(
            this.district,
            this.state,
            this.region,
            this.locale
          );
          break;
        default:
          true;
      }
    },
    async fetchRegions(locale) {
      this.regionsLoading = true;
      try {
        const resp = await RealEstateRepo.getRegions(locale);
        if (resp.data.regions) this.regionsList = resp.data.regions;
        this.regionsLoading = false;
      } catch (e) {
        this.regionsLoading = false;
        throw e;
      }
    },
    async fetchStates(region, locale) {
      this.statesLoading = true;
      try {
        const resp = await RealEstateRepo.getStates(region, locale);
        if (resp.data.states) this.statesList = resp.data.states;
        this.statesLoading = false;
      } catch (e) {
        this.statesLoading = false;
        throw e;
      }
    },
    async fetchDistricts(state, region, locale) {
      this.districtsLoading = true;
      try {
        const resp = await RealEstateRepo.getDistricts(region, state, locale);
        if (resp.data.districts) this.districtsList = resp.data.districts;
        this.districtsLoading = false;
      } catch (e) {
        this.districtsLoading = false;
        throw e;
      }
    },
    async fetchBoroughs(district, state, region, locale) {
      this.boroughsLoading = true;
      try {
        const resp = await RealEstateRepo.getBoroughs(
          region,
          state,
          district,
          locale
        );
        if (resp.data.boroughs) this.boroughsList = resp.data.boroughs;
        this.boroughsLoading = false;
      } catch (e) {
        this.boroughsLoading = false;
        throw e;
      }
    },

    closeCongrats() {
      this.closeDialog();
      this.closeHandler("simple");
    },

    closeDialog() {
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
    },
    openDialog(type, content) {
      this.dialog = {
        open: true,
        type: type,
        content: content,
      };
    },
    validationRequirements() {
      if (
        this.commandTypeErrorMessages.length > 0 ||
        this.floorErrorMessages.length > 0 ||
        this.energyClassErrorMessages.length > 0 ||
        this.priceErrorMessages.length > 0 ||
        this.unitErrorMessages.length >
          0 /* || this.agentPriceErrorMessages.length > 0 || this.objectivePriceErrorMessages.length > 0  */
      )
        this.errorOnCharacteristics = true;
      else this.errorOnCharacteristics = false;
      if (
        this.regionErrorMessages.length > 0 ||
        this.districtErrorMessages.length > 0 ||
        this.cityErrorMessages.length > 0
      )
        this.errorOnLocation = true;
      else this.errorOnLocation = false;
    },
    isAuthorized(action, data) {
      let key = AuthHelper.getKeyForAuth(this.userDetails.Role);
      return AuthHelper.authorization(
        action,
        data[key] ? data[key] : null,
        data["userID"] ? data["userID"] : null
      );
    },
    filterList(item, queryText, itemText) {
      if (queryText.length >= 1) {
        let queryWord = queryText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .split(" ");
        let itemWord = itemText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase();
        return queryWord.every((item) => itemWord.includes(item));
      }
    },
  },
  watch: {
    show() {
      if (!this.show) this.closeHandler("simple");
    },
    // activeTab() {
    //   // this.$v.$touch();
    //   // this.validationRequirements();
    // },
    searchOwner(val) {
      val && this.fetchOwnerList(val, "splitName");
    },
    searchAgent(val) {
      val && this.fetchAgentsList(val, "splitName");
    },
    searchAttorney(val) {
      val && this.fetchAttorneysList(val, "splitName");
    },
    searchNotary(val) {
      val && this.fetchNotariesList(val, "splitName");
    },
    currentUseDateFrom() {
      this.currentUseDateFromFormatted = this.formatDate(
        this.currentUseDateFrom
      );
    },
    payment_in_advance_date() {
      this.payment_in_advance_date_formatted = this.formatDate(
        this.payment_in_advance_date
      );
    },
    date_of_lease_expiration() {
      this.date_of_lease_expiration_formatted = this.formatDate(
        this.date_of_lease_expiration
      );
    },
    gps() {
      let lat = this.gps.split(",")[0] ? this.gps.split(",")[0] : "";
      let lng = this.gps.split(",")[1] ? this.gps.split(",")[1] : "";
      lat = parseFloat(parseFloat(lat).toFixed(7));
      lng = parseFloat(parseFloat(lng).toFixed(7));
      this.setMarker(lat, lng);
    },
    sLocationDetails() {
      if (this.sLocationDetails.length != 0) {
        this.state = this.sLocationDetails.vicinity
          ? this.sLocationDetails.vicinity
          : "";
        if (this.sLocationDetails.addressExtracted) {
          this.region = this.sLocationDetails.addressExtracted.administrative_area_level_1
            ? this.sLocationDetails.addressExtracted.administrative_area_level_1
            : this.sLocationDetails.addressExtracted.administrative_area_level_2
            ? this.sLocationDetails.addressExtracted.administrative_area_level_2
            : this.sLocationDetails.addressExtracted.administrative_area_level_3
            ? this.sLocationDetails.addressExtracted.administrative_area_level_3
            : "";
          this.district = this.sLocationDetails.addressExtracted.locality
            ? this.sLocationDetails.addressExtracted.locality
            : "";
          this.borough = this.sLocationDetails.addressExtracted.neighborhood ? this.sLocationDetails.addressExtracted.neighborhood : this.sLocationDetails.addressExtracted.route
            ? this.sLocationDetails.addressExtracted.route
            : "";
        }
        this.address = this.sLocationDetails.formatted_address
          ? this.sLocationDetails.formatted_address
          : "";
        let lat =
          this.sLocationDetails?.geometry &&
          this.sLocationDetails?.geometry?.location &&
          this.sLocationDetails?.geometry?.location?.lat
            ? this.sLocationDetails?.geometry?.location?.lat()
            : null;
        let lng =
          this.sLocationDetails?.geometry &&
          this.sLocationDetails?.geometry?.location &&
          this.sLocationDetails?.geometry?.location?.lng
            ? this.sLocationDetails?.geometry?.location?.lng()
            : null;
        this.gps = lat != null && lng != null ? lat + "," + lng : ",";
      }
    },
    locale() {
      this.fetchLocation("regions");
    },
  },
};
</script>
<style lang="scss" scoped>
h6 {
  padding: 10px 0px 10px 0px;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
}

.map {
  display: block;
}

.mapInput {
  position: absolute;
  top: 9%;
  right: 27%;
  width: 400px;
}

.mapDiv {
  position: relative;
}

.btn {
  text-transform: unset !important;
  color: #263573;
}

.listHeader {
  color: #263573;
  font-size: 12px;
}

.v-expansion-panel--active + .v-expansion-panel,
.v-expansion-panel--active:not(:first-child) {
  margin-top: 0px;
}

.v-text-input {
  font-size: 14px;
}

.v-btn-toggle {
  border-radius: 4px;
  display: block;
  max-width: 100%;
}

.btn-custom {
  text-transform: capitalize !important;
  font-family: CenturyGothicBold;
  font-weight: 500;
  background-color: white;
}

.btn-custom-blue {
  text-transform: capitalize !important;
  font-family: CenturyGothicBold;
  font-weight: 500;
  background-color: #263573;
}

.v-btn-toggle .v-btn.v-btn.v-size--default {
  min-width: 48px;
  min-height: 0;
}

.v-btn-toggle--group > .v-btn.v-btn {
  border-color: #263573 !important;
}

.v-btn-toggle > .v-btn.v-btn {
  border-radius: 5px !important;
  border-width: thin !important;
  background-color: white !important;
}

.v-input--selection-controls {
  margin: 0px 0px -16px 0px;
  padding-top: 0px;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: rgba(38, 53, 115, 1) !important;
  text-transform: capitalize !important;
  font-family: CenturyGothicBold;
  font-weight: 500;
  background-color: white;
}

.v-label {
  font-size: 13px;
}

.checkboxRow {
  margin: -14px 0px -43px -13px;
}

.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 35px;
}

.checkboxInputCombo {
  margin: -12px 1px -45px -3px;
}

.checkboxInputCombo2 {
  margin: 0px 12px 0px 12px;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) {
  background: #fff;
  color: #263573;
}

.theme--light.v-btn-toggle.v-btn-toggle--group {
  background: #fff;
  color: #263573;
}

.white-btn {
  color: #263573;
  border: solid 2px #70707059;
  font-size: 13px;
}

span {
  padding-left: 4px;
}

.tabsText {
  color: black;
  font-size: 16px;
  letter-spacing: normal;
}

.noCapitalize {
  text-transform: unset !important;
}

.textCenter {
  margin: 21px 1px 1px 1px;
}

.fontSize14 {
  font-size: 14px;
}

::v-deep .fontSize14 .v-label {
  font-size: 14px;
}

.fontColorBlue {
  color: #43425d;
}

.theme--light.v-navigation-drawer .v-divider {
  border-color: rgba(0, 0, 0, 0.12);
  position: relative;
  left: 0;
  right: 0;
}

.minusPlus {
  cursor: pointer;
  color: #263573;
  max-height: 40px;
}

.overFlowRowShort,
.overFlowRowLong {
  max-height: calc(100vh - 160px);
  overflow: auto;
}

@media only screen and (max-width: 828px) {
  .overFlowRowLong {
    max-height: calc(100vh - 220px);
  }
}

@media only screen and (max-width: 571px) {
  .mapInput {
    right: unset;
    left: 35px;
    width: 75vw;
  }
}

@media only screen and (max-width: 652px) {
  .overFlowRowShort {
    max-height: calc(100vh - 220px);
  }
}
</style>
