import moment from "moment";
import i18n from "../../i18n/i18n";
import store from "@/store";

export default {
  capitalizeFirstLetter(string) {
    return string
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  },
  isFormattedDate(dateString) {
    if (dateString == null) return false;
    let regEx =
      /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
    return dateString.match(regEx) != null;
  },
  formatDate(date) {
    if (!date) return null;
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  },
  toRegex(string = "") {
    let regObj = {
      Ά: "[Α|Ά]",
      Έ: "[Ε|Έ]",
      Ή: "[Η|Ή]",
      Ί: "[Ι|Ί]",
      Ό: "[Ο|Ό]",
      Ύ: "[Υ|Ύ]",
      Ώ: "[Ω|Ώ]",
      Α: "[Α|Ά]",
      Ε: "[Ε|Έ]",
      Η: "[Η|Ή]",
      Ι: "[Ι|Ί]",
      Ο: "[Ο|Ό]",
      Υ: "[Υ|Ύ]",
      Ω: "[Ω|Ώ]",
    };
    let chars = [];
    try {
      if (string != null) {
        chars = string.toUpperCase().split("");
        chars.map((e, i) => {
          if (regObj[e]) {
            chars[i] = regObj[e];
          }
        });

        string = chars.join("");

        return string.trim();
      } else {
        return "";
      }
    } catch (ex) {
      console.error(ex);
      return undefined;
    }
  },
  dateToEpoch(date) {
    return moment(date).unix();
  },
  EpochToDate(date) {
    return moment.unix(date).format("DD/MM/YYYY");
  },
  EpochToDateFormatted(date){
    return moment.unix(date).format("YYYY-MM-DD");
  },
  EpochToDateTime(date, seperator) {
    return moment.unix(date).format("DD/MM/YYYY " + seperator + " HH:mm");
  },

  EpochToFullDateTime(time) {
    const month = [
      i18n.t("January"),
      i18n.t("February"),
      i18n.t("March"),
      i18n.t("April"),
      i18n.t("May"),
      i18n.t("June"),
      i18n.t("July"),
      i18n.t("August"),
      i18n.t("September"),
      i18n.t("October"),
      i18n.t("November"),
      i18n.t("December"),
    ];
    let date = new Date(time * 1000);
    let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let fullDate =
      date.getDate() +
      " " +
      month[new Date(time).getMonth() + 1] +
      " " +
      date.getFullYear() +
      ", " +
      hours +
      ":" +
      minutes;
    return fullDate;
  },

  ExpireAt(date, minutes) {
    var expireDate = new Date(date * 1000);
    expireDate.setTime(expireDate.getTime() + minutes * 60 * 1000);
    var currentDate = new Date();
    var diffTime = expireDate - currentDate;
    var diffMonthTime =
      expireDate.getMonth() +
      12 * expireDate.getFullYear() -
      (currentDate.getMonth() + 12 * currentDate.getFullYear());
    var diffYearTime = expireDate.getFullYear() - currentDate.getFullYear();
    if (diffTime <= 0) {
      return i18n.t("expired2");
    }
    if (parseInt(diffTime / 60000) > 0 && parseInt(diffTime / 60000) <= 60) {
      return (
        i18n.t("expiresIn") +
        " " +
        parseInt(diffTime / 60000) +
        " " +
        i18n.t("minutes")
      );
    } else if (
      parseInt(diffTime / 3600000) > 0 &&
      parseInt(diffTime / 3600000) <= 24
    ) {
      return (
        i18n.t("expiresIn") +
        " " +
        parseInt(diffTime / 3600000) +
        " " +
        i18n.t("hours")
      );
    } else if (
      parseInt(diffTime / (24 * 3600 * 1000)) > 0 &&
      parseInt(diffTime / (24 * 3600 * 1000)) <= 31
    ) {
      return (
        i18n.t("expiresIn") +
        " " +
        parseInt(diffTime / (24 * 3600 * 1000)) +
        " " +
        i18n.t("days")
      );
    } else if (
      parseInt(diffTime / (24 * 3600 * 1000 * 7)) > 0 &&
      parseInt(diffTime / (24 * 3600 * 1000 * 7)) <= 4
    ) {
      return (
        i18n.t("expiresIn") +
        " " +
        parseInt(diffTime / (24 * 3600 * 1000 * 7)) +
        " " +
        i18n.t("weeks")
      );
    } else if (diffMonthTime > 0 && diffMonthTime <= 12) {
      return i18n.t("expiresIn") + " " + diffMonthTime + " " + i18n.t("months");
    } else if (diffYearTime) {
      return i18n.t("expiresIn") + " " + diffYearTime + " " + i18n.t("years");
    }
  },
  time_ago(time) {
    const month = [
      i18n.t("JanuaryShort"),
      i18n.t("FebruaryShort"),
      i18n.t("MarchShort"),
      i18n.t("AprilShort"),
      i18n.t("MayShort"),
      i18n.t("JuneShort"),
      i18n.t("JulyShort"),
      i18n.t("AugustShort"),
      i18n.t("SeptemberShort"),
      i18n.t("OctoberShort"),
      i18n.t("NovemberShort"),
      i18n.t("DecemberShort"),
    ];
    switch (typeof time) {
      case "number":
        break;
      case "string": {
        let t = time.split(/[- :]/);
        time = +new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
        // time = +new Date(time);
        break;
      }
      case "object":
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
    var time_formats = [
      [60, i18n.t("seconds"), 1], //i18n.t("onoma")
      [120, i18n.t("aMinuteAgo"), i18n.t("inAMinute")],
      [3600, i18n.t("minutes"), 60],
      [7200, i18n.t("anHourAgo"), i18n.t("inAnHour")],
      [86400, i18n.t("hours"), 3600],
      [115200, i18n.t("day"), 86400],
      [2629743, i18n.t("days"), 86400],
      [31556926, i18n.t("months"), 2629743],
      [58060800000, i18n.t("years"), 31556926],
      // [58060800000, "", 86400],
      // [172800, "Εχθές", "Αύριο"],
      // [604800, "μέρες", 86400],
      // [1209600, "Την προηγούμενη εβδομάδα", "Την επόμενη εβδομάδα"],
      // [2419200, "εβδομάδες", 604800],
      // [4838400, "Τον προηγούμενο μήνα", "Τον επόμενο μήνα"],
      // [29030400, "μήνες", 2419200],
      // [58060800, "Τον προηγούμενο χρόνο", "Τον επόμενο χρόνο"],
      // [2903040000, "χρόνια", 29030400],
      // [5806080000, "Τον προηγούμενο αιώνα", "Τον επόμενο αιώνα"],
      // [58060800000, "αιώνες", 2903040000],
    ];
    let x = 13 - time.toString().length;
    if (x > 0) {
      time = Number(time + "0".repeat(x));
    }
    var seconds = (+new Date() - time) / 1000,
      token = i18n.t("ago"),
      list_choice = 1;
    if (seconds == 0) {
      return i18n.t("justNow");
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = i18n.t("in");
      list_choice = 2;
    }
    var i = 0,
      format;
    while ((format = time_formats[i++]))
      if (seconds < format[0]) {
        if (typeof format[2] == "string") return format[list_choice];
        else
          return format[1] == ""
            ? new Date(time).getDate() + " " + month[new Date(time).getMonth()]
            : token + " " + Math.floor(seconds / format[2]) + " " + format[1];
      }
    return time;
  },
  time_ago_card(time, preFormat) {
    switch (typeof time) {
      case "number":
        break;
      case "string": {
        let t = time.split(/[- :]/);
        time = +new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
        // time = +new Date(time);
        break;
      }
      case "object":
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
    var time_formats = [
      [60, i18n.t("seconds"), 1], //i18n.t("onoma")
      [
        120,
        preFormat ? i18n.t("aMinuteAgo2") : i18n.t("aMinuteAgo"),
        i18n.t("inAMinute"),
      ],
      [3600, i18n.t("minutes"), 60],
      [
        7200,
        preFormat ? i18n.t("anHourAgo2") : i18n.t("anHourAgo"),
        i18n.t("inAnHour"),
      ],
      [86400, i18n.t("hours"), 3600],
      [115200, i18n.t("day"), 86400],
      [2629743, i18n.t("days"), 86400],
      [31556926, i18n.t("months"), 2629743],
      [58060800000, i18n.t("years"), 31556926],
    ];
    let x = 13 - time.toString().length;
    if (x > 0) {
      time = Number(time + "0".repeat(x));
    }
    var seconds = (+new Date() - time) / 1000,
      token = preFormat ? i18n.t("ago2") : i18n.t("ago"),
      list_choice = 1;
    if (seconds == 0) {
      return i18n.t("justNow");
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = preFormat ? i18n.t("in2") : i18n.t("in");
      list_choice = 2;
    }
    var i = 0,
      format;
    while ((format = time_formats[i++]))
      if (seconds < format[0]) {
        if (typeof format[2] == "string") {
          return format[list_choice];
        } else {
          if (preFormat) {
            return (
              token +
              " " +
              Math.ceil(seconds / format[2] - 0.3) +
              " " +
              format[1]
            );
          } else {
            return (
              Math.ceil(seconds / format[2] - 0.3) +
              " " +
              format[1] +
              " " +
              token
            );
          }
        }
      }
    return time;
  },
  resultRating(rating) {
    if (rating == 5) {
      return "Πάρα πολύ";
    } else if (rating == 4) {
      return "Καλή";
    } else if (rating == 3) {
      return "Μέτρια";
    } else if (rating <= 2 && rating >= 0) {
      return "Κακή";
    } else {
      return "";
    }
  },
  customStringify(column, type) {
    return `{'${column}':${type}}`;
  },
  getImagePath(item) {
    // item: Object,
    /*  {
          path: require('@/assets/draggable/addImageCard.png') or url,
          absolutePath: false, -- If true then "https://my.newdeal.gr/" will not be added in the beginning
        }
    */
    if (!item.absolutePath && item.path)
      return "https://my.newdeal.gr/" + item.path;
    else if (item.path) return item.path;
    else return require("@/assets/realEstateCard/no-image.png");
  },
  getVideoEmbedLink(url) {
    let lUrl = url;
    if (lUrl.indexOf("=") !== -1) lUrl = lUrl.split("=")[1];
    if (lUrl.indexOf("&") !== -1) lUrl = lUrl.split("&")[0];
    return lUrl;
  },
  getFileNameFromUrl(url) {
    return url.replace(/.*\//g, "");
  },

  //Fuction that returns the currency type based the currencysChoice variable in store.
  // If symbolView is true the returns only the symbol else returns the sympol with the currency ISO code.
  getCurrency(symbolView) {
    let currency = store.state.settings.currency;
    if (currency == 0) {
      if (symbolView) {
        return "€";
      } else {
        return "EUR(€)";
      }
    } else if (currency == 1) {
      if (symbolView) {
        return "$";
      } else {
        return "USD($)";
      }
    } else {
      if (symbolView) {
        return "£";
      } else {
        return "GBP(£)";
      }
    }
  },

  getFileType(name) {
    return name.split(".").pop();
  },

  getFileSize(size) {
    var i = Math.floor(Math.log(size) / Math.log(1024));
    return (
      (size / Math.pow(1024, i)).toFixed(2) * 1 +
      " " +
      ["B", "kB", "MB", "GB", "TB"][i]
    );
  },
  parseBoolean(variable) {
    try {
      if (variable != undefined) {
        if (typeof variable == "boolean") {
          return variable;
        } else {
          return variable.toLowerCase() == "true" ? true : false;
        }
      }
      return false;
    } catch {
      return false;
    }
  },
  createComputed(readVariable) {
    return {
      get() {
        return this[readVariable];
      },
      set(newValue) {
        newValue;
      },
    };
  },
  sortByArray(mainArray, sortArray) {
    Array.prototype.remove = function () {
      var what,
        a = arguments,
        L = a.length,
        ax;
      while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
          this.splice(ax, 1);
        }
      }
      return this;
    };
    if (sortArray && sortArray.length > 0)
      sortArray.map((m) => {
        mainArray.remove(m);
        mainArray.unshift(m);
      });
    return mainArray;
  },
  escapeHTML(str) {
    const tagsToReplace = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
    };
    return str.replace(/[&<>]/g, (tag) => tagsToReplace[tag] || tag);
  },
  getMaskedCharacters(text, searchInput) {
    searchInput = (searchInput || "")
      .toString()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();
    const index = text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase()
      .indexOf(searchInput);
    if (index < 0) return { start: text, middle: "", end: "" };
    const indexEnd = index + searchInput.length;
    const start = text.slice(0, index);
    const middle = text.slice(index, indexEnd);
    let whiteSpaceAfter1 = text.charAt(indexEnd).trim() === "";
    let whiteSpaceAfter2 = text.charAt(indexEnd - 1).trim() === "";
    const end = text.slice(
      (whiteSpaceAfter == true ? index + 1 : index) + searchInput.length
    );
    const whiteSpaceBefore = text.charAt(index - 1).trim() === "";
    const whiteSpaceAfter =
      whiteSpaceAfter1 == true || whiteSpaceAfter2 == true ? true : false;
    return { start, middle, end, whiteSpaceBefore, whiteSpaceAfter };
  },

  getBrowserLanguage() {
    // var browserLang = navigator.languages; 
      // && navigator.languages.length
      //   ? navigator.languages[0]
      //   : navigator.language;
      return "gr"
    // if ([browserLang].includes("el"))
    //   return "gr";
    // else if ( [browserLang].includes('en'))
    //   return "en";
    // else return "en";
  },
  showObjects(data, arr, extraArr, maxCnt) {
    /* Function that finds which objects keys should be shown */
    var i = 0,
      showMore = undefined,
      showKeys = [];
    /* Fisrt loop based on arr values */
    while (i < arr.length) {
      if (data[arr[i]])
        if (showKeys.length < maxCnt) showKeys.push(arr[i]);
        else {
          /* If showKeys length is equal or bigger than max count then will break loop */
          showMore = false;
          break;
        }
      i++;
    }
    /* If showkeys length is not equal or bigger than max count then will run extra loop with extraArr values */
    if (showKeys.length < maxCnt && extraArr && extraArr.length > 0)
      /* Second loop based on extraArr values*/
      while (i < extraArr.length) {
        if (showKeys.length < maxCnt) showKeys.push(extraArr[i]);
        else {
          /* If showKeys length is equal or bigger than max count then will break loop */
          showMore = false;
          break;
        }
      }
    /* Return object containing keys to show and boolean for showMore */
    return { showKeys: showKeys, showMore: showMore };
  },
  showDualObjects(data, arr, maxCnt) {
    /* Function that finds which objects keys should be shown */
    var i = 0,
      showMore = undefined,
      showKeys = [];
    /* Loop based on arr values */
    while (i < arr.length) {
      if (data[arr[i][1]] || data[arr[i][2]])
        if (showKeys.length < maxCnt) showKeys.push(arr[i][0]);
        else {
          /* If showKeys length is equal or bigger than max count then will break loop */
          showMore = false;
          break;
        }
      i++;
    }
    /* Return object containing keys to show and boolean for showMore */
    return { showKeys: showKeys, showMore: showMore };
  },

  checkExtraShow(array) {
    var newArr = array.map((item) =>
      item != undefined
        ? item != null
          ? item.length > 1
            ? true
            : false
          : false
        : false
    );
    return newArr.includes(true);
  },
  genSuffix(FirstName, LastName) {
    let temp = FirstName.substr(0, 1) + LastName.substr(0, 2);
    let result = temp
      .split("")
      .sort(() => 0.5 - Math.random())
      .join("");
    return result.toUpperCase();
  },
};
