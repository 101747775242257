<template>
  <v-col>
    <v-data-table
      :headers="headers"
      :fixed-header="fixedHeader"
      :height="tableHeight ? tableHeight : null"
      :items="data"
      :items-per-page="itemsPerPage"
      :hide-default-footer="true"
      :loading="loading"
      class="elevation-1"
    >
      <!-- <template v-slot:header="{ props: { headers } }">
        <thead>
          <tr>
            <th v-for="h in headers" :key="h.text" :style="'width:' + h.width">
              <span>{{ $t(h.text) }}</span>
            </th>
          </tr>
        </thead>
      </template> -->
      <template
        v-for="(h, i) in headers"
        v-slot:[`header.${h.value}`]="{ header }"
      >
        <span
          v-if="![null, undefined, ''].includes(header.tooltip)"
          :key="'1-' + i"
        >
          <v-row>
            <v-col class="flex-grow-0 ml-8 pr-1 text-no-wrap">
              {{ $t(header.text) }}
            </v-col>
            <v-col class="flex-grow-0 pt-1 pl-0 pb-1">
              <InfoField color="#263573" :hoverText="header.tooltip" />
            </v-col>
          </v-row>
        </span>
        <span v-else :key="'2-' + i"> {{ $t(header.text) }} </span>
      </template>
      <template v-slot:[`item.actions`]="{ item }" class="text-center">
        <div class="d-flex flex-row align-center">
          <v-btn
            class="imgBtn"
            v-if="supportView"
            color="#D4D4D4"
            icon
            v-on:click="onView(item)"
          >
            <v-icon class="primaryColorOnHover">mdi-eye</v-icon>
          </v-btn>
          <v-btn
            class="imgBtn"
            v-if="supportEdit == true && isAuthorized('edit', item) == true"
            color="#D4D4D4"
            icon
            v-on:click="onEdit(item)"
          >
            <v-icon class="primaryColorOnHover">mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn
            class="imgBtn"
            v-if="supportDelete == true && isAuthorized('delete', item) == true"
            color="#D4D4D4"
            icon
            v-on:click="onDelete(item)"
          >
            <v-icon class="primaryColorOnHover">mdi-delete-outline</v-icon>
          </v-btn>
          <v-btn
            v-if="supportApproved && !item[approveItem]"
            small
            color="#77BD86"
            class="mr-1 approve-report-btn"
            v-on:click="onApproveHandler(item)"
          >
            <v-icon dark color="white" size="18"> mdi-check </v-icon>
          </v-btn>
          <v-btn
            v-if="supportApproved && !item[approveItem]"
            small
            color="#D4D4D4"
            v-on:click="onRejectHandler(item)"
            class="reject-report-btn"
          >
            <v-icon dark color="white" size="18"> mdi-close </v-icon>
          </v-btn>
          <v-btn
            class="imgBtn"
            v-if="supportDeleteAttr == true && item[deleteAttr]"
            color="#D4D4D4"
            icon
            v-on:click="onDelete(item)"
          >
            <v-icon class="primaryColorOnHover">mdi-delete-outline</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-for="t in templates" v-slot:[`item.${t.column}`]="{ item }">
        <v-img
          :key="t.column"
          v-if="t.elementType == 'img'"
          class="imgContent"
          height="95"
          width="124"
          :src="getImage(item[t.dataObject])"
          :lazy-src="getImage(item[t.dataObject])"
        />
        <v-progress-linear
          :class="t.rowClass"
          :value="item[t.dataObject]"
          :key="t.column"
          :color="item[t.colorBar]"
          v-if="t.elementType === 'progress'"
        />
        <span
          :key="t.column + ' prog'"
          v-if="t.elementType == 'progress'"
          class="d-flex justify-center"
        >
          {{ item[t.dataObject] }}%
        </span>

        <AvatarField
          v-if="t.elementType == 'avatar'"
          :key="t.column"
          :rowClass="t.rowClass"
          :isImg="item[t.isImg]"
          :absolutePath="item[t.absolutePath]"
          :data="item[t.dataObject]"
          :showName="t.content != undefined && t.content != ''"
          :nameContent="item[t.content]"
          :nameContentClass="t.nameContentClass ? t.nameContentClass : ''"
          :showRating="t.ratingData != undefined && t.ratingData != ''"
          :ratingData="item[t.ratingData]"
          :showSubContent="t.subContent != undefined && t.subContent != ''"
          :subContent="item[t.subContent]"
          :subContentClass="t.subContentClass ? t.subContentClass : ''"
          :size="t.size ? t.size : 45"
          :fontSizeName="t.fontSizeName ? t.fontSizeName : '15px'"
          :fontSizeSubName="t.fontSizeSubName ? t.fontSizeSubName : '13px'"
          :fontSizeSubContent="
            t.fontSizeSubContent ? t.fontSizeSubContent : '14px'
          "
          :backgroundColor="
            item[t.backgroundAvatarColor]? item[t.backgroundAvatarColor] : '#77bd86'
          "
          :avatarTitleClass="
            t.avatarTitleClass ? item[t.avatarTitleClass] : 'white--text'
          "
          :avatarStyle="t.avatarStyle ? item[t.avatarStyle] : ''"
          :fontSizeImg="t.fontSizeImg ? t.fontSizeImg : '24px'"
          v-on:click.native="
            () =>
              item[t.allowClickableAvatar]
                ? clickedAvatar(item[t.avatarId] ? item[t.avatarId] : null)
                : ''
          "
        />

        <span v-if="t.elementType == 'customAvatar'" :key="t.column">
          <AvatarField
            v-if="t.isAvatar == true || item[t.column + 'IsAvatar'] != true"
            :rowClass="t.rowClass"
            :isImg="item[t.isImg]"
            :data="item[t.dataObject]"
            :showName="t.content != undefined && t.content != ''"
            :nameContent="item[t.content]"
            :nameContentClass="t.nameContentClass ? t.nameContentClass : ''"
            :showRating="t.ratingData != undefined && t.ratingData != ''"
            :ratingData="item[t.ratingData]"
            :showSubContent="t.subContent != undefined && t.subContent != ''"
            :subContent="item[t.subContent]"
            :subContentClass="t.subContentClass ? t.subContentClass : ''"
            :size="t.size ? t.size : 45"
            :fontSizeName="t.fontSizeName ? t.fontSizeName : '15px'"
            :fontSizeSubName="t.fontSizeSubName ? t.fontSizeSubName : '13px'"
            :fontSizeSubContent="
              t.fontSizeSubContent ? t.fontSizeSubContent : '14px'
            "
            :backgroundColor="
              t.backgroundAvatarColor ? t.backgroundAvatarColor : '#263573'
            "
            :avatarTitleClass="
              t.avatarTitleClass ? item[t.avatarTitleClass] : 'white--text'
            "
            :avatarStyle="t.avatarStyle ? item[t.avatarStyle] : ''"
            :fontSizeImg="t.fontSizeImg ? t.fontSizeImg : '24px'"
             v-on:click.native="
              () =>
                item[t.allowClickableAvatar]
                  ? clickedAvatar(item[t.avatarId] ? item[t.avatarId] : null)
                  : ''
            "
          />
          <IconField
            v-else
            :masterRowClass="t.rowClass"
            :nameContent="item[t.content]"
            :nameContentClass="item[t.nameContentClass]"
            :subContent="item.iconFieldSubContent"
            :subContentClass="item[t.subContentClass]"
            :iconObj="item.iconFieldΙconObj"
            :iconColClass="item.iconFieldΙconColClass"
            :color="item.iconFieldColor"
          />
        </span>
        <!-- :class="item[t.bcolor] == 1 ? 'greenStatusBox pl-4 pr-4 pt-1 pb-1 mt-1' : item[t.bcolor] == 2 ? 'blueStatusBox pl-4 pr-4 pt-1 pb-1 mt-1' : item[t.bcolor] == 3 ? 'yellowStatusBox pl-4 pr-4 pt-1 pb-1 mt-1' : 'whiteStatusBox pl-4 pr-4 pt-1 pb-1 mt-1'"         -->
        <span
          :class="item[t.bcolor]"
          :key="t.column"
          v-if="t.elementType == 'textBox'"
        >
          {{ item[t.dataObject] != undefined ? item[t.dataObject] : "" }}
        </span>

        <p
          :key="t.column"
          v-if="t.elementType == 'coloredTextBox' && item[t.dataObject]"
          style="padding-top: 13px"
        >
          <span style="color: #263573">
            {{
              item[t.dataObject].split(" ")[0] != undefined
                ? item[t.dataObject].split(" ")[0]
                : ""
            }}</span
          >
          <span style="color: #e52628">
            {{
              item[t.dataObject].substring(
                item[t.dataObject].indexOf(" ") + 1
              ) != undefined
                ? item[t.dataObject].substring(
                    item[t.dataObject].indexOf(" ") + 1
                  )
                : ""
            }}
          </span>
        </p>
        <EstateInfo
          v-if="t.elementType == 'estateInfo'"
          :key="t.column"
          :title="item[t.title]"
          :subTitle="item[t.subTitle]"
          :description="item[t.description]"
          :image="item[t.image]"
          :vip="item[t.vip]"
          v-on:click.native="onCustomView(item)"
        />
        <CharacteristicsInfo
          v-if="t.elementType == 'characteristics'"
          :key="t.column"
          :characteristicsArray="item[t.characteristicsArray]"
          :rightSelect="item[t.rightSelect]"
        />

        <MatchingLeadStatus
          v-if="t.elementType == 'matchingStatus'"
          :key="t.column"
          :title="item[t.title]"
        />
        <ClickableBox
          v-if="t.elementType == 'ClickableBox'"
          :key="t.column"
          :title="item[t.title]"
          :titleBox="item[t.titleBox]"
          :titleColor="item[t.titleColor]"
          :titleOnHoverColor="item[t.titleOnHoverColor]"
          v-on:click.native="item[t.titleBox] ? onCustomView(item) : ''"
        />

        <span v-if="t.elementType == 'spanIcon'" :key="t.column">
          <Icon
            v-if="item[t.isIcon]"
            :icon="item[t.iconObj].icon"
            :height="item[t.iconObj].height ? item[t.iconObj].height : '21'"
            :color="item[t.iconObj].color ? item[t.iconObj].color : 'white'"
          />
          <span v-else :class="item[t.textClass]">{{ item[t.textData] }}</span>
        </span>

        <span v-if="t.elementType == 'statusIcon'" :key="t.column">
          <Icon
            v-if="item[t.isIcon]"
            :icon="item[t.iconObj].icon"
            :height="item[t.iconObj].height ? item[t.iconObj].height : '21'"
            :color="item[t.iconObj].color ? item[t.iconObj].color : 'white'"
          />
          <span :class="item[t.textClass]">{{ item[t.textData] }}</span>
        </span>

        <v-row
          v-if="t.elementType == 'rating'"
          :key="t.column"
          class="flex-nowrap"
        >
          <v-col class="px-0" align="right">
            <v-rating
              dense
              color="#243774"
              background-color="#243774 !important"
              :length="1"
              :readonly="true"
              size="18"
            />
          </v-col>
          <v-col
            class="textDecorationBold font14 primaryColor px-0"
            align="left"
          >
            <span> {{ item[t.ratingValue] }} </span>
          </v-col>
        </v-row>

        <span
          v-if="t.elementType == 'customSpan'"
          :key="t.column"
          :class="t.rowClass"
        >
          {{ item[t.title] }}
        </span>

        <v-row
          v-if="t.elementType == 'unread'"
          :key="t.column"
          class="flex-nowrap flex-grow-0 ml-n4 mt-n2"
        >
          <v-col
            class="flex-grow-0 pl-0 align-self-center"
            style="min-width: 9px !important"
        >
            <svg
              v-if="item[t.redRow] == true"
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="61"
              viewBox="0 0 9 61"
            >
              <rect
                id="Rectangle_3502"
                data-name="Rectangle 3502"
                width="9"
                height="61"
                fill="#eb1f28"
              />
            </svg>
          </v-col>
          <v-col
            class="align-self-center font14 ml-1 mt-n2 mr-n4"
            :class="item[t.redRow] ? 'textDecorationBold' : 'textDecoration'"
          >
            <span :style="item[t.redRow] ? 'color: #E52628;' : ''">
              {{ item[t.numTitle] }}
            </span>
          </v-col>
        </v-row>

        <v-col
          v-if="t.elementType == 'plainField'"
          :key="t.column"
          align="left"
        >
          <v-row class="align-center">
            <v-col :class="t.nameContentClass ? t.nameContentClass : ''">
              <span> {{ item[t.content] }} </span>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col :class="t.subContentClass ? t.subContentClass : ''">
              <span> {{ item[t.subContent] }} </span>
            </v-col>
          </v-row>
        </v-col>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
/* 
  Name:CustomTable
  Description: Table component to generate quickly tables. Support three action buttons and custom template columns 
  API Property 
  headers: Columns of tables
  fixedHeader: Boolean that defines if header is fixed or not
  tableHeight: Number that defines the height of the table
  data: Data to be displayed in the table
  page: Current page for pagination
  templates: Array of object for custom columns 
    Examples:[
      {column:"img",dataObject:"img",isDisabled:true,elementType:"img",budgetVariant:"",avatarVariant:""} //img
      {column:"progress",dataObject:"progress",isDisabled:true,elementType:"progress",colorBar:"primary"} // progress bar
      {column:"avatar",dataObject:"photo/char",isImg:"",content:"",subContent:"",ratingData:"",elementType:"avatar", rowClass:"justify-center", allowClickableAvatar: "true/false", avatarId: "76437", backgroundAvatarColor:"#77BD86"} 
      {column:"status",dataObject:"status",elementType:"textBox"}
      {column:"estateInfo",dataObject:"estateInfo",elementType:"estateInfo"}
      {column:"CharacteristicsInfo",characteristicsArray:"characteristicsArray",rightSelect:"true"elementType:"characteristics"}
      {column:"rating",elementType:"rating",colorRating:"colorRating",disabledRating:"disabledRating",valueRating:"valueRating",sizeRating:"sizeRating",ratingValue:"ratingValue"},
      {column:"customSpan",elementType: "customSpan",rowClass:"orangeTextInput",title:"hello"}
      {column:"unread",elementType: "unread",numTitle:"10",redRow:"true"},
      {column:"status",elementType:"spanIcon",isIcon:false,iconObj: {icon: "ant-design:up-outlined",height: 15,color: "white"},textData:'',textColor:'' }
      {column:"statusText",elementType:"statusIcon",isIcon:false,iconObj: {icon: "ant-design:up-outlined",height: 15,color: "white"},textData:'',textColor:'' }
     {column:"coloredTextBox", elementType:"coloredTextBox", dataObject:"newdeal delux"},
      {column:"matchingStatus",dataObject:"approved",elementType:"matchingStatus"}
      {column:"ClickableBox",title:"approved",titleOnHoverColor:"#77BD86",elementType:"ClickableBox"}
    ] 
  supportView: Boolean variable to show/hide button view
  supportEdit: Boolean variable to show/hide button edit
  supportDelete: Boolean variable to show/hide button delete
  supportDownload: Boolean variable to show/hide button Download
  supportApproved:Boolean variable to show/hide buttons Approved
  supportDeleteAttr:Boolean variable to show/hide dynamic delete button
  deleteAttr:Second name variable to check if show/hide delete button
  approveItem:Item to check when button show
  handleEdit: Handler function from parent when click edit button
  handleView: Handler function from parent when click view button
  handleDelete: Handler function from parent where click delete button
  handleDownload: Handler function from parent where click download button  
  handleApproved:Handler function from parent when click approve button
  handleRejected:Handler function from parent when click reject button
  handleEstateInfoView: Function, Handler function from parent when click Estate Info
  loading: Boolean viriable to know component loading data
  clickedAvatar: Function for the click avatart handler
*/

import AvatarField from "@/components/Fields/AvatarField/AvatarField.vue";
import IconField from "@/components/Fields/IconField/IconField.vue";
import EstateInfo from "@/components/Fields/EstateInfo/EstateInfo";
import MatchingLeadStatus from "@/components/Fields/MatchingLeadStatus/MatchingLeadStatus.vue";
import ClickableBox from "@/components/Fields/ClickableBox/ClickableBox.vue";
import CharacteristicsInfo from "@/components/Fields/CharacteristicsInfo/CharacteristicsInfo";
import CoreHelper from "@/helpers/Core/core.js";
import AuthHelper from "@/helpers/Core/auth.js";
import ContactsHelper from "@/helpers/Contacts/contacts.js";
import DemandsHelper from "@/helpers/Demand/demand.js";
import InfoField from "@/components/Fields/InfoField/InfoField.vue";
import { Icon } from "@iconify/vue2";
import { mapState } from "vuex";
export default {
  name: "CustomTable",
  props: {
    headers: Array, // headers of table
    fixedHeader: { type: Boolean, default: false },
    tableHeight: Number,
    data: Array, // data of table
    itemsPerPage: Number, // number of row table
    page: Number, // currentPage
    templates: Array, // template for custom view on columns
    supportView: { type: Boolean, default: false }, //show/hide view button
    supportEdit: { type: Boolean, default: false }, // show/hide edit button
    supportDelete: { type: Boolean, default: false }, // show/hide delete button
    supportDownload: { type: Boolean, default: false }, // show/hide download button
    supportApproved: { type: Boolean, default: false },
    supportDeleteAttr: { type: Boolean, default: false },
    approveItem: String,
    deleteAttr: String,
    showDelete: { type: Boolean, default: false },
    showEdit: { type: Boolean, default: false },
    handleEdit: Function, // handler function on edit button
    handleView: Function, // handler function on view button
    handleDelete: Function, // handler function on delete button
    handleDownload: Function, // handler function on download button
    handleApproved: Function,
    handleRejected: Function,
    handleEstateInfoView: Function, // handler function on Estate Info View
    loading: Boolean, // variable to know component loading data
    source: { type: String, default: "Estate" },
    clickedAvatar: Function,
  },
  components: {
    AvatarField,
    IconField,
    EstateInfo,
    CharacteristicsInfo,
    MatchingLeadStatus,
    ClickableBox,
    Icon,
    InfoField,
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.auth.userDetails,
    }),
  },
  methods: {
    onDelete(item) {
      this.handleDelete(item);
    },
    onEdit(item) {
      this.handleEdit(item);
    },
    onView(item) {
      this.handleView(item);
    },
    onCustomView(item) {
      this.handleEstateInfoView(item);
    },
    getImage(image) {
      if (image != null && image != undefined && image != "") {
        return CoreHelper.getImagePath({ path: image });
      } else {
        return require("@/assets/realEstateCard/no-image.png");
      }
    },
    isAuthorized(action, data) {
      if (this.source == "Estate") {
        let key = AuthHelper.getKeyForAuth(this.userDetails.Role);
        return AuthHelper.authorization(
          action,
          data[key] ? data[key] : null,
          data["userID"] ? data["userID"] : null
        );
      } else if (this.source == "Contacts") {
        return ContactsHelper.isAuthorizedContact({
          role: data.Role ? data.Role : "",
          type: data.Type && data.Type.length > 0 ? data.Type : [],
          _id: data._id ? data._id : "",
          lastCompany: data.LastCompany ? data.LastCompany : "",
        });
      } else if (this.source =='Demands'){ 
          return DemandsHelper.isAuthorizedDemand(data);
      }else return true;
    },
    onApproveHandler(item) {
      this.handleApproved(item);
    },
    onRejectHandler(item) {
      this.handleRejected(item);
    },
  },
};
</script>

<style scoped>
.v-data-table-header {
  color: #3a3a40 !important;
}

.v-pagination__item {
  box-shadow: none !important;
}

.v-pagination__navigation {
  box-shadow: none !important;
}

.table-button {
  text-transform: capitalize !important;
  font-family: CenturyGothicBold;
  font-weight: 500;
}

.page-container {
  width: 150px;
}

.imgContent {
  margin: 10px;
  border-radius: 4px;
}

.whiteStatusBox {
  font-size: 13px !important;
  color: #263573 !important;
  border: solid 2px #707070 !important;
}
.reject-report-btn,
.approve-report-btn {
  min-height: 21px !important;
  height: 21px !important;
  min-width: 21px !important;
  width: 21px !important;
  border-radius: 0px !important;
}
</style>
