import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name != "NavigationDuplicated") {
      throw err;
    }
  });
};

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: () => import("@/views/Authorization/Login/Login"),
    meta: { isNotAuthenticated: true },
  },
  // {
  //   path: "/register",
  //   component: () => import("@/views/Authorization/Register/Register"),
  //   meta: { isNotAuthenticated: true },
  // },
  {
    path: "/registerSuccess",
    component: () => import("@/views/Authorization/Register/RegisterSuccess"),
    meta: { isNotAuthenticated: true },
  },
  {
    path: "/forgotPassword",
    component: () => import("@/views/Authorization/ForgotPass/ForgotPassword"),
    meta: { isNotAuthenticated: true },
  },
  {
    path: "/newPassword",
    component: () => import("@/views/Authorization/ForgotPass/NewPassword"),
    meta: { isNotAuthenticated: true },
  },
  {
    path: "/newPasswordSuccess",
    component: () =>
      import("@/views/Authorization/ForgotPass/NewPasswordSuccess"),
    meta: { isNotAuthenticated: true },
  },
  {
    path: "/forgotPasswordSent",
    component: () =>
      import("@/views/Authorization/ForgotPass/ForgotPasswordSent"),
    meta: { isNotAuthenticated: true },
  },
  {
    path: "/dashboard",
    component: () => import("@/views/Dashboard/Dashboard"),
    meta: { requiresAuth: true },
  },
  {
    path: "/real_estate",
    component: () => import("@/views/RealEstate/RealEstate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/requests",
    redirect: '/requests/cooperations',
    component: () => import("@/views/Requests/Requests.vue"),
    children: [
      {
        path: "cooperations",
        component: () =>
          import("@/views/Requests/RequestTabs/Cooperations.vue"),
      },
      {
        path: "demands",
        component: () =>
          import("@/views/Requests/RequestTabs/Demands.vue"),
      },
    ],
  },
  {
    path: "/secretary",
    redirect: '/secretary/accounts',
    component: () => import("@/views/Secretary/Secretary.vue"),
    children: [
      {
        path: "callManager",
        component: () =>
          import("@/views/Secretary/SecretaryTabs/CallManager.vue"),
      },
      {
        path: "callCenter",
        component: () =>
          import("@/views/Secretary/SecretaryTabs/CallCenter.vue"),
      },
      {
        path: "accounts",
        component: () =>
          import("@/views/Secretary/SecretaryTabs/Accounts.vue"),
      },
    ],
  },
  {
    path: "/createCall",
    component: () => import("@/components/Secretary/CreateCall/CreateCall.vue"),
    redirect: '/requests/demandCall',
    children: [
      {
        path: "demandCall",
        component: () =>
        import("@/components/Secretary/CreateCall/DemandCall.vue"),
      },
      {
        path: "recommendationCall",
        component: () =>
        import("@/components/Secretary/CreateCall/RecommendationCall.vue"),
      },
      {
        path: "assignmentCall",
        component: () =>
        import("@/components/Secretary/CreateCall/AssignmentCall.vue"),
      },
    ],
  },
  {
    path: "/contacts",
    component: () => import("@/views/Contacts/Contacts.vue"),
  },
  {
    path: "/secretary",
    component: () => import("@/views/Secretary/Secretary.vue"),
  },
  {
    path: "/libraries",
    component: () => import("@/views/Library/Library.vue"),
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log(to.matched.some((record) => record.meta.requiresAuth));
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // console.log(store.getters.isAuthenticated);
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/login");
  } else if (to.matched.some((record) => record.meta.isNotAuthenticated)) {
    // if (store.getters.isAuthenticated) {
    //   next("/");
    // } else {
    next();
    // }
  } else {
    next(); // make sure to always call next()!
  }
});
export default router;
