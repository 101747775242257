<template>
  <v-card :loading="loading" :class="cardClass">
    <!-- max-width="300"
    max-height="435"
    min-width="300"
    min-height="435" 
    :class="cardClass"-->
    <v-row class="cardContent" style="cursor: pointer" @click.prevent="openRealEstate()">
      <v-col class="pa-0">
        <v-row>
          <v-col class="pb-0">
            <v-img :class="{ opacity: !isApproved, imgContent: true }" height="203" :src="tempImage"
              :lazy-src="tempImage" @error="imageError">
              <v-row class="h50">
                <v-col>
                  <v-app-bar flat color="rgba(0, 0, 0, 0)">
                    <v-btn v-if="mobileBreakpoint != true" color="white" class="text-h6 white--text pl-0 imgBtn" icon>
                      <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                    </v-btn>
                    <span class="timeAgoSpan" v-else>
                      {{ timeAgo(RegisterationDay) }}
                    </span>
                    <div></div>
                    <v-spacer></v-spacer>
                    <v-btn class="imgBtn" color="white" icon v-if="this.isAuthorized('edit', authObj) == true"
                      @click.stop="openRealEstateEdit">
                      <v-icon class="primaryColorOnHover">
                        mdi-pencil-outline
                      </v-icon>
                    </v-btn>
                    <v-btn class="imgBtn" color="white" icon v-if="this.isAuthorized('delete', authObj) == true"
                      @click.stop="onDeleteHandler">
                      <v-icon class="primaryColorOnHover">
                        mdi-delete-outline
                      </v-icon>
                    </v-btn>
                  </v-app-bar>
                </v-col>
              </v-row>
              <v-row class="h50">
                <v-col class="d-flex align-end">
                  <v-row class="pl-6 d-flex align-center">
                    <span v-if="booleanVip" class="textDecorationBold vipClass mr-2">
                      VIP
                    </span>
                    <span v-if="showDifPrice" class="textDecorationBold discountClass pl-2 pr-2">
                      {{ getPrice.type + " " + getPrice.percentage + "%" }}
                    </span>
                  </v-row>
                </v-col>
              </v-row>
            </v-img>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pa-0">
            <v-card-title class="textDecorationBold pb-2" style="color: #263573">
              <v-tooltip bottom :disabled="
                txtLength(
                  (title != undefined ? title : '') +
                  ' ' +
                  (unit != undefined ? '|' + unit + ' τ.μ.' : '')
                ) < (mobileBreakpoint == true ? 60 : 28)
              ">
                <template v-slot:activator="{ on, attrs }">
                  <div class="font16 pb-0 text-truncate" align="left" v-bind="attrs" v-on="on">
                    {{
                        textOverFlow(
                          (![undefined, "", null].includes(title)
                            ? title
                            : "--") +
                          " " +
                          (![undefined, "", null].includes(unit)
                            ? "|" + unit + " τ.μ."
                            : "--"),
                          mobileBreakpoint == true ? 60 : 27
                        )
                    }}
                  </div>
                </template>
                <span>{{ ![undefined, "", null].includes(title) ? title : "--" }}
                  {{
                      ![undefined, "", null].includes(unit)
                        ? "|" + unit + " τ.μ."
                        : "--"
                  }}</span>
              </v-tooltip>
            </v-card-title>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pa-0">
            <v-card-text class="textFontDecoration">
              <v-row>
                <v-col class="pt-0 pb-0">
                  <h3 class="font14 textContent textDecorationBold" align="left">
                    {{ ![undefined, "", null].includes(price) ? price : "--" }}
                  </h3>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col class="pt-0 pb-0">
                  <v-tooltip bottom :disabled="txtLength(location) < 33">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="textContent font12 pb-0 text-truncate" align="left" v-bind="attrs" v-on="on">
                        {{
                            ![undefined, "", null].includes(location)
                              ? textOverFlow(
                                location,
                                mobileBreakpoint == true ? 60 : 32
                              )
                              : "--"
                        }}
                      </div>
                    </template>
                    <span>{{
                        ![undefined, "", null].includes(location)
                          ? location
                          : "--"
                    }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row class="mt-0 mb-0">
                <v-col class="pt-2 pb-0">
                  <div class="textContent textDecorationBold font14 veryDarkGrayColor" align="right">
                    {{ code }}
                  </div>
                </v-col>
              </v-row>
              <v-row align="center" class="mt-0 flex-nowrap" >
                <v-col class="
                    text-no-wrap
                    textContent
                    textDecorationBold
                    ml-3
                    flex-grow-0
                  ">
                  <span>
                    {{
                        ![undefined, "", null].includes(typeName)
                          ? typeName
                          : "--"
                    }}
                  </span>
                </v-col>
                <v-col class="mb-2 flex-grow-0" v-if="mobileBreakpoint != true">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="font16 pb-0" align="left" v-bind="attrs" v-on="on">
                        <v-img height="23px" width="23px" :src="require('@/assets/realEstateCard/global.svg')">
                        </v-img>
                      </div>
                    </template>
                    <span> {{ timeAgo(RegisterationDay) }} </span>
                  </v-tooltip>
                </v-col>
                <v-col class="pl-0">
                  <div class="text-no-wrap textContent textFontDecoration font14" style="color: #263573" align="right">
                    {{ typeExtraContent }}
                  </div>
                </v-col>
              </v-row>

              <div>
                <v-divider></v-divider>
              </div>

              <v-row v-if="!isApproved && isAuthorized('approveEstate', authObj)" class="pt-4 justify-start align-end">
                <!-- v-if="status == "Προς Έγκριση"-->
                <v-col :class="mobileBreakpoint == true ? '' : 'flex-grow-0'">
                  <v-btn :width="mobileBreakpoint == true ? '100%' : 81" :height="30" color="success"
                    class="approveClass" @click.stop="approveAction(true)">
                    {{ $t("approve") }}
                  </v-btn>
                </v-col>
                <v-col align="left" :class="mobileBreakpoint == true ? '' : 'flex-grow-0'">
                  <v-btn :width="mobileBreakpoint == true ? '100%' : 81" :height="30" color="error" class="rejectClass"
                    @click.stop="approveAction(false)">
                    {{ $t("reject") }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-else class="flex-nowrap space-between pt-4">
                <v-col class="flex-grow-0">
                  <span :class="
                    getStatusClass +
                    ' pt-0 pb-0 pl-4 pr-4 d-flex justify-center align-center text-no-wrap'
                  " style="height: 30px">
                    {{
                        ![undefined, "", null].includes(status) ? status : "--"
                    }}
                  </span>
                </v-col>
                <v-col class="pt-4">
                  <v-row class="flex-nowrap space-between">
                    <v-col class="pr-0" align="left" v-if="commentsCnt != 0">
                      <v-row class="justify-end">
                        <v-col class="flex-grow-0 pl-0 pr-0 pt-3 errorColor">
                          <span class="textDecorationBold font14">
                            {{ commentsCnt }}
                          </span>
                        </v-col>
                        <v-col s class="flex-grow-0 pl-1 pt-4">
                          <v-img style="width: 20px" :src="
                            require('@/assets/realEstateCard/comment.svg')
                          " color="#e52628" />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="pl-0" v-if="avgReviewsRating.revCnt != 0">
                      <v-row class="justify-end">
                        <v-col class="flex-grow-0 pl-0 pr-0">
                          <v-img style="width: 20px" :src="require('@/assets/realEstateCard/star.svg')" />
                        </v-col>
                        <v-col style="color: #e89008" class="flex-grow-0 pl-1">
                          <span class="textDecorationBold font14">
                            {{ avgReviewsRating.revAvg }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="!isApproved" class="forApproveClass justify-center ma-0 textDecorationBold"
      @click.prevent="openRealEstate()">
      <v-col align="center">
        <span> {{ $t("toBeApproved") }} </span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
/*
 Name: RealEstateCard
 Description: Card to show real estate with basic info
 API Property: 
    id: String, Number for the id of card
    cardClass: String for the card class
    image: String for the image of card
    title: String for the tile of card
    price: String, Number for the price of object
    location: String for the location of object
    code: String for the unique code of card/object
    typeName: String for the title of type
    typeExtraContent: String for the sub title of type
    comments: Number, String, Array for the comments
    status: String for the status of object
    rating: Array, Object for the rating
    openRealEstateEditHandler: Function on edit card
    openRealEstateSliderHandler: Function on open card
    onDelete: Function on delete
    approvalActionHandler: Function on approve/reject
    isApproved: Boolean for when it is false we show aprove and reject btns
    unit: String, Number for the unit price
    prices: Array for the hint of price
    booleanVip: Boolean for the vip can be true or false
    RegisterationDay: String, Number for the date registered
    authObj: Object containing values necessary for authorization
*/
import CoreHelper from "@/helpers/Core/core.js";
import AuthHelper from "@/helpers/Core/auth.js";
import { mapState } from "vuex";
export default {
  name: "RealEstateCard",
  props: {
    id: [String, Number], // Id of card
    cardClass: { type: String, default: "defaultEstateCard" },
    image: String, // Image of card
    title: String, // Tile of card
    price: [String, Number], // price of object
    location: String, // location of object
    code: String, // unique code of card/object
    typeName: String, // Title of type
    typeExtraContent: String, //Sub title of type
    comments: [Number, String, Array], // Comments
    status: String, // status of object
    rating: [Array, Object], // rating
    openRealEstateEditHandler: Function, // Handler on edit card
    openRealEstateSliderHandler: Function, // Handler on open card
    onDelete: Function,
    approvalActionHandler: Function, //Handler on approve/reject
    isApproved: Boolean, //When it is false we show aprove and reject btns
    unit: [String, Number], //τιμη τμ
    prices: Array, // hint of price
    booleanVip: Boolean, //vip can be true or false
    RegisterationDay: [String, Number], //Date registered
    authObj: Object, // Object containing values necessary for authorization
  },
  created() {
    this.tempImage = this.getImage(this.image);
  },
  data() {
    return {
      loading: false,
      loader: false,
      loader2: false,
    };
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.auth.userDetails,
    }),
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    showDifPrice() {
      if (this.prices) return this.prices.length > 1;

      return false;
    },
    getStatusClass() {
      if (
        ["Υπο διαπραγμάτευση", "Μη ενεργό", "Ενεργό", "Προκαταβολή"].includes(
          this.status
        )
      ) {
        return "whiteStatusBox";
      } else if (["Πουλήθηκε", "Ενοικιάστηκε"].includes(this.status)) {
        return "redStatusBox textDecorationBold";
      } else if (this.status == "Πρόχειρο") {
        return "grayStatusBox";
      } else if (this.status == "Μη δημοσιευμένη") {
        return "darkGrayStatusBox";
      } else {
        return "";
      }
    },
    getPrice() {
      let max = this.prices.length;
      if (max > 1) {
        let type =
          this.prices[max - 1].change_literal == "initial_price"
            ? "Αρχική τιμή"
            : this.prices[max - 1].change_literal == "price_increase"
              ? "ΑΥΞΗΣΗ ΤΙΜΗΣ"
              : "ΜΕΙΩΣΗ ΤΙΜΗΣ";
        return {
          type: type,
          percentage: this.prices[max - 1].change_percentage,
        };
      }
      return "";
    },
    avgReviewsRating() {
      let reviewsSum = 0;
      let revCnt = 0;
      if (this.rating && this.rating.length > 0) {
        this.rating.map((m) => {
          if (m.rating_average) {
            reviewsSum += m.rating_average;
            revCnt += 1;
          }
        });
      }
      let revAvg = 0;
      revAvg =
        revCnt != 0 ? parseFloat(reviewsSum / revCnt).toFixed(2) : revAvg;
      return {
        revAvg: revAvg,
        revCnt: revCnt,
      };
    },
    commentsCnt() {
      try {
        return this.comments.filter((d) => {
          if (!d.is_deleted) {
            return d;
          }
        }).length;
      } catch {
        return 0;
      }
    },
  },
  methods: {
    txtLength(txt) {
      try {
        return txt.length;
      } catch {
        return 0;
      }
    },
    textOverFlow(text, length) {
      try {
        if (text.length <= length) return text;
        else return text.substr(0, length) + " ..";
      } catch {
        return text;
      }
    },
    onDeleteHandler() {
      this.onDelete({ _id: this.id, EstateCode: this.code });
    },
    approveAction(action) {
      this.loader = true;
      this.loader2 = true;
      this.approvalActionHandler(this.id, action);
    },
    reserve() {
      this.loading = true;
      setTimeout(() => (this.loading = false), 2000);
    },
    openRealEstateEdit() {
      this.openRealEstateEditHandler();
    },
    openRealEstate() {
      this.openRealEstateSliderHandler();
    },
    imageError() {
      this.tempImage = require("@/assets/realEstateCard/no-image.png");
    },
    getImage() {
      return CoreHelper.getImagePath({ path: this.image });
    },
    timeAgo(date) {
      try {
        return CoreHelper.time_ago_card(date);
      } catch {
        return "1";
      }
    },
    isAuthorized(action, data) {
      let key = AuthHelper.getKeyForAuth(this.userDetails.Role);
      return AuthHelper.authorization(
        action,
        data[key] ? data[key] : null,
        data["userID"] ? data["userID"] : null
      );
    },
  },
};
</script>

<style scoped>
.vipClass {
  background: rgba(232, 144, 8, 0.75);
  color: #ffffff;
  width: 40px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeAgoSpan {
  font-family: "InterMedium";
  font-size: 14px;
  background-color: #263573b5;
  border-radius: 5px;
  padding: 2px 8px 3px 8px;
  color: white;
}

.discountClass {
  background: #e52628;
  color: #ffffff;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whiteStatusBox {
  font-size: 13px !important;
  color: #263573 !important;
  border: solid 2px rgba(112, 112, 112, 0.39) !important;
}

.redStatusBox {
  font-size: 13px !important;
  color: #ffffff !important;
  background-color: #e52628 !important;
}

.grayStatusBox {
  font-size: 13px !important;
  color: #ffffff !important;
  background-color: #dadada !important;
}

.darkGrayStatusBox {
  font-size: 13px !important;
  color: #263573 !important;
  background-color: #43425d !important;
}

.cardContent {
  margin: 5% 5% 0% 5%;
}

.textContent {
  padding: 0px 0px 9px 0px !important;
}

.imgContent {
  margin: 0px !important;
  border-radius: 5px;
}

.textTranformUnset {
  text-transform: unset !important;
}

.imgBtn {
  height: 35px !important;
  width: 35px !important;
}

.font16 {
  font-size: 16px;
}

.font14 {
  font-size: 14px;
}

.font12 {
  font-size: 12px;
}

.btn {
  text-transform: unset !important;
  color: #263573;
}

.approveClass {
  font-size: 13px;
  background-color: #77bd85 !important;
  border-radius: 0px !important;
  text-transform: capitalize !important;
}

.rejectClass {
  font-size: 13px;
  background-color: #e52628 !important;
  border-radius: 0px !important;
  text-transform: capitalize !important;
}

.opacity {
  filter: brightness(0.5);
}

.forApproveClass {
  font-size: 16px !important;
  color: #ffffff !important;
  position: absolute;
  width: 100%;
  top: 25%;
  cursor: pointer;
}

.h25 {
  height: 25%;
}

.h50 {
  height: 50%;
}
</style>
