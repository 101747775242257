import AxiosClientToken from "../Client/AxiosClientToken";
import firebase from "firebase";

const resource = "/list";
const resourceCud = "/general";
export default {
  get(filters, params) {
    return AxiosClientToken.post(`${process.env.VUE_APP_BASE_URL}/${resource}/QueryContacts`, filters, {
      params: params,
    });
  },
  getId(id, params) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}/${resource}/QueryContacts`,
      { _id: id},
      { params: params }
    );
  },
  getOwners(filters, params) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}/${resource}/QueryOwners`,
      filters,
      { params: params }
    );
  },
  getAgents(filters, params) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}/${resource}/QueryAgents`,
      filters,
      { params: params }
    );
  },
  getCustomers(filters, params) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}/${resource}/QueryCustomers`,
      filters,
      { params: params }
    );
  },
  // getId() { },
  // create(payload) {
  //   return AxiosClientToken.post(
  //     `${process.env.VUE_APP_BASE_URL}/${resourceCud}/InsertEstateTemplate`,
  //     payload
  //   );
  // },
  createContact(payload) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}/${resourceCud}/InsertContact`,
      payload
    );
  },
  editContact(id, payload) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}${resourceCud}/EditContact/${id}`,
      payload
    );
  },
  async createContactWithPass(payload) {
    try {
      var resp = await AxiosClientToken.post(
        `${process.env.VUE_APP_BASE_URL}/${resourceCud}/InsertContact`,
        payload
      );
      await firebase
        .auth()
        .createUserWithEmailAndPassword(payload.Email, payload.UserPassword)
        .then(() => {
          true
        });
      return resp;
    } catch (e) {
      throw e;
    }
  },
  uploadContactPhoto(contact_id, payload) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}${resourceCud}/UploadContactPhoto/${contact_id}`,
      payload,
      {
        headers: { "Content-Type": "multipart/form-data", },
      }
    );
  },
  uploadContactCV(contact_id, payload) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}${resourceCud}/UploadContactCV/${contact_id}`,
      payload,
      {
        headers: { "Content-Type": "multipart/form-data", },
      }
    );
  },



};
