<template>
  <v-row class="mt-10" v-if="checkData == true">
    <v-col cols="12" align="left" class="pa-0">
      <v-row>
        <v-col cols="12" align="left">
          <span class="listHeader fontOrange">{{ $t("otherFeatures") }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xl="4" lg="4" md="6" sm="12" xs="12" class="pt-4" v-if="true == checkShow('buildingStatus')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('condition')" :data="buildingStatus" :isUnderline="false" />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="6" sm="12" xs="12" class="pt-4" v-if="true == checkShow('interior')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('interior')" :data="interior" :isUnderline="false" />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="6" sm="12" xs="12" class="pt-4" v-if="true == checkShow('orientation')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('orientation')" :data="orientation" :isUnderline="false" />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="6" sm="12" xs="12" class="pt-4" v-if="true == checkShow('floor')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('ground')" :data="floor" :isUnderline="false" />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="6" sm="12" xs="12" class="pt-4" v-if="true == checkShow('suitable')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('exterior')" :data="suitable" :isUnderline="false" />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="6" sm="12" xs="12" class="pt-4" v-if="true == checkShow('zone')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('zone')" :data="zone" :isUnderline="false" />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="6" sm="12" xs="12" class="pt-4" v-if="true == checkShow('boxes')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('frames')" :data="boxes" :isUnderline="false" />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="6" sm="12" xs="12" class="pt-4" v-if="true == checkShow('view')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('view')" :data="view" :isUnderline="false" />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="6" sm="12" xs="12" class="pt-4" v-if="true == checkShow('position')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('position')" :data="position" :isUnderline="false" />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="6" sm="12" xs="12" class="pt-4" v-if="true == checkShow('dimensions')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('dimensions') + ' ' + $t('meters')" :data="dimensions" :isUnderline="false" />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="6" sm="12" xs="12" class="pt-4" v-if="true == checkShow('can_build')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('build') + ' ' + $t('sqftTable')" :data="can_build" :isUnderline="false" />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="6" sm="12" xs="12" class="pt-4"
          v-if="true == checkShow('coverage_coefficient')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('coverageRatio') + ' ' + $t('sqftTable')" :data="coverage_coefficient" :isUnderline="false" />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="6" sm="12" xs="12" class="pt-4"
          v-if="true == checkShow('structure_coefficient')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('structureFactor') + ' ' + $t('sqftTable')" :data="structure_coefficient" :isUnderline="false" />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="6" sm="12" xs="12" class="pt-4" v-if="true == checkShow('benefits')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('benefits')" :data="benefits" :isUnderline="false" />
        </v-col>
      </v-row>
      <v-row v-if="mobileBreakpoint == true && topShow.showMore != undefined" class="mt-0 justify-center">
        <v-col class="pt-0 pb-0" align="center">
          <v-btn class="primaryColor unsetCapitalize opacity1 textDecorationBold" text plain
            @click="() => (topShow.showMore = !topShow.showMore)">
            {{ topShow.showMore == true ? $t("lessCharacteristics") : $t("moreCharacteristics") }}...
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
 /*
 Name: ExtraCharacteristics
 Description: Component for real estate extra characteristics at the slider

 API Property: 
    Components Used:
      ReadField
*/
import i18n from "@/i18n/i18n.js";
import ReadField from "@/components/Fields/ReadField/ReadField";
import CoreHelper from "@/helpers/Core/core.js";
export default {
  name: "ExtraCharacteristics",
  props: ["data"],
  components: {
    ReadField,
  },
  data() {
    return {
      topArray: [
        "buildingStatus",
        "interior",
        "orientation",
        "floor",
        "suitable",
        "zone",
        "boxes",
        "view",
        "position",
        "dimensions",
        "coverage_coefficient",
        "structure_coefficient",
        "benefits",
      ],
      topShow: { showKeys: [], showMore: undefined },
      fontSizeRF: "14px", //font-size for ReadingField component
      fontFamilyLabel: "InterBold", //font-family for ReadingField component label
      fontFamilyValue: "InterMedium", //font-family for ReadingField component value
    };
  },
  created() {
    if (this.mobileBreakpoint == true)
      this.topShow = CoreHelper.showObjects(
        {
          buildingStatus: this.buildingStatus,
          interior: this.interior,
          orientation: this.orientation,
          floor: this.floor,
          suitable: this.suitable,
          zone: this.zone,
          boxes: this.boxes,
          view: this.view,
          position: this.position,
          dimensions: this.dimensions,
          can_build: this.can_build,
          coverage_coefficient: this.coverage_coefficient,
          structure_coefficient: this.structure_coefficient,
          benefits: this.benefits,
        },
        this.topArray,
        [],
        2
      );
  },
  computed: {
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    checkData() {
      let array = [
        this.buildingStatus,
        this.interior,
        this.orientation,
        this.floor,
        this.suitable,
        this.zone,
        this.boxes,
        this.view,
        this.position,
        this.dimensions,
        this.can_build,
        this.coverage_coefficient,
        this.structure_coefficient,
        this.benefits,
      ]
      return CoreHelper.checkExtraShow(array);
    },
    buildingStatus() {
      return this.data.characteristics
        ? CoreHelper.parseBoolean(this.data.characteristics.renovate) == true ? i18n.t("renovated")
          : CoreHelper.parseBoolean(this.data.characteristics.perfect_condition) == true ? i18n.t("perfectCondition")
            : CoreHelper.parseBoolean(this.data.characteristics.under_construction) == true ? i18n.t("underConstruction")
              : CoreHelper.parseBoolean(this.data.characteristics.good_condition) == true ? i18n.t("goodCondition") : CoreHelper.parseBoolean(this.data.characteristics.newly_built) == true ? i18n.t("newlyBuilt")
                : CoreHelper.parseBoolean(this.data.characteristics.unfinished) == true ? i18n.t("unfinished")
                  : CoreHelper.parseBoolean(this.data.characteristics.needs_renovation) == true ? i18n.t("needsToBeRenovated")
                    : null
        : null;
    },
    interior() {
      let result = "";
      if (this.data.characteristics) {
        if (CoreHelper.parseBoolean(this.data.characteristics.furnished) == true) result += i18n.t("furnished") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.bright) == true) result += i18n.t("bright") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.painted) == true) result += i18n.t("painted") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.electrical_equipment) == true) result += i18n.t("electricalEquipment") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.satellite_antenna) == true) result += i18n.t("satelliteAntenna") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.luxurious) == true) result += i18n.t("luxurious") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.pets_allowed) == true) result += i18n.t("allowingPets") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.internal_staircase) == true) result += i18n.t("internalStaircase") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.elevator) == true) result += i18n.t("elevator") + " | ";
      }
      return result.slice(0, -1);
    },
    dimensions() {
      return this.data.characteristics.dimensions;
    },
    can_build() {
      return this.data.characteristics.can_build;
    },
    coverage_coefficient() {
      return this.data.characteristics.coverage_coefficient;
    },
    structure_coefficient() {
      return this.data.characteristics.structure_coefficient;
    },
    benefits() {
      return this.data.characteristics.benefits;
    },
    orientation() {
      let result = "";
      if (this.data.characteristics) {
        if (CoreHelper.parseBoolean(this.data.characteristics.eastern_coverage) == true) result += i18n.t("eastern") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.eastern_western_coverage) == true) result += i18n.t("eastWest") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.eastern_meridian_coverage) == true) result += i18n.t("easternMeridian") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.northern_coverage) == true) result += i18n.t("north") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.northern_eastern_coverage) == true) result += i18n.t("northEast") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.north_west) == true) result += i18n.t("northWest") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.western_coverage) == true) result += i18n.t("west") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.meridian_coverage) == true) result += i18n.t("meridian") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.southern_coverage) == true) result += i18n.t("northWest") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.southern_eastern_coverage) == true) result += i18n.t("southEast") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.southern_western_coverage) == true) result += i18n.t("southWest") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.western_meridian_coverage) == true) result += i18n.t("westMeridian") + " | ";
      }
      if (result == "") {
        return null;
      }
      return result.slice(0, -1);
    },
    position() {
      let result = "";
      if (this.data.characteristics) {
        if (CoreHelper.parseBoolean(this.data.characteristics.eastern_western_coverage) == true) result += i18n.t("eastWest") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.internal) == true) result += i18n.t("interior") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.facade) == true) result += i18n.t("facade") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.penthouse) == true) result += i18n.t("penthouse") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.double_frontage) == true) result += i18n.t("through") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.on_main_road) == true) result += i18n.t("onMainStreet") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.on_sidewalk) == true) result += i18n.t("onPedestrianStreet") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.corner) == true) result += i18n.t("corner") + " | ";
      }
      if (result == "") {
        return null;
      }
      return result.slice(0, -1);
    },
    floor() {
      let result = "";
      if (this.data.characteristics) {
        if (CoreHelper.parseBoolean(this.data.characteristics.marble_floors) == true) result += i18n.t("marble") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.wooden_floor) == true) result += i18n.t("wood") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.stone_floors) == true) result += i18n.t("stone") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.tile_floors) == true) result += i18n.t("tile") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.mosaic_floor) == true) result += i18n.t("mosaic") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.marble_wooden_floor) == true) result += i18n.t("marbleWood") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.marble_tile_floor) == true) result += i18n.t("marbleTile") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.stone_wooden_floors) == true) result += i18n.t("stoneWood") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.stone_marble_floors) == true) result += i18n.t("stoneMarble") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.tile_wooden_floor) == true) result += i18n.t("tileWood") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.mosaic_wooden_floors) == true) result += i18n.t("mosaicWood") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.industrial_floor) == true) result += i18n.t("industrialFlooring") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.laminate) == true) result += i18n.t("lamite") + " | ";
      }

      if (result == "") {
        return null;
      }
      return result.slice(0, -1);
    },
    suitable() {
      let result = "";
      if (this.data.characteristics) {
        if (CoreHelper.parseBoolean(this.data.characteristics.suitable_for_students) == true) result += i18n.t("student") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.airbnb) == true) result += i18n.t("airbnb") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.suitable_for_commercial_use) == true) result += i18n.t("professionalUse") + " | ";
      }
      if (result == "") {
        return null;
      }
      return result.slice(0, -1);
    },
    zone() {
      let result = "";
      if (this.data.characteristics) {
        if (CoreHelper.parseBoolean(this.data.characteristics.residential_zone) == true) result += i18n.t("residentialZone") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.commercial_zone) == true) result += i18n.t("commericalZone") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.rural_zone) == true) result += i18n.t("ruralZone") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.industrial_zone) == true) result += i18n.t("industrialZone") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.regeneration_zone) == true) result += i18n.t("regenerationZone") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.outside_city_plan) == true) result += i18n.t("offPlan") + " | ";
      }
      if (result == "") {
        return null;
      }
      return result.slice(0, -1);
    },
    boxes() {
      let result = "";
      if (this.data.characteristics) {
        if (CoreHelper.parseBoolean(this.data.characteristics.double_layer_windows) == true) result += i18n.t("doubleGlazingBars") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.wooden_frames) == true) result += i18n.t("woodenFrames") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.synthetic_frames) == true) result += i18n.t("syntheticFrames") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.electrical_frames) == true) result += i18n.t("eletricFrames") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.aluminum_frames) == true) result += i18n.t("aluminiumFrames") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.sliding_rollers) == true) result += i18n.t("slidingRolls") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.screens) == true) result += i18n.t("screens") + " | ";
      }
      if (result == "") {
        return null;
      }
      return result.slice(0, -1);
    },
    view() {
      let result = "";
      if (this.data.characteristics) {
        if (CoreHelper.parseBoolean(this.data.characteristics.urban_view) == true) result += i18n.t("city") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.mountain_view) == true) result += i18n.t("mountain") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.acropolis_or_monuments_view) == true) result += i18n.t("acropoliMonuments") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.lake_view) == true) result += i18n.t("lake") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.sea_view) == true) result += i18n.t("sea") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.river_view) == true) result += i18n.t("river") + " | ";
        if (CoreHelper.parseBoolean(this.data.characteristics.park_view) == true) result += i18n.t("park") + " | ";
      }
      if (result == "") {
        return null;
      }
      return result.slice(0, -1);
    },



  },
  methods: {
    checkShow(objKey) {
      return this[objKey] ? this.mobileBreakpoint != true ? true
        : (this.topShow.showKeys && this.topShow.showKeys.includes(objKey)) || this.topShow.showMore != false ? true
          : false
        : false;
    },
  },
};
</script>

<style scoped>
.listHeader {
  color: #263573;
  font-size: 14px;
}

.fontOrange {
  color: #e89008;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
}
</style>
