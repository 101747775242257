import i18n from "@/i18n/i18n";
import store from "@/store";
import CoreHelper from "@/helpers/Core/core.js";
import DemandsStaticLists from "@/static/demands/demands.js";

export default {

  setFilterObject(filters) {
    let charArray = [];
    let tagFilterArray = [];
    let object = {
      $and: [
      ],
    };
    if (filters.available != null && filters.available.length > 0) {
      let val = [];
      let options = store.getters.availableForList;
      filters.available.map((a, index) => {
        val.push(options[a].val);
        tagFilterArray.push({
          display: i18n.t(options[a].text),
          attrHead: [{ name: 'available', action: "updateFieldValue" }],
          attrValue: options[a].val,
          attrType: "array",
          attrIndex: index
        })
      });

      let filObject = { ["Estate.Task"]: { $in: val } };
      object["$and"].push(filObject);
    }
    if (filters.category != null) {
      if (filters.category.val) {
        let filObject = { ["Estate.Category"]: filters.category.val };
        object["$and"].push(filObject);
      }
      if(filters.category.text!='allCategories'){
        tagFilterArray.push({
          display: i18n.t(filters.category.text),
          attrHead: [{ name: 'category', action: "updateFieldValue" }],
          attrValue: filters.category.val,
          attrType: "string",
        });
     }
    }
    if (filters.subCategory != null) {
      let type = [];
      filters.category.subCategoriesList.map((sc) => {
        if (filters.subCategory.includes(sc.id)) {
          type.push(sc.val);
          tagFilterArray.push({
            display: i18n.t(sc.text),
            attrHead: [{ name: 'subCategory', action: "updateFieldValue" }],
            attrValue: sc.id,
            attrType: "arrayByVal",
          });
        }
      });
      if (type.length > 0) {
        let filObject = { ["Estate.Type"]: { $in: type } };
        object["$and"].push(filObject);
      }
    }
    if (filters.priceMin != null && filters.priceMax != null) {
      let filObject = { ["Estate.Price"]: { $gte: filters.priceMin, $lte: filters.priceMax } };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("price") +
          " " +
          filters.priceMin +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.priceMax,
        attrHead: [
          { name: "priceMin", action: "updateFieldValue" },
          { name: "priceMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.priceMin, filters.priceMax],
      });
    } else if (filters.priceMin != null && filters.priceMax == null) {
      let filObject = { ["Estate.Price"]: { $gte: filters.priceMin, $lte: 999999999 } };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("price") +
          " " +
          filters.priceMin,
        attrHead: [
          { name: "priceMin", action: "updateFieldValue" },
          { name: "priceMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.priceMin, filters.priceMax],
      });
    } else if (filters.priceMin == null && filters.priceMax != null) {
      let filObject = { ["Estate.Price"]: { $gte: -999999999, $lte: filters.priceMax } };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("price") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.priceMax,
        attrHead: [
          { name: "priceMin", action: "updateFieldValue" },
          { name: "priceMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.priceMin, filters.priceMax],
      });
    }

    if (filters.buildFrom != null && filters.buildTo != null) {
      let filObject = {
        ["Estate.characteristics"]: {
          $elemMatch: { Characteristic: "Κτίζει", Value: { $gte: filters.buildFrom, $lte: filters.buildTo } }
        },
      };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("build") +
          " " +
          filters.buildFrom +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.buildTo,
        attrHead: [
          { name: "buildFrom", action: "updateFieldValue" },
          { name: "buildTo", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.buildFrom, filters.buildTo],
      });
    } else if (filters.buildFrom != null && filters.buildTo == null) {
      let filObject = {
        ["Estate.characteristics"]: {
          $elemMatch: { Characteristic: "Κτίζει", Value: { $gte: filters.buildFrom, $lte: 999999999 } }
        },
      };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("build") +
          " " +
          filters.buildFrom,
        attrHead: [
          { name: "buildFrom", action: "updateFieldValue" },
          { name: "buildTo", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.buildFrom, filters.buildTo],
      });
    } else if (filters.buildFrom == null && filters.buildTo != null) {
      let filObject = {
        ["Estate.characteristics"]: {
          $elemMatch: { Characteristic: "Κτίζει", Value: { $gte: -999999999, $lte: filters.buildTo } }
        },
      };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("build") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.buildTo,
        attrHead: [
          { name: "buildFrom", action: "updateFieldValue" },
          { name: "buildTo", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.buildFrom, filters.buildTo],
      });
    }
    if (filters.numberOfPlacesMin != null && filters.numberOfPlacesMax != null) {
      let filObject = {
        ["Estate.characteristics"]: {
          $elemMatch: { Characteristic: "Αριθμός χώρων", Value: { $gte: filters.numberOfPlacesMin, $lte: filters.numberOfPlacesMax } }
        },
      };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("numberOfPlaces") +
          " " +
          filters.numberOfPlacesMin +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.numberOfPlacesMax,
        attrHead: [
          { name: "numberOfPlacesMin", action: "updateFieldValue" },
          { name: "numberOfPlacesMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.numberOfPlacesMin, filters.numberOfPlacesMax],
      });
    } else if (filters.numberOfPlacesMin != null && filters.numberOfPlacesMax == null) {
      let filObject = {
        ["Estate.characteristics"]: {
          $elemMatch: { Characteristic: "Αριθμός χώρων", Value: { $gte: filters.numberOfPlacesMin, $lte: 999999999 } }
        },
      };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("numberOfPlaces") +
          " " +
          filters.numberOfPlacesMin,
        attrHead: [
          { name: "numberOfPlacesMin", action: "updateFieldValue" },
          { name: "numberOfPlacesMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.numberOfPlacesMin, filters.numberOfPlacesMax],
      });
    } else if (filters.numberOfPlacesMin == null && filters.numberOfPlacesMax != null) {
      let filObject = {
        ["Estate.characteristics"]: {
          $elemMatch: { Characteristic: "Αριθμός χώρων", Value: { $gte: -999999999, $lte: filters.numberOfPlacesMax } }
        },
      };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("numberOfPlaces") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.numberOfPlacesMax,
        attrHead: [
          { name: "numberOfPlacesMin", action: "updateFieldValue" },
          { name: "numberOfPlacesMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.numberOfPlacesMin, filters.numberOfPlacesMax],
      });
    }
    if (filters.floorMin != null && filters.floorMax != null) {
      let filObject = { ["Estate.Floors"]: { $gte: filters.floorMin, $lte: filters.floorMax } };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("floors") +
          " " +
          filters.floorMin +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.floorMax,
        attrHead: [
          { name: "floorMin", action: "updateFieldValue" },
          { name: "floorMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.floorMin, filters.floorMax],
      });
    } else if (filters.floorMin == null && filters.floorMax != null) {

      let filObject = { ["Estate.Floors"]: { $gte: -999999999, $lte: filters.floorMax } };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("floors") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.floorMax,
        attrHead: [
          { name: "floorMin", action: "updateFieldValue" },
          { name: "floorMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.floorMin, filters.floorMax],
      });
    } else if (filters.floorMin != null && filters.floorMax == null) {
      let filObject = { ["Estate.Floors"]: { $gte: filters.floorMin, $lte: 999999999 } };
      object["$and"].push(filObject);

      tagFilterArray.push({
        display:
          i18n.t("floors") +
          " " +
          filters.floorMin,
        attrHead: [
          { name: "floorMin", action: "updateFieldValue" },
          { name: "floorMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.floorMin, filters.floorMax],
      });
    }

    if (filters.areaPriceMin != null && filters.areaPriceMax != null) {
      let filObject = { ["Estate.PricePerUnit"]: { $gte: filters.areaPriceMin, $lte: filters.areaPriceMax } };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("price") +
          " / " +
          i18n.t("sqft") +
          " " +
          filters.areaPriceMin +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.areaPriceMax,
        attrHead: [
          { name: "areaPriceMin", action: "updateFieldValue" },
          { name: "areaPriceMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.areaPriceMin, filters.areaPriceMax],
      });
    } else if (filters.areaPriceMin != null && filters.areaPriceMax == null) {

      let filObject = { ["Estate.PricePerUnit"]: { $gte: filters.areaPriceMin, $lte: 999999999 } };
      object["$and"].push(filObject);

      tagFilterArray.push({
        display:
          i18n.t("price") +
          " / " +
          i18n.t("sqft") +
          " " +
          filters.areaPriceMin,
        attrHead: [
          { name: "areaPriceMin", action: "updateFieldValue" },
          { name: "areaPriceMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.areaPriceMin, filters.areaPriceMax],
      });
    } else if (filters.areaPriceMin == null && filters.areaPriceMax != null) {
      let filObject = { ["Estate.PricePerUnit"]: { $gte: -999999999, $lte: filters.areaPriceMax } };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("price") +
          " / " +
          i18n.t("sqft") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.areaPriceMax,
        attrHead: [
          { name: "areaPriceMin", action: "updateFieldValue" },
          { name: "areaPriceMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.areaPriceMin, filters.areaPriceMax],
      });
    }
    if (filters.areaMin != null && filters.areaMax != null) {
      let filObject = { ["Estate.Unit"]: { $gte: filters.areaMin, $lte: filters.areaMax } };
      object["$and"].push(filObject);

      tagFilterArray.push({
        display:
          i18n.t("sqft") +
          " " +
          filters.areaMin +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.areaMax,
        attrHead: [
          { name: "areaMin", action: "updateFieldValue" },
          { name: "areaMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.areaMin, filters.areaMax],
      });
    } else if (filters.areaMin != null && filters.areaMax == null) {
      let filObject = { ["Estate.Unit"]: { $gte: filters.areaMin, $lte: 999999999 } };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("sqft") +
          " " +
          filters.areaMin,
        attrHead: [
          { name: "areaMin", action: "updateFieldValue" },
          { name: "areaMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.areaMin, filters.areaMax],
      });
    } else if (filters.areaMin == null && filters.areaMax != null) {

      let filObject = { ["Estate.Unit"]: { $gte: -999999999, $lte: filters.areaMax } };
      object["$and"].push(filObject);

      tagFilterArray.push({
        display:
          i18n.t("sqft") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.areaMax,
        attrHead: [
          { name: "areaMin", action: "updateFieldValue" },
          { name: "areaMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.areaMin, filters.areaMax],
      });
    }

    let areasMetersMin = filters.areaType == "acres" ? filters.areasSqmMin * 1000 : filters.areasSqmMin;
    let areasMetersMax = filters.areaType == "acres" ? filters.areasSqmMax * 1000 : filters.areasSqmMax;
    if (filters.areasSqmMin != null && filters.areasSqmMax != null) {
      let filObject = { ["Estate.Unit"]: { $gte: areasMetersMin, $lte: areasMetersMax } };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("areasqm") +
          " (" + i18n.t(filters.areaType) + ") " +
          filters.areasSqmMin +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.areasSqmMax,
        attrHead: [
          { name: "areasSqmMin", action: "updateFieldValue" },
          { name: "areasSqmMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.areasSqmMin, filters.areasSqmMax],
      });
    } else if (filters.areasSqmMin != null && filters.areasSqmMax == null) {
      let filObject = { ["Estate.Unit"]: { $gte: areasMetersMin, $lte: 999999999 } };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("areasqm") +
          " (" + i18n.t(filters.areaType) + ") " +
          filters.areasSqmMin,
        attrHead: [
          { name: "areasSqmMin", action: "updateFieldValue" },
          { name: "areasSqmMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.areaPriceMin, filters.areasSqmMax],
      });
    } else if (filters.areasSqmMin == null && filters.areasSqmMax != null) {
      let filObject = { ["Estate.Unit"]: { $gte: -999999999, $lte: areasMetersMax } };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("areasqm") +
          " (" + i18n.t(filters.areaType) + ") " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.areasSqmMax,
        attrHead: [
          { name: "areasSqmMin", action: "updateFieldValue" },
          { name: "areasSqmMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.areasSqmMin, filters.areasSqmMax],
      });
    }

    if (filters.roomMin != null && filters.roomMax == null) {
      let filObject = {
        ["Estate.characteristics"]: {
          $elemMatch: { Characteristic: "Υ/Δ", Value: { $gte: filters.roomMin, $lte: 999999999 } }
        },
      };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("bedrooms") +
          " " +
          filters.roomMin,
        attrHead: [
          { name: "roomMin", action: "updateFieldValue" },
          { name: "roomMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.roomMin, filters.roomMax],
      });
    } else if (filters.roomMin != null && filters.roomMax != null) {
      let filObject = {
        ["Estate.characteristics"]: {
          $elemMatch: { Characteristic: "Υ/Δ", Value: { $gte: filters.roomMin, $lte: filters.roomMax } }
        },
      };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("bedrooms") +
          " " +
          filters.roomMin +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.roomMax,
        attrHead: [
          { name: "roomMin", action: "updateFieldValue" },
          { name: "roomMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.roomMin, filters.roomMax],
      });
    } else if (filters.roomMin == null && filters.roomMax != null) {
      let filObject = {
        ["Estate.characteristics"]: {
          $elemMatch: { Characteristic: "Υ/Δ", Value: { $gte: -999999999, $lte: filters.roomMax } }
        },
      };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("bedrooms") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.roomMax,
        attrHead: [
          { name: "roomMin", action: "updateFieldValue" },
          { name: "roomMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.roomMin, filters.roomMax],
      });
    }
    if (filters.bathroomMin != null && filters.bathroomMax == null) {

      let filObject = {
        ["Estate.characteristics"]: {
          $elemMatch: { Characteristic: "Μπάνια", Value: { $gte: filters.bathroomMin, $lte: 999999999 } }
        },
      };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("bathrooms") +
          " " +
          filters.bathroomMin,
        attrHead: [
          { name: "bathroomMin", action: "updateFieldValue" },
          { name: "bathroomMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.bathroomMin, filters.bathroomMax],
      });
    } else if (filters.bathroomMin != null && filters.bathroomMax != null) {

      let filObject = {
        ["Estate.characteristics"]: {
          $elemMatch: { Characteristic: "Μπάνια", Value: { $gte: filters.bathroomMin, $lte: filters.bathroomMax } }
        },
      };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("bathrooms") +
          " " +
          filters.bathroomMin +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.bathroomMax,
        attrHead: [
          { name: "bathroomMin", action: "updateFieldValue" },
          { name: "bathroomMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.bathroomMin, filters.bathroomMax],
      });
    } else if (filters.bathroomMin == null && filters.bathroomMax != null) {
      let filObject = {
        ["Estate.characteristics"]: {
          $elemMatch: { Characteristic: "Μπάνια", Value: { $gte: -999999999, $lte: filters.bathroomMax } }
        },
      };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("bathrooms") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.bathroomMax,
        attrHead: [
          { name: "bathroomMin", action: "updateFieldValue" },
          { name: "bathroomMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.bathroomMin, filters.bathroomMax],
      });
    }

    if (filters.yearMin != null && filters.yearMax == null) {
      let filObject = { ["Estate.BuildYear"]: { $gte: Number(filters.yearMin), $lte: 999999999 } };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("yearOfConstruction") +
          " " +
          filters.yearMin,
        attrHead: [
          { name: "yearMin", action: "updateFieldValue" },
          { name: "yearMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.yearMin, filters.yearMax],
      });
    } else if (filters.yearMin != null && filters.yearMax != null) {
      let filObject = { ["Estate.BuildYear"]: { $gte: Number(filters.yearMin), $lte: Number(filters.yearMax) } };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("yearOfConstruction") +
          " " +
          filters.yearMin +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.yearMax,
        attrHead: [
          { name: "yearMin", action: "updateFieldValue" },
          { name: "yearMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.yearMin, filters.yearMax],
      });
    } else if (filters.yearMin == null && filters.yearMax != null) {
      let filObject = { ["Estate.BuildYear"]: { $gte: -999999999, $lte: Number(filters.yearMax) } };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("yearOfConstruction") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.yearMax,
        attrHead: [
          { name: "yearMin", action: "updateFieldValue" },
          { name: "yearMax", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.yearMin, filters.yearMax],
      });
    }

    if (filters.reNewDateFrom != null && filters.reNewDateTo == null) {
      let filObject = { ["Estate.UpdatedDay"]: { $gte: filters.reNewDateFrom, $lte: 999999999 } };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("renewalDate") +
          " " +
          filters.reNewDateFrom,
        attrHead: [
          { name: "reNewDateFrom", action: "updateFieldValue" },
          { name: "reNewDateTo", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.reNewDateFrom, filters.reNewDateTo],
      });
    } else if (filters.reNewDateFrom != null && filters.reNewDateTo != null) {
      let filObject = { ["Estate.UpdatedDay"]: { $gte: filters.reNewDateFrom, $lte: filters.reNewDateTo } };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("renewalDate") +
          " " +
          filters.reNewDateFrom +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.reNewDateTo,
        attrHead: [
          { name: "reNewDateFrom", action: "updateFieldValue" },
          { name: "reNewDateTo", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.reNewDateFrom, filters.reNewDateTo],
      });
    } else if (filters.reNewDateFrom == null && filters.reNewDateTo != null) {

      let filObject = { ["Estate.UpdatedDay"]: { $gte: -999999999, $lte: filters.reNewDateTo } };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("renewalDate") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.reNewDateTo,
        attrHead: [
          { name: "reNewDateFrom", action: "updateFieldValue" },
          { name: "reNewDateTo", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.reNewDateFrom, filters.reNewDateTo],
      });
    }

    if (filters.buildingStatus != null) {
      let filObject = {
        ["Estate.characteristics"]: {
          $elemMatch: { Characteristic: filters.buildingStatus, Value: true }
        },
      };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display: i18n.t(filters.buildingStatus),
        attrHead: [{ name: "buildingStatus", action: "updateFieldValue" }],
        attrValue: filters.buildingStatus,
        attrType: "string",
      });
    }

    if (filters.buildingUse != null && filters.buildingUse.length > 0) {
      let options = DemandsStaticLists.buildingUseList;
      filters.buildingUse.map((a, index) => {
        let filObject = {
          ["Estate.characteristics"]: {
            $elemMatch: { Characteristic: options[a].val, Value: true }
          },
        };
        object["$and"].push(filObject);
        tagFilterArray.push({
          display: i18n.t(options[a].text),
          attrHead: [{ name: 'buildingUse', action: "updateFieldValue" }],
          attrValue: options[a].val,
          attrType: "array",
          attrIndex: index
        })
      });
    }

    if (filters.filterRequest != null && filters.filterRequest.val != 0) {
      let filObject;
      if (filters.filterRequest.val == 1) {
        filObject = { ["EstateCode"]: { $regex: filters.associateId }, };
      } else {
        filObject = { ["LastCompany"]: filters.companyId };
      }
      object["$and"].push(filObject);
      tagFilterArray.push({
        display: i18n.t(filters.filterRequest.text),
        attrHead: [{ name: 'filterRequest', action: "updateFieldValue" }],
        attrValue: filters.filterRequest.val,
        attrType: "string",
      })
    }

    if (filters.askingTypeStatus != null && filters.askingTypeStatus.length > 0) {
      let zoneArray = [];
      filters.askingTypeStatus.map((object, index) => {
        zoneArray.push(object.val);
        tagFilterArray.push({
          display: i18n.t(object.text),
          attrHead: [{ name: 'askingTypeStatus', action: "updateFieldValue" }],
          attrValue: object.val,
          attrType: "array",
          attrIndex: index
        })
      });

      if (filters.askingTypeStatus.length < 2) {
        let filObject = {};
        if (filters.askingTypeStatus[0].text == "generalDemands") {
          filObject = { EstateCode: { $exists: false } }
        } else {
          filObject = { EstateCode: { $exists: true } }
        }
        object["$and"].push(filObject);
      }
    }

    if (filters.locationGoogle != null) {
      let locObject = {};
      if (
        filters.locationGoogle &&
        filters.locationGoogle.geometry &&
        filters.locationGoogle.geometry.viewport
      ) {
        try {
          let values = JSON.parse(
            JSON.stringify(filters.locationGoogle.geometry.viewport)
          );
          locObject["first"] = { $gte: values.south, $lte: values.north };
          locObject["second"] = { $gte: values.west, $lte: values.east };
          let gpsFirstObject = { ["Estate.GPS.0"]: locObject["first"] };
          object["$and"].push(gpsFirstObject);
          let gpsSecondObject = { ["Estate.GPS.1"]: locObject["second"] };
          object["$and"].push(gpsSecondObject);
        } catch (e) {
          console.error(e);
        }
      }
      tagFilterArray.push({
        display: filters.locationGoogle.formatted_address,
        attrHead: [{ name: "locationGoogle", action: "updateFieldValue" }],
        attrValue: filters.locationGoogle,
        attrType: "string",
      });
    }

    if (filters.publishedSelected != null) {
      let filObject = { ["Estate.RequestStatus"]: filters.publishedSelected };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display: i18n.t(filters.publishedSelected),
        attrHead: [{ name: "publishedSelected", action: "updateFieldValue" }],
        attrValue: filters.publishedSelected,
        attrType: "string",
      });
    }

    if (filters.isActiveMatching == true) {
      let filObject = { ["ReturnMatchingLeads"]: true };
      object["$and"].push(filObject);
    }

    if (filters.agent != null) {
      let filObject = { ["Agent._id"]: filters.agent._id };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display: i18n.t("responsible") + " " + filters.agent.FirstName + " " + filters.agent.LastName,
        attrHead: [{ name: "agent", action: "updateFieldValue" }],
        attrValue: filters.agent._id,
        attrType: "string",
      });
    }

    if (filters.realEstateCode != null) {
      let filObject = { ["Estate.EstateCode"]: filters.realEstateCode };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display: i18n.t("propertyCode") +
          " " + filters.realEstateCode,
        attrHead: [{ name: "realEstateCode", action: "updateFieldValue" }],
        attrValue: filters.realEstateCode,
        attrType: "string",
      });
    }

    if (filters.ratingFrom != null && filters.ratingTo == null) {
      let filObject = { ["Client.customer_evaluation"]: { $gte: filters.ratingFrom, $lte: 999999999 } };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("valuationF") +
          " " +
          filters.ratingFrom,
        attrHead: [
          { name: "ratingFrom", action: "updateFieldValue" },
          { name: "ratingTo", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.ratingFrom, filters.ratingTo],
      });
    } else if (filters.ratingFrom != null && filters.ratingTo != null) {
      let filObject = { ["Client.customer_evaluation"]: { $gte: filters.ratingFrom, $lte: filters.ratingTo } };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("valuationF") +
          " " +
          filters.ratingFrom +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.ratingTo,
        attrHead: [
          { name: "ratingFrom", action: "updateFieldValue" },
          { name: "ratingTo", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.bathroomMin, filters.ratingTo],
      });
    } else if (filters.ratingFrom == null && filters.ratingTo != null) {
      let filObject = { ["Client.customer_evaluation"]: { $gte: -999999999, $lte: filters.ratingTo } };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display:
          i18n.t("valuationF") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.ratingTo,
        attrHead: [
          { name: "ratingFrom", action: "updateFieldValue" },
          { name: "ratingTo", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.ratingFrom, filters.ratingTo],
      });
    }

    if (filters.customer != null) {
      let filObject = { ["ClientID"]: filters.customer._id };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display: i18n.t("customer") + " " + filters.customer.name,
        attrHead: [{ name: "customer", action: "updateFieldValue" }],
        attrValue: filters.customer._id,
        attrType: "string",
      });
    }



    if (filters.statusSelected != null && filters.statusSelected.length > 0) {
      let options = DemandsStaticLists.availabilityList;
      filters.statusSelected.map((a, index) => {
        let filObject = {
          ["Estate.characteristics"]: {
            $elemMatch: { Characteristic: options[a].val, Value: true }
          },
        };
        object["$and"].push(filObject);
        tagFilterArray.push({
          display: i18n.t(options[a].text),
          attrHead: [{ name: 'statusSelected', action: "updateFieldValue" }],
          attrValue: options[a].val,
          attrType: "array",
          attrIndex: index
        })
      });
    }

    if (filters.zoneSelected != null) {
      let filObject = {
        ["Estate.characteristics"]: {
          $elemMatch: { Characteristic: filters.zoneSelected.val, Value: true }
        },
      };
      object["$and"].push(filObject);
      tagFilterArray.push({
        display: i18n.t(filters.zoneSelected.text),
        attrHead: [{ name: 'zoneSelected', action: "updateFieldValue" }],
        attrValue: filters.zoneSelected.val,
        attrType: "string",
      })
    }

    if (object.$and.length < 1) {
      object = {};
    }

    /* --------------------------- End of Object Creation ---------------------------------*/
    if (charArray.length > 0)
      return [
        tagFilterArray,
        { ...object, characteristics: { $and: charArray } },
      ];
    else return [tagFilterArray, object];
  },

  buildingStatus(specificEstate, data) {
    if (specificEstate) {
      return data.Estate.characteristics ?
        CoreHelper.parseBoolean(data.Estate.characteristics.renovate) == true ? i18n.t("renovated") :
          CoreHelper.parseBoolean(data.Estate.characteristics.perfect_condition) == true ? i18n.t("perfectCondition") :
            CoreHelper.parseBoolean(data.Estate.characteristics.under_construction) == true ? i18n.t("underConstruction") :
              CoreHelper.parseBoolean(data.Estate.characteristics.good_condition) == true ? i18n.t("goodCondition") : CoreHelper.parseBoolean(data.Estate.characteristics.newly_built) == true ? i18n.t("newlyBuilt") :
                CoreHelper.parseBoolean(data.Estate.characteristics.unfinished) == true ? i18n.t("unfinished") :
                  CoreHelper.parseBoolean(data.Estate.characteristics.needs_renovation) == true ? i18n.t("needsToBeRenovated") :
                    null :
        null;
    } else {
      return data.characteristics ?
        CoreHelper.parseBoolean(data.characteristics?.renovate) == true ? i18n.t("renovated") :
          CoreHelper.parseBoolean(data.characteristics?.perfect_condition) == true ? i18n.t("perfectCondition") :
            CoreHelper.parseBoolean(data.characteristics?.under_construction) == true ? i18n.t("underConstruction") :
              CoreHelper.parseBoolean(data.characteristics?.good_condition) == true ? i18n.t("goodCondition") : CoreHelper.parseBoolean(data.newly_built) == true ? i18n.t("newlyBuilt") :
                CoreHelper.parseBoolean(data.characteristics?.unfinished) == true ? i18n.t("unfinished") :
                  CoreHelper.parseBoolean(data.characteristics?.needs_renovation) == true ? i18n.t("needsToBeRenovated") :
                    null :
        null;
    }
  },

  initData(data, currency){
    let unReadCNT = 0;
    let array = data.requests.map((c) => {
      let secondDate = c?.UpdatedDay??c.RegistrationDate;
      let diffMonths = this.monthDiff(new Date(secondDate * 1000), new Date()); 
      
      //Fetch for the status
      if(diffMonths >= 3){
        c["inactivityDemand"] = true;
        c["statusIsIcon"]= true;
        c["statusTextData"] = i18n.t("inactivity");
        c["statusTextClass"] = "errorColor textDecorationBold pl-1";
          c["statusIconObj"] = {
            icon: 'eva:alert-circle-outline',
            color: "#e52628",
            height: "15",
          };
      } else if(c.Client && ['', null, undefined].includes(c.Client?.customer_evaluation) && 
      c?.RequestStatus != "withdrawal" && c?.RequestStatus != "deal"){
        c["inactivityDemand"] = false;
        c["displayRequestStatus"] = "evaluationPending";
        c["statusTextData"] = i18n.t("evaluationPending");
        c["statusTextClass"] = "orangeText textDecorationBold align-self-center";
        c["statusIsIcon"]= false;
        c["statusIconObj"] = null;
      } else if(c.RequestStatus){
        if(c.RequestStatus == "deal"){
          c["inactivityDemand"] = false;
          c["statusTextData"] = i18n.t("Deal");
          c["statusTextClass"] = "successColor textDecorationBold align-self-center";
          c["statusIsIcon"]= false;
          c["statusIconObj"] = null;
        }else{
          c["inactivityDemand"] = false;
          c["statusTextData"] = i18n.t(c.RequestStatus);
          c["statusTextClass"] = "cusTab textDecorationBold align-self-center";
          c["statusIsIcon"]= false;
          c["statusIconObj"] = null;
        }
      } else if(!c["RequestStatus"]){
        c["inactivityDemand"] = false;
        c["displayRequestStatus"] = "new";
        c["statusIsIcon"]= false;
        c["statusIconObj"] = null;
        c["statusTextData"] = i18n.t("newAll");
        c["statusTextClass"] = "cusTab textDecorationBold align-self-center";
      }
        c.estateBoxColor = "#77BD86";
        if (c["EstateCode"]) {
          c.titleBox = true;
          c.estateCodeTitle = c.EstateCode;
        } else {
          c.estateCodeTitle = "Γενική Ζήτηση";
          c.titleBox = false;
          c.estateTitleColor = "#77BD86";
        }

        if (c.follow_up) {
          c.followUpDisplay = CoreHelper.EpochToDateTime(
            c.follow_up,
            " | "
          );
        } else {
          c.followUpDisplay = '-';
        }


        if (c.RegistrationDate) {
          c.RegistrationDateDisplay = CoreHelper.EpochToDate(
            c.RegistrationDate);
          c.RegistrationDateFormatted = CoreHelper.EpochToDateTime(
            c.RegistrationDate,
            " | "
          );
          c.expireTimeLeft =
            c.isDeleted != true || c.isRemoved != true
              ? c.IsApproved != true || c.HasAccepted != true
                ? CoreHelper.time_ago(c.RegistrationDate)
                : ""
              : "";
        } else {
          c.RegistrationDateDisplay = '-';
        }
        if (c.Estate) {
          c.EstateCategory = c.Estate.Category ? c.Estate.Category : "-";
          c.EstateArea = c.Estate.Area ? c.Estate.Area : "-";
          //Fetch for the contact demand
          c.displayType =  (i18n.t(c.Type) ? c.Estate.Type : '-');
          c.displayTask =  (c.Estate.Task ? c.Estate.Task : '-');
          //Fetch for cards
          c.cardType =  (c.Estate.Type ? c.Estate.Type : '') + (c.Estate.Task ? ' ' + c.Estate.Task : '');
          //Fetch for the report
          if (c.Estate.floors && c.Estate.floors.length > 0) {
            if (c.Estate.floors.length > 1) {
              c.floorsText = c.Estate.floors[0].FloorLiteral + " - " + c.Estate.floors[1].FloorLiteral;
            } else {
              c.floorsText = c.Estate.floors[0].FloorLiteral;
            }
          } else {
            c.floorsText = "-";
          }
          
          if (!['',undefined,null].includes(c.Estate.RenovationYear)) {
            c.renovationText = c.Estate.RenovationYear;
          } else {
            c.renovationText = '-';
          }

          if (!['',undefined,null].includes(c.Estate.BuildYear)) {
            c.constructionText = c.Estate.BuildYear;
          }else {
            c.constructionText = '-';
          }
          if (!['',undefined,null].includes(c.Estate.Price)) {
            c.priceFromText = c.Estate.Price;
            c.priceToText = c.Estate.Price;
          } else {
            c.priceFromText = '-';
            c.priceToText = '-';
          }
          if (!['',undefined,null].includes(c.Estate.Bedrooms)) {
            c.characteristicsArray = [
              {
                image: "realEstateSilder/bedroom.png",
                value: (c.Estate.Bedrooms || c.Estate.Bedrooms> 0)  ? c.Estate.Bedrooms : '-' ,
              },
            ];
            c.rightSelect = false;
          }
        }else {
          //Fetch for the contact demand
          c.displayType =  (c.Type ? i18n.t(c.Type) : '-');
          c.displayTask =  (c.Task ? c.Task : '-');
          //Fetch for the cards
          c.cardType =  (c.Type ? c.Type : '') + (c.Task ? ' ' + c.Task : '');
          //Fetch for the report
          c.EstateCategory = c?.Category ? c.Category : "";
          if (!['',undefined,null].includes(c.Floors)) {
            if(c.Floors.length > 1){
              c.floorsText = c.Floors[0]?.text != -999999999  ? i18n.t(c.Floors[0]?.text) : '';
              c.floorsText = c.Floors[1]?.text != 999999999 ? c.floorsText + ' - ' + i18n.t(c.Floors[1]?.text) : '';
            } else {
              c.floorsText = c.Floors[0]?.text != -999999999  ? i18n.t(c.Floors[0]?.text) : '';
            }
          } else {
            c.floorsText = "-";
          }
         if (!['',undefined,null].includes(c.RenovationYear)) {
            c.renovationText =
              c.RenovationYear[0] +
              (c.RenovationYear[1] ? " - " + c.RenovationYear[1] : "");
          } else {
            c.renovationText = '-';
          }

          if (c.BuildYear) {
            c.constructionText = ((c.BuildYear[0] == -5364615539) || (['', null, undefined].includes(c.BuildYear[0]))) ? '' :  CoreHelper.EpochToDate(c.BuildYear[0]);
            c.constructionText += ((c.BuildYear[1] == 7258165261) || (['', null, undefined].includes(c.BuildYear[1]))) ? '' : c.constructionText != '' ? ' - ' +  CoreHelper.EpochToDate(c.BuildYear[1]) :  CoreHelper.EpochToDate(c.BuildYear[1]);
          } else {
            c.constructionText = '-';
          }
          if (!['',undefined,null].includes(c.Price)) {
            c.priceFromText = c.Price[0] ? c.Price[0] : "";
            c.priceToText = c.Price[1] ? c.Price[1] : "";
          }else {
            c.priceFromText  = '-';
            c.priceToText = '-';
          }
          if (!['',undefined,null].includes(c.Bedrooms)) {
            c.characteristicsArray = [
              {
                image: "realEstateSilder/bedroom.png",
                value: ((c.Bedrooms[0] == -999999999 || !c.Bedrooms[0]? '' : c.Bedrooms[0]) + 
                        (c.Bedrooms[1] == 999999999 || !c.Bedrooms[1] ? '' : ' - ' + c.Bedrooms[1])),
              },
            ];
            c.rightSelect = false;
          }
        if (![null,undefined,''].includes(c.locationGoogle) && c.locationGoogle.length > 0) {
          let locationsArray = [];
          if(c?.locationGoogle){
            c.locationGoogle.map((obj) => {
              locationsArray.push(obj.description);
            });
          }
          if(![null,undefined,''].includes(c.location) && c.location.length > 0){
            c.location.map((obj) => {
              locationsArray.push(obj.description);
            });
          }
          let areaLength = locationsArray.length - 1;
          c.EstateArea = (locationsArray[0] + (areaLength > 0 ? "...+ " + areaLength + ' ' +  i18n.t("still") : ''));
        }
        }
        c.cardCharacteristics = this.getCharacteristics(c, currency);
          c.backgroundAgentColor = "#77bd86";
        //If there is agent
        if (c.Agent) {
          c.agentId = c?.Agent?._id ?? c?.Agent ?? '';
          c.AgentName = (c?.Agent?.FirstName ?? '--') + ' ' +  (c?.Agent?.LastName ?? ' --');
          c.AgentLastCompany = c?.Agent?.Company ?? "--";
          if (['',undefined,null].includes(c?.Agent?.Photo)) {
            let name = (c?.Agent?.FirstName ?? '-' ) + " " + (c?.Agent?.LastName ?? '-');
            name = name.split(/\s/).reduce( (response, word) => (response += word.slice(0, 1)), "" );
            c.AgentPhoto = name;
            c.isImgAgent = false;
          } else {
            c.AgentPhoto = c?.Agent?.Photo;
            c.isImgAgent = true;
          }
          c.fromDetails = {
            name: c.Agent?.FirstName ?? "--",
            surname: c.Agent?.LastName ?? "--",
            img: c.AgentPhoto,
            isImg: c.isImgAgent,
            id: c.agentId,
          };
        }
        c.backgroundClientColor = "white";
        // If there is client
        if (c.Client) {
          c.clientId = c.Client?._id ?? c?.ClientID ?? '';
          c.ClientName =  (c?.Client?.FirstName ?? '--') + ' ' + (c?.Client?.LastName ?? ' --');
          c.isImgClient = false;
          c.ratingValue = c.Client?.customer_evaluation?.avgRate ? c.Client.customer_evaluation.avgRate : '-';
          c.avatarStyle = "border: 2px solid #ACB2CA;"; 
          let name = (c?.Client?.FirstName ?? 'N' ) + " " + (c?.Client?.LastName ?? 'N');
          c.ClientPhoto = name.split(/\s/)
              .reduce(
                (response, word) => (response += word.slice(0, 1)),
                ""
              );
          if (['',undefined,null].includes(c?.Client?.Photo)) {
            c.ClientCardPhoto = c.ClientPhoto;
            c.isImgClient = false;
          } else {
            c.ClientCardPhoto = c.Client.Photo;
            c.isImgClient = true;
          }
          if (!c.fromDetails) {
            c.fromDetails = {
              name: c?.Client?.FirstName ?? '--',
              surname: c?.Client?.LastName ?? ' --',
              img: c.ClientCardPhoto,
              isImg: c.isImgClient,
              id: c.clientId,
            };
          }
        } else {
          c.ClientName = "--";
          c.isImgClient = false;
        }
        //If there is creator
        if(c.Creator){
          c.creatorId = c.Creator?._id ?? c?.ClientID ?? '';
          c.CreatorName =  (c?.Creator?.FirstName ?? '--') + ' ' + (c?.Creator?.LastName ?? ' --');
          c.isImgCreator = false;
          c.ratingValue = c.Creator?.customer_evaluation?.avgRate ? c.Creator.customer_evaluation.avgRate : '-';
          c.avatarStyle = "border: 2px solid #ACB2CA;";
          let name = (c?.Creator?.FirstName ?? 'N' ) + " " + (c?.Creator?.LastName ?? 'N');
          c.CreatorPhoto = name.split(/\s/)
              .reduce(
                (response, word) => (response += word.slice(0, 1)),
                ""
              );
          if (['',undefined,null].includes(c?.Creator?.Photo)) {
            c.CreatorCardPhoto = c.CreatorPhoto;
            c.isImgCreator = false;
          } else {
            c.CreatorCardPhoto = c.Creator.Photo;
            c.Creator = true;
          }
          if (!c.fromDetails) {
            c.fromDetails = {
              name: c?.Creator?.FirstName ?? '--',
              surname: c?.Creator?.LastName ?? ' --',
              img: c.CreatorCardPhoto,
              isImg: c.isImgCreator,
              id: c.creatorId,
            };
          }
        }
        // If the user is the creator
        if(store.state.auth.userDetails._id == c.Creator){
          //Fetch foer the report 
          c.AgentName = (c?.fromDetails?.name ?? '-')  + ' ' + (c?.fromDetails?.surname ?? '-');
          c.AgentLastCompany = i18n.t("outgoingDemand");
          c.AgentPhoto = require('@/assets/demands/outDemand.png');
          c.backgroundAgentColor = "white";
          c.AgentAbsolutePath = true;
          c.isImgAgent = true;

          // Fetch for the card
          c.fromDetails.img = require('@/assets/demands/outDemand.png');
          c.fromDetails.backgroundColor = 'white';
          c.fromDetails.isImg = true;
          c.fromDetails.absolutePath = true;
        }
        if(c?.Agent == store.state.auth.userDetails._id|| c?.Agent?._id == store.state.auth.userDetails._id){
          c.unRead = c.unRead ?? true;
          unReadCNT ++
        }else delete c.unRead;
        //Fetch for the comments
          if(c.comments){
          c.comments = c.comments.map((m,index) => {
            m.comment_index = index;
            return m;
          });
          }
          c.AgentName = c.AgentName ? c.AgentName : "--";
          c.AgentLastCompany = c.AgentLastCompany ? c.AgentLastCompany : "--";
        c.allowClickableAvatar = true;
        return c;
      });
      return [array, unReadCNT]
  },
  monthDiff(date1, date2) {
    var month1=date1.getMonth();
    var month2=date2.getMonth();
    if(month1===0){
      month1++;
      month2++;
    }
    var year1=date1.getFullYear();
    var year2=date2.getFullYear();
    return (year2 - year1) * 12 + (month2 - month1);
  },
  initDemandOrigin(data) {
    let object = {};
    if(data?.sourceType){
      if(data.sourceType == "Direct"){
        object["source"] =  i18n.t("direct");
        object["directSource"] = data?.source? data.source : "";
      } else {
        object["source"] =  i18n.t("agent");
        object["addedFrom"] = data.source?.fullname ?? (data.source?.FirstName ?? '--') + ' ' + (data.source?.LastName ?? '--');
      }
    }
    object["sourceDescription"] = data?.Comments ? data.Comments : "";
    return object;
  },
  initMapLocations(data) {
    let locations = {descriptions: [], gps: []}
    
    if (data.EstateCode) {
      locations.descriptions.push(data?.Estate?.Area)
      locations.gps.push({        
        position: {
          lat: data?.Estate?.GPS ? data.Estate.GPS[0] : '',
          lng: data?.Estate?.GPS ? data.Estate.GPS[1] : '',
        },
        data: {},
        type: "Πώληση",
      })
    }
    else {
      if (data?.locationGoogle.length > 0) {
        data.locationGoogle.map((g)=>{
          locations.descriptions.push(g?.description);
            locations.gps.push({
              position: {
                lat: g?.GPS[0],
                lng: g?.GPS[1],
              },
              data: {},
              type: "Πώληση",
            });
        })
      }
      if (data?.location.length > 0){
        data.location.map((l)=>{
          locations?.descriptions.push(l?.description);
        })
      }
    }
    return locations;
  },
  initDetailsSlider(data) {
    let object = {};

    object["status"] = data?.RequestStatus ?
      data.RequestStatus :
      "";

    object["code"] = data?._id ?
      data._id :
      "";

    object["created"] = data?.RegistrationDate ?
      CoreHelper.EpochToFullDateTime(data.RegistrationDate) :
      "";

    object["updated"] = data?.UpdatedDay ?
      CoreHelper.EpochToFullDateTime(data.UpdatedDay) :
      "";

    object["nextFollowUp"] = data?.follow_up ?
      CoreHelper.EpochToDate(data.follow_up) :
      "";

    object["contactPersonName"] = data?.Client?.FirstName ? data?.Client?.FirstName: null;
    object["contactPersonSurName"] = data?.Client?.LastName ? data?.Client?.LastName: null;
    object["contactPersonPhoto"] = data?.Client?.Photo ? data?.Client?.Photo: null;
    object["contactId"] = data?.Client?.FirstName ? data?.Client?._id: null;

    object["responsiblePersonName"] =  data?.Agent?.FirstName ? data?.Agent?.FirstName: null;
    object["responsiblePersonSurName"] =  data?.Agent?.LastName ? data?.Agent?.LastName: null;
    object["responsiblePersonPhoto"] =  data?.Agent?.Photo ? data?.Agent?.Photo: null;
    object["agentId"] =  data?.Agent?._id ? data?.Agent?._id: null;

    object["addedFromName"] =  data?.Creator?.FirstName ? data?.Creator?.FirstName: null;
    object["addedFromSurName"] =  data?.Creator?.LastName ? data?.Creator?.LastName: null;
    object["addedFromPhoto"] =  data?.Creator?.Photo ? data?.Creator?.Photo: null;
    object["addedFromId"] =  data?.Creator?._id ? data?.Creator?._id: null;
    return object;
  },
  initContactInfo(data) {

    let avatarDisplay = data?.Agent ? 'Agent' : data?.Client ? 'Client' : data?.Creator ? 'Creator' : '';
    let object = {};
   if(avatarDisplay){
      object["telephone"] = data[avatarDisplay]?.Telephone ?
      data[avatarDisplay].Telephone[0] :
      "";

      object["cellPhone"] = data[avatarDisplay]?.Mobile ?
      data[avatarDisplay].Mobile :
      "";

      object["email"] = data[avatarDisplay]?.Email ?
      data[avatarDisplay].Email :
      "";
   }

    object["created"] = data?.RegistrationDate ?
      CoreHelper.EpochToFullDateTime(data.RegistrationDate) :
      "";

    object["updated"] = data?.UpdatedDay ?
      CoreHelper.EpochToFullDateTime(data.UpdatedDay) :
      "";
    return object;
  },
  initSpecifications(data) {
    let object = {};
    if (data?.Estate) {
      // Specific Demands
      object["type_of_property"] = data?.Estate?.Category ?
        data.Estate.Category :
        "";
      object["business_type"] = data?.Estate?.Type ?
        data.Estate.Type :
        "";
      object["condition"] = this.buildingStatus(true, data);
      if (data?.Estate?.floors && data?.Estate?.floors.length > 0) {
        if (data.Estate.floors.length > 1) {
          object["floors"] = data.Estate.floors[0].FloorLiteral + " - " + data.Estate.floors[1].FloorLiteral;
        } else {
          object["floors"] = data.Estate.floors[0].FloorLiteral;
        }
      }
        object["area"] = data?.Estate?.Unit ? data.Estate.Unit +"㎡" : "";
        object["rooms"] = data?.Estate?.characteristics?.bedrooms ?
          data.Estate.characteristics.bedrooms :
          "";
        object["bathroom"] = data?.Estate?.characteristics?.bathrooms ?
          data.Estate.characteristics.bathrooms :
          "";

        object["plot_area"] = data?.Estate?.characteristics
          .plot_unit ?
          data.Estate.characteristics.plot_unit + "㎡" :
          "";
     
      object["availability"] = data?.Estate?.CurrentUse ? data.Estate.CurrentUse : '';

      if (data?.Estate?.characteristics?.airbnb) {
        object["airbnb"] = CoreHelper.parseBoolean(data.Estate.characteristics.airbnb);
      }

      if (data?.Estate?.characteristics?.storage_room) {
        object["save"] = CoreHelper.parseBoolean(data.Estate.characteristics.storage_room);
      }
      if (data?.Estate?.characteristics?.furnished) {
        object["furnished"] = CoreHelper.parseBoolean(data.Estate.characteristics.furnished);
      }
      if (data?.Estate?.characteristics?.allow_pet) {
        object["allow_pet"] = CoreHelper.parseBoolean(data.Estate.characteristics.allow_pet);
      }

      if (data?.description) {
        object["description"] = data.description;
      }
      return object;
    } else {
      // General Demands
      object["type_of_property"] = data?.Category ?
        data.Category :
        "";
      object["business_type"] = data?.Type ?
        data.Type :
        "";
      object["condition"] = this.buildingStatus(false, data);

      if(data?.Floors){
        object["floors"] = data?.floorsText ? data?.floorsText : '';
      }

      if(data?.Unit){
        object["area"] = this.generateCharText(data.Unit, "㎡");
      }

      if(data?.Bedrooms){
        object["rooms"] = this.generateCharText(data.Bedrooms,"");
      }

      if(data?.Bathrooms){
        object["bathroom"] = this.generateCharText(data.Bathrooms,"");
      }

      if(data?.Land){
        object["plot_area"] = this.generateCharText(data.Land,"㎡");
      }

      let availability = [];
      if(![null,undefined,''].includes(data.property_characteristics) && data.property_characteristics.length > 0){
        data.property_characteristics.map((obj) => {
          availability.push(' ' + i18n.t(obj));
        });
      }
      object["availability"] = availability.toString();

      if (data?.characteristics?.airbnb) {
        object["airbnb"] = CoreHelper.parseBoolean(data.characteristics.airbnb);
      }

      if (data?.characteristics?.storage_room) {
        object["save"] = CoreHelper.parseBoolean(data.characteristics.storage_room);
      }
      if (data?.characteristics?.furnished) {
        object["furnished"] = CoreHelper.parseBoolean(data.characteristics.furnished);
      }
      if (data?.characteristics?.allow_pet) {
        object["allow_pet"] = CoreHelper.parseBoolean(data.characteristics.allow_pet);
      }

      if (data?.description) {
        object["description"] = data.description;
      }
      return object;
    }
  },
  generateCharText(array, symbol){
    let text;
    text = ((array[0] == -999999999) || (['', null, undefined].includes(array[0]))) ? '' : array[0] + symbol;
    text += ((array[1] == 999999999) || (['', null, undefined].includes(array[1]))) ? '' : text != '' ? ' - ' + array[1] + symbol : array[1] + symbol;
    return text;
  },
  getCharacteristics(c, currency) {
    let array = [];

    if (c.cardCharacteristics) {
      return c.cardCharacteristics;
    }

    if(c.Estate){
      if (c.Estate?.Price || c.Estate?.RentPrice) {
        array.push(c.Estate?.Price ?? c.Estate.RentPrice);
      }
      if (c.Estate?.Area) {
        let arrayArea = c.Estate.Area.split('/');
          array.push(arrayArea[0]);
        if (array.length >= 2) {
          return array;
        }
      }

      if (c.Estate?.Unit) {
        array.push(c.Estate.Unit + ' ㎡');
        if (array.length >= 2) {
          return array;
        }
      }
    } else {
      if (c?.Price) {
        c.Price ? c.Price[1] && (c.Price[1] != 999999999)  ? array.push(i18n.t("toDt2") + ' ' +  c.Price[1] + currency) : 
        c.Price[0] && (c.Price[0] != -999999999) ? array.push(i18n.t("footerP3") + ' ' +  c.Price[0] + currency) : '-' : '-';
      }
      if (![null,undefined,''].includes(c.locationGoogle) && c.locationGoogle.length > 0) {
        let locationsArray = [];
        if(c?.locationGoogle){
          c.locationGoogle.map((obj) => {
            locationsArray.push(obj.description);
          });
        }
        if(![null,undefined,''].includes(c.location) && c.location.length > 0){
          c.location.map((obj) => {
            locationsArray.push(obj.description);
          });
        }
        let areaLength = locationsArray.length - 1;
        array.push(locationsArray[0] + "...+ " + areaLength + ' ' +  i18n.t("still"));
        if (array.length >= 2) {
          return array;
        }
      }

      if(c?.Unit){
        array.push(this.generateCharText(c.Unit, "㎡"));
        if (array.length >= 2) {
          return array;
        }
      }

    }
    return array;
  }, 
  isAuthorizedDemand(demandObject) {
    if (store.state.auth.userDetails.Role == "Admin") {
      return true;
    } else if (
      store.state.auth.userDetails.Role == "Associate" ||
      store.state.auth.userDetails.Role == "Leader"
    ) {
      // If user associate and creator of the demand
      //Assigned
      if (store.state.auth.userDetails._id == demandObject?.Agent?._id) {
        return true;
      }
      //Creator
      let creatorId = null;
      //If creator is object
      if (typeof demandObject?.Creator == "object") {
        creatorId = demandObject?.Creator?._id;
      } else if (demandObject?.Creator) {
        creatorId = demandObject.Creator;
      }
      if (creatorId != null) {
        if (store.state.auth.userDetails._id == creatorId) {
          return true;
        }
      }
    } else if (store.state.auth.userDetails.Role == "Leader") {
      var team =
        store.state.auth.userDetails.Team && store.state.auth.userDetails.Team.length > 0
          ? store.state.auth.userDetails.Team
          : [];
      if (team && team.length > 0) {
        if (team.includes(this.demandObject?.Agent?._id)) {
          return true;
        }
      }
    } else if (
      ["Secretary", "OfficeAdmin"].includes(store.state.auth.userDetails.Role)
    ) {
      if (store.state.auth.userDetails.LastCompany == demandObject.LastCompany) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  },
};
