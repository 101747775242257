import AxiosClientToken from "../Client/AxiosClientToken";

const resourceCud = "/general";

export default {
  get(filters, params) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}/list/QueryEstates`,
      filters,
      { params: params }
    );
  },
  getId(id, params) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}/list/QueryEstates`,
      { $and: { _id: id }, $or: {} },
      { params: params }
    );
  },
  create(payload) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}${resourceCud}/InsertEstateTemplate`,
      payload
    );
  },
  update(id, payload) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}${resourceCud}/UpdateEstateTemplate/${id}`,
      payload
    );
  },
  delete(id) {
    return AxiosClientToken.delete(
      `${process.env.VUE_APP_BASE_URL}${resourceCud}/DeleteEstateTemplate/${id}`
    );
  },
  uniqueView(id) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}${resourceCud}/InsertUniqueView/${id}`
    );
  },
  saveEstate(id) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}${resourceCud}/InsertEstateSave/${id}`
    );
  },
  sendEmail() {
    return AxiosClientToken.post(`${process.env.VUE_APP_BASE_URL}/messenger/sendemail`);
  },
  async getReportData(filters, params) {
    try {
      const response = await AxiosClientToken.post(`${process.env.VUE_APP_BASE_URL}/list/QuerySpreadsheet`,
        filters,
        {
          params: params,
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.headers["content-type"], })
      );
      const link = document.createElement("a");
      link.href = url;
      let currentDate = new Date();
      currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "_");
      link.setAttribute("download", "estates_" + currentDate); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      throw err;
    }
  },
  setCooperationRequest(payload, id) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}${resourceCud}/InsertCooperationRequest/${id}`,
      payload
    );
  },
  getUnreadCooperation(id) {
    return AxiosClientToken.get(
      `${process.env.VUE_APP_BASE_URL}${resourceCud}/QueryUnreadCooperationRequests/${id}`
    );
  },
  vipInterest(id) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}${resourceCud}/InsertVipListingInterest/${id}`
    );
  },
  reminderVip(id) {
    return AxiosClientToken.put(
      `${process.env.VUE_APP_BASE_URL}${resourceCud}/InsertVipListingReminder/${id}`
    );
  },
  cancelVip(id) {
    return AxiosClientToken.delete(
      `${process.env.VUE_APP_BASE_URL}${resourceCud}/DeleteVipListingInterest/${id}`
    );
  },
  insertProvider(provider, id) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}${resourceCud}/InsertProvider/${id}/${provider}`
    );
  },
  deleteProvider(provider, id) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}${resourceCud}/DeleteProvider/${id}/${provider}`
    );
  },
  uploadEstatePhotos(payload, id) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}${resourceCud}/UploadEstatePhotos/${id}`,
      payload,
      {
        headers: { "Content-Type": "multipart/form-data", },
      }
    );
  },
  createEstateWatermarkedPhotos(id) {
    return AxiosClientToken.get(
      `${process.env.VUE_APP_BASE_URL}${resourceCud}/CreateEstateWatermarkedPhotos/${id}`
    );
  },
  reOrderEstatePhotos(payload, id) {
    return AxiosClientToken.post(`${process.env.VUE_APP_BASE_URL}${resourceCud}/ReorderEstatePhotos/${id}`,
      payload
    );
  },
  uploadEstateDocuments(payload, id, doc_type) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}${resourceCud}/UploadEstateDocuments/${id}/${doc_type}`,
      payload,
      {
        headers: { "Content-Type": "multipart/form-data", },
      }
    );
  },
  updateEstateDocuments(estId, documentType, documentIndex, payload) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}${resourceCud}/UpdateEstateDocuments/${estId}/${documentType}/${documentIndex}`,
      payload,
      {
        headers: { "Content-Type": "multipart/form-data", },
      }
    );
  },
  async downloadEstateDocuments(id, fileName, realFileName) {
    let encFileName = encodeURIComponent(fileName)
    return AxiosClientToken
      .get(
        `${process.env.VUE_APP_BASE_URL}${resourceCud}/DownloadEstateDocuments/${id}/${encFileName}`,
        { responseType: "blob" }
      )
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", realFileName);
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch((err) => {
        return err;
      });
  },
  deleteEstateDocuments(estId, documentType, documentIndex) {
    return AxiosClientToken.delete(
      `${process.env.VUE_APP_BASE_URL}${resourceCud}/DeleteEstateDocuments/${estId}/${documentType}/${documentIndex}`
    );
  },
  getOffers(id) {
    return AxiosClientToken.get(`${process.env.VUE_APP_BASE_URL}/list/QueryOffers/${id}`);
  },
  getReviews(id) {
    return AxiosClientToken.get(`${process.env.VUE_APP_BASE_URL}/list/QueryReviews/${id}`);
  },
  getMatchingLead(id) {
    return AxiosClientToken.get(`${process.env.VUE_APP_BASE_URL}/list/QueryLeadMatching/${id}`);
  },
  getShowcases(id) {
    return AxiosClientToken.get(`${process.env.VUE_APP_BASE_URL}/list/QueryShowcases/${id}`);
  },
  getCooperationRequest(id) {
    return AxiosClientToken.get(`${process.env.VUE_APP_BASE_URL}/list/QueryCooperationRequests/${id}`);
  },
  createComment(id, body) {
    return AxiosClientToken.post(`${process.env.VUE_APP_BASE_URL}${resourceCud}/InsertEstateComment/${id}`,
      body
    );
  },
  updateComment(id, body, index) {
    return AxiosClientToken.put(`${process.env.VUE_APP_BASE_URL}${resourceCud}/UpdateEstateComment/${id}/${index}`,
      body
    );
  },
  deleteComment(id, index) {
    return AxiosClientToken.delete(`${process.env.VUE_APP_BASE_URL}${resourceCud}/DeleteEstateComment/${id}/${index}`);
  },
  getInternationalSchools() {
    return AxiosClientToken.post(`${process.env.VUE_APP_BASE_URL}/list/QueryInternationalSchools`);
  },
  getAreas() {
    return AxiosClientToken.get(`${process.env.VUE_APP_BASE_URL}${resourceCud}/QueryAreas`);
  },
  getAreasEn() {
    return AxiosClientToken.get(`${process.env.VUE_APP_BASE_URL}${resourceCud}/QueryAreasEn`);
  },
  getRegions(locale) {
    return AxiosClientToken.get(`${process.env.VUE_APP_BASE_URL}${resourceCud}/QueryRegions/${locale}`);
  },
  getStates(region, locale) {
    return AxiosClientToken.get(`${process.env.VUE_APP_BASE_URL}${resourceCud}/QueryStates/${region}/${locale}`);
  },
  getDistricts(region, state, locale) {
    return AxiosClientToken.get(`${process.env.VUE_APP_BASE_URL}${resourceCud}/QueryDistricts/${region}/${state}/${locale}`);
  },
  getBoroughs(region, state, district, locale) {
    return AxiosClientToken.get(`${process.env.VUE_APP_BASE_URL}${resourceCud}/QueryBoroughs/${region}/${state}/${district}/${locale}`);
  },
  getLawyers(filters, params) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}/list/QueryAttorneys`,
      filters,
      { params: params }
    );
  },
  getNotaries(filters, params) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}/list/QueryNotaries`,
      filters,
      { params: params }
    );
  },
};
