import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "@/store";

Vue.use(VueI18n);

export default new VueI18n({
  locale: store?.state?.settings?.language ?? 'gr', // set default locale
  fallbackLocale: "gr",
  silentTranslationWarn: true,
  messages: {
    en: {
      username: "Username",
      password: "Password",
      backToLogin: "Back to login",
      newPassword: "New Password",
      submit: "Submit",
      save: "Save",
      confPass: "Confirmation password",
      newPasswordTitle: "Set new password",
      newPasswordSuccess: "Your password has been changed successfully",
      newPasswordSubtitle:
        "To change your password on my.newdeal.gr, please enter the new password.",
      confNewPass: "Confirmation new password",
      loginWithPhone: "Login with Phone",
      loginWithGoogle: "Login with Google",
      requiredField: "Required field",
      reqFieldsprg: "You have not filled in all the required fields",
      stayConnected: "Stay connected",
      login: "Login",
      register: "Register",
      forgotPass: "Forgot your password?",
      resetPass: "Reset password",
      to: "to",
      other: "Other",
      role: "Role",
      addressEmail: "Email",
      email: "Email",
      accountInfo: "Account information",
      customerInfo: "Customer information",
      profInfo: "Professional details",
      companyName: "Company name",
      companyName2: "Company name",
      countryResid: "Country of residence",
      regions: "Area",
      municipal: "Municipal",
      responsiblePerson: "Responsible person",
      chamberNo: "Chamber registration number",
      firstname: "Firstname",
      lastname: "Lastname",
      fathersName: "Fathers name",
      dateOfBirth: " Date of birth",
      nameDay: "Nameday",
      vatNumber: "VAT number",
      idPassp: "ID/Passport",
      publFinanceService: "Public Financial Service",
      profession: "Profession",
      contactInfo: "Contact info",
      otherContactInfo: "Other contact info",
      writeMoreContactInfo:
        "Write additional information you need for the contact",
      createSysAccount: "Create system account",
      contactRights: "Contact rights",
      contactOrigin: "Contact origin",
      chooseOrigin: "Choose origin",
      addressInfo: "Address info",
      otherAddressInfo: "Other address info",
      morePersonalInfo: "More personal information",
      greece: "Greece",
      telephone: "Telephone",
      mobile: "Mobile",
      telCom: "Telephone",
      otherTel: "Other telephone",
      securityCode: "Security Code",
      policy:
        "I agree that my.newdeal.gr will keep my personal data for future communication regarding new properties that may be of interest to me",
      back: "Back",
      send: "Send",
      resend: "Resend",
      backToConnect: "Back to login",
      addRealEstate: "Create real estate",
      realEstate: "Real estate | Real estate ",
      realEstate2: "Real estate",
      realEstateSubHeader: "Add, view & edit real estate",
      createRealEstateSubHeader: "Create new property",
      logout: "Log out",
      exit: "Exit",
      settings: "Settings",
      availableFor: "AVAILABLE FOR",
      availableForTable: "Available For",
      availableFrom: "Available from",
      all: "Αll",
      sale: "Sale",
      lease: "Lease",
      consideration: "Consideration",
      propertyCategories: "Property Categories",
      allCategories: "All Categories",
      allSubCategories: "PROPERTY TYPE",
      residence: "Residence",
      businessProperty: "Bus. Property",
      land: "Land",
      businesses: "Businesses",
      others: "Others",
      subpropertyCategories: "PROPERTY TYPES",
      subpropertyCategoriesLabel:
        "Select a property category to display the types.",
      features: "Features",
      location: "Location",
      bedrooms: "Bedrooms",
      bathrooms: "Bathrooms",
      from: "from..",
      toFilter: "to..",
      floors: "Floors",
      price: "PRICE",
      priceTable: "Price",
      initialPrice: "Initial price",
      priceIncrease: "Price increase",
      priceReduction: "Price reduction",
      priceNegotiable: "Price negotiable",
      sqft: "SQUARE FEET",
      sqftBalcony: "Balconies Square Feet",
      sqftTable: "Square feet",
      numberOfPlaces: "Number of places",
      warehouseSqft: "Warehouse Square Feet",
      propertyCode: "Estate Code",
      yearOfConstruction: "Construction Year",
      apartment: "Apartment",
      office: "Office",
      store: "Store",
      storageArea: "Storage Area",
      industrialSite: "Industrial Site",
      hotel: "Hotel",
      craftArea: "Craft Area",
      warehouse: "Warehouse",
      professionalBuilding: "Professional Building",
      building: "Building",
      professionalSpace: "ProfessionalSpace",
      room: "Room",
      site: "Site",
      house: "House",
      maisonette: "Maisonette",
      blockOfFlats: "Block Of Flats",
      villaWithGarden: "Villa With Garden",
      plot: "Plot",
      parcel: "Parcel",
      field: "Field",
      otherField: "Other field",
      restaurant: "Restaurant",
      hotelsPension: "Hotel -Pension",
      miniMarket: "Mini Market",
      cafe: "Café",
      tavern: "Tavern",
      bakeryPastry: "Bakery-Pastry",
      ouzeri: "Ouzeri",
      privateSchool: "Private School",
      barsPubsDanceHouse: "Bars/Pubs - Dance House",
      supermarket: "Supermarket",
      clothingStore: "Clothing Store",
      serviceCompany: "Service Company",
      gyms: "Gyms",
      drugStores: "Drug Stores",
      parking: "Parking",
      enclosedParking: "Enclosed Parking",
      shoeStore: "Shoe Store",
      technicalFeaturesAndInterior: "Technical Features And Interior",
      frames: "Frames",
      floorTypes: "Floor Types",
      bright: "Bright",
      painted: "Painted",
      electricalEquipment: "Electrical Equipment",
      electricFrames: "Electric Frames",
      satelliteAntenna: "Satellite Antenna",
      luxurious: "Luxurious",
      windowNets: "Window Nets",
      allowingPets: "Allowing-Pets",
      internalStaircase: "Internal Staircase",
      elevator: "Elevator",
      safety: "Safety",
      safetyDoor: "Safety Door",
      cameras: "Cameras",
      safetySystem: "Safety System",
      alarm: "Alarm",
      cctv: "Cctv",
      moreFeatures: "More Features",
      attic: "Attic",
      atticSqft: "Attic Square Feet",
      serviceRoom: "Service Room",
      serviceRoomSqft: "Service Room Square Feet",
      privateTerrace: "Private Terrace",
      privateTerraceSqft: "Private Terrace Square Feet",
      playroom: "Playroom",
      playroomSqft: "Playroom Squre Feet",
      heating: "Heating",
      independentHeating: "Independent Heating",
      acUnit: "A'c Unit",
      solarBoiler: "Solar Boiler",
      fireplace: "Fireplace",
      liquidGas: "Liquid Gas",
      heater: "Heater",
      centralHeating: "Central heating",
      underfloorHeating: "Underfloor Heating",
      naturalGas: "Natural Gas",
      oil: "Oil",
      electricity: "Electricity",
      heatAccumulator: "Heat Accumulator",
      propertyType: "Property Type",
      otherFilters: "Other Filters",
      filters: "Filters",
      status: "Status",
      kind: "Kind",
      toMeOnly: "To me only",
      network: "Network",
      public: "Public",
      yes: "Yes",
      no: "No",
      newlyBuilt: "Newly Built",
      unfinished: "Unfinished",
      preservable: "Preservable",
      renovateYear: "Renovate Year",
      renovated: "Renovated",
      needsToBeRenovated: "Needs To Be Renovated",
      generalFeatures: "GENERAL FEATURES",
      view: "View",
      pool: "Pool",
      garden: "Garden",
      furnished: "Furnished",
      vechParking: "Parking",
      published: "PUBLISHED",
      notPublished: "Not Published",
      publish: "Publish",
      newdealGr: "newdeal.gr",
      spiti360Gr: "spiti360.gr",
      spitogatos: "spitogatos",
      xrisiEukairia: "Xrisi Eukairia",
      toBeApproved: "To be approved",
      leased: "Leased",
      free: "Free",

      adsWith: "ADS WITH",
      new: "New",
      editPhoto: "Edit photo",
      photoDescr: "Photo description",
      photoDescrGr: "Photo description | Greek",
      photoDescrEn: "Photo description | English",
      photoDescrPlaceHolder: "Write what the photo shows",
      photos: "Photos",
      noPhotos: "No Photos",
      video: "Video",
      videoCode: "Video code",
      pasteYoutubeLink:
        "Paste the YouTube address from the video of the property.",
      photo360: "Photo 360",
      manufacturers: "Manufacturers",
      reducedPrice: "Reduced Price",
      outdoorAreasAndPropertyLocation: "Outdoor Areas & Property Location",
      veranda: "Veranda",
      awnings: "Awnings",
      independentEntrance: "Independent Entrance",
      disabledAccess: "Disabled Access",
      bbq: "BBQ",
      suitableFor: "Suitable for",
      student: "Student",
      holidayHome: "Holiday home",
      professionalUse: "Professional Use",
      investmentAirbnb: "Investement / Airbnb",
      investment: "Investement",
      energyClass: "ENERGY CLASS",
      valuation: "VALUATION",
      valuationF: "Valuation",
      renewalDate: "RENEWAL DATE",
      renewalDateF: "Renewal Date",
      registrationDateProviders: "REGISTRATION DATE (Providers)",
      registrationDateProvidersF: "Registration Date (Providers)",
      registrationDate: "REGISTRATION DATE",
      registrationDateF: "Registration Date",
      registration: "Registration",

      clear: "Clear",
      telephoneFilter: "Telephone",
      createRealEstate: "Create Real Estate",
      editNonPublishedProperty: "Edit Non Published Property",
      floor: "Floor",

      f5basement: "-5th",
      f4basement: "-4th",
      f3basement: "-3rd",
      f2basement: "-2nd",
      basement: "Basement",
      semiBasement: "Semi Basement",
      groundFloor: "Ground Floor",
      mezzanineFloor: "Mezzanine Floor",
      higherGroundFloor: "Higher Ground Floor",
      f1th: "1st",
      f2th: "2nd",
      f3th: "3rd",
      f4th: "4th",
      f5th: "5th",
      f6th: "6th",
      f7th: "7th",
      f8th: "8th",
      f9th: "9th",
      f10th: "10th",
      f11th: "11th",
      f12th: "12th",
      f13th: "13th",
      f14th: "14th",
      f15th: "15th",
      f16th: "16th",
      f17th: "17th",
      f18th: "18th",
      f19th: "19th",
      f20th: "20th",

      prefabrication: "Prefabrication",
      mobileHome: "Mobile Home",
      studio: "Studio",
      inkHouse: "Inkhouse",
      vanilla: "Villa",
      traditional: "Traditional",
      loft: "Loft",
      neoclassical: "Neoclassical",
      shortTermRent: "Short term rent",
      longTermRent: "Long term rent",
      notRented: "No (not rented)",
      bungalow: "Bungalow",
      farm: "Farm",
      residenceComplex: "Residence Complex",
      availability: "Availability",

      inhabited: "Inhabited",
      chooseEnergyClass: "Choose Energy Class",
      energyClassDescr: "Energy Class",
      Aplus: "A+",
      A: "A",
      Bplus: "B+",
      B: "B",
      G: "G",
      D: "D",
      E: "E",
      Z: "Z",
      H: "H",
      noEnergyPerformance:
        "No Energy Performance Certificate Is Required For This Property",
      issueOfenergy: "Issue Of Energy Performance Certificate In Progress",
      selectBoxes: "Select Boxes",
      doubleGlazingBars: "Double Glazing Bars",
      woodenFrames: "Wooden Frames",
      syntheticFrames: "Synthetic Frames",
      eletricFrames: "Eletric Frames",
      aluminiumFrames: "Aluminium Frames",
      slidingRolls: "Sliding Rolls",
      sliding: "Sliding",
      rolls: "Rolls",
      screens: "Screens",
      ground: "Floor",
      marble: "Marble",
      wood: "Wood",
      stone: "Stone",
      tile: "Tile",
      cement: "Cement",
      mosaic: "Mosaic",
      marbleWood: "Marble - Wood",
      marbleTile: "Marble - Tile",
      stoneWood: "Marble - Wood",
      stonemarble: "Stone - Marble",
      tileWood: "Tile - Wood",
      mosaicWood: "Mosaic - Wood",
      industrialFlooring: "Industrial Flooring",
      lamite: "Lamite",
      chooseAView: "Choose A View",
      city: "City",
      mountain: "Mountain",
      acropoliMonuments: "Acropolis Monuments",
      lake: "Lake",
      sea: "Sea",
      river: "River",
      park: "Park",
      selectZone: "Select Zone",
      residentialZone: "Residential Zone",
      commericalZone: "Commercial Zone",
      ruralZone: "Rural Zone",
      industrialZone: "Industrial Zone",
      regenerationZone: "Regeneration Zone",
      offPlan: "Out off Plan",
      levels: "Levels",
      selectPosition: "Select Position",
      corner: "Corner",
      eastWest: "East-West",
      interior: "Interior",
      facade: "Facade",
      penthouse: "Penthouse",
      through: "Through",
      onMainStreet: "On Main Street",
      onPedestrianStreet: "On Pedestrian Street",
      orientation: "Orientation",
      eastern: "Eastern",
      easternMeridian: "Easter Meridian",
      north: "North",
      northEast: "Northeast",
      northWest: "Northwest",
      west: "West",
      westMeridian: "West Meridian",
      meridian: "Meridian",
      south: "South",
      southEast: "Southeast",
      southWest: "Southwest",
      typeOfParking: "Type Of Parking",
      closed: "Closed",
      pilot: "Pilot",
      open: "Open",
      typeOfMandate: "Type Of Mandate",
      simple: "Simple",
      newDealExclusive: "New Deal Exclusive",
      exclusive: "Exclusive",
      marketResearch: "Market Research",
      hiddenAdminOnly: "Hidden (Admin Only)",
      changeTheStatusOfTheAd: "Change the status of the Ad...",
      active: "Active",
      inactive: "Inactive",
      rented: "Rented",
      sold: "Sold",
      underNegotiation: "Under Negotiation",
      receivedAdvancePayment: "Received Advance Payment",
      setAsVipAdminOnly: "Set As Vip (Admin Only)",
      company: "Company*",
      newDeal: "New Deal",
      newDealKolonaki: "newdeal deluxe",
      newDealGlyfada: "newdeal Glyfada",
      newDealPiraeus: "newdeal Piraeus",
      newDealThessaloniki: "newdeal Thessaloniki",
      typeOfOrder: "Type Of Order*",
      moreAreas: "More areas",
      livingRooms: "Living Rooms",
      exteriorPlaces: "Exterior places",
      wc: "WC",
      propertyFinancials: "Property Financials",
      depositAmount: "Deposit Amount",
      monthlyRent: "Monthly Rent",
      monthlyFees: "Monthly Fees",
      eurosPerMonth: "€/PM",
      yearlyTaxes: "Annual tax (ENFIA)",
      yearlyReturn: "Annual Return",
      yearlyReturnWithoutTaxes: "Anual Return Without Taxes",
      bank: "Bank",
      mortgage: "Mortgage",
      amountPercentage: "Amount/Percentage",
      underConstraction: "Under Constraction",
      security: "Security",
      vechParkingCount: "Parking Slots",
      gardenSqft: "Garden Square Feet",
      poolSqft: "Pool Square Feet",
      technicalFeaturesInterior: "technicalFeatures & Interior",
      luxury: "Luxury",
      petsWelcome: "Pets Welcome",
      securityDoor: "Security Door",
      securityCamera: "Security Camera",
      securitySystem: "Security System",
      alarmSystem: "Alarm System",
      terrace: "Terrace",
      airbnb: "Airbnb",
      school: "School",
      metro: "Metro",
      ΗΣΑΠ: "ISAP",
      suburban: "Suburban",
      internationalSchools: "International Schools",
      airport: "Airport",
      urbanTransport: "Urban Transport",
      square: "Square",
      hospital: "Hospital",
      tram: "Tram",
      inputDescription: "Enter a description for the property",
      greek: "Greek",
      english: "English",
      arabic: "Arabic",
      chinesse: "Chinesse",
      heatAndConsuption: "Heat And Consuption",
      construction: "Construction",
      meters: "m.",
      meters2: "Meters",
      km: "km",
      km2: "Kilometers",
      realEstateNotary: "Real Estate Notary",
      realEstateLawyer: "Real Estate Lawyer",
      propertyManagement: "Property Management",
      propertyResponsiblePerson: "Property Responsible Person",
      ownerProfile: "Owner Profile",
      orderCode: "Order Code",
      orderForOtherCustomer: "Order For Other Customer",
      orderForSpesificCustomer: "Order For Spesific Customer",
      commissionPercentage: "Commission Percentage %",
      commissionAmountWithoutTax: "Commission Amount Without Tax",
      privateNotes: "Keep private notes for the property.",
      notes: "Notes",
      depositDate: "Deposit Date",
      agreementEndDate: "Agreement End Date",
      apartmentForSale: "Apartment for sale",
      processingOfProperty: "Edit of Property",
      requestForCooperation: "Request for Cooperation",
      coopStatusAppr: "Request status (approver)",
      coopStatusRec: "Request status (recipient)",
      description: "Description",
      distances: "Distances",
      actions: "Actions",
      views: "Views",
      stores: "Saves",
      unrecognisedRequests: "Unrecognised Requests",
      forwardByEmail: "Forward By Email",
      presentation: "Presentation",
      assignmentInformation: "Assignment Information",
      propertyConsultant: "Property Consultant",
      owner: "Owner",
      buyer: "Buyer",
      byRecommendation: "By Recommendation",
      newdealDeluxe: "Newdeal Deluxe",
      statusAdvancePayment: "Status Advance Payment",
      TypeOfAssignment: "Type of assignment",
      listingDate: "Listing Date",
      expirationDate: "Expiration Date",
      lastEdited: "Last Edited",
      keywords: "Keywords",
      keys: "Keys",
      bannerAtTheEntrance: "Banner (At The Entrance)",
      bannerOnStreets: "Banner (On Streets)",
      assignmentCode: "Assignment Code",
      adStatus: "Ad Status",
      propTo: "for",
      financialData: "Financial Data",
      newdealNetwork: "Newdeal Network",
      salePrice: "Sale Price",
      rentPrice: "Rent price",
      considerationPrice: "Consideration price",
      objectiveValue: "Objective Value",
      valuationAgent: "Valuation Agent",
      entryInProvider: "Entry In Provider",
      entryEstateInProvider: "Entry Estate In Provider",
      priceSqM: "Price / sq.m.",
      estimatedRent: "Estimated Rent",
      investmentLeased: "Investment/Leased",
      dateOfAdvancePayment: "Date Of Advance Payment",
      advancePaymentAmount: "Advance Payment Amount",

      endOfLease: "End Of Lease",
      monthlyUtilities: "Monthly Utilities",
      annualTaxENFIA: "Annual Tax (ENFIA)",
      annualReturn: "Annual Return",
      annualIncomeBeforeTax: "Annual Income Before Tax",
      amountOrPercentageOfMortgage: "Amount Or Percentage Of Mortgage",
      submissionOfEntryToProvider: "Submission Of Entry To Provider",
      intrestVip1: "I am interested in",
      intrestVip2: "VIP status",
      intrestVip3: "on the site",
      intrestVip4: "The",
      intrestVip5: "application is pending",
      intrestVip6: "application is canceled",
      sendTo: "Send To",
      areasqm: "Area",
      balconies: "Balconies",
      plotAreasqm: "Plot area",
      fromManufacturers: "From manufacturers",

      kitchens: "Kitchens",
      yearOfRenovation: "Year Of Renovation",
      extraSpaces: "Extra Spaces",
      swimmingPool: "Swimming Pool",
      otherFeatures: "Other Features",
      exterior: "Exterior",
      zone: "Zone",

      priceHistory: "Price History",
      dateOf: "Date Of",
      date: "Date",
      event: "Event",
      theLocationOfTheProperty: "The location of the property",
      country: "Country",
      region: "Region",
      addressNumber: "Address & Number",
      addressNumber2: "Address & Number",
      postalCode: "Postal Code",
      ViewInternationalSchools: "View International Schools",
      documentsMedia: "Documents / Media",
      docMediaSubMsg: "Add or edit property documents",
      documentType: "Document type",
      edit: "Edit",
      addNewDocuments: "Add new files",
      addFilesTextP1: "Drag and drop your files or",
      addFilesTextP2: "browse through your device",
      addFilesTextP3: "to add files manually",
      allFiles: "All Files",
      legalTasks: "Commandments",
      estateDocs: "Property Documents",
      marketing: "Promotional Material",
      visibleOnlyToYou: "Visible Only To You",
      matchingDeals: "Matching Deals",
      showcaseTaskDocuments: "Suggestions",
      offers: "Offers",
      reviews: "Reviews",
      viewEditOrAddReviewsThatThePropertyHasReceivedFromInterestedParties:
        "View Edit Or Add Reviews That The Property Has Received From Interested Parties",
      viewCustomerActions: "View Customer Actions",
      comment: "Comment",
      comments: "Comments",
      martketResearch: "Market Research",
      basic: "Basic",
      record: "Record",
      hidden: "Hidden",
      newDealType: "New Deal",
      commandType: "Advertisement type",
      orderType: "Order Type",
      draft: "Draft",
      basicInfo: "Basic Info",
      or: "Or",
      condition: "Condition",
      perfectCondition: "Perfect condition",
      goodCondition: "Good condition",
      underConstruction: "Under construction",
      selectRefferal: "Select Refferal",
      recommendation: "Recommendation",
      adInternet: "Ad / Internet",
      poster: "Poster",
      newsletter: "Newsletter",
      brand: "Brand",
      viewAll: "View All",
      JanuaryShort: "Jan",
      FebruaryShort: "Feb",
      MarchShort: "Mar",
      AprilShort: "Apr",
      MayShort: "May",
      JuneShort: "Jun",
      JulyShort: "Jul",
      AugustShort: "Aug",
      SeptemberShort: "Sep",
      OctoberShort: "Oct",
      NovemberShort: "Nov",
      DecemberShort: "Dec",
      seconds: "seconds",
      aMinuteAgo: "a minute ago",
      aMinuteAgo2: "A minute ago",
      inAMinute: "in a minute",
      minutes: "minutes",
      anHourAgo: "an hour ago",
      anHourAgo2: "An hour ago",
      inAnHour: "in an hour",
      days: "days",
      day: "day",
      hours: "hours",
      ago: "ago",
      justNow: "Just now",
      in: "in",
      approve: "Approve",
      reject: "Reject",
      xPhotosUploadedP1: "",
      xPhotosUploadedP2: " photos uploaded | Drag and drop to change the order",
      withWatermark: "With watermark",
      downloadPhotos: "Download photos",
      downloadPhotosWithWatermark: "Download photos with watermark",
      watermarkProcessMsg:
        "The process of creating watermarked photos has started and will be completed within the next few minutes!",
      more: "More",
      less: "Less",
      noFilesFound: "No files found",
      noFilesUploadedYet: "You have not uploaded any files yet",
      update: "Update",
      showHeadrOption: "View columns option",
      showSortOption: "Sort columns option",
      sort: "Sort",
      map: "Map",
      buildingExterior: "Exterior of building",
      created: "Created",
      rent: "rent",
      adNoPhoto: "Ad without photos",
      photoBetterDeal: "Photos help you achieve a better deal.",
      upload: "Upload",
      lawyer: "Lawyer",
      notary: "Notary",
      saySmth: "Say something",
      nearestAbrSchool: "Nearest abroad school",
      add: "Add",
      reviewTopMsg: "View, edit or add ratings received by interested parties",
      atForDate: "at",
      exportAsPdf: "Export as pdf",
      ratingAvg: "Rating average",
      rating: "Rating",
      addNewRating: "Add new rating",
      interestedFrom: "Intrested from",
      chooseDisplayOnUser: "Choose what will be displayed to the user",
      typeAddrNo: "Enter the street and number",
      search: "Search",
      searchMapLoc: "Find a location on the map",
      searchLoc: "Search location",
      searchMapLocMsg:
        "Only if you specify a location on the map (any option) can your ad appear in map search results",
      exactLocation: "Exact Location (pin)",
      selectedAreas: "selected areas",
      areaSelect: "Area selection",
      area: "Area",
      municipality: "Municipality / District",
      neighborhood: "Neighborhood",
      setSecureLevel:
        "Set the security level of the assignment (to which individuals will is visible)",
      generalMarkInfo: "General marketing elements of real estate",
      selectPropSubcat: "Select a property type",
      entrOwnerDets: "Enter Owner details",
      addNewOwner: "Add new owner",
      impOrderEsign: "Import order data from esign.newdeal.gr",
      findEsignCode: "Find code from esign",
      responsiblePartner: "Responsible Partner",
      agent: "Agent",
      startOfOrder: "START OF ORDER",
      endOfOrder: "END OF ORDER",
      contacts: "Contact | Contacts",
      newContact: "Add a new Owner",
      ofOwner: "of Owner",
      ofPotentialCus: "of Potential Customer",
      itsBusOrg: "It is a business / organization",
      busOrgName: "Business/Organization name",
      needMoreCoinsMsg: "Need more coins? Click here.",
      setPeriodTime: "Set a specific time period",
      fromDt: "From",
      toDt: "To",
      action: "Action",
      expires: "expires",
      noBalance: "Insufficient balance",
      publication: "Publication",
      delete: "Delete",
      cancel: "Cancel",
      acceptTermsCoop:
        "I accept the terms of apportionment of brokerage fee as they are described in the forms and in the office regulations that I have signed",
      selectSuggestionToReview: "Selection of suggestion for evaluation",
      intrestedFrom: "Intrested from",
      addCommentQuestion: "Do you want to add a comment?",
      dateTimeNote: "Date and time of Note",
      dateTimeNoteShort: "Date & time of Note",
      dateNote: "Date of Note",
      customer: "Customer",
      coopFrom: "From",
      coopTo: "To",
      contactSoon: "Soon we will contact you",
      smthWrong: "Something went wrong, please try again later",
      air: "Air",
      footerP1: "Displaying",
      footerP2: "to",
      footerP3: "of",
      footerP4: "entries",
      areaSettings: "Settings area",
      language: "Language",
      currency: "Currency",
      surfaceMeasurementUnit: "Surface Measurement Unit",
      distanceMeasurementUnit: "Distance Measurement Unit",
      euro: "Euro",
      usDollar: "US Dollar",
      britishPound: "British Pound Sterling",
      squareMeters: "Square Meters",
      squareFeet: "sqm",
      moreFilters: "More Filters",
      from2: "From",
      requestPost: "Request Post",
      approvalStatus: "APPROVAL STATUS",
      approved: "APPROVED",
      rejected: "REJECTED",
      requestStatus: " REQUEST STATUS",
      expired: "Expired",
      deleted: "Deleted",
      noData: "No Data Avaliable",
      recommendations: "Recommendations",
      assignments: "Assignments",
      collaborations: "Collaborations",
      deals: "Deals",
      to2: "To",
      requests: "Requests",

      myCalls: "My calls",
      myNotifications: "My notifications",
      generalSettings: "General Settings",

      realVoiceSettings: "Realtor Voice settings",
      informationDeal: " INFORMATION DEAL",
      socialMedia: "Social Media",
      validEmail: "Email must be valid",
      noMatchPassword: "Does not match password",

      areYouSure: "Are you sure?",
      deleteNoRecovered: "Once deleted, it cannot be recovered.",
      additionalSpaces: "Additional Spaces",
      DataAndFeatures: "Data and features",
      type: "Type",
      sqft2: "Square Feet",
      sqft3: "Sq. Ft.",
      sqft4: "S.F.",
      yearOfInnovation: "Year of Renovation",
      imSure: "Yes, im sure",
      superMarket: "Super Market",
      suburbanRailway: "Suburban Railway",
      virtualTour: "Present your properties with a virtual tour",
      virtualCreate:
        " Create a virtual property tour consisting of 360 ° panoramic photos, in a simple and fast way. Offered to your customers a realistic view of the property that can and to replace the visit.",
      learnMore: "Learn more",
      continue: "Continue",
      utilizableLand: "Utilizable Land",
      monuments: "Monuments",
      onMainRoad: "On a main road",
      onSideWalk: "On a sidewalk",
      noRatings: "It has no ratings yet",
      apartmentSale: "For apartment for sale",

      suggestPrice: "It will suggest price",
      offerPrice: "Offer price",
      evaluationPublic: " Is the evaluation public?",
      interestProperty: "Interest in the property",
      satisfactionPrice: "Satisfaction with the price",
      satisfactionOutsideSpace: "Satisfaction with the outside space",
      satisfactionInsideSpace: "Satisfaction with the inside space",
      overallScore: "Overall average score",
      oralOffer: "Oral Offer",
      writtenOffer: "Written offer",
      reEnterEmail:
        "Enter the email address associated with your account to receive a link to reset your password",

      emailSentSuccess: "Email send successfully",
      emailWillSent:
        "An email will be sent to your address if it is associated with your account.",
      emailResetLink: "The email includes a reset link password.",
      emailConfirm:
        "Please confirm that the email you enter is right, and don't forget to check the Spams.",
      emailNoReceive: "Didn't receive an email?",
      emailResend: "Re-send.",
      accountCreated: "Your account has been created!",
      activateAccount:
        "One of our partners will contact you about activate your account",
      ListOfLocations: "List of locations",
      deleteCommentSure: "Are you sure you want to delete the comment",
      expiresInTwentyFour: "Expires in 24 hours",
      internalUse:
        "This information is NOT displayed on the websites and is for internal use only",
      banner: "Banner",
      moreSelected: "more",
      selectzone: "Select Zone",
      readAccept: "I have read and accept them",
      termsOfUse: "terms of use",
      andRegister: "and",
      privacyPolicy: "privacy policy",
      layout: "Layout",
      titles: "Titles",
      privateAgreement: "Private Agreement",
      sellerTaxAwarenessDocument: "Tax awareness of the seller",
      efkaSellerInsuranceAwareness: "Insurance awarenes EFKA",
      enfiaCertificates: "ENFIA certificates from the seller",
      engineerCertificate: "Engineer's Certificate",
      energyClassCertificate: "Energy Performance Certificate",
      propertyFee: "Real Estate Tax",
      topographic: "Topographic",
      personalDocuments: "Personal data documents",
      leaflets: "Leaflets",
      presentations: "Presentations",
      PriceUpTo: "Price up to",
      interestedParty: "Full name interested party",
      correspondence: "Correspondence",
      interested: "Interested",
      dateΟfAppointment: "Date of Appointment",
      offer: "Offer",
      evaluation: "Evaluation",
      choices: "Choices",
      partnerOffice: "Partner/Office",
      dateRequest: "Date Request",
      miles: "Miles",
      kilometersMeters: "Kilometers / Meters",
      privateClass: "Private class",
      offerFrom: "Offer from",
      offerDate: "Offer date",
      requestDate: "Date of Request",
      acceptanceDate: "Date of Acceptance",
      expirationDate2: "Expiration Date",
      interestInTheProperty: "Interest in the property",
      satisfactionWithThePrice: "Satisfaction with the price",
      satisfactionWithTheOutdoorSpace: "Satisfaction with the outdoor space",
      satisfactionWithTheInteriorSpace: "Satisfaction with interior space",
      counterOfferPrice: "Counter offer price",
      counterOfferDate: "Counter offer date",
      select: "Select",
      noSelect: "None selected",

      scheduled: "Scheduled",
      suggestion: "Suggestion",
      call: "Call",
      hide: "Hide",
      cooperations: "Cooperations",
      satisfiedService: "I was satisfied with the service",
      associateMyNeeds: "The NewDeal associate met my needs",
      associateKnowledge:
        "The NewDeal associate had the knowledge to meet my needs",
      associateHonesty: "I was satisfied with the associate's honesty",
      associateMarketingProcess:
        "My associate explained the New Deal marketing process",
      associatePresentation:
        "I felt that I was properly informed by the associate's presentation",
      associateRecommend:
        "I plan to recommend NewDeal associate to anyone who needs their services",
      at: "at",
      latestSales: "Latest Sales",
      oldestSales: "Oldest Sales",
      /* Contacts */
      myCustomers: "My customers",
      sellerTaxAwarenessDocumentHover:
        "Tax Awareness of the seller for <br /> transfer from the income tax office",
      efkaSellerInsuranceAwarenessHover:
        "EFKA insurance information of the seller for the transfer <br /> of buildings after submitting a relevant application to the competent <br /> IKA Branch of the area of ​​the property being sold.",
      propertyFeeHover:
        "Certificate from the Municipality for Real Estate Duty (TAP).",
      personalDocumentsHover:
        "Documents of personal details / accounts /<br /> joint use / lease / house address",
      propertyDocuments: "Property Documents",
      villa: "Villa",
      preserved: "Preserved",
      informationProfessional: "Professional Information",
      address: "Address",
      mobile2: "Mobile",
      collaboratorCode: "Collaborator Code",
      websites: "Websites",
      languages: "Languages",
      memberfrom: "Member From",
      awards: "Awards",
      studies: "Studies",
      activities: "Activities",
      noDataAvailable: "No Data Available",
      reviewsReceived: "Received reviews",
      reviewsPosted: "Posted reviews",
      mostRecent: "Most recent",
      ratingHighestToLowest: "Rating highest to lowest",
      ratingLowestToHighest: "Rating lowest to highest",
      dateOfSale: "Date of Sale",
      months: "months",
      years: "years",
      viewTeamRatings: "View Team Ratings",
      viewAgentRatings: "View Agent Ratings",
      sortAscending: "Sort Ascending",
      sortDescending: "Sort Descending",

      lessPersons: "Less persons",
      morePersons: "More Persons",
      lessInfo: "Less info",
      moreInfo: "More info",
      lessCharacteristics: "Less characteristics",
      moreCharacteristics: "More characteristics",
      lessDistances: "Less distances",
      moreDistances: "More distances",
      viewGroupRealEstates: "View Group Real Estates",
      viewAgentRealEstates: "View Agent Real Estates",
      forSale: "For Sale",
      forRent: "For Rent",
      characteristics: "Characteristics",
      partnerStatus: "Partner Status",
      briefCurriculumVitae: "Brief Curriculum Vitae",
      partnerDocuments: "Partner Documents",
      moreFrequentCommunication: "More Frequent Communication",
      myNetwork: "My Network",
      panoHintText:
        "Banner posting ensures 100% commission. Upload it here as proof.",
      service: "Service",
      plan: "Plan",
      submitted: "Submitted",
      renewal: "Renewal",
      payment: "Payment",
      changePlan: "Change Plan",
      addComment: "Do you want to add a comment?",
      createJointAccount: "Create a joint account",
      brokerFeeAccept:
        "I accept the broker fee sharing terms as described in the forms and office rules I have signed",
      requestSubmitted: "Your request has been submitted!",
      receiveActivation: "You will receive an activation notification shortly.",
      partnershipRequest: "Partnership Request",
      collaboratorCodeRealEstate:
        "The collaborator code is the unique 3-digit code that will appear in front of each real estate listing.",
      newDemandRegistration: "New Demand Registration",
      editDemandRegistration: "Edit Demand Registration",
      addNew: "Add New",
      assigned: "Assigned",
      assign: "Assign",
      requestStatus2: "Request Status",
      requestDate2: "Request Date",
      nextFollowUp: "Next Follow-Up",
      requestOriginated: "Request originated from",
      commentsInterested:
        "Optional comments about the source of the interested",
      describeDemand: "Please describe the demand",
      budget: "Budget",
      toDt2: "to",
      requestedRealEstate: "Real Estate he requested himself",
      enterRealEstateCode: "Enter real estate code",
      originData: "Data Origin",
      requestCameFrom: "The request came from",
      optionalComments: "Optional comments about the source of the interested",
      realEstateFeatures: "Real Estate Features",
      demandDescription: "Please provide a description of the demand",
      sqm: "sq.m.",
      otherFeatures2: "Other Features",
      yearFrom: "Year from",
      yearTo: "Year to",
      contact: "Contact",
      negotiation: "Negotiation",
      withdrawal: "Withdrawal",
      inert: "Inert",
      newInterestedContact: "Add a new Interested contact",
      addDemand: "Add Demand",
      youHave: "you have",
      youHave2: "You have",
      newRequests: "new requests",
      facade2: "Facade",
      build: "Building",
      acres: "Acres",
      acres2: "Acres",
      responsible: "Responsible",
      companyDoc: "Company Document",
      newActivity: "New Activity",
      suggestionRegistration: "Καταχώρηση Υπόδειξης",
      suggestionDocument: "Suggestion Document",
      esignCode: "esign Code",
      nameInterestedParty: "Νame interested party",
      suggestionStatus: "Suggestion Status",
      scheduled2: "Scheduled",
      cancelled: "Cancelled",
      completed: "Completed",
      demands: "Demands",
      expired2: "Expired",
      expiresIn: "Expires in",
      weeks: "weeks",
      address2: "Address 2",
      demand: "Demand",
      assignment: "Assignment",
      appointment: "Appointment",
      newCallRegistration: "New Call Registration",
      callFrom: "Call from",
      quickSearch: "Quick search",
      assignmentPartner: "Assignment Partner",
      quickPartnerSearch: "Quick partner search",
      availableForTable2: "Available for",
      priceSqft: "Price / Square Feet",
      renovation: "Renovation",
      easy: "Easy",
      normal: "Normal",
      urgent: "Urgent",
      callΙmportance: "Call importance",
      interested2: "Interested ",
      still: "still",
      relatedRequest: "Related Request",
      suggestionDocument2: "Suggestion Document",
      dateAndTime: "Date & Time",
      submittedFrom: "Submitted from",
      dateOfCreation: "Date of Creation",
      lastEditedDate: "Last Edited Date",
      addContact: "Add contact",
      contactType: "Contact type",
      companyBrokers: "Company Brokers",
      accountInformation: "Account information",
      curriculumVitae: "Curriculum Vitae",
      appearsToYou: "it only appears to you",
      companyTeamsAgents: "The company's teams and agents",
      contactInformation: "Contact information",
      addContactFrom: "Add contact from",
      accountInCrm: "Account in CRM",
      otherCompanyBrokers: "Other Company Brokers",
      secretariat: "Secretariat",
      search2: "Search",
      demandAssignment: "Demand Assignment",
      demandHandlePartner: "Select a partner who can handle the Demand",
      confirmation: "Confirmation",
      interesting: "Interesting",
      buy: "Buy",
      demandInformation: "You can ask the interested party for more information. Register your demand.      ",
      inactiveDemand: "The Request has been set to inactive because it has been too long to receive an update (3 months no contact). Ask the customer if they have changed their mind about the property they are interested in.",
      demandOriginated: "Where did the demand originated?",
      source: "Source",
      direct: "Direct",
      directSource: "Direct Source",
      addedBy: "Added by",
      descriptionSource: "Source Description",
      details: "Details",
      contactPerson: "Contact person",
      modified: "Modified",
      demandCode: "Demand Code",
      financialCriteria: "Financial criteria",
      priceFrom: "Price from",
      priceTo: "Price to",
      interestedEvaluation: "Interested party Evaluation",
      enterPrice: "Enter price",
      demandEvaluation: "Demand Evaluation",
      moreQuestions: "More Questions",
      demandRegistration: "Demand Registration",
      lessQuestions: "Less Questions",
      createNewOffer: "Create new offer",
      choiceSuggestions: "Choice real estate suggestions",
      realEstateCodeSearch: "Search Real Estate by code",
      offerStatus: "Offer status",
      typeOfOffer: "Type of offer",
      offerDate2: "Offer Date",
      ownerCounterproposal: "Is there a counterproposal from the owner?",
      counterproposalPrice: "Counterproposal Price",
      counterproposalDate: "Counterproposal Date",
      interestedParties: "Ιnterested Parties",
      customerSearch: "Customer Search",
      responsibleSearch: "Responsible Person search",
      demandType: "Demand Type",
      dateForm: "DD | MM | YYYY",
      proposedRealEstate: "Proposed Real Estate Code",
      realEstateSpecifications: "Real Estate Specifications",
      realEstateType: "Real Estate Type",
      businessType: "Business Type",
      realEstateLocation: "Real Estate Location",
      demandContactInformation: "Demand Contact Information",
      byEmail: "By email",
      bySms: "By SMS",
      exportAsLink: "Export as LINK",
      correspondinglyRealEstates: "Correspondingly Real Estates",
      furnished2: "Furnished",
      role2: "Role",
      profile: "Profile",
      account: "Account",
      subscriptionsCoins: "Subscriptions & Coins",
      publicInformation: "Public Information",
      uploadYourPhoto: "Upload your photo",
      photoAtLeast: "The photo must be at least",
      message: "Message",
      underground: "Underground",
      backToList: "Back to list",
      createAnotherProperty: "Create another property",
      propertyPublished: "Your property has been published and has a code",
      viewProperty: "View the property",
      floorApartment: "Floor Apartment",
      cooperation: "Cooperation",
      morePlotInfo: "More land plot info",
      dimensions: "Dimensions width/length",
      coverageRatio: "Coverage ratio",
      structureFactor: "Structure factor",
      benefits: "Allowances",
      loadUserCoins: "Load user coins",
      reasonCoins: "Title (reason) for adding coins",
      accountInfoSecur: "Account Information & Security",
      partnerStatus2: "Partner Status",
      oldPassword: "Old Password",
      confPass2: "Confirmation Password",
      passwordChange: "Save Password Change",
      loadCoins: "Load Coins",
      loadManageCoins: "Load, history and manage your coins.",
      myCoins: "My coins",
      availableCoins: "Available coins",
      notSamePasswords: "Passwords do not match",
      position: "Position",
      memberTitle: "Member Title",
      deletedAssociate: "You have just deleted the partner",
      deleteContact: "You are about to delete a contact.",
      deletedContact: "You just delete a contact.",
      passwordChange2: "Password change successful.",
      favoriteContacts: "Favorite contacts",
      cost: "Cost",
      total: "Total",
      buyCoins: "Buy Coins",
      buyOver: "Buy over",
      addOfficeAdminBrokers: "Add Office Admin / Brokers",
      addNewAdmin: "Add new admin",
      organizationArea: "Organization Area",
      organizationWebsite: "Organization Website",
      secretaryEmail: "Secretary Email",
      coinsPublish: "Coins allow you to publish your real estates to various providers.",
      documents: "Documents",
      companyLogo: "Upload your company logo",
      searchContactName: "Search contact name",
      addNewContact: "Add New Contact or Account",
      chooseCategory: "Choose a category",
      selectNetwork: "Select the network",
      companySelection: "Company selection",
      itsAbout: "It's about",
      teamManager: "team manager",
      costumerOwner: "Is the customer an owner or an interested party?",
      stallRole: "Staff role selection",
      createNewContact: "Create new contact",
      undergroundParking: "Underground Parking",
      pilotParking: "Pilot Parking",
      openParking: "Open Parking",
      assignedTo: "Assigned to",
      bathroom: "Bathroom",
      balcony: "Balcony",
      sqftBalcony2: "Balcony Square Feet",
      kitchen: "Kitchen",
      livingRoom: "Living Room",
      active2: "Active",
      inactive2: "Inactive",
      semiActive2: "Semi-Active",
      personalInformation: "Personal information",
      myProfile: "My profile",
      editProfile: "Edit profile",
      myAccount: "My account",
      coinsSubscriptions: "Coins & Subscriptions",
      myEstates: "My estates",
      favorites: "Favorites",
      callManager: "Call Manager",
      callCenter: "Call Center",
      myAgenda: "My Agenda",
      messageCenter: "Message Center",
      notificationCenter: "Notification Center",
      administrative: "Administrative",
      accounts: "Accounts",
      approvingScreen: "Screen for approving or rejecting new users in the system.",
      nothingHere: "There's nothing here! Just us!",
      notRightSearch: "Are you sure you did the right search?",
      serverProblem: "Oh! Problem with the server!",
      pageRefresh: "Refresh the page or else contact us.",
      editFilters: "Edit Filters",
      sendError: "Send Error",
      contactEstates: "Real estates associated with this contact",
      weMatched: "We matched",
      properties: "Property | Properties",
      contactDemands: "Demands related to this contact",
      sentEstates: "Sent Real-Estates",
      curriculumVitae2: "Curriculum Vitae",
      addFile: "Add a file",
      replace: "Replace",
      specialization: "Specialization",
      atLocation: "at location | at locations",
      maxLocations: "Max locations number",
      addedLocation: "Location already added",
      areasMaxSelect: "Select up to three (3) areas in which you are an expert.",
      writeCv: "Write a CV that will help you gain the trust of a potential client.",
      cooperationApproved: "The cooperation request has been approved!",
      availableEstates: "Available Real Estate",
      soldEstates: "Sold Real Estate",
      admin: "Admin",
      associateCandidate: "Associate Candidate",
      recommendedEstate: "Recommended Real Estate",
      communicationWasMade: "Communication Was Made",
      dealOrWithdrawal: "Deal or Withdrawal",
      newAll: "New",
      goodMatch: "Good match",
      noMatch: "No match",
      itHasBeenApproved: "It has been approved",
      notApproved: "Not approved",
      relevantDemands: "Find relevant demands",
      completed2: "Completed",
      demandType2: "Demand Type",
      lastUpdate: "Date of last update",
      priceSqM2: "Price per sq.m.",
      creationDate: "Creation date",
      bedroomsShort: "BDRMS",
      networkRequests: "Requests within my network",
      clientsRequests: "Requests from my clients",
      priceTo2: "Price to",
      officeRequests: "Requests within my office",
      findDemands: "Find easy which requests correspond to your own properties. To see the demands you are interested in, click on the green button and wait for the results to appear.",
      valuationPoints: "Valuation is an easy way to indicate the reliability of a demand. 0 = not a reliable demand, 5 = very reliable demand",
      ago2: "Ago",
      in2: "In",
      demandDelete: "You are about to delete the Demand with code",
      featuresFilters: "Features Filters",
      selectUrbanZone: "Select urban zone",
      demandRealEstate: "Demand with real estate",
      generalDemands: "General Questions",
      searchComment: "Search Comment",
      patnerSearch: "Partner Search",
      january: "January",
      february: "February",
      march: "March",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December",
      customerTelSearch: "Customer search by name or phone number",
      allRelatedProperties: "All related properties",
      myProperties: "My properties",
      evaluationPending: "Evaluation Pending",
      inactivity: "Inactivity",
      outgoingDemand: "Outgoing Demand",
      dateDemand: "Date demand",
      library: "Library",
      noDemandsIntersted:"No demands were found for the interested person.",
      interestedAssessment: "Interested Person Assessment",
      interestedAssessment2: "Interested Person Assessment",
      giftCoins:"coins and get gift even",
      toEachProvider:"to each provider",
      completion: "Completion",
      totalOrder:"The total of your order",
      acceptThem: "I accept them",
      totalAmount: "Total amount",
      terms: "terms",
      ofTheTransaction: "of the transaction",
      universitySchool: "University / School",
      specialty: "Specialty",
      mapPartners: "On the map you can see the partner's area of activity and the properties he has taken over",
      otherOperators: "Other Operators",
      addNewBroker: "Add new broker",
      addNewSecretary: "Add new secretary",
      addNewAgent: "Add new associate (Agent Pro)",
      addNewCustomer: "Add new customer",
      addNewMember: "Add new member",
      addNewIfAssociate: "Add new partner candidate",
      addNewCompany: "Add New Company",
      addNewContact2: "Add new Contact",
      paymentMethod: "Choose payment method",
      creditCard: "Credit Card",
      debitCard: "Debit Card",
      cashCompany: "In cash to the company you belong to",
      depositAccount: "Deposit to account",
      memberInterested: "I am interested in becoming a member of the team",
      agentLeader: "The agent is a Team Leader",
      teamInformation: "Team Information",
      newTag: "Create a new tag",
      savedTags: "Selection of saved tags",
      critics: "Critics",
      sold2: "Sold",
      accept:"Accept",
      commissionBanner: "Hanging a banner ensures 100% commission. Upload it here as proof.",
      orderRegistered: "Your order was registered",
      contactSecretary: "Contact with secretary",
      creditedAccount: "to be paid and credited to your account",
      visitOffice: "Contact your company or visit your office",
      orderCoins: "directly the coins of your order",
      profileBack: "Back to profile",
      systemDate:"Date added to the system from",
      callCode:"Call code",
      typeCall: "type call",
      priority:"priority",
      callType: "call type",
      callRegistration:"call registration",
      deletedContacts: "Only deleted contacts",
      contactAccount: "The contact has a user account",
      contactOwner: "The contact has real estate",
      contactActiveRequests:"Contact has active requests",
      personalNewDeal: "Has a personal newdeal page",
      assignmentTo: "Assignment to",
      notReceiveNews: "Not receiving newsletter",
      agreements:"Agreements",
      myDocuments:"My documents",
      networkChoice: "Network selection",
      all2: "All",
      works: "Works",
      calls: "Calls",
      outgoingRequest: "Outgoing Request",
      allRequests: "All the requests",
      allNetworks: "All networks",
      allTheRoles: "All the roles",
      allMyClients: "All my clients",
      entryEstate: "Entry Estate",
      howContinue: "How would you like to continue",
      contactRegistered: "The contact was registered",
      professionalAcademic: "Professional & Academic Education in Real Estate",
      cooperationBetween: "Cooperation between",
      callsMessages: "Calls, messages, administrative, reports, notification center",
      incomingRequest: "Incoming Request",
      whoWeAre: "Who we are",
      partnerAccount: "Partner account",
      transferPatner:"Transfer to a partner account (company only)",
      adminOrSecretary: "Admin or secretary account",
      transferToSecretary: "Transfer to secretary or admin account",
      allCustomersTranfer: "All customers, owners and properties will be transferred to another user. Then they can be distributed to other partners",
      transferEstate: "Transfer of real estate and customers to another account",
      oneMoreThing: "There's one more thing left",
      tagSearch:"Search by tag",
      profileΙnformation: "Profile information",
      ownerSell: "Owner (Sell)",
      ownerRent: "Owner (rental)",
      ownerConsideration: "Owner (consideration)",
      interestedPartyBuy: "Interested (Buy)",
      interestedPartySell: "Interested (rental)",
      interestedConsideration: "Interested party (consideration)",
      candidatePartners: "Candidate Partners",
      admins: "Admins",
      systemAddDate: "Date of addition to the system",
      addNewPerson: "You want to add a new person",
      person: "Person",
      otherContact: "Other contact",
      demandEdit: "Demand Edit",
      entryDemand: "Entry Demand",
      successfullyRequest: "The Request was successfully registered!",
      evaluationQuest1: "How long have you been looking for real estate?",
      evaluationQuest2: "How many houses have you seen?",
      evaluationQuest3: "By when do you want to own your real estate?",
      evaluationQuest4: "Will you use your funds or would you like to take advantage of a loan amount?",
      evaluationQuest5: "Do you have pre-approval and how much?",
      evaluationQuest6: "Which feature made the most 'click' (impression) in the ad you read?",
      evaluationQuest7: "What feature of the home is non-negotiable for you?",
      evaluationQuest8: "Will you make the decision to buy on your own or will you need someone's opinion?",
      evaluationQuest9: "Is there any reason why you would want that particular area or could you go elsewhere?",
      evaluationQuest10: "Did you like any of the houses you saw?",
      evaluationQuest11: "Why didn't you buy it?",
      evaluationQuest12: "What didn't you like and why?",
      evaluationQuest13: "Is the house you live in yours?",
      evaluationQuest14: "Is there a specific reason for moving?",
      evaluationQuest15: "If we found a property that suits you today, would you buy it?",
      evaluationQuest16: "Does this have to be sold in order to buy?",
      evaluationQuest1_1: "1 to 3 months",
      evaluationQuest1_2: "6 to 12 months",
      evaluationQuest1_3: "More than a year",
      evaluationQuest1_4: "Don't know",
      evaluationQuest2_1: "1 to 20",
      evaluationQuest2_2: "20 to 50",
      evaluationQuest2_3: "More than 50",
      evaluationQuest2_4: "Don't know",
      evaluationQuest3_1: "1 to 3 months",
      evaluationQuest3_2: "6 to 12 months",
      evaluationQuest3_3: "More than a year",
      evaluationQuest3_4: "Don't know",
      evaluationQuest4_1: "My own funds",
      evaluationQuest4_2: "Loan",
      evaluationQuest4_3: "Both",
      evaluationQuest4_4: "Don't know",
      evaluationQuest5_1: "I have pre-approval",
      evaluationQuest5_2: "I do not have pre-approval",
      evaluationQuest5_3: "I will not take a loan",
      evaluationQuest5_4: "Don't know",
      evaluationQuest6_1: "Write us the reason",
      evaluationQuest7_1: "Write us the reason",
      evaluationQuest8_1: "Alone",
      evaluationQuest8_2: "Together with someone/s",
      evaluationQuest8_3: "Don't know",
      evaluationQuest9_1: "Yes there is a reason (specify)",
      evaluationQuest9_2: "I'd go to another",
      evaluationQuest9_3: "Don't know",
      evaluationQuest10_1: "Yes",
      evaluationQuest10_2: "No",
      evaluationQuest10_3: "Don't know",
      evaluationQuest11_1: "It's expensive",
      evaluationQuest11_2: "I don't have a loan",
      evaluationQuest11_3: "I didn't find anything that I like",
      evaluationQuest12_1: "Write us the reason",
      evaluationQuest13_1: "Yes",
      evaluationQuest13_2: "No",
      evaluationQuest13_3: "Don't know",
      evaluationQuest14_1: "Yes",
      evaluationQuest14_2: "No",
      evaluationQuest14_3: "Don't know",
      evaluationQuest14_4: "Write us the reason... (optional)",
      evaluationQuest15_1: "Yes",
      evaluationQuest15_2: "No",
      evaluationQuest15_3: "Don't know",
      evaluationQuest16_1: "Yes",
      evaluationQuest16_2: "No",
      evaluationQuest16_3: "Don't know",
      levelEvaluation: "Level Evaluation",
    },
    gr: {
      username: "Όνομα χρήστη",
      password: "Κωδικός πρόσβασης",
      newPassword: "Νεος κωδικός πρόσβασης",
      confPass: "Επαλήθευση κωδικού πρόσβασης",
      confNewPass: "Επαλήθευση νέου κωδικού πρόσβασης",
      newPasswordTitle: "Ορισμός νέου κωδικού πρόσβασης",
      submit: "Υποβολή",
      save: "Αποθήκευση",
      newPasswordSuccess: "Ο κωδικός σου άλλαξε με επιτυχία",
      backToLogin: "Επιστροφή στην αρχική σελίδα",
      newPasswordSubtitle:
        "Σε συνέχεια της διαδικασίας ανανέωσης του κωδικού πρόσβασης σας στο my.newdeal.gr, παρακαλούμε δώστε το νέο κωδικό που επιθυμείτε.",
      stayConnected: "Να παραμείνω συνδεδεμένος",
      requiredField: "Υποχρεωτικό πεδίο",
      reqFieldsprg:
        "Δεν έχετε συμπληρώσει όλα τα υποχρεωτικά πεδία της καταχώρησης",
      loginWithPhone: "Σύνδεση μέσω τηλεφώνου",
      loginWithGoogle: "Σύνδεση μέσω Google",
      login: "Είσοδος",
      register: "Εγγραφή",
      forgotPass: "Ξέχασες τον κωδικό σου;",
      resetPass: "Επαναφορά κωδικού πρόσβασης",
      to: "στο",
      other: "Άλλο",
      role: "Ιδιότητα",
      addressEmail: "Διευθύνση Email",
      email: "Email",
      accountInfo: "Πληροφοριές Λογαριασμού",
      cusInfo: "Στοιχεία πελάτη",
      profInfo: "Στοιχεία επαγγελματία",
      companyName: "Επωνυμία γραφείου",
      companyName2: "Εταιρεία",
      countryResid: "Χώρα έδρας",
      regions: "Περιοχή / Νομός",
      manicipal: "Δήμος / Συνοικία",
      responsiblePerson: "Όνομα υπεύθυνου",
      propertyResponsiblePerson: "Υπεύθυνος Ακινήτου",
      chamberNo: "Αριθμός εγγραφής επιμελητηρίου",
      firstname: "Όνομα",
      lastname: "Επώνυμο",
      fathersName: "Πατρώνυμο",
      dateOfBirth: " Ημερομηνία Γέννησης",
      nameDay: "Ονομαστική Εορτή",
      vatNumber: "ΑΦΜ",
      idPassp: "Ταυτότητα/Διαβατήριο",
      publFinanceService: "ΔΟΥ",
      profession: "Επάγγελμα",
      contactInfo: "Στοιχεία επικοινωνίας",
      otherContactInfo: "Άλλο πεδίο επικοινωνίας",
      writeMoreContactInfo:
        "Γράψε επιπλέον πληροφορίες που χρειάζεσαι για την επαφή",
      createSysAccount: "Δημιουργία λογαριασμού στο σύστημα",
      contactRights: "Δικαιώματα επαφής",
      contactOrigin: "Προέλευση επαφής",
      chooseOrigin: "Επιλέξτε προέλευση",
      addressInfo: "Στοιχεία διεύθυνσης",
      otherAddressInfo: "Άλλο πεδίο διεύθυνσης",
      morePersonalInfo: "Περισσότερα προσωπικά στοιχεία",
      greece: "Ελλάδα",
      telephone: "Σταθερό τηλέφωνο",
      mobile: "Κινητό τηλέφωνο",
      telCom: "Τηλέφωνο επικοινωνίας",
      otherTel: "Άλλο τηλέφωνο",
      securityCode: "Κωδικός ασφαλείας",
      policy:
        "Δέχομαι να διατηρήσει τα στοιχεία μου το my.newdeal.gr για μελλονική επικοινωνία σε σχέση με νέα ακίνητα που μπορεί να με ενδιαφέρουν",
      back: "Πίσω",
      send: "Αποστολή",
      resend: "Επαναποστολή",
      backToConnect: "Πίσω στην σύνδεση",
      addRealEstate: "Προσθήκη Ακινήτου",
      realEstate: "Ακίνητο | Ακίνητα",
      realEstate2: "Ακίνητο",
      realEstateSubHeader: "Προσθήκη, προβολή & επεξεργασία ακινήτων",
      createRealEstateSubHeader: "Δημιουργία νέου Ακινήτου",
      logout: "Έξοδος",
      exit: "Έξοδος",
      settings: "Ρυθμίσεις",
      availableFor: "ΔΙΑΘΕΣΙΜΟ ΠΡΟΣ",
      availableForTable: "Διαθέσιμο Προς",
      availableFrom: "Διαθέσιμο από",
      all: "Ολα",
      sale: "Πώληση",
      lease: "Ενοικίαση",
      consideration: "Αντιπαροχή",
      allCategories: "Όλες οι κατηγορίες",
      allSubCategories: "ΤΥΠΟΣ ΑΚΙΝΗΤΟΥ",
      propertyCategories: "Κατηγορία Ακινήτου",
      residence: "Κατοικία",
      businessProperty: "Επαγγ. Στέγη",
      land: "Γη",
      businesses: "Επιχειρήσεις",
      others: "Λοιπά",
      subpropertyCategories: "ΤΥΠΟΣ ΑΚΙΝΗΤΟΥ",
      subpropertyCategoriesLabel:
        "Επίλεξε κατηγορία ακινήτου για να εμφανιστούν οι τύποι.",
      features: "Χαρακτηριστικά",
      location: "Τοποθεσία",
      bedrooms: "Υπνοδωμάτια",
      bathrooms: "Μπάνια",
      from: "από..",
      toFilter: "έως..",
      floors: "Όροφος",
      price: "ΤΙΜΗ",
      priceTable: "Τιμή",
      initialPrice: "Αρχική τιμή",
      priceIncrease: "Αύξηση τιμής",
      priceReduction: "Μείωση τιμής",
      priceNegotiable: "Τιμή συζητίσιμη",
      sqft: "ΕΜΒΑΔΟΝ (Τ.Μ.)",
      sqftBalcony: "Εμβαδόν Μπαλκονιών",
      sqftTable: "τ.μ.",
      numberOfPlaces: "Αριθμός Χώρων",
      propertyCode: "Κωδικός Ακινήτου",
      orderCode: "Κωδικός εντολής",
      apartment: "Διαμέρισμα",
      office: "Γραφείο",
      store: "Κατάστημα",
      storageArea: "Αποθηκευτικός Χώρος",
      industrialSite: "Βιομηχανικός Χώρος",
      hotel: "Ξενοδοχείο",
      craftArea: "Βιοτεχνικός Χώρος",
      warehouse: "Αποθήκη",
      professionalBuilding: "Επαγγελματικό Κτίριο",
      building: "Κτίριο",
      professionalSpace: "Επαγγελματικός Χώρος",
      room: "Αίθουσα",
      site: "Χώρος",
      house: "Μονοκατοικία",
      maisonette: "Μεζονέτα",
      blockOfFlats: "Κτίριο-Πολυκατοικία",
      villaWithGarden: "Βίλα με κήπο",
      plot: "Οικόπεδο",
      parcel: "Αγροτεμάχιο",
      field: "Έκταση",
      otherField: "Άλλο πεδίο",
      restaurant: "Εστιατόρια",
      hotelsPension: "Ξενοδοχεία - Πανσίον",
      miniMarket: "Mini Market",
      cafe: "Καφετέριες - Αναψυκτήρια",
      tavern: "Ταβέρνες - Ψητοπωλεία",
      bakeryPastry: "Αρτοποιεία - Ζαχαροπλαστεία",
      ouzeri: "Μεζεδοπωλεία - Ουζερί",
      privateSchool: "Εκπαιδευτήρια",
      barsPubsDanceHouse: "Bars - Κέντρα Διασκέδασης",
      supermarket: "Καταστήματα Τροφίμων-Ποτών",
      clothingStore: "Καταστήματα Ενδυμάτων",
      serviceCompany: "Επιχειρήσεις Υπηρεσίων",
      gyms: "Γυμναστήρια",
      drugStores: "Φαρμακεία - Ειδικά Κέντρα",
      parking: "Παρκιν Οχημάτων - Σκαφών",
      shoeStore: "Υποδημάτων - Αξεσουάρ",
      technicalFeaturesAndInterior: "Τεχνικά γνωρίσματα & εσωτερικό",
      frames: "Κουφώματα",
      floorTypes: "Τύπος Δαπέδων",
      electricFrames: "Ηλεκτρικά Κουφώματα",
      bright: "Φωτεινό",
      painted: "Βαμμένο",
      electricalEquipment: "Ηλεκτρικός Εξοπλισμός",
      satelliteAntenna: "Δορυφορική Κεραία",
      luxurious: "Πολυτελές",
      windowNets: "Σίτες",
      allowingPets: "Κατοικίδια Ευπρόσδεκτα",
      internalStaircase: "Εσωτερική Σκάλα",
      elevator: "Ασανσέρ",
      safety: "ΑΣΦΆΛΕΙΑ",
      safetyDoor: "Πόρτα Ασφαλείας",
      cameras: "Κάμερα Ασφαλείας",
      safetySystem: "Σύστημα Ασφάλειας",
      alarm: "Συναργερμός",
      cctv: "Θυροτηλεόραση",
      moreFeatures: "Περισσότερα Χαρακτηριστικά",
      attic: "Σοφίτα",
      atticSqft: "Εμβαδόν σοφίτας",
      serviceRoom: "Δωμάτιο Υπηρεσίας",
      serviceRoomSqft: "Εμβαδόν υπηρεσίας",
      privateTerrace: "Ιδιόκτητη Ταράτσα",
      privateTerraceSqft: "Εμβαδόν Ταράτσας",
      playroom: "Playroom",
      playroomSqft: "Εμβαδόν Playroom",
      heating: "ΘΈΡΜΑΝΣΗ",
      independentHeating: "Αυτόνομη Θέρμανση",
      acUnit: "Κλιματισμός",
      solarBoiler: "Ηλιακός Θερμοσίφωνας",
      fireplace: "Τζάκι",
      liquidGas: "Υγραέριο",
      heater: "Σόμπα",
      centralHeating: "Κεντρική Θέρμανση",
      underfloorHeating: "Υποδαπέδια Θέρμανση",
      naturalGas: "Φυσικό Αέριο",
      oil: "Πετρέλαιο",
      electricity: "Ρεύμα",
      heatAccumulator: "Θερμοσυσσωρευτής",
      propertyType: "Είδος Ακινήτου",
      otherFilters: "Λοιπά Φίλτρα",
      filters: "Φίλτρα",
      status: "Κατάσταση",
      kind: "Είδος",
      inkHouse: "Πέτρινο",
      toMeOnly: "Μόνο σε μένα",
      network: "Δίκτυο",
      public: "Δημόσια",
      yes: "Ναι",
      no: "Όχι",
      newlyBuilt: "Νεόδμητο",
      unfinished: "Ημιτελές",
      preservable: "Διατηρητέο",
      neoclassical: "Νεοκλασικό",
      shortTermRent: "Βραχυχρόνια μίσθωση",
      longTermRent: "Μακροχρόνια μίσθωση",
      notRented: "Όχι (δεν είναι μισθωμένο)",
      renovated: "Ανακαινισμένο",
      renovateYear: "Έτος ανακαίνησης",
      needsToBeRenovated: "Χρήζει ανακαίνισης",
      generalFeatures: "ΓΕΝΙΚΑ ΧΑΡΑΚΤΗΡΙΣΤΙΚΑ",
      view: "Θέα",
      pool: "Πισίνα",
      poolSqft: "Εμβαδόν Πισίνας",
      warehouseSqft: "Εμβαδόν αποθήκης",
      garden: "Κήπος",
      gardenSqft: "Eμβαδόν Κύπου",
      furnished: "Επιπλωμένο",
      vechParking: "Πάρκινγκ",
      vechParkingCount: "Αριθμός θέσεων πάρκινγκ",
      published: "Δημοσιευμένο",
      notPublished: "Μη Δημοσιευμένο",
      publish: "Δημοσίευση",
      newdealGr: "newdeal.gr",
      spiti360Gr: "spiti360.gr",
      spitogatos: "spitogatos",
      xrisiEukairia: "Χρυσή Ευκαιρία",
      toBeApproved: "Προς Έγκριση",
      adsWith: "ΑΓΓΕΛΙΕΣ ΜΕ",
      new: "Νέο",
      editPhoto: "Επεξεργασία φωτογραφίας",
      photoDescr: "Περιγραφή Φωτογραφίας",
      photoDescrGr: "Περιγραφή φωτογραφίας | Ελληνικά",
      photoDescrEn: "Περιγραφή φωτογραφίας | Αγγλικά",
      photoDescrPlaceHolder: "Γράψε τι απεικονίζει η φωτογραφία",
      photos: "Φωτογραφίες",
      noPhotos: "Χωρίς Φωτογραφίες",
      video: "Βίντεο",
      videoCode: "κωδικός βίντεο",
      pasteYoutubeLink:
        "Επικόλλησε τη YouTube διεύθυνση από το βίντεο του ακινήτου.",
      photo360: "Φωτογραφία 360",
      manufacturers: "Κατασκευαστές",
      reducedPrice: "Μειωμένη Τιμή",
      outdoorAreasAndPropertyLocation: "Outdoor Areas & Property Location",
      veranda: "Βεράντα",
      awnings: "Τέντες",
      independentEntrance: "Ανεξάρτητη Είσοδος",
      disabledAccess: "Πρόσβαση για ΑμεΑ",
      bbq: "BBQ",
      suitableFor: "Κατάλληλο για",
      student: "Φοιτητικό",
      holidayHome: "Εξοχικό",
      professionalUse: "Επαγγελματική χρήση",
      investmentAirbnb: "Επενδυτικό / Airbnb",
      energyClass: "ΕΝΕΡΓΕΙΑΚΗ ΚΛΑΣΗ",
      valuation: "ΑΞΙΟΛΟΓΗΣΗ",
      valuationF: "Αξιολόγηση",
      renewalDate: "ΗΜ/ΝΙΑ ΑΝΑΝΕΩΣΗΣ",
      renewalDateF: "Ημερομηνία Ανανέωσης",
      registrationDateProviders: "ΗΜ/ΝΙΑ ΚΑΤΑΧΩΡΗΣΗΣ (σε providers)",
      registrationDateProvidersF: "Ημ/νία Καταχώρησης (σε providers)",
      registrationDate: "ΗΜ/ΝΙΑ ΚΑΤΑΧΩΡΗΣΗΣ",
      registrationDateF: "Ημ/νία Καταχώρησης",
      clear: "Καθαρισμός πεδίων",
      telephoneFilter: "Τηλέφωνο",
      createRealEstate: "Δημιουργία καταχώρησης",
      editNonPublishedProperty: "Επεξεργασία Μη δημοσιευμένου Ακινήτου",
      floor: "Όροφος",
      f5basement: "-5ος",
      f4basement: "-4ος",
      f3basement: "-3ος",
      f2basement: "-2ος",
      basement: "Υπόγειο",
      semiBasement: "Ημιυπόγειο",
      groundFloor: "Ισόγειο",
      mezzanineFloor: "Ημιόροφος",
      higherGroundFloor: "Υπερυψωμένο Ισόγειο",
      f1th: "1ος",
      f2th: "2ος",
      f3th: "3ος",
      f4th: "4ος",
      f5th: "5ος",
      f6th: "6ος",
      f7th: "7ος",
      f8th: "8ος",
      f9th: "9ος",
      f10th: "10ος",
      f11th: "11ος",
      f12th: "12ος",
      f13th: "13ος",
      f14th: "14ος",
      f15th: "15ος",
      f16th: "16ος",
      f17th: "17ος",
      f18th: "18ος",
      f19th: "19ος",
      f20th: "20ος",
      prefabrication: "Προκατασκευή",
      mobileHome: "Λυόμενο",
      studio: "Studioς",
      villa: "Βίλα",
      traditional: "Παραδοσιακό",
      loft: "Loft",
      preserved: "Διατηρητέο",

      bungalow: "Bungalow",
      farm: "Φάρμα",
      residenceComplex: "Συγκρότημα Κατοικιών",
      availability: "Διαθεσιμότητα",
      leased: "Μισθωμένο",
      rent: "Ενοικιάζεται",
      free: "Ελεύθερο",
      inhabited: "Κατοικείται",
      chooseEnergyClass: "Επιλέξε Ενεργειακή Κλάση",
      energyClassDescr: "Ενεργειακή Κλάση",
      APlus: "Α+",
      A: "A",
      BPlus: "B+",
      G: "Γ",
      D: "Δ",
      E: "E",
      Z: "Ζ",
      H: "Η",

      noEnergyPerformance:
        "Δεν απαιτείται Πιστοποιητικό Ενεργειακής Απόδοσης για το συγκεκριμένο ακίνητο",
      issueOfenergy: "Έκδοση Πιστοποιητικού Ενεργειακής Απόδοσης σε εξέλιξη",
      selectBoxes: "Επιλέξε Κουφώματα",
      doubleGlazingBars: "Διπλά Τζάμια",
      woodenFrames: "Ξύλινα Κουφώματα",
      syntheticFrames: "Συνθετικά Κουφώματα",
      eletricFrames: "Ηλεκτρικά Κουφώματα",
      aluminiumFrames: "Κουφώματα αλουμινίου",
      slidingRolls: "Συρόμενα Ρολά",
      sliding: "Συρόμενα",
      rolls: "Ρολά",
      screens: "Σίτες",
      ground: "Δάπεδο",
      marble: "Μάρμαρο",
      wood: "Ξύλο",
      stone: "Πέτρα",
      tile: "Πλακάκι",
      cement: "Τσιμέντο",
      mosaic: "Μωσαϊκό",
      marbleWood: "Μάρμαρο - Ξύλο",
      marbleTile: "Μάρμαρο - Πλακάκι",
      stoneWood: "Πέτρα - Ξύλο",
      stonemarble: "Πέτρα - Μάρμαρο",
      tileWood: "Πλακάκι - Ξύλο",
      mosaicWood: "Μωσαϊκό - Ξύλο",
      industrialFlooring: "Βιομηχανικό Δάπεδο",
      lamite: "Laminate",
      chooseAView: "Επιλέξτε Θέα",
      city: "Πόλη",
      mountain: "Βουνό",
      acropoliMonuments: "Ακρόπολη / Μνημεία",
      lake: "Λίμνη",
      sea: "Θάλασσα",
      river: "Ποτάμι",
      park: "Πάρκο",
      selectzone: "Επιλέξτε Ζώνη",
      residentialZone: "Οικιστική ζώνη",
      commericalZone: "Εμπορική ζώνη",
      ruralZone: "Αγροτική ζώνη",
      industrialZone: "Βιομηχανική ζώνη",
      regenerationZone: "Ζώνη ανάπλασης",
      offPlan: "Εκτός σχεδίου",
      levels: "Επίπεδα",
      selectPosition: "Επιλέξτε Θέση",
      corner: "Γωνιακό",
      eastWest: "Ανατολικοδυτικός",
      interior: "Εσωτερικό",
      facade: "Προσόψεως",
      penthouse: "Ρετιρέ",
      through: "Διαμπερές",
      onMainStreet: "Σε Κεντρικό Δρόμο",
      onPedestrianStreet: "Σε Πεζόδρομο",
      orientation: "Προσανατολισμός",
      eastern: "Ανατολικός",
      easternMeridian: "Ανατολικομεσημβρινός",
      north: "Βόρειος",
      northEast: "Βορειοανατολικός",
      northWest: "Βορειοδυτικός",
      west: "Δυτικός",
      westMeridian: "Δυτικομεσημβρινός",
      meridian: "Μεσημβρινός",
      south: "Νότιος",
      southEast: "Νοτιοανατολικός",
      southWest: "Νοτιοδυτικός",
      typeOfParking: "Τύπος Πάρκινγκ",
      closed: "Κλειστό",
      pilot: "Πιλοτής",
      open: "Ανοιχτό",
      typeOfMandate: "Είδος Εντολής",
      simple: "Απλή",
      newDealExclusive: "New Deal Exclusive",
      exclusive: "Αποκλειστικη",
      marketResearch: "Έρευνα αγοράς",
      hiddenAdminOnly: "Κρυφή (Μόνο Για Admin)",
      changeTheStatusOfTheAd: "Αλλάξτε την κατάσταση της Αγγελίας...",
      active: "Ενεργό",
      inactive: "Ανενεργό",
      rented: "Ενοικιάστηκε",
      sold: "Πωλήθηκε",
      underNegotiation: "Υπό Διαπραγμάτευση",
      receivedAdvancePayment: "Έλαβε προκαταβολή",
      setAsVipAdminOnly: "Mη δημοσιευμένnο",
      company: "Εταιρεία*",
      newDeal: "newdeal",
      newDealKolonaki: "newdeal deluxe",
      newDealGlyfada: "newdeal Γλυφάδα",
      newDealPiraeus: "newdeal Πειραιάς",
      newDealThessaloniki: "newdeal Θεσσαλονίκη",
      typeOfOrder: "Είδος Εντολής*",
      moreAreas: "Επιπλέον Χώροι",
      livingRooms: "Σαλόνια",
      exteriorPlaces: "Εξωτερ. χώροι & θέση ακινήτου",
      propertyFinancials: "Οικονομικά ακινήτου",
      depositAmount: "Ποσό προκαταβολής",
      monthlyRent: "Μηνιαίο μίσθωμα",
      monthlyFees: "Μηνιαία κοινόχρηστα",
      eurosPerMonth: "€/μήνα",
      yearlyTaxes: "Ετήσιος φόρος (ΕΝΦΙΑ)",
      yearlyReturn: "Ετήσια απόδοση",
      yearlyReturnWithoutTaxes: "Ετήσιο εισόδημα προ φόρων",
      bank: "Τράπεζα",
      mortgage: "Υποθήκη",
      amountPercentage: "Ποσό/Ποσοστό",
      underConstraction: "Υπό Κατασκεύη",
      luxury: "Πολυτελές",
      petsWelcome: "Κατοικίδια Ευπρόσδεκτα",
      securityDoor: "Πόρτα Ασφαλείας",
      securityCamera: "Κάμερα Ασφαλείας",
      securitySystem: "Σύστημα Ασφάλειας",
      alarmSystem: "Συναργεμός",
      terrace: "Βεράντα",
      airbnb: "Airbnb",
      school: "Σχολείο",
      metro: "ΜΕΤΡΟ",
      ΗΣΑΠ: "ΗΣΑΠ",
      suburban: "Προαστικός",
      internationalSchools: "International Schools",
      airport: "Αεροδρόμιο",
      urbanTransport: "Αστικές Συγκοινωνίες",
      square: "Πλατεία",
      hospital: "Νοσoκομείο",
      tram: "Τράμ",
      description: "Περιγραφή",
      inputDescription: "Συμπληρώστε μια περιγραφή για το ακίνητο",
      greek: "Ελληνικά",
      english: "Αγγλικά",
      arabic: "Αραβικά",
      chinesse: "Κινέζικα",
      heatAndConsuption: "Θέρμανση & κατανάλωση",
      construction: "Κατασκευή",
      meters: "μ.",
      km: "χλμ",
      meters2: "Μέτρα",
      km2: "Χιλιόμετρα",
      realEstateNotary: "Συμβολαιογράφος Ακινήτου",
      realEstateLawyer: "Δικηγόρος Ακινήτου",
      propertyManagement: "Διαχείριση ακινήτου",
      ownerProfile: "Προφίλ ιδιοκτήτη",
      orderForOtherCustomer: "Εντολή για πελάτη εξωτερικού",
      orderForSpesificCustomer: "Εντολή για ειδικό πελάτη",
      commissionPercentage: "Ποσοστό προμήθειας %",
      commissionAmountWithoutTax: "Ποσό προμήθειας πλέον ΦΠΑ",
      notes: "Σημειώσεις",
      privateNotes: "Κράτα ιδιωτικές σημειώσεις για το ακίνητο",
      depositDate: "Ημερομηνία προκαταβολής",
      agreementEndDate: "Ημερομηνία λήξης μίσθωσης",
      apartmentForSale: "Διαμέρισμα για πώληση",
      processingOfProperty: "Επεξεργασία Ακινήτου",
      requestForCooperation: "Αίτημα Συνεργασίας",
      coopStatusAppr: "Κατάσταση Αιτήματος (έγκρισης)",
      coopStatusRec: "Κατάσταση Αιτήματος (παραλήπτη)",
      yearOfConstruction: "Έτος Κατασκευής",
      distances: "Αποστάσεις",
      actions: "Ενέργειες",
      views: "Προβολές",
      stores: "Αποθηκεύσεις",
      unrecognisedRequests: "Μη αναγνωσμ αιτήσεις",
      forwardByEmail: "Προώθηση με email",
      presentation: "Παρουσίαση",
      assignmentInformation: "Πληροφορίες ανάθεσης",
      propertyConsultant: "Σύμβουλος Ακινήτου",
      owner: "Ιδιοκτήτης",
      buyer: "Αγοραστής",
      byRecommendation: "Από σύσταση",
      newdealDeluxe: "Newdeal Deluxe",
      statusAdvancePayment: "Κατάσταση Προκαταβολή",
      TypeOfAssignment: "Είδος ανάθεσης",
      listingDate: "Ημερομηνία Listing",
      expirationDate: "Ημερομηνία Λήξης",
      lastEdited: "Τελευταία επεξεργασία",
      keywords: "Κλειδιά",
      keys: "Κλειδιά",
      banner: "Πανό",
      bannerAtTheEntrance: "Banner (στην είσοδο)",
      bannerOnStreets: "Banner (σε δρόμους)",
      assignmentCode: "Κωδικός Ανάθεσης",
      adStatus: "Κατάσταση αγγελίας",
      propTo: "προς",
      financialData: "Οικονομικά στοιχεία",
      newdealNetwork: "Newdeal Network",
      salePrice: "Τιμή πώλησης",
      rentPrice: "Τιμή ενοικίασης",
      considerationPrice: "Τιμή αντιπαροχής",
      objectiveValue: "Αντικειμενική αξία",
      valuationAgent: "Εκτίμηση agent",
      entryInProvider: "Καταχώρηση σε Provider",
      entryEstateInProvider: "Καταχώρηση Ακινήτου σε Provider",
      priceSqM: "Τιμή / τ.μ.",
      estimatedRent: "Εκτιμώμενο ενοίκιο",
      investment: "Επενδυτικό",
      investmentLeased: "Επενδυτικό/μισθωμένο",
      dateOfAdvancePayment: "Ημ/νία Προκαταβολής",
      advancePaymentAmount: "Ποσό Προκαταβολής",
      endOfLease: "Λήξη μίσθωσης",
      monthlyUtilities: "Μηνιαία κοινόχρηστα",
      annualTaxENFIA: "Ετήσιος φόρος (ΕΝΦΙΑ)",
      annualReturn: "Ετήσια απόδοση",
      annualIncomeBeforeTax: "Ετήσιο εισόδημα προ φόρων",
      amountOrPercentageOfMortgage: "Ποσό ή ποσοστό υποθήκης",
      submissionOfEntryToProvider: "Υποβολή καταχώρησης σε Provider",
      intrestVip1: "Ενδιαφέρομαι για",
      intrestVip2: "VIP θέση",
      intrestVip3: "στο site",
      intrestVip4: "Η αίτηση για",
      intrestVip5: "εκρρεμεί",
      intrestVip6: "ακυρώθηκε",
      sendTo: "Αποστολή",
      areasqm: "Εμβαδόν",
      balconies: "Μπαλκόνια",
      plotAreasqm: "Εμβαδόν οικοπέδου",
      fromManufacturers: "Από κατασκευαστές",
      wc: "W/C",
      kitchens: "Κουζίνες",
      yearOfRenovation: "Έτος κατασκευής",
      extraSpaces: "Επιπλέον χώροι",
      swimmingPool: "Πισίνα",
      otherFeatures: "Άλλα χαρακτηριστικά",
      exterior: "Εξωτερικοί χώροι",
      zone: "Ζώνη",
      priceHistory: "Ιστορικό τιμής",
      security: "Ασφάλεια",
      dateOf: "Ημερομηνία",
      date: "Ημερομηνία",
      event: "Γεγονός",
      theLocationOfTheProperty: "Η τοποθεσία του ακινήτου",
      country: "Χώρα",
      region: "Περιοχή",
      addressNumber: "Διεύθυνση & Αριθμός",
      addressNumber2: "Οδός & Αριθμός",
      postalCode: "Τ.Κ.",
      ViewInternationalSchools: "Προβολή International Schools",
      documentsMedia: "Έγγραφα / Media",
      docMediaSubMsg: "Προσθέστε ή επεξεργαστείτε τα έγγραφα του ακινήτου",
      documentType: "Είδος εγγράφου",
      edit: "Επεξεργασία",
      addNewDocuments: "Προσθήκη νέων αρχείων",
      addFilesTextP1: "Σύρετε τα Αρχεία που θέλετε να προσθέσετε εδώ ή",
      addFilesTextP2: "περιηγηθείτε στη συσκευή σας",
      addFilesTextP3: "για να προσθέσετε μη αυτόματα.",
      allFiles: "Όλα τα αρχεία",
      legalTasks: "Εντολές",
      estateDocs: "Έγγραφα ακινήτου",
      marketing: "Διαφημιστικό υλικό",
      visibleOnlyToYou: "Ορατό μόνο σε εσάς",
      matchingDeals: "Matching Deals",
      showcaseTaskDocuments: "Υποδείξεις",
      offers: "Προσφορές",
      reviews: "Αξιολογήσεις",
      viewEditOrAddReviewsThatThePropertyHasReceivedFromInterestedParties:
        "Δείτε, επεξεργαστείτε ή προσθέστε αξιολογήσεις που έλαβε το ακίνητο από ενδιαφερόμενους",
      viewCustomerActions: "Προβολή ενεργιών πελάτη",
      comment: "Σχόλιο",
      comments: "Σχόλια",
      martketResearch: "Έρευνα αγοράς",
      basic: "Απλή",
      record: "Καταγραφή",
      hidden: "Κρυφή (μόνο για admin)",
      newDealType: "New Deal",
      commandType: "Είδος αγγελίας",
      orderType: "Είδος Εντολής",
      draft: "Πρόχειρο",
      basicInfo: "Βασικά στοιχεία",
      or: "Ή",
      condition: "Κατάσταση",
      perfectCondition: "Άριστη κατάσταση",
      goodCondition: "Καλή κατάσταση",
      underConstruction: "Υπό κατασκεύη",
      selectRefferal: "Επιλέξτε προέλευση",
      recommendation: "Σύσταση",
      adInternet: "Αγγελία / Internet",
      poster: "Αφίσα",
      newsletter: "Ενημερωτικό Δελτίο",
      brand: "Brand",
      viewAll: "Προβολή Όλων",
      JanuaryShort: "Ιαν",
      FebruaryShort: "Φεβ",
      MarchShort: "Μαρ",
      AprilShort: "Απρ",
      MayShort: "Μάι",
      JuneShort: "Ιουν",
      JulyShort: "Ιουλ",
      AugustShort: "Αυγ",
      SeptemberShort: "Σεπ",
      OctoberShort: "Οκτ",
      NovemberShort: "Νοε",
      DecemberShort: "Δεκ",
      seconds: "δευτερόλεπτα",
      aMinuteAgo: "πριν 1 λεπτό",
      aMinuteAgo2: "Πριν 1 λεπτό",
      inAMinute: "σε 1 λεπτό",
      minutes: "λεπτά",
      anHourAgo: "πριν 1 ώρα",
      anHourAgo2: "Πριν 1 ώρα",
      inAnHour: "σε μία ώρα",
      days: "ημέρες",
      day: "ημέρα",
      hours: "ώρες",
      ago: "πριν",
      justNow: "Μόλις τώρα",
      in: "σε",
      approve: "Έγκριση",
      reject: "Απόρριψη",
      xPhotosUploadedP1: "Έχουν ανέβει ",
      xPhotosUploadedP2: " φωτογραφίες | Σύρε τις για να αλλάξεις σειρά",
      withWatermark: "Με υδατογράφημα",
      downloadPhotos: "Κατέβασμα φωτογραφιών",
      downloadPhotosWithWatermark: "Κατέβασμα φωτογραφιών με υδατογράφημα",
      watermarkProcessMsg:
        "Έχει ξεκινήσει η διαδικασία δημιουργίας φωτογραφιών με υδατογράφημα η οποία θα ολοκληρωθεί εντος των επόμενων λεπτών!",
      more: "Περισσότερα",
      less: "Λιγότερα",
      noFilesFound: "Δεν βρέθηκαν αρχεία",
      noFilesUploadedYet: "Δεν έχετε ανεβάσει ακόμα κανένα αρχείο",
      update: "Ενημέρωση",
      showHeadrOption: "Επιλογή Εμφάνισης Στηλών",
      showSortOption: "Επιλογή Ταξινόμησης Στηλών",
      sort: "Ταξινόμηση",
      map: "Χάρτης",
      buildingExterior: "Εξωτερική όψη κτιρίου",
      created: "Δημιουργήθηκε",
      adNoPhoto: "Αγγελία χωρίς φωτογραφίες",
      photoBetterDeal: "Οι φωτογραφίες σας βοηθούν να πετύχετε καλύτερο deal.",
      upload: "Ανέβασμα",
      lawyer: "Δικηγόρος",
      notary: "Συμβολαιογράφος",
      saySmth: "Πες κάτι",
      nearestAbrSchool: "Πιο κοντινό σχολείο εξωτερικού",
      add: "Προσθήκη",
      reviewTopMsg:
        "Δείτε, επεξεργαστείτε ή προσθέστε αξιολογήσεις που έλαβε το ακίνητο από ενδιαφερόμενους",
      atForDate: "στις",
      exportAsPdf: "Εξαγωγή ως pdf",
      ratingAvg: "Μέσος όρος αξιολόγησης",
      rating: "Αξιολόγηση",
      addNewRating: "Προσθήκη νέας αξιολόγησης",
      interestedFrom: "Από ενδιαφερόμενο",
      chooseDisplayOnUser: "Επίλεξε τι θα εμφανίζεται στο χρήστη",
      typeAddrNo: "Πληκτρολόγησε την οδό και αριθμό",
      search: "Αναζήτηση",
      searchMapLoc: "Εύρεση τοποθεσίας στο χάρτη",
      searchLoc: "Αναζήτηση τοποθεσίας",
      searchMapLocMsg:
        "Μόνο αν ορίσεις τοποθεσία στο χάρτη (οποιαδήποτε επιλογή), μπορεί η αγγελία σου να εμφανίζεται στα αποτελέσματα των αναζητήσεων μέσω χάρτη",
      exactLocation: "Ακριβής Τοποθεσία (pin)",
      selectedAreas: "επιλεγμένες περιοχές",
      areaSelect: "Επιλογή περιοχής",
      area: "Περιοχή",
      municipality: "Δήμος / Συνοικία",
      neighborhood: "Γειτονιά",
      setSecureLevel:
        "Όρισε το επίπεδο ασφάλειας της ανάθεσης (σε ποια άτομα θα είναι ορατό)",
      generalMarkInfo: "Γενικά στοιχεία marketing ακινήτου",
      selectPropSubcat: "Επιλέξτε τύπο ακινήτου",
      entrOwnerDets: "Εισαγωγή στοιχείων Ιδιοκτήτη",
      addNewOwner: "Προσθήκη νέου ιδιοκτήτη",
      impOrderEsign: "Εισαγωγή δεδομένων εντολής από το esign.newdeal.gr",
      findEsignCode: "Εύρεση κωδικού από το esign",
      responsiblePartner: "Υπεύθυνος Συνεργάτης",
      agent: "Συνεργάτης",
      startOfOrder: "ΕΝΑΡΞΗ ΕΝΤΟΛΗΣ",
      endOfOrder: "ΛΗΞΗ ΕΝΤΟΛΗΣ",
      contacts: "Επαφή | Επαφές",
      newContact: "Προσθήκη μιας νέας επαφής",
      ofOwner: "Ιδιοκτήτη",
      ofPotentialCus: "Υποψήφιου Πελάτη",
      itsBusOrg: "Είναι επιχείρηση / οργανισμός",
      busOrgName: "Όνομα επιχείρησης / οργανισμού",
      needMoreCoinsMsg: "Χρειάζεστε περισσότερα coins; Πατήστε εδώ.",
      setPeriodTime: "Ορισμός συγκεκριμένου χρονικού διαστήματος",
      fromDt: "Από",
      toDt: "Έως",
      action: "Ενέργεια",
      expires: "λήγει",
      noBalance: "Μη επαρκές υπόλοιπο",
      publication: "Δημοσίευση",
      delete: "Διαγραφή",
      cancel: "Ακύρωση",
      acceptTermsCoop:
        "Αποδέχομαι τους όρους επιμερισμού μεσιτικής αμοιβής όπως αυτοί περιγράφονται στα έντυπα και στον κανονισμό γραφείου που έχω υπογράψει",
      addCommentQuestion: "Θέλετε να προσθέσετε κάποιο σχόλιο;",
      selectSuggestionToReview: "Επιλογή υπόδειξης προς αξιολόγηση",
      intrestedFrom: "Από ενδιαφερόμενο",
      dateTimeNote: "Ημερομηνία και ώρα Υπόδειξης",
      dateTimeNoteShort: "Ημερ/νία & Ώρα υπόδειξης",
      dateNote: "Ημερομηνία Υπόδειξης",
      customer: "Πελάτης",
      coopFrom: "Από",
      coopTo: "Προς",
      contactSoon: "Θα επικοινωνήσουμε σύντομα μαζί σου",
      smthWrong: "Κάτι πήγε στραβά, παρακαλώ προσπαθήστε αργότερα",
      air: "Αέρας",
      footerP1: "Προβολή",
      footerP2: "προς",
      footerP3: "από",
      footerP4: "καταχωρήσεις",
      areaSettings: "Ρυθμίσεις περιοχής",
      language: "Γλώσσα",
      currency: "Νόμισμα",
      surfaceMeasurementUnit: "Μονάδα Μέτρησης Επιφάνειας",
      distanceMeasurementUnit: "Μονάδα Μέτρησης Απόστασης",

      euro: "Ευρώ",
      usDollar: "Αμερικάνικο Δολλάριο",
      britishPound: "Λίρα Στερλίνα Βρετανίας",
      squareMeters: "Tετραγωνικά Mέτρα",
      squareFeet: "Tετραγωνικά Πόδια",

      moreFilters: "Περισσότερα Φίλτρα",
      from2: "Απο",
      to2: "Προς",
      requestPost: "Καταχωρηση αιτηματος",
      approvalStatus: "STATUS ΕΓΚΡΙΣΗΣ",
      approved: "ΕΓΚΕΚΡΙΜΕΝΑ",
      rejected: "ΑΠΟΡΡΙΦΘΗΚΑΝ",
      requestStatus: "STATUS ΑΙΤΗΜΑΤΟΣ",
      expired: "Έληξαν",
      deleted: "Διαγραμμένα",
      noData: "Δεν υπάρχουν διαθέσιμα δεδομένα",
      recommendations: "Συστάσεις",
      assignments: "Αναθέσεις",
      collaborations: "Συνεργασίες",
      deals: "Deals",
      requests: "Αιτήματα",
      myCalls: "Οι κλήσεις μου",
      myNotifications: "Οι ειδοποιήσεις μου",
      generalSettings: "Γενικές Ρυθμίσεις",
      realVoiceSettings: "Ρυθμίσεις του Realtor Voice",
      informationDeal: "ΠΛΗΡΟΦΟΡΙΕΣ DEAL",
      socialMedia: "Μέσα κοινωνικής δικτύωσης",
      validEmail: "Το email πρέπει να ειναι έγκυρο",
      noMatchPassword: "Δεν ταιριάζει με τον κωδικό",
      areYouSure: "Είστε σίγουρος/η;",
      deleteNoRecovered: "Μετά τη διαγραφή, δεν μπορεί να επανακτηθεί.",
      additionalSpaces: "Επιπλέον χώροι",
      DataAndFeatures: "Στοιχεία και χαρακτηριστικά",
      type: "Τύπος",
      sqft2: "Εμβαδόν τ.μ.",
      sqft3: "τ.μ.",
      sqft4: "τ.μ.",
      yearOfInnovation: "Έτος ανακαίνησης",
      imSure: "Ναι, είμαι σίγουρος",
      superMarket: "Σούπερ Μάρκετ",
      suburbanRailway: "Προαστιακός",
      virtualTour: "Παρουσίασε τα ακίνητά σου με εικονική περιήγηση",
      virtualCreate:
        "Δημιούργησε μια εικονική περιήγηση ακινήτου που αποτελείται από πανοραμικές φωτογραφίες 360°, με απλό και γρήγορο τρόπο. Πρόσφερε στους πελάτες σου μια ρεαλιστική προβολή του ακινήτου που μπορεί και να αντικαταστήσει την επίσκεψη.",
      learnMore: "Μάθε περισσότερα",
      continue: "Συνέχεια",
      utilizableLand: "Αξιοποιήσιμη Γη",
      monuments: "Μνημεία",
      onMainRoad: "Σε κεντρικό δρόμο",
      onSideWalk: "Σε πεζόδρομο",
      noRatings: "Δεν έχει αξιολογήσεις ακόμα",
      apartmentSale: "Για διαμέρισμα προς πώληση",
      suggestPrice: "Θα αντιπροτείνει τιμή",
      offerPrice: "Τιμή Προσφοράς",
      evaluationPublic: " Η αξιολόγηση είναι δημόσια;",
      interestProperty: "Ενδιαφέρον για το ακίνητο",
      satisfactionPrice: "Ικανοποίηση από την τιμή",
      satisfactionOutsideSpace: "Ικανοποίηση με τον εξωτερικό χώρο",
      satisfactionInsideSpace: "Ικανοποίηση με τον εσωτερικό χώρο",
      overallScore: "Συνολικός μέσος όρος βαθμολογίας",
      oralOffer: "Προφορική Προσφορά",
      writtenOffer: "Γραπτή Προσφορά",
      reEnterEmail:
        "Συμπλήρωσε το email που σχετίζεται με το λογαριασμό σου, για να σου στείλουμε τον σύνδεσμο επαναφοράς",
      emailSentSuccess: "Επιτυχής αποστολή email",
      emailWillSent:
        "Θα σταλεί ένα email στη διεύθυνσή σου, εφόσον αυτή συνδέεται με λογαριασμό χρήστη.",
      emailResetLink: "Το email περιλαμβάνει έναν σύνδεσμο επαναφοράς κωδικού.",
      emailConfirm:
        "Παρακαλούμε επιβεβαίωσε ότι το email που συμπλήρωσες είναι σωστό, και μην ξεχάσεις να ελέγξεις τα Ανεπιθύμητα.",
      emailNoReceive: "Δεν έλαβες email;",
      emailResend: "Αποστολή εκ νέου.",
      accountCreated: "Ο λογαριασμός σας δημιουργήθηκε!",
      activateAccount:
        " Ένας συνεργάτης μας θα επικοινωνήσει μαζί σου για την ενεργοποίηση του λογαριασμού σου",
      ListOfLocations: "Λίστα τοποθεσιών",
      deleteCommentSure: "Είστε σίγουροι οτι θέλετε να διαγράψετε το σχόλιο",
      expiresInTwentyFour: "Λήγει σε 24 ώρες",
      internalUse:
        "Οι συγκεκριμένες πληροφορίες ΔΕΝ εμφανίζονται στις ιστοσελίδες και είναι μόνο για εσωτερική χρήση",
      moreSelected: "άλλα",
      readAccept: "Έχω διαβάσει και αποδέχομαι τους",
      termsOfUse: "όρους χρήσης",
      andRegister: "και την",
      privacyPolicy: "πολυτική απορρήτου",
      layout: "Κάτοψη",
      titles: "Τίτλοι",
      privateAgreement: "Ιδιωτικό Συμφωνητικό",
      sellerTaxAwarenessDocument: "Φορολογική ενημερότητα του πωλητή",
      efkaSellerInsuranceAwareness: "Ασφαλιστική ενημερότητα ΕΦΚΑ",
      enfiaCertificates: "Πιστοποιητικά ΕΝΦΙΑ από τον πωλητή",
      engineerCertificate: "Βεβαίωση Μηχανικού",
      energyClassCertificate: "Πιστοποιητικό Ενεργειακής Απόδοσης",
      propertyFee: "Τέλος Ακινήτου Περιουσίας",
      topographic: "Τοπογραφικό",
      personalDocuments: "Έγγραφα προσωπικών στοιχείων",
      leaflets: "Διαφημιστικά Φυλλάδια",
      presentations: "Παρουσιάσεις",
      PriceUpTo: "Τιμή έως",
      interestedParty: "Ονοματεπώνυμο ενδιαφερόμενου",
      correspondence: "Αντιστοιχία",
      dateΟfAppointment: "ΗΜ/νία Ραντεβού",
      offer: "Προσφορά",
      evaluation: "Αξιολόγηση",
      choices: "Επιλογές",
      partnerOffice: "Συνεργάτης/Γραφείο",
      dateRequest: "ΗΜ/νία Αιτήματος",
      miles: "Μίλια",
      kilometersMeters: "Χιλιόμετρα / Μέτρα",
      privateClass: "Ιδιωτική κατηγορία",
      offerFrom: "Προσφορά από",
      offerDate: "Ημ/νία προσφοράς",
      requestDate: "Ημ/νία Αιτήματος",
      acceptanceDate: "Ημ/νία Αποδοχής",
      expirationDate2: "Ημ/νία Λήξης",
      interestInTheProperty: "Ενδιαφέρον για το ακίνητο",
      satisfactionWithThePrice: "Ικανοποίηση από την τιμή",
      satisfactionWithTheOutdoorSpace: "Ικανοποίηση με τον εξωτερικό χώρο",
      satisfactionWithTheInteriorSpace: "Ικανοποίηση με τον εσωτερικό χώρο",
      counterOfferPrice: "Τιμή αντιπροσφοράς",
      counterOfferDate: "Ημ/νία αντιπροσφοράς",
      /* Contacts */
      myCustomers: "Οι πελάτες μου",
      sellerTaxAwarenessDocumentHover:
        "Φορολογική Ενημερότητα του πωλητή για <br /> μεταβίβαση από την εφορία εισοδήματος",
      efkaSellerInsuranceAwarenessHover:
        "Ασφαλιστική ενημερότητα ΕΦΚΑ του πωλητή για μεταβίβαση <br /> κτισμάτων κατόπιν υποβολής σχετικής αιτήσεως στο αρμόδιο <br /> Υποκατάστημα ΙΚΑ της περιοχής του πωλούμενου ακινήτου.",
      propertyFeeHover:
        "Βεβαίωση από τον Δήμο για Τέλος Ακινήτου Περιουσίας (ΤΑΠ).",
      personalDocumentsHover:
        "Έγγραφα προσωπικών στοιχείων / λογαριασμών /<br /> κοινοχρήστων / μισθωτηρίου / διεύθυνσης οικίας",
      propertyDocuments: "Εγγραφα ακινήτου",
      technicalFeaturesInterior: "Τεχνικά Χαρακτηριστικά & Εσωτερικό",
      informationProfessional: "Πληροφορίες Επαγγελματία",
      address: "Διεύθυνση",
      mobile2: "Κινητό",
      collaboratorCode: "Κωδικός Συνεργάτη",
      websites: "Ιστοσελίδες",
      languages: "Γλώσσες",
      memberfrom: "Μέλος Από",
      awards: "Διακρίσεις",
      studies: "Σπουδές",
      select: "Επιλογή",
      noSelect: "Καμία επιλογή",
      activities: "Δραστηριότητες",
      call: "Κλήση",
      hide: "Aπόκρυψη",
      suggestion: "Υπόδειξη",
      cooperations: "Συνεργασίες",
      noDataAvailable: "Δεν υπάρχουν διαθέσιμα δεδομένα",
      satisfiedService: "Έμεινα ικανοποιηµένος/η απὀ την εξυπηρέτηση",
      associateMyNeeds:
        "Ο συνεργάτης της NewDeal ανταποκρίθηκε στις ανάγκες µου",
      associateKnowledge:
        "Ο συνεργάτης της NewDeal είχε τις γνώσεις για να ανταποκριθεί στις ανάγκες µου",
      associateHonesty:
        "Έμεινα ικανοποιηµένος/η απὀ την ειλικρίνεια του συνεργάτη",
      associateMarketingProcess:
        "Ο συνεργάτης µου εξήγησε την διαδικασία μάρκετινγκ που ακολουθεί η New Deal",
      associatePresentation:
        "Ένιωσα ότι ενηµερώθηκα σωστά από την παρουσίαση του συνεργάτη",
      associateRecommend:
        "Σκοπεύω να συστήσω ewDeal σε κάποιον που χρειάζεται τις υπηρεσίες του",
      at: "στις",
      reviewsReceived: "Αξιολογήσεις που έλαβε",
      reviewsPosted: "Αξιολογήσεις που καταχώρησε",
      mostRecent: "Πιο πρόσφατες",
      ratingHighestToLowest: "Βαθμολογία (Υψηλότερη προς χαμηλότερη)",
      ratingLowestToHighest: "Βαθμολογία (Χαμηλότερη προς Υψηλότερη)",
      dateOfSale: "Ημερομηνία Πώλησης",
      months: "μήνες",
      years: "χρόνια",
      viewTeamRatings: "Προβολή Αξιολογήσεων ομάδας",
      viewAgentRatings: "Προβολή Αξιολογήσεων Agent",
      oldestSales: "Οι παλαιότερες πωλήσεις",
      latestSales: "Οι πιο πρόσφατες πωλήσεις",
      sortAscending: "Ταξινόμηση κατά τιμή φθίνουσα",
      sortDescending: "Ταξινόμηση κατά τιμή αύξουσα",

      lessPersons: "Λιγότερα άτομα",
      morePersons: "Περισσότερα άτομα",
      lessInfo: "Λιγότερες πληροφορίες",
      moreInfo: "Περισσότερες πληροφορίες",
      lessCharacteristics: "Λιγότερα χαρακτηριστικά",
      moreCharacteristics: "Περισσότερα χαρακτηριστικά",
      lessDistances: "Λιγότερες αποστάσεις",
      moreDistances: "Περισσότερες αποστάσεις",
      viewGroupRealEstates: "Προβολή Ακινήτων ομάδας",
      viewAgentRealEstates: "Προβολή Ακινήτων Agent",
      forSale: "Προς Πώληση",
      forRent: "Προς Ενοικίαση",
      characteristics: "Χαρακτηριστικά",
      partnerStatus: "Κατάσταση συνεργάτη",
      briefCurriculumVitae: "Συνοπτικό Βιογραφικό σημείωμα",
      partnerDocuments: "Έγγραφα Συνεργάτη",
      moreFrequentCommunication: "Συχνότερη επικοινωνία",
      myNetwork: "Το δίκτυο μου",
      panoHintText:
        "Η ανάρτηση πανό εξασφαλίζει 100% προμήθεια. Ανεβάστε το εδώ ως απόδειξη.",
      service: "Υπηρεσία",
      plan: "Πλάνο",
      submitted: "Υποβλήθηκε",
      renewal: "Ανανέωση",
      payment: "Εξόφληση",
      changePlan: "Αλλαγή πλάνου",
      addComment: "Θέλετε να προσθέσετε κάποιο σχόλιο;",
      createJointAccount: "Δημιουργία κοινού λογαριασμού",
      brokerFeeAccept:
        "Αποδέχομαι τους όρους επιμερισμού μεσιτικής αμοιβής όπως αυτοί περιγράφονται στα έντυπα και στον κανονισμό γραφείου που έχω υπογράψει",
      requestSubmitted: "Το αίτημα σας καταχωρήθηκε!",
      receiveActivation: "Θα λάβετε σύντομα ειδοποίηση ενεργοποίησης.",
      partnershipRequest: "Αίτημα Διαρκούς Συνεργασίας (partnership)",
      collaboratorCodeRealEstate:
        "Ο κωδικός συνεργάτη είναι ο μοναδικός 3ψήφιος κωδικός που θα υπάρχει μπροστά από κάθε καταχώρηση ακίνητου.",
      newDemandRegistration: "Καταχώρηση Νέας Ζήτησης",
      editDemandRegistration: "Επεξεργασία Ζήτησης",
      addNew: "Προσθήκη νέου",
      interested: "Ενδιαφερόμενος",
      assigned: "Ανατέθηκε",
      assign: "Ανάθεση",
      requestStatus2: "Κατάσταση Ζήτησης",
      requestDate2: "Ημερομηνία Αιτήματος",
      nextFollowUp: "Επόμενο Follow-Up",
      requestOriginated: "Η Ζήτηση προήλθε από",
      commentsInterested: "Προαιρετικά σχόλια για την πηγή του ενδιαφερόμενου",
      describeDemand: "Δώστε μια περιγραφή της ζήτησης",
      budget: "Προϋπολογισμός",
      toDt2: "έως",
      requestedRealEstate: "Ακίνητο που ζήτησε ο ίδιος",
      enterRealEstateCode: "Πληκτρολογήστε κωδικό ακίνητου",
      originData: "Στοιχεία Πηγής",
      requestCameFrom: "Η Ζήτηση προήλθε από",
      optionalComments: "Προαιρετικά σχόλια για την πηγή του ενδιαφερόμενου",
      realEstateFeatures: "Χαρακτηριστικά Ακινήτου",
      demandDescription: "Δώστε μια περιγραφή της ζήτησης",
      sqm: "τ.μ.",
      otherFeatures2: "Λοιπά Χαρακτηριστικά",
      yearFrom: "Έτος από",
      yearTo: "Έτος έως",
      contact: "Επικοινωνία",
      negotiation: "Διαπραγμάτευση",
      withdrawal: "Απόσυρση",
      inert: "Αδρανές",
      newInterestedContact: "Προσθήκη μιας νέας επαφής Ενδιαφερόμενου ",
      addDemand: "Προσθήκη Ζήτησης",
      youHave: "Έχετε",
      youHave2: "Έχετε",
      newRequests: "νέα αιτήματα",
      facade2: "Πρόσοψη",
      build: "Κτίζει",
      acres: "Στρέμματα",
      acres2: "Στρέμ",
      responsible: "Υπεύθυνος",
      companyDoc: "Έγγραφα Εταιρείας",
      newActivity: "Νέα Δραστηριότητα",
      suggestionRegistration: "Καταχώρηση Υπόδειξης",
      suggestionDocument: "Έγγραφο Υποδείξεων",
      esignCode: "Κωδικός esign",
      nameInterestedParty: "Όνομα ενδιαφερόμενου",
      suggestionStatus: "Κατάσταση Υπόδειξης",
      scheduled: "Προγραμματισμένο",
      scheduled2: "Προγραμματισμένη",
      cancelled: "Ακυρωμένη",
      completed: "Πραγματοποιήθηκε",
      demands: "Ζητήσεις",
      expired2: "Έληξε",
      expiresIn: "Λήγει σε",
      weeks: "εβδομάδες",
      address2: "Διεύθυνση 2",
      demand: "Ζήτηση",
      assignment: "Ανάθεση",
      appointment: "Ραντεβού",
      newCallRegistration: "Καταχώρηση Νέας Κλήσης",
      callFrom: "Κλήση από",
      quickSearch: "Γρήγορη αναζήτηση",
      assignmentPartner: "Συνεργάτης για Ανάθεση",
      quickPartnerSearch: "Γρήγορη αναζήτηση συνεργάτη",
      availableForTable2: "Διαθέσιμο προς",
      priceSqft: "Τιμή / Εμβαδόν",
      renovation: "Ανακαίνηση",
      easy: "Εύκολο",
      normal: "Κανονικό",
      urgent: "Επείγων",
      callΙmportance: "Σημαντικότητα κλήσης",
      interested2: "Ενδιαφερόμενου",
      still: "ακόμα",
      relatedRequest: "Σχετική Ζήτηση",
      suggestionDocument2: "Έγγραφο Υπόδειξης",
      dateAndTime: "Ημ/νία & Ώρα",
      submittedFrom: "Καταχωρήθηκε από",
      dateOfCreation: "Ημ/νία Δημιουργίας",
      lastEditedDate: "Ημ/νία Τελευταίας Επεξεργασίας",
      addContact: "Προσθήκη επαφής",
      contactType: "Τύπος επαφής",
      companyBrokers: "Brokers Εταιρείας",
      accountInformation: "Πληροφορίες λογαριασμού",
      curriculumVitae: "Βιογραφικό Σημείωμα",
      appearsToYou: "εμφανίζεται μόνο σε εσάς",
      companyTeamsAgents: "Οι ομάδες και οι agents της εταιρείας",
      contactInformation: "Πληροφορίες επαφής",
      addContactFrom: "Προσθήκη επαφής από",
      accountInCrm: "Λογαριασμός στο CRM",
      otherCompanyBrokers: "Άλλοι Brokers Εταιρείας",
      secretariat: "Γραμματεία",
      search2: "Ψάχνει",
      demandAssignment: "Ανάθεση Ζήτησης",
      demandHandlePartner: "Επιλέξτε έναν συνεργάτη που μπορεί να αναλάβει την Ζήτησης",
      confirmation: "Επιβεβαίωση",
      interesting: "Ενδιαφέρον",
      buy: "Αγορά",
      demandInformation: "Μπορείτε να ρωτήσετε περισσότερες πληροφορίες τον ενδιαφερόμενο. Καταχωρήστε την αξιολόγηση σας.",
      inactiveDemand: "Η Ζήτηση ορίστηκε σε κατάσταση αδράνειας επειδή έχει να λάβει ενημέρωση πολύ καιρό (3 μήνες χωρίς επαφή). Ρωτήστε τον πελάτη αν έχει αλλάξει γνώμη για το ακίνητο που ενδιαφέρεται.",
      demandOriginated: "Από πού προήλθε η ζήτηση;",
      source: "Πηγή",
      direct: "Απευθείας",
      directSource: "Άμεση Πηγή",
      addedBy: "Προστέθηκε από",
      descriptionSource: "Περιγραφή πηγής",
      details: "Λεπτομέριες",
      contactPerson: "Άτομο επικοινωνίας",
      modified: "Τροποποιήθηκε",
      demandCode: "Κωδικός Ζήτησης",
      financialCriteria: "Οικονομικά κριτήρια",
      priceFrom: "Τιμή από",
      priceTo: "Τιμή προς",
      interestedEvaluation: "Αξιολόγηση Ενδιαφερόμενου",
      enterPrice: "Πληκτρολογήστε ποσό",
      demandEvaluation: "Αξιολόγηση Ζήτησης",
      moreQuestions: "Περισσότερες Ερωτήσεις",
      demandRegistration: "Καταχώρηση Ζήτησης",
      lessQuestions: "Λιγότερες Ερωτήσεις",
      createNewOffer: "Δημιουργία νέας προσφοράς",
      choiceSuggestions: "Επιλογή ακινήτων Υποδειξων",
      realEstateCodeSearch: "Αναζήτηση Ακινήτου με κωδικό",
      offerStatus: "Κατάσταση Προσφοράς",
      typeOfOffer: "Είδος προσφοράς",
      offerDate2: "Ημερομηνία Προσφοράς",
      ownerCounterproposal: "Υπάρχει αντιπρόταση από τον ιδιοκτήτη;",
      counterproposalPrice: "Τιμή Αντιπρότασης",
      counterproposalDate: "Ημερομηνία Αντιπρότασης",
      interestedParties: "Ενδιαφερόμενοι",
      customerSearch: "Αναζήτηση Πελάτη",
      responsibleSearch: "Αναζήτηση υπεύθυνου",
      demandType: "Τύπος Ζήτησης",
      dateForm: "ΗΗ | ΜΜ | ΧΧΧΧ",
      proposedRealEstate: "Κωδικός Προτεινόμενου Ακινήτου",
      realEstateSpecifications: "Προδιαγραφές Ακινήτου",
      realEstateType: "Τύπος Ακινήτου",
      businessType: "Τύπος Επιχείρησης",
      realEstateLocation: "Τοποθεσία Ακινήτου",
      demandContactInformation: "Στοιχεία επικοινωνίας Ζήτησης",
      byEmail: "Με email",
      bySms: "Με SMS",
      exportAsLink: "Εξαγωγή ως LINK",
      correspondinglyRealEstates: "Αντίστοιχα Ακίνητα",
      furnished2: "Επιπλωμένα",
      role2: "Ρόλος",
      profile: "Προφίλ",
      account: "Λογαριασμός",
      subscriptionsCoins: "Συνδρομές & Coins",
      publicInformation: "Δημόσιες Πληροφορίες",
      uploadYourPhoto: "Ανεβάστε φωτογραφία σας",
      photoAtLeast: "Η φωτογραφία πρέπει να είναι τουλάχιστον",
      message: "Μήνυμα",
      underground: "Υπόγειο",
      backToList: "Επιστροφή στην λίστα",
      createAnotherProperty: "Καταχώρησε άλλο ακίνητο",
      propertyPublished: "Το ακίνητο σου δημοσιεύτηκε και έχει κωδικό",
      viewProperty: "Δες το ακίνητο",
      floorApartment: "Οροφοδιαμέρισμα",
      cooperation: "Συνεργασία",
      morePlotInfo: "Περισσότερα στοιχεία οικοπέδου",
      dimensions: "Διαστάσεις πλάτος/μήκος",
      coverageRatio: "Συντελεστής κάλυψης",
      structureFactor: "Συντελεστής Δόμησης",
      benefits: "Παροχές",
      loadUserCoins: "Φόρτιση coins χρήστη",
      reasonCoins: "Τίτλος (λόγος) προσθήκης coins",
      accountInfoSecur: "Πληροφορίες & Ασφάλεια Λογαριασμού",
      partnerStatus2: "Κατάσταση Συνεργάτη",
      oldPassword: "Παλιός Κωδικός Πρόσβασης",
      confPass2: "Επαλήθευση Κωδικού Πρόσβασης",
      passwordChange: "Αποθήκευση Αλλαγής Κωδικού",
      loadCoins: "Φόρτιση coins",
      loadManageCoins: "Φόρτιση, ιστορικό και διαχείριση των coins σας.",
      myCoins: "Τα coins μου",
      availableCoins: "Διαθέσιμα coins",
      notSamePasswords: "Οι κωδικοί πρόσβασης δεν ταιριάζουν",
      position: "Θέση",
      memberTitle: "Τίτλος Member",
      deletedAssociate: "Μόλις διαγράψατε τον συνεργάτη",
      deleteContact: "Πρόκειται να διαγράψετε μία επαφή.",
      deletedContact: "Μόλις διαγράψατε μία επαφή.",
      passwordChange2: "Επιτυχής αλλαγή κωδικού.",
      favoriteContacts: "Αγαπημένες επαφές",
      cost: "Κόστος",
      total: "Σύνολο",
      buyCoins: "Αγορά Coins",
      buyOver: "Αγόρασε πάνω από",
      addOfficeAdminBrokers: "Προσθήκη Office Admin / Brokers",
      addNewAdmin: "Προσθήκη νέου διαχειριστή",
      organizationArea: "Περιοχή Οργάνωσης",
      organizationWebsite: "Ιστοσελίδα Οργανισμού",
      secretaryEmail: "Email Γραμματείας",
      coinsPublish: "Τα coins (νομίσματα) σας δίνουν την δυνατότητα να δημοσιεύσετε τα ακίνητα σας σε διάφορους παρόχους (providers).",
      documents: "Έγγραφα",
      companyLogo: "Ανεβάστε το λογότυπο της εταιρείας",
      searchContactName: "Αναζητήστε όνομα επαφής",
      addNewContact: "Προσθήκη Νέας Επαφής ή Λογαριασμού",
      chooseCategory: "Επιλέξτε κατηγορία",
      selectNetwork: "Επιλέξετε το δίκτυο",
      companySelection: "Επιλογή εταιρείας",
      itsAbout: "Πρόκειται για",
      teamManager: "διευθυντής ομάδας",
      costumerOwner: "Ο πελάτης είναι ιδιοκτήτης ή ενδιαφερόμενος;",
      stallRole: "Επιλογή ρόλου staff",
      createNewContact: "Δημιουργία νέας επαφής",
      enclosedParking: "Κλειστό Parking",
      undergroundParking: "Υπόγειο Parking",
      pilotParking: "Πιλοτική στάθμευση",
      openParking: "Ανοιχτό Parking",
      assignedTo: "Ανατέθηκε σε",
      bathroom: "Μπάνιο",
      balcony: "Μπαλκόνι",
      sqftBalcony2: "Εμβαδόν Μπαλκονιού",
      kitchen: "Κουζίνα",
      livingRoom: "Σαλόνι",
      active2: "Ενεργός",
      inactive2: "Ανενεργός",
      semiActive2: "Ημιενεργός",
      personalInformation: "Προσωπικά στοιχεία",
      myProfile: "Το προφίλ μου",
      editProfile: "Επεξεργασία προφίλ",
      myAccount: "Ο λογαριασμός μου",
      coinsSubscriptions: "Coins & Συνδρομές",
      myEstates: "Δικά μου ακίνητα",
      favorites: "Αγαπημένα",
      callManager: "Διαχειριστικό Κλήσεων",
      callCenter: "Τηλεφωνικό Κέντρο",
      myAgenda: "Η Αντζέντα μου",
      messageCenter: "Κέντρο Μηνυμάτων",
      notificationCenter: "Κέντρο Ειδοποιήσεων",
      administrative: "Διαχειριστικό",
      accounts: "Λογαριασμοί",
      approvingScreen: "Οθόνη έγκρισης η απόρριψης νέων χρηστών στο σύστημα.",
      nothingHere: "Δεν υπάρχει τίποτα εδώ! Μόνο εμείς!",
      notRightSearch: "Είσαι βέβαιος ότι έκανες σωστή αναζήτηση;",
      serverProblem: "Ωχ! Πρόβλημα με τον server!",
      pageRefresh: "Κάντε ανανέωση την σελίδα ή αλλιώς επικοινωνήστε μαζί μας.",
      editFilters: "Επεξεργασία Φίλτρων",
      sendError: "Αποστολή Προβήματος",
      contactEstates: "Ακίνητα που σχετίζονται με αυτήν την επαφή",
      weMatched: "Αντιστοιχίσαμε",
      properties: "ακίνητα | ακίνητα",
      contactDemands: "Ζητήσεις που σχετίζονται με αυτήν την επαφή",
      sentEstates: "Ακίνητα που στάλθηκαν",
      curriculumVitae2: "Βιογραφικό",
      addFile: "Προσθήκη αρχείου",
      replace: "Αντικατάσταση",
      specialization: "Εξειδίκευση",
      atLocation: "στην περιοχή | στις περιοχές",
      maxLocations: "Μέγιστος αριθμός περιοχών",
      addedLocation: "Ήδη υπάρχουσα τοποθεσία",
      areasMaxSelect: "Επιλέξτε έως τρεις (3) περιοχές στις οποίες είστε ειδικός.",
      writeCv: "Γράψτε ένα βιογραφικό σημείωμα που θα σας βοηθήσει να σας εμπιστευτεί κάποιος υποψήφιος πελάτης.",
      cooperationApproved: "Το αίτημα συνεργασίας εγκρίθηκε!",
      availableEstates: "Διαθέσιμα Aκίνητα",
      soldEstates: "Πουλημένα Ακίνητα",
      admin: "Διαχειριστής",
      associateCandidate: "Υποψήφιος Συνεργάτης",
      recommendedEstate: "Προτεινόμενο Ακίνητο",
      communicationWasMade: "Έγινε Επικοινωνία",
      dealOrWithdrawal: "Deal ή Απόσυρση",
      newAll: "Νέα",
      goodMatch: "Καλή αντιστοιχία",
      noMatch: "Χωρίς αντιστοιχία",
      itHasBeenApproved: "Έχει εγκριθεί",
      notApproved: "Δεν έχει εγκριθεί",
      relevantDemands: "Βρες σχετικές ζητήσεις",
      completed2: "Ολοκληρώθηκε",
      demandType2: "Είδος Ζήτησης",
      lastUpdate: "Ημερομηνία τελ. τροποποίησης",
      priceSqM2: "Τιμή ανά τ.μ.",
      creationDate: "Ημερομηνία δημιουργίας",
      bedroomsShort: "Υ/Δ",
      clientsRequests: "Ζητήσεις από πελάτες μου",
      networkRequests: "Ζητήσεις εντός του δικτύου μου",
      priceTo2: "Τιμή έως",
      officeRequests: "Ζητήσεις εντός του γραφείου μου",
      findDemands: "Βρείτε εύκολα ποιες ζητήσεις αντιστοιχούν με δικά σας ακίνητα. Για να σας εμφανιστούν οι ζητήσεις που σας ενδιαφέρουν, πατήστε πάνω στο πράσινο πλήκτρο και περιμένετε μέχρι να εμφανιστούν τα αποτελέσματα.",
      valuationPoints: "Η αξιολόγηση είναι ένας εύκολος τρόπος ένδειξης αξιοπιστίας μιας ζήτησης. 0 = δεν είναι αξιόπιστη ζήτηση, 5 = πολύ αξιόπιστη ζήτηση",
      ago2: "Πριν",
      in2: "Σε",
      demandDelete: "Πρόκειται να διαγράψετε την Ζήτηση με κωδικό",
      featuresFilters: "Φίλτρα Χαρακτηριστικών",
      selectUrbanZone: "Επιλέξτε πολεοδομική ζώνη",
      demandRealEstate: "Ζήτηση με ακίνητο",
      generalDemands: "Γενικές Ζητήσεις",
      searchComment: "Σχόλια ζήτησης",
      patnerSearch: "Αναζήτηση Συνεργάτη",
      January: "Ιανουάριος",
      February: "Φεβρουάριος",
      March: "Μάρτιος",
      April: "Απρίλιος",
      May: "Μάιος",
      June: "Ιούνιος",
      July: "Ιούλιος",
      August: "Αύγουστος",
      September: "Σεπτέμβριος",
      October: "Οκτώβριος",
      November: "Νοέμβριος",
      December: "Δεκέμβριος",
      customerTelSearch: "Αναζήτηση πελάτη με όνομα ή τηλέφωνο",
      allRelatedProperties: "Όλα τα αντίστοιχα ακίνητα",
      myProperties: "Τα ακίνητα μου",
      evaluationPending: "Εκρεμμεί Αξιολόγηση",
      inactivity: "Αδράνεια",
      outgoingDemand: "Εξερχόμενη Ζήτηση",
      dateDemand: "Ημερομηνία ζήτησης",
      library: "Βιβλιοθήκη",
      noDemandsIntersted:"Δεν βρέθηκαν αξιολογήσεις για τον ενδιαφερόμενο.",
      interestedAssessment: "Αξιολογηση Ενδιαφερόμενου",
      interestedAssessment2: "Αξιολογηση Ενδιαφερομενου",
      giftCoins:"coins και πάρε δώρο ακόμα",
      toEachProvider:"σε κάθε provider",
      completion: "Ολοκλήρωση",
      totalOrder:"Το σύνολο της παραγγελίας σας",
      acceptThem: "Αποδέχομαι τους",
      totalAmount: "Συνολικό ποσό",
      terms: "όρους",
      ofTheTransaction: "της συναλλαγής",
      universitySchool: "Πανεπιστήμιο / Σχολή",
      specialty: "Ειδικότητα",
      mapPartners: "Στον χάρτη βλέπετε την περιοχή δραστηριότητας του συνεργάτη και τα ακίνητα που έχει αναλάβει",
      otherOperators: "Άλλοι Διαχειριστές",
      addNewBroker: "Προσθήκη νέου broker",
      addNewSecretary: "Προσθήκη νέας γραμματείας",
      addNewAgent: "Προσθήκη νέου συνεργάτη (Agent Pro)",
      addNewCustomer: "Προσθήκη νέου πελάτη",
      addNewMember: "Προσθήκη νέου member",
      addNewIfAssociate: "Προσθήκη νέου υποψήφιου συνεργάτη",
      addNewCompany: "Προσθήκη Νέας Εταιρείας",
      addNewContact2: "Προσθήκη νέας επαφής",
      paymentMethod: "Διάλεξε τρόπο πληρωμής",
      creditCard: "Πιστωτική Κάρτα",
      debitCard: "Χρεωστική Κάρτα",
      depositAccount: "Κατάθεση σε λογαριασμό",
      cashCompany: "Με μετρητά στην εταιρεία που ανήκετε",
      newTag: "Δημιουργία νέου tag",
      savedTags: "Επιλογή αποθηκευμένων tags",
      teamInformation: "Πληροφορίες Ομάδας",
      agentLeader: "Ο agent είναι Leader της ομάδας",
      memberInterested: "Ενδιαφέρομαι να γίνω μέλος της ομάδας",
      critics: "Κριτικές",
      sold2: "Επωλήθη",
      visitOffice: "Επικοινωνήστε με την εταιρεία σας ή επισκεφτείτε το γραφείο σας",
      creditedAccount: "ώστε να εξοφληθεί και να πιστωθούν στον λογαριασμό σας",
      orderCoins: "άμεσα τα coins της παραγγελίας σας",
      profileBack: "Επιστροφή στο προφίλ",
      contactSecretary: "Επικοινωνία με γραμματεία",
      orderRegistered: "Η παραγγελία σας καταχωρήθηκε",
      commissionBanner: "Η ανάρτηση πανό εξασφαλίζει 100% προμήθεια. Ανεβάστε το εδώ ως απόδειξη.",
      accept:"Αποδοχή",
      callRegistration:"καταχώρηση κλήσης",
      callType: "είδος κλήσης",
      priority: "προτεραιοτητα",
      typeCall: "τύπος κλήσης",
      callCode: "Κωδικός Κλήσης",
      systemDate: "Ημ/νια προσθήκης στο σύστημα απο",
      deletedContacts: "Μόνο διεγραμμένες επαφές",
      myDocuments:"Τα έγγραφα μου",
      agreements:"Συμφωνητικά",
      notReceiveNews: "Δεν λαμβάνει newsletter",
      assignmentTo:"Ανάθεση σε",
      personalNewDeal: "Έχει προσωική σελίδα newdeal",
      contactActiveRequests:"Η επαφή έχει ενεργές ζητήσεις",
      contactAccount: "Η επαφή έχει λογαριασμό χρήστη",
      contactOwner: "Η επαφή έχει ακίνητα",
      all2: "Όλες",
      works: "Εργασίες",
      calls: "Κλήσεις",
      networkChoice: "Επιλογή δικτύου",
      allMyClients: "Όλοι οι πελάτες μου",
      allTheRoles: "Όλοι οι ρόλοι",
      allNetworks: "Όλα τα δίκτυα",
      allRequests: "Όλα τα αιτήματα",
      outgoingRequest: "Εξερχόμενο Αίτημα",
      incomingRequest: "Εισερχόμενο Αίτημα",
      callsMessages: "Κλήσεις, μηνύματα, διαχειριστικό, reports, κέντρο ειδοποιήσεων",
      cooperationBetween: "Συνεργασία μεταξύ",
      professionalAcademic: "Επαγγελματική & Ακαδημαϊκη Εκπαίδευση στο Real Estate",
      contactRegistered: "Η επαφή καταχωρήθηκε",
      howContinue: "Πως θα Θέλατε να συνεχίσετε",
      entryEstate: "Καταχώρηση Ακινήτου",
      oneMoreThing: "Έμεινε κάτι ακόμα",
      transferEstate: "Μεταφορά ακινήτων και πελατών σε άλλον λογαριασμό",
      allCustomersTranfer: "Όλοι οι πελάτες, ιδιοκτήτες και ακίνητα θα περάσουν σε άλλον χρήστη. Μετά μπορεί να γίνει ο διαμορισμός τους σε άλλους συνεργάτες",
      transferToSecretary: "Μεταφορά σε λογαριασμό γραμματείας ή admin",
      adminOrSecretary: "Λογαριασμό admin ή γραμματείας",
      transferPatner:"Μεταφορά σε λογαριασμό συνεργάτη (μόνο εταιρείας)",
      partnerAccount: "Λογαριασμό συνεργάτη",
      whoWeAre: "Ποιοι είμαστε",
      tagSearch:"Αναζήτηση με tag",
      profileΙnformation: "Πληροφορίες προφίλ",
      systemAddDate: "Ημ/νια προσθήκης στο σύστημα",
      admins: "Διαχειριστές",
      ownerSell: "Ιδιοκτήτης (Πώληση)",
      ownerRent: "Ιδιοκτήτης (ενοικίαση)",
      ownerConsideration: "Ιδιοκτήτης (αντιπαροχή)",
      interestedPartyBuy: "Ενδιαφερόμενος (Αγορά)",
      interestedPartySell: "Ενδιαφερόμενος (ενοικίαση)",
      interestedConsideration: "Ενδιαφερόμενος (αντιπαροχή)",
      candidatePartners: "Υποψήφιοι Συνεργάτες",
      person: "Άτομο",
      addNewPerson: "Θέλετε να προσθέσετε νέο άτομο",
      otherContact: "Άλλη επαφή",
      demandEdit: "Επεξεργασία Ζήτησης",
      entryDemand: "Καταχώρηση Αξιολόγησης",
      successfullyRequest: "Η Ζήτηση καταχωρήθηκε επιτυχώς!",
      evaluationQuest1: "Πόσο καιρό ψάχνετε για ακίνητο;",
      evaluationQuest2: "Πόσα σπίτια έχετε δει;",
      evaluationQuest3: "Μέχρι πότε θέλετε να αποκτήσετε το ακίνητο σας;",
      evaluationQuest4: "Θα χρησιμοποιήσετε κεφάλαιο σας ή θα θέλατε να εκμεταλλευτείτε και ένα ποσό δανείου;",
      evaluationQuest5: "Έχετε προέγκριση και πόσα;",
      evaluationQuest6: "Ποιό χαρακτηριστικό σας έκανε περισσότερο 'κλικ' (εντύπωση) στην αγγελία που διαβάσατε",
      evaluationQuest7: "Ποιό χαρακτηριστικό του σπιτιού είναι αδιαπραγμάτευτο για εσάς;",
      evaluationQuest8: "Θα πάρετε μόνος την απόφαση για αγορά ή θα χρειαστείτε την γνώμη κάποιου δικού σας;",
      evaluationQuest9: "Υπάρχει κάποιος λόγος που θα θέλατε την συγκεκριμένη περιοχή ή θα μπορούσατε να πάτε και αλλού;",
      evaluationQuest10: "Σας άρεσε κάποιο σπίτι από αυτά είδατε;",
      evaluationQuest11: "Γιατί δεν το αγοράσατε;",
      evaluationQuest12: "Τι δεν σας άρεσε και γιατί;",
      evaluationQuest13: "Το σπίτι που μένετε είναι δικό σας;",
      evaluationQuest14: "Υπάρχει κάποιος συγκεκριμένος λόγος μετακόμισης;",
      evaluationQuest15: "Εάν βρίσκαμε ακίνητο που σας κάνει σήμερα θα το αγοράζατε;",
      evaluationQuest16: "Αυτό πρέπει να πουληθεί προκειμένου να αγοράσετε;",
      evaluationQuest1_1: "1 έως 3 μήνες",
      evaluationQuest1_2: "6 έως 12  μήνες",
      evaluationQuest1_3: "Πάνω από χρόνο",
      evaluationQuest1_4: "ΔΞ/ΔΑ",
      evaluationQuest2_1: "1 έως 20",
      evaluationQuest2_2: "20 έως 50",
      evaluationQuest2_3: "Πάνω από 50",
      evaluationQuest2_4: "ΔΞ/ΔΑ",
      evaluationQuest3_1: "1 έως 3 μήνες",
      evaluationQuest3_2: "6 έως 12 μήνες",
      evaluationQuest3_3: "Πάνω από χρόνο",
      evaluationQuest3_4: "ΔΞ/ΔΑ",
      evaluationQuest4_1: "Δικό μου κεφάλαιο",
      evaluationQuest4_2: "Δάνειο",
      evaluationQuest4_3: "Και τα δύο",
      evaluationQuest4_4: "ΔΞ/ΔΑ",
      evaluationQuest5_1: "Έχω προέγκριση",
      evaluationQuest5_2: "Δεν έχω προέγκριση",
      evaluationQuest5_3: "Δεν θα πάρω δάνειο",
      evaluationQuest5_4: "ΔΞ/ΔΑ",
      evaluationQuest6_1: "Γράψτε μας τον λόγο",
      evaluationQuest7_1: "Γράψτε μας τον λόγο",
      evaluationQuest8_1: "Μόνος μου",
      evaluationQuest8_2: "Μαζί με κάποιον/ους",
      evaluationQuest8_3: "ΔΞ/ΔΑ",
      evaluationQuest9_1: "Ναι υπάρχει λόγος (προσδιορίστε)",
      evaluationQuest9_2: "Θα πήγαινα και άλλου",
      evaluationQuest9_3: "ΔΞ/ΔΑ",
      evaluationQuest10_1: "Ναι",
      evaluationQuest10_2: "Όχι",
      evaluationQuest10_3: "ΔΞ/ΔΑ",
      evaluationQuest11_1: "Είναι ακριβά",
      evaluationQuest11_2: "Δεν έχω δάνειο",
      evaluationQuest11_3: "Δεν βρήκα κάτι που να μου αρέσει",
      evaluationQuest12_1: "Γράψτε μας τον λόγο",
      evaluationQuest13_1: "Ναι",
      evaluationQuest13_2: "Όχι",
      evaluationQuest13_3: "ΔΞ/ΔΑ",
      evaluationQuest14_1: "Ναι",
      evaluationQuest14_2: "Όχι",
      evaluationQuest14_3: "ΔΞ/ΔΑ",
      evaluationQuest14_4: "Γράψτε μας τον λόγο... (προαιρετικά)",
      evaluationQuest15_1: "Ναι",
      evaluationQuest15_2: "Όχι",
      evaluationQuest15_3: "ΔΞ/ΔΑ",
      evaluationQuest16_1: "Ναι",
      evaluationQuest16_2: "Όχι",
      evaluationQuest16_3: "ΔΞ/ΔΑ",
      levelEvaluation: "Αξιολόγηση Επιπέδου",
    },
  },
});
